import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlateModel} from '../../../models/Plate/plate.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FileUploadValidator} from '../../../validators/file-upload-validator';
import {FormUtil} from '../../../utils/form.util';
import {FileType} from '../../../enums/FileType';
import {PlateService} from '../../../services/plate.service';
import {FileRecord} from '../../../models/file/FileRecord';
import {MessageService} from '../../../services/message.service';


@Component({
  selector: 'app-plate-file',
  template: `
    <ng-container *ngIf="plate">
      <h4>{{ header }}</h4>
      <form *ngIf="!readonly && !plate.uploadedFile" [formGroup]="fileForm">
        <app-file-upload [formControlName]="NEW_FILE"
                         (onFileSelect)="saveUpload()">
        </app-file-upload>
      </form>

      <app-file
        *ngIf="plate?.uploadedFile"
        [file]="plate?.uploadedFile"
        (onRemove)="onRemoveFile()"
        [deletable]="deletable">
      </app-file>
    </ng-container>
  `,
  styleUrls: ['./plate-file.component.scss']
})
export class PlateFileComponent implements OnInit {

  @Input() plate: PlateModel;
  @Input() header: string;
  @Input() readonly: boolean;
  @Input() deletable: boolean;
  @Input() fileType: FileType;

  @Output() fileUploaded = new EventEmitter<FileRecord>();
  @Output() fileRemoved = new EventEmitter<void>();

  fileForm: FormGroup;

  protected readonly NEW_FILE = 'newFile';

  constructor(private plateService: PlateService,
              private messageService: MessageService) {}

  ngOnInit(): void {
    this.initFilesForm();
  }

  saveUpload(): void {
    if (this.fileForm.valid && Object.values(this.fileForm.value[this.NEW_FILE])?.length && this.fileType) {
      const documentsForm: FormData = FormUtil.toFormData(this.fileForm.value);
      this.plateService.uploadFileToPlate(this.plate.id, this.fileType, documentsForm)
        .subscribe(resp => {
          if (resp.uploadedFile) {
            this.plate.uploadedFile = resp.uploadedFile;
            this.initFilesForm();
            this.fileUploaded.emit(resp.uploadedFile);
          }
        });
    } else {
      FormUtil.markFormControlsAsTouchedAndDirty(this.fileForm);
      this.messageService.addErrorMessage('Bitte wählen Sie zuerst Dateien aus!');
    }
  }

  onRemoveFile(): void {
    if (this.plate && this.plate.id) {
      this.plateService.removeUploadedFileFromPlate(this.plate.id)
        .subscribe((resp) => {
          if (resp) {
            this.plate.uploadedFile = null;
          }
        });
    }
  }

  private initFilesForm(): void {
    this.fileForm = new FormGroup({
      newFile: new FormControl(null, [Validators.required, FileUploadValidator.maxFileSizeValidator(10000000)])
    });
  }
}
