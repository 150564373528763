import {Injectable} from '@angular/core';
import {MessageService as PrimeNgMessageService} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private primeNgMessageService: PrimeNgMessageService) {
  }

  /**
   * Add a temporary success message with the given content.
   *
   * @param summary to display.
   * @param detail to display.
   */
  addSuccessMessage(detail: string, summary: string = 'Erfolg'): void {
    this.addMessage('success', summary, detail);
  }

  /**
   * Add a temporary info message with the given content.
   *
   * @param summary to display.
   * @param detail to display.
   */
  addInfoMessage(detail: string, summary: string = 'Information'): void {
    this.addMessage('info', summary, detail);
  }

  /**
   * Add a temporary warning message with the given content.
   *
   * @param summary to display.
   * @param detail to display.
   */
  addWarnMessage(detail: string, summary: string = 'Warnung'): void {
    this.addMessage('warn', summary, detail);
  }

  /**
   * Add a sticky error message with the given content.
   *
   * @param summary to display.
   * @param detail to display.
   * @param sticky default true.
   */
  addErrorMessage(detail: string, summary: string = 'Fehler', sticky: boolean = true): void {
    this.addMessage('error', summary, detail, sticky);
  }

  /**
   * Clear all messages or the message with the given key.
   *
   * @param key to clear.
   */
  clear(key: string = null): void {
    this.primeNgMessageService.clear(key);
  }

  private addMessage(severity: string, summary: string, detail: string, sticky: boolean = false): void {
    this.primeNgMessageService.add({severity, summary, detail, sticky});
  }
}
