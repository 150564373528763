import {EnumUtils} from "./EnumUtils";

export enum FileErrorType {
  FILE_PROCESSING_ERROR = 'FILE_PROCESSING_ERROR',
  PATH_ERROR = 'PATH_ERROR'
}

export namespace FileErrorType {

  export function getFileErrorTypeReadable(type: FileErrorType): string {
    if (!type) return ''

    switch (type) {
      case FileErrorType.FILE_PROCESSING_ERROR:
        return 'Dateiverarbeitungsproblem';
      case FileErrorType.PATH_ERROR:
        return 'Fehler beim Öffnen des Pfades';
    }
  }

  export function getEnumByValue(value: string): FileErrorType {
    return EnumUtils.getEnumByValue<FileErrorType>(FileErrorType, 'string', value);
  }

  export function getAll(): FileErrorType[] {
    return EnumUtils.getAll<FileErrorType>(FileErrorType, 'string');
  }

}
