import {IWorkflowStepBase} from "./workflow-step-base.model";
import {IWorkflowStepValidation} from "./workflow-step-validation.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {WorkflowInstanceBaseModel} from "../workflow/workflow-instance-base.model";
import {FileRecord} from "../file/FileRecord";
import {BatchModel} from "../batch/batch.model";
import {BatchType} from "../../enums/BatchType";

export class WorkflowStepLibraryAgilantModel implements IWorkflowStepBase, IWorkflowStepValidation {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  selectedWorkflowInstances: WorkflowInstanceBaseModel[];

  file: FileRecord;
  batches: BatchModel[];

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, file: FileRecord, batches: BatchModel[], isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.selectedWorkflowInstances = workflowInstance?.libraryWorkflowInstances ?? [];
    this.file = file;
    this.batches = batches;
  }

  isValid(): boolean {
    return this.file && this.batches?.filter(batch => batch.id).length ===  BatchType.getAllForAgilant().length;
  }
}
