import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {AuthGuard} from './guards/auth.guard';
import {RoleGuard} from './guards/role.guard';
import {Role} from './enums/Role';
import {ArchiveComponent} from './pages/archive/archive.component';
import {BatchesComponent} from './pages/batches/batches.component';
import {Dashboard96384Component} from "./pages/dashboard/dashboard-96-384.component";
import {DashboardLibraryComponent} from "./pages/dashboard/dashboard-library.component";
import {ErrorComponent} from "./pages/error/error.component";

const routes: Routes = [
  {
    path: 'dashboard-96-384',
    component: Dashboard96384Component,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-library',
    component: DashboardLibraryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {role: Role.HLAADMIN}
  },
  {
    path: 'batches',
    component: BatchesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'archive',
    component: ArchiveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {path: '**', redirectTo: 'dashboard-96-384'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
