import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {FileService} from "../../../services/file.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {
  faTimes = faTimes;

  @Input() file: any;
  @Input() maxNameLength = 99999999;
  @Input() deletable = false;
  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();

  constructor(public fileService: FileService) { }

  ngOnInit(): void {
  }

  downloadFile(): void {
    if (this.file.id) {
      const fileName = this.file.name ? this.file.name : this.file.filename;
      this.fileService.downloadFile(this.file.id, fileName);
    }
  }

}
