import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewBatchDialogComponent } from './new-batch-dialog.component';
import {DialogModule} from "primeng/dialog";
import {InputModule} from "../../input/input.module";
import {ReactiveFormsModule} from "@angular/forms";
import {CustomPipeModule} from "../../../pipes/custom-pipe.module";



@NgModule({
  declarations: [
    NewBatchDialogComponent
  ],
  exports: [
    NewBatchDialogComponent
  ],
    imports: [
        CommonModule,
        DialogModule,
        InputModule,
        ReactiveFormsModule,
        CustomPipeModule
    ]
})
export class NewBatchDialogModule { }
