import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-state-update-with-comment-dialog',
  templateUrl: './state-update-with-comment-dialog.component.html',
  styleUrls: ['./state-update-with-comment-dialog.component.scss']
})
export class StateUpdateWithCommentDialog implements OnInit {
  @Input() showStateUpdateDialog = false;
  @Input() form: FormGroup;
  @Input() header: string;
  @Input() saveButtonText: string;
  @Input() message: string;
  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() onSaveChanges = new EventEmitter<FormGroup>();
  faTimes = faTimes;
  faCheck = faCheck;

  constructor() {
  }

  ngOnInit(): void {
  }

  onCloseDialog() {
    this.closeDialog.emit(false);
    this.form = null;
  }

  saveChanges() {
    if (this.form.valid) {
      this.onSaveChanges.emit(this.form);
      this.onCloseDialog();
    }
  }
}
