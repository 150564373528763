import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  WorkflowStepLibraryPostSequencingModel
} from 'src/app/models/workflow-step/workflow-step-library-post-sequencing.model';
import { FormUtil } from 'src/app/utils/form.util';
import { WorkflowStepUtils } from 'src/app/utils/workflow-step.utils';
import { Globals } from '../global/globals';
import { FileRecord } from '../models/file/FileRecord';

@Injectable({
  providedIn: 'root'
})
export class WorkflowInstanceStepPostSequencingService {

  private readonly baseUri: string;

  constructor(private http: HttpClient,
              private globals: Globals) {
    this.baseUri = this.globals.backendUri + '/workflow-instance-steps/post-sequencing';
  }

  manuallyAddFile(workflowInstanceStepId: number, fileList: FileList): Observable<WorkflowStepLibraryPostSequencingModel> {
    const formData = FormUtil.createFormDataFromFirstFile(fileList);
    // @ts-ignore will always be an instance of WorkflowStepLibraryPostSequencingModel
    return this.http.post<FileRecord>(`${this.baseUri}/${workflowInstanceStepId}`, formData)
      .pipe(map(WorkflowStepUtils.initWorkflowStepFromData));
  }

}
