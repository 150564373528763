import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {WorkflowInstanceDtoModel} from "../../../models/workflow/workflow-instance-dto.model";
import {WorkflowInstanceService} from "../../../services/workflow-instance.service";
import {MessageService} from "../../../services/message.service";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {SelectItem} from "../../../models/select-item.model";
import {Workflow} from "../../../enums/Workflow";

@Component({
  selector: 'app-new-workflow-dialog',
  templateUrl: './new-workflow-dialog.component.html',
  styleUrls: ['./new-workflow-dialog.component.scss']
})
export class NewWorkflowDialogComponent implements OnInit {

  @Input() showWorkFlowDialog = false;
  @Input() workflowInstanceDto: WorkflowInstanceDtoModel;
  @Input() isForLibrary: boolean = false;

  optionsWorkflows: SelectItem[];
  libraryTypeOptions: SelectItem[];
  form: FormGroup;

  faTimes = faTimes;

  @Output() closeDialog = new EventEmitter<boolean>();

  constructor(private workflowInstanceService: WorkflowInstanceService,
              private formBuilder: FormBuilder,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.initForm(this.workflowInstanceDto);
    this.optionsWorkflows = Workflow.getCreateWorkflowOptions();
    this.libraryTypeOptions = Workflow.getCreateLibraryOptions();
  }


  initForm(workflowInstance: WorkflowInstanceDtoModel) {
    this.form = this.formBuilder.group({
      id: [workflowInstance.id],
      workflow: [workflowInstance.workflow, [Validators.required]]
    });
  }

  submitChanges() {
    const workflow: WorkflowInstanceDtoModel = this.form.getRawValue();

    if (this.isForLibrary) {
      this.workflowInstanceService.createNewLibrary(workflow).subscribe({
        next: (resp) => {
          this.workflowInstanceService.refreshNeed$.next(true);
          this.messageService.addSuccessMessage('Library wurde erfolgreich angelegt!');
        },
        error: (error) => {
          console.log(error);
        }
      })
    } else {
      this.workflowInstanceService.createWorkFlowInstance(workflow).subscribe({
        next: (resp) => {
          this.workflowInstanceService.refreshNeed$.next(true);
          this.messageService.addSuccessMessage('Workflow wurde erfolgreich angelegt!');
        },
        error: (error) => {
          console.log(error);
        }
      });
    }

    this.showWorkFlowDialog = false;
  }

  onCloseDialog() {
    this.closeDialog.emit(false);
  }
}
