import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PositionModel} from "../../models/Plate/position.model";
import {PositionStatus} from "../../enums/PositionStatus";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PositionService} from "../../services/position.service";
import {WorkflowDetailClientService} from "../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {faMessage} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-position-detail',
  templateUrl: './position-detail.component.html',
  styleUrls: ['./position-detail.component.scss']
})
export class PositionDetailComponent {
  PositionStatus = PositionStatus;
  faMessage = faMessage;

  @Input() position: PositionModel;
  @Input() isReadonly: boolean = false;

  @Input() showBloodDrawNumber: boolean = false;
  @Input() showChangeStateButton: boolean = false;
  @Input() showMeasurement: boolean = false;
  @Input() showState: boolean = false;
  @Input() showDnaWater: boolean = false;
  @Input() showPipettingStatus: boolean = false;

  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() onMarkasNotOk = new EventEmitter<PositionModel>();

  showConfirmDialog = false;
  form: FormGroup;
  header: string;
  saveButtonText: string;
  message: string;

  constructor(private formBuilder: FormBuilder,
              private positionService: PositionService,
              private workflowDetailClientService: WorkflowDetailClientService) {
  }

  onCloseDialog(event: boolean) {
    this.showConfirmDialog = false;
  }

  changePositionState() {
    this.initForm(this.position);
    this.initText()
    this.showConfirmDialog = true;
  }

  initForm(position: PositionModel) {
    if (position.cutoffStatus) {
      this.form = this.formBuilder.group({
        id: [position.id, [Validators.required]],
        status: [position.cutoffStatus],
        comment: [position.comment, Validators.maxLength(1000)]
      })
    } else if (position.pipettingStatus) {
      this.form = this.formBuilder.group({
        id: [position.id, [Validators.required]],
        status: [position.pipettingStatus],
        comment: [position.comment, Validators.maxLength(1000)]
      })
    }
  }

  private initText(): void {
    if (this.position?.cutoffStatus === PositionStatus.OK || this.position?.pipettingStatus === PositionStatus.OK) {
      this.header = 'Position als Nicht OK setzen';
      this.message = 'Möchten Sie die Position als Nicht OK setzen?';
      this.saveButtonText = 'Nicht OK setzen';
    } else if (this.position?.cutoffStatus === PositionStatus.NOT_OK || this.position?.pipettingStatus === PositionStatus.NOT_OK) {
      this.header = 'Position als OK setzen';
      this.message = 'Möchten Sie die Position als OK setzen?';
      this.saveButtonText = 'OK setzen';
    }
  }

  updateState(form: FormGroup) {
    let positionStateToUpdate = form.getRawValue();
    if (positionStateToUpdate && positionStateToUpdate.id && positionStateToUpdate.status) {
      if (positionStateToUpdate.status === PositionStatus.OK) {
        positionStateToUpdate.status = PositionStatus.NOT_OK;
      } else {
        positionStateToUpdate.status = PositionStatus.OK;
      }
      this.positionService.updatePositionState(positionStateToUpdate)
        .subscribe({
          next: (resp: PositionModel) => {
            // update the position model here but do not change the reference
            this.position.status = resp.status;
            this.position.pipettingStatus = resp.pipettingStatus;
            this.position.cutoffStatus = resp.cutoffStatus;
            this.position.comment = resp.comment;
            this.position.updatedBy = resp.updatedBy;
          },
          error: (e) => {
            console.log(e);
          }
        })
    }
  }

  getPositionStatus(): PositionStatus {
    if (this.position) {
      if (this.showPipettingStatus) return this.position.pipettingStatus;
      if (this.showMeasurement) return this.position.cutoffStatus;
      return this.position.status;
    }
    return null;
  }

}
