import {UnsubscribeBaseComponent} from "./unsubscribe-base.component";
import {Component} from "@angular/core";
import {SortModel} from "../models/sort.model";
import {SortEvent} from "primeng/api";

@Component({
  template: ''
})
export abstract class PaginationBaseComponent extends UnsubscribeBaseComponent {

  totalElements: number;
  pageSize: number = 15;
  sortField: SortModel = new SortModel();
  first = 0; // needs reset the table paginator

  isLoading: boolean = false;

  abstract changePage(event: any): void;
  abstract onSort(sortEvent: SortEvent): void;

}
