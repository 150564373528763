import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputModule} from "../input/input.module";
import {PositiveNegativeControlSelectionComponent} from "./positive-negative-control-selection.component";
import {CustomPipeModule} from "../../pipes/custom-pipe.module";


@NgModule({
  declarations: [
    PositiveNegativeControlSelectionComponent
  ],
  exports: [
    PositiveNegativeControlSelectionComponent
  ],
  imports: [
    CommonModule,
    InputModule,
    CustomPipeModule
  ]
})
export class PositiveNegativeControlSelectionModule {
}
