<app-workflow-step-layout [infoWidth]="'44rem'">
  <ng-container info>
    <div>

      <div style="margin-bottom: 2rem">
        <h3>Sample Sheet</h3>
        <div class="sample-sheet">
          <app-file [file]="workflowStep?.sampleSheet"></app-file>
        </div>
      </div>

      <div *ngIf="batchFormArray && !readOnly">
        <ng-container *ngFor="let batchFormGroup of batchFormArray.controls">
          <app-batch-selector
            [readOnly]="readOnly"
            [form]="getFormGroupFromAbstractControl(batchFormGroup)"
            (onChange)="validateStep()"
            [showExpirationDate]="false">
          </app-batch-selector>
        </ng-container>
      </div>

      <div *ngIf="readOnly">
        <h3>Verwendete Chargen</h3>
        <app-batches-selector
          [readOnly]="readOnly"
          [forms]="batchFormArray">
        </app-batches-selector>
      </div>
    </div>
  </ng-container>

  <ng-container plate>
    <app-pcr-tube-viewer></app-pcr-tube-viewer>
  </ng-container>
</app-workflow-step-layout>
