import {IWorkflowStepBase} from './workflow-step-base.model';
import {IWorkflowStepValidation} from './workflow-step-validation.model';
import {WorkflowStep} from '../../enums/WorkflowStep';
import {WorkflowInstanceModel} from '../workflow/workflow-instance.model';
import {TecanStatus} from '../../enums/TecanStatus';
import {PlateModel} from '../Plate/plate.model';

export class WorkflowStepTargetPoolingModel implements IWorkflowStepBase, IWorkflowStepValidation {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  checkBarcodes: TecanStatus;
  checkRun: TecanStatus;
  plates: PlateModel[];

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, checkBarcodes: TecanStatus,
              checkRun: TecanStatus, plates: PlateModel[], isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.checkBarcodes = checkBarcodes;
    this.checkRun = checkRun;
    this.plates = plates.map(PlateModel.fromData);
  }

  isValid(): boolean {
    // this is always valid, step is generated in the backend
    return true;
  }

}
