import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {BatchModel} from "../../batch/batch.model";
import {WorkflowStepPurificationModel} from "../workflow-step-purification.model";

export class WorkflowInstanceStepPurificationEditModel extends WorkflowInstanceStepEditModel {

  batches: BatchModel[];

  static initFromStep(step: WorkflowStepPurificationModel): WorkflowInstanceStepPurificationEditModel {
    const ret = new WorkflowInstanceStepPurificationEditModel();
    ret.workflowStep = WorkflowStep.NORMALIZATION;
    ret.workflowInstanceStepId = step.id;
    ret.batches = step.batches;
    return ret;
  }

}
