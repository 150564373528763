import {NgModule} from '@angular/core';
import {InputNumberModule} from 'primeng/inputnumber';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MultiSelectModule} from 'primeng/multiselect';
import {TooltipModule} from 'primeng/tooltip';
import {DropdownModule} from 'primeng/dropdown';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {InputTextComponent} from './text/input-text.component';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {InputDropdownComponent} from './dropdown/input-dropdown.component';
import {InputMultiselectComponent} from './multiselect/input-multiselect.component';
import {ChipModule} from 'primeng/chip';
import {InputNumberComponent} from './number/input-number.component';
import {InputMoneyComponent} from './money/input-money.component';
import {InputPasswordComponent} from './password/input-password.component';
import {FileUploadModule} from 'primeng/fileupload';
import {ImageModule} from 'primeng/image';
import {InputTextAreaComponent} from './text-area/input-text-area.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ButtonSubmitComponent} from './button/submit/button-submit.component';
import {ButtonComponent} from './button/button/button.component';
import {InputDatePickerComponent} from './date-picker/input-date-picker.component';
import {CalendarModule} from "primeng/calendar";
import {InputCheckboxComponent} from './checkbox/input-checkbox.component';
import { PlusButtonComponent } from './button/plus-button/plus-button.component';

@NgModule({
  declarations: [
    InputMoneyComponent,
    InputNumberComponent,
    InputTextComponent,
    InputDropdownComponent,
    InputMultiselectComponent,
    InputPasswordComponent,
    InputTextAreaComponent,
    ButtonComponent,
    ButtonSubmitComponent,
    InputDatePickerComponent,
    InputCheckboxComponent,
    PlusButtonComponent
  ],
  imports: [
    InputNumberModule,
    DropdownModule,
    MultiSelectModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    TooltipModule,
    FontAwesomeModule,
    InputTextModule,
    ButtonModule,
    ChipModule,
    FileUploadModule,
    ImageModule,
    InputTextareaModule,
    CalendarModule
  ],
    exports: [
        InputMoneyComponent,
        InputNumberComponent,
        InputTextComponent,
        InputDropdownComponent,
        InputMultiselectComponent,
        InputPasswordComponent,
        InputTextAreaComponent,
        ButtonComponent,
        ButtonSubmitComponent,
        InputDatePickerComponent,
        InputCheckboxComponent,
        PlusButtonComponent
    ]
})
export class InputModule {

}
