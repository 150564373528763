import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-workflow-step-layout',
  template: `
    <div class="container">
      <div class="info" [style.width]="infoWidth">
        <ng-content select="[info]"></ng-content>
      </div>
      <div class="plate">
        <ng-content select="[plate]"></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./workflow-step-layout.component.scss']
})
export class WorkflowStepLayoutComponent {

  @Input() infoWidth: string = '26rem';

}
