<app-workflow-step-layout>
  <ng-container info>
    <!-- selected plate relevant fields -->
    <div *ngIf="selectedPlateWater">
      <div class="file-import-export-container">
        <div class="data-row">
          <b>Wasser</b>
          <div>{{ selectedPlateWater.water }} µL</div>
        </div>

        <h3>Exportdatei für die Normalisierung</h3>
        <ng-container *ngIf="selectedPlateWater?.plate.exportedFile">
          <div>
            <app-file [file]="selectedPlateWater?.plate.exportedFile"></app-file>
          </div>
        </ng-container>

        <core-button
          *ngIf="!workflowInstance.isFinishedOrAborted() && !selectedPlateWater?.plate.exportedFile"
          [width]="'100%'"
          [text]="'Berechnung exportieren'"
          [backgroundColor]="'#189D18FF'"
          class="export-button"
          (onClick)="exportCalculation()">
        </core-button>

        <core-button
          *ngIf="!workflowInstance.isFinishedOrAborted() && selectedPlateWater?.plate.exportedFile"
          [width]="'100%'"
          [text]="'Berechnung erneut exportieren'"
          class="export-button"
          [backgroundColor]="'#189D18FF'"
          (onClick)="reExportCalculation()">
        </core-button>
      </div>

      <app-plate-file
        header="Integra Datei"
        [plate]="selectedPlateWater.plate"
        [deletable]="workflowDetailClientService.workflowInstance.currentStep === WorkflowStep.NORMALIZATION_AND_POOLING"
        [readonly]="readOnly"
        [fileType]="FileType.INTEGRA"
        (fileUploaded)="onFileUploadedToSelectedPlate($event)"
        (fileRemoved)="onFileRemovedFromSelectedPlate()">
      </app-plate-file>

      <div>
        <app-position-detail
          [position]="selectedPosition"
          [showDnaWater]="true">
        </app-position-detail>
      </div>
    </div>

    <!-- overview relevant fields -->
    <div *ngIf="!selectedPlateWater">
      <h3>Verwendete Chargen</h3>
      <div *ngIf="batchForm && !readOnly" class="batch-selector-container">
        <app-batch-selector
          [readOnly]="readOnly"
          [form]="getFormGroupAbstractControl(batchForm)"
          (onChange)="validateStep()"
          [showExpirationDate]="false">
        </app-batch-selector>
      </div>

      <div *ngIf="readOnly" class="batch-selector-container">
        <app-batches-selector
          [readOnly]="readOnly"
          [forms]="batchFormArray">
        </app-batches-selector>
      </div>

      <h3>Verwendete statische Werte</h3>

      <div class="data-row">
        <div>Endkonzentration:</div>
        <div>{{workflowStep.finalConcentration}} nM</div>
        <div>Faktor Standard:</div>
        <div>{{workflowStep.factorStandard}}</div>
        <div>Cutoff Minimum:</div>
        <div>{{workflowStep.cutoffMin}} µL</div>
        <div>Cutoff Maximum:</div>
        <div>{{workflowStep.cutoffMax}} µL</div>
      </div>

      <h3>Verwendete Formeln</h3>

      <div>nM = Glomax Wert in ng/µl * Faktor Standard</div>
      <div>µL DNA = (Endkonzentration * 5) / nM</div>
      <div>Wasser = (Anzahl Proben × 5) − (Summe aller Positionen)</div>

    </div>
  </ng-container>

  <ng-container plate>
    <!-- Plate List -->
    <ng-container *ngIf="!selectedPlateWater">
      <div class="plate-list-container">
        <div *ngFor="let plateWater of workflowStep?.plates let i = index"
             class="plate-container"
             (click)="onPlateClicked(plateWater)">
          <app-plate
            [plateMode]="PlateMode.DNA_WATER"
            [plate]="plateWater.plate"
            [ignoreHeightBoundary]="true"
            [name]="getSelectedWorkflowByIndex(i)">
          </app-plate>
          <div class="validation_container">
            <div *ngIf="!plateWater.plate.exportedFile" class="error-hint">
              <fa-icon [icon]="faTime"></fa-icon>
              Berechnung nicht Exportiert
            </div>
            <div *ngIf="plateWater.plate.exportedFile" class="success-hint">
              <fa-icon [icon]="faCheck"></fa-icon>
              Berechnung Exportiert
            </div>
            <div *ngIf="!plateWater.plate.uploadedFile" class="error-hint">
              <fa-icon [icon]="faTime"></fa-icon>
              Integra Datei noch nicht hochgeladen
            </div>
            <div *ngIf="plateWater.plate.uploadedFile" class="success-hint">
              <fa-icon [icon]="faCheck"></fa-icon>
              Integra Datei bereits hochgeladen
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Selected Plate for detail -->
    <ng-container *ngIf="this.selectedPlateWater">
      <app-plate
        [showBackButton]="true"
        [plate]="this.selectedPlateWater.plate"
        [plateMode]="PlateMode.DNA_WATER"
        (onPositionClick)="onCellClick($event)"
        (onBackButtonClicked)="clearSelection()">
      </app-plate>
    </ng-container>
  </ng-container>
</app-workflow-step-layout>
