import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {
  WorkflowStepMeasurementOnGlomaxSecondModel
} from "../../../../models/workflow-step/workflow-step-measurement-on-glomax-second.model";
import {PositionModel} from "../../../../models/Plate/position.model";
import {PlateMode} from "../../../../enums/PlateMode";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {AbstractControl, UntypedFormArray, UntypedFormGroup} from "@angular/forms";
import {BatchModel} from "../../../../models/batch/batch.model";
import {BatchType} from "../../../../enums/BatchType";
import {BatchUtils} from "../../../../utils/batch.utils";

@Component({
  selector: 'app-measurement-on-glomax-second',
  templateUrl: './measurement-on-glomax-second.component.html',
  styleUrls: ['./measurement-on-glomax-second.component.scss']
})
export class MeasurementOnGlomaxSecondComponent implements OnInit {

  PlateMode = PlateMode;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepMeasurementOnGlomaxSecondModel;
  @Input() readOnly: boolean = false;

  selectedPosition: PositionModel;

  batchFormArray: UntypedFormArray;
  batchTypes: BatchType[] = [];

  constructor(private workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.batchTypes = BatchType.getAllForGlomax();
    this.batchFormArray = BatchUtils.initBatchFormArray(this.workflowStep.batches, this.batchTypes, this.readOnly);
    this.validateStep();
  }

  getFormGroupFromAbstractControl(form: AbstractControl): UntypedFormGroup {
    return form as UntypedFormGroup;
  }

  onCellClick(position: PositionModel) {
    this.selectedPosition = position;
  }

  validateStep(): void {
    this.workflowStep.batches = BatchModel.getBatchesFromBatchSelectionFormArray(this.batchFormArray);
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

}
