import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceModel} from '../../../models/workflow/workflow-instance.model';
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {WorkflowInstanceState} from "../../../enums/WorkflowInstanceState";

@Component({
  selector: 'app-workflow-item',
  template: `
    <div class="work-flow-item-container" [ngStyle]="{ color: color, borderColor: color}">
      <div class="work-flow-item-icon">
        <app-workflow-instance-status-icon
          [workflowState]="workflowInstance.state">
        </app-workflow-instance-status-icon>
      </div>
      <div class="work-flow-item-plate-num">
        {{ workflowInstance?.workflowNumber }}
      </div>
    </div>
  `,
  styleUrls: ['./workflow-item.component.scss']
})
export class WorkflowItemComponent implements OnInit {

  @Input() workflowInstance: WorkflowInstanceModel;

  headerText: string;
  color: string;

  ngOnInit() {
    this.headerText = WorkflowStep.getWorkFlowStepReadable(this.workflowInstance.currentStep);
    this.color = WorkflowInstanceState.getStateColor(this.workflowInstance.state);
  }

}
