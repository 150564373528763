import {BatchState} from "../../enums/BatchState";
import {FormControl, FormGroup} from "@angular/forms";
import {SortModel} from "../sort.model";
import {BatchType} from "../../enums/BatchType";

export interface BatchFilterForm {
  batchNumber: FormControl<string>,
  type: FormControl<string>,
  stateList: FormControl<BatchState[]>,
  expirationDateFrom: FormControl<Date>,
  expirationDateTo: FormControl<Date>,
}

export class BatchFilterModel {
  static readonly BATCH_NUMBER = 'batchNumber';
  static readonly TYPE = 'type';
  static readonly STATE = 'stateList';
  static readonly EXPIRATION_DATE_FROM = 'expirationDateFrom';
  static readonly EXPIRATION_DATE_TO = 'expirationDateTo';

  batchNumber: string;
  type: BatchType;
  stateList: BatchState[];
  expirationDateFrom: Date;
  expirationDateTo: Date;

  sortDto: SortModel;


  static initFilterForm(): FormGroup<BatchFilterForm> {
    return new FormGroup<BatchFilterForm>({
      batchNumber: new FormControl<string>(null),
      type: new FormControl<BatchType>(null),
      stateList: new FormControl<BatchState[]>([BatchState.NEW, BatchState.IN_USE]),
      expirationDateFrom: new FormControl<Date>(null),
      expirationDateTo: new FormControl<Date>(null)
    })
  }
}
