import {BatchState} from "../../enums/BatchState";
import {DateUtils} from "../../utils/date.utils";
import {BatchType} from "../../enums/BatchType";
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {BatchModel} from "./batch.model";

export class BatchShareModel {

  id: number;
  version: number;
  batchNumber: string;
  type: BatchType;
  batchState: BatchState;
  expirationDate: Date;
  approvedBy: string;
  approvedAt: Date;
  comment: string;
  share: boolean;

  static fromData(data: BatchModel): BatchShareModel {
    const ret = Object.assign(new BatchShareModel(), data);
    ret.approvedAt = DateUtils.fromDate(ret.approvedAt);
    ret.expirationDate = DateUtils.fromDate(ret.expirationDate);
    ret.share = true;
    return ret;
  }

}
