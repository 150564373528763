import {Directive, EventEmitter, HostListener, Output} from "@angular/core";

@Directive({
  selector: '[hla-file-drop-directive]'
})
export class FileDropDirective {

  @Output() filesDropped = new EventEmitter<FileList>();

  constructor() {}

  @HostListener('drop', ['$event']) onDrop($event: { preventDefault: () => void; dataTransfer: { files: FileList; }; }) {
    $event.preventDefault();
    // this.hover = false;
    this.filesDropped.emit($event.dataTransfer.files);
  }

  @HostListener('dragover', ['$event']) onDragOver($event: { preventDefault: () => void; }) {
    $event.preventDefault();
    // this.hover = true;
    // this.filesHovered.emit(true);
  }

  @HostListener('dragleave', ['$event']) onDragLeave($event: { preventDefault: () => void; }) {
    $event.preventDefault();
    // this.hover = false;
    // this.filesHovered.emit(false);
  }
}
