export enum BatchState {
  NEW = 'NEW',
  IN_USE = 'IN_USE',
  EXPIRED = 'EXPIRED',
  BLOCKED = 'BLOCKED',
  INACTIVE = 'INACTIVE'
}

export function getBatchStateReadable(batchState: BatchState): string {
  if (!batchState) return ''

  switch (batchState) {
    case BatchState.NEW:
      return 'Neu Angelegt';
    case BatchState.IN_USE:
      return 'In Verwendung';
    case BatchState.EXPIRED:
      return 'Abgelaufen';
    case BatchState.BLOCKED:
      return 'Gesperrt';
    case BatchState.INACTIVE:
      return 'Inaktiv';
  }
}
