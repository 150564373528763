import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {WorkflowStepAddingChecksModel} from "../../../../models/workflow-step/workflow-step-adding-checks.model";
import {PositionModel} from "../../../../models/Plate/position.model";
import {PositionStatus} from "../../../../enums/PositionStatus";
import {PlateModel} from "../../../../models/Plate/plate.model";
import {PlateMode} from "../../../../enums/PlateMode";
import {BatchType} from 'src/app/enums/BatchType';
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";

@Component({
  selector: 'app-adding-checks',
  templateUrl: './adding-checks.component.html',
  styleUrls: ['./adding-checks.component.scss']
})
export class AddingChecksComponent implements OnInit {
  PlateMode = PlateMode;
  BatchType = BatchType;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepAddingChecksModel;
  @Input() readOnly: boolean = false;

  selectedPlate: PlateModel;

  constructor(public workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.validateStep();
  }

  validateStep() {
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

}
