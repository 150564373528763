import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {WorkflowStepLibraryNormalisationAndPooling} from "../workflow-step-library-normalisation-and-pooling";
import {BatchModel} from "../../batch/batch.model";

export class WorkflowInstanceStepLibraryNormalisationAndPoolingEditModel extends WorkflowInstanceStepEditModel {
  batch: BatchModel;
  indexPoolsCombined: boolean;

  static initFromStep(step: WorkflowStepLibraryNormalisationAndPooling): WorkflowInstanceStepLibraryNormalisationAndPoolingEditModel {
    const ret = new WorkflowInstanceStepLibraryNormalisationAndPoolingEditModel();
    ret.workflowStep = WorkflowStep.NORMALIZATION_AND_POOLING;
    ret.workflowInstanceStepId = step.id;
    ret.batch = BatchModel.isValid(step.batches[0]) ? step.batches[0] : null;
    return ret;
  }
}
