import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddingControlsBatchSelectorComponent} from './adding-controls-batch-selector.component';
import {AutoCompleteModule} from "primeng/autocomplete";
import {InputModule} from "../input/input.module";
import {ReactiveFormsModule} from "@angular/forms";
import {CustomPipeModule} from "../../pipes/custom-pipe.module";
import {PaginatorModule} from "primeng/paginator";
import {NewBatchDialogModule} from "../dialog/new-batch-dialog/new-batch-dialog.module";


@NgModule({
  declarations: [
    AddingControlsBatchSelectorComponent
  ],
  exports: [
    AddingControlsBatchSelectorComponent
  ],
    imports: [
        CommonModule,
        AutoCompleteModule,
        InputModule,
        ReactiveFormsModule,
        CustomPipeModule,
        PaginatorModule,
        NewBatchDialogModule,
    ]
})
export class AddingControlsBatchSelectorModule {
}
