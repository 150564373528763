<div style="height: 100%;">
  <div
    hla-file-drop-directive
    (filesDropped)="onFilesAdded($event)"
    [class]="'hla-file-upload ' + (getAllChosenFilesLength() ? 'hla-files-exist' : 'hla-files-empty')">
    <div *ngIf="getAllChosenFilesLength(); else placeholder"
      class="hla-all-files">
      <app-file
        *ngFor="let file of allFiles.filesToUpload"
        [file]="file"
        [deletable]="false"
        (onRemove)="removeFile(file)">
      </app-file>
    </div>

    <ng-template #placeholder>
      <div class="placeholder">
        <div style="display: flex; justify-content: center; padding-top: 2.5rem">
          <div>Ziehen und Ablegen</div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="hla-filebrowse-container">
    <div
      (click)="fileBrowser.click()"
      class="hla-download-upload-icon-button">
      <input
        #fileBrowser
        (change)="onFilesAdded(castEvent($event.target).files)"
        type="file"
        hidden/>
      <div style="display: flex; justify-content: center;">
        <fa-icon [icon]="faUpload"></fa-icon>
      </div>
      <div style="display: flex; justify-content: center; cursor: pointer">
        <span>Dateien durchsuchen</span>
      </div>
    </div>
  </div>
</div>

