<app-workflow-step-layout>
  <ng-container info>
    <div>

      <form *ngIf="!readOnly && !workflowStep.file" [formGroup]="filesForm">
        <app-file-upload [formControlName]="NEW_FILES"
                         (onFileSelect)="saveUpload()">
        </app-file-upload>
      </form>
      <div *ngIf="workflowStep.file">
        <h4>Upload Datei</h4>
        <app-file
          [file]="workflowStep.file"
          [deletable]="workflowDetailClientService.workflowInstance.currentStep === WorkflowStep.INDEX_PURIFICATION"
          (onRemove)="onRemoveFile()">
        </app-file>
      </div>
    </div>
  </ng-container>

  <ng-container plate>
    <app-pcr-tube-viewer [count]="4"></app-pcr-tube-viewer>
  </ng-container>

</app-workflow-step-layout>
