import { Pipe, PipeTransform } from '@angular/core';
import {BatchType} from "../enums/BatchType";

@Pipe({
  name: 'batchTypeReadable'
})
export class BatchTypeReadablePipe implements PipeTransform {

  transform(value: BatchType): string {
    return BatchType.getBatchTypeReadable(value);
  }

}
