import {IndexPrimerPlate} from "../../../enums/IndexPrimerPlate";
import {WorkflowInstanceBaseModel} from "../../workflow/workflow-instance-base.model";

export class WorkflowForLibraryModel {
  id: number;
  indexPrimerPlate: IndexPrimerPlate;

  static fromWorkFlowBaseModel(workflowInstance: WorkflowInstanceBaseModel): WorkflowForLibraryModel {
    const ret: WorkflowForLibraryModel = new WorkflowForLibraryModel();
    ret.id = workflowInstance.id;
    ret.indexPrimerPlate = workflowInstance.indexPrimerPlate;
    return ret;
  }
}
