<app-workflow-step-layout>
  <ng-container info>
    <div class="container">
      <!-- code -->
      <label>Generierte Code: </label>
      <div>{{ workflowInstance?.workflowNumber}}</div>
      <!-- state -->
      <label>Status Drucker:</label>

      <div class="printer-status">
        <fa-icon
          [icon]="faCircle"
          [ngStyle]="{'color': PrinterState.getPrinterStateColor(workFlowStep?.printerState)}">
        </fa-icon>
        {{ PrinterState.getPrinterStateReadable(workFlowStep?.printerState) }}
      </div>
      <!-- button -->
      <core-button
        class="btn"
        classList="p-button-secondary"
        [width]="'100%'"
        (onClick)="reprintTicket(workFlowStep?.id)"
        [text]="'Etikett erneut drucken'"
        [icon]="faPrint">
      </core-button>
    </div>
  </ng-container>
  <ng-container plate>
    <app-plate-view
      [plateMode]="PlateMode.POSITION_EXIST"
      [workflowInstance]="workflowInstance"
      [plates]="plates">
    </app-plate-view>
  </ng-container>
</app-workflow-step-layout>
