import {WorkflowInstanceBaseModel} from "./workflow-instance-base.model";
import {WorkflowInstanceModel} from "./workflow-instance.model";

export class WorkflowInstanceArchiveModel extends WorkflowInstanceBaseModel {
  updatedBy: string;
  updated: Date;

  static fromData(data: WorkflowInstanceArchiveModel): WorkflowInstanceArchiveModel {
    return Object.assign(new WorkflowInstanceArchiveModel(), data);
  }

  static toWorkflowInstanceModel(data: WorkflowInstanceArchiveModel): WorkflowInstanceModel {
    const ret = new WorkflowInstanceModel();
    ret.id = data.id;
    ret.workflow = data.workflow;
    ret.workflowNumber = data.workflowNumber;
    ret.currentStep = data.currentStep;
    return ret;
  }
}
