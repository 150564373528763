export class WorkflowStepLibraryQubitConcentrationsModel {

  id: number;
  concentration: number;
  ad: number;
  dilution: number;

  constructor(id: number, concentration: number, ad: number, dilution: number) {
    this.id = id;
    this.concentration = concentration;
    this.ad = ad;
    this.dilution = dilution;
  }

}
