import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'core-button-submit',
  template: `
    <core-button
      [id]="id"
      [text]="text"
      [icon]="faCheck"
      [disabled]="disabled"
      [width]="width"
      [backgroundColor]="backgroundColor"
      (onClick)="onClick.emit($event)">
    </core-button>
  `
})
export class ButtonSubmitComponent {

  faCheck = faCheck;

  @Input() id: string;
  @Input() disabled: boolean;
  @Input() width: string;
  @Input() backgroundColor: string;

  @Input() text = 'Änderungen speichern';

  @Output() onClick = new EventEmitter<any>();

}
