import {AbstractControl, ValidatorFn} from '@angular/forms';


export class FileUploadValidator {

  public static maxFileSizeValidator(maxSizeInBytes: number): ValidatorFn {
    return function (control: AbstractControl) {
      const fileList = control.value;
      if (fileList && fileList.length > 0) {
        for (let file of fileList) {
          if (file.size > maxSizeInBytes) {
            return {sizeTooBig: true}
          }
        }
      }
      return null;
    };
  }
}
