import {FileRecord} from "../file/FileRecord";
import {PlateModel} from "./plate.model";
import {BatchModel} from "../batch/batch.model";

export class PlateWithBatchModel extends PlateModel {

  positiveProbe: BatchModel;

  static fromData(data: PlateWithBatchModel): PlateWithBatchModel {
    if (!data) return null;
    const d = Object.assign(new PlateModel(), data);
    if (d.exportedFile) d.exportedFile = FileRecord.fromData(d.exportedFile);
    if (d.importedFile) d.importedFile = FileRecord.fromData(d.importedFile);
    if (d.uploadedFile) d.uploadedFile = FileRecord.fromData(d.uploadedFile);
    d.positiveProbe = BatchModel.fromData(d.positiveProbe);
    return d;
  }

}
