import {Component, Input, OnInit} from '@angular/core';
import {PlateMode} from "../../../../enums/PlateMode";
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {WorkflowStepIndexPcrSetupModel} from "../../../../models/workflow-step/workflow-step-index-pcr-setup.model";
import {IndexPrimerPlate} from "../../../../enums/IndexPrimerPlate";
import {WorkflowInstanceService} from "../../../../services/workflow-instance.service";
import {SelectItem} from "../../../../models/select-item.model";
import {
  WorkflowInstanceStepIndexPcrSetupService
} from "../../../../services/workflow-instance-step-index-pcr-setup.service";
import {
  AbstractControl,
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup
} from "@angular/forms";
import {PositionModel} from "../../../../models/Plate/position.model";
import {CyclerRunThroughService} from "../../../../services/cycler-run-through.service";
import {
  CyclerRunThroughModel,
  CyclerRunTroughFormGroup
} from "../../../../models/cycler-run-trough/cycler-run-through.model";
import {BatchService} from "../../../../services/batch.service";
import {BatchType} from "../../../../enums/BatchType";
import {BatchModel} from "../../../../models/batch/batch.model";
import {
  WorkflowInstanceStepIndexPcrSetupPlateEditModel
} from "../../../../models/workflow-step/edit/workflow-instance-step-index-pcr-setup-plate-edit.model";
import {BatchUtils} from "../../../../utils/batch.utils";
import {PlateModel} from "../../../../models/Plate/plate.model";
import {CyclerRunThroughState} from "../../../../enums/CyclerRunThroughState";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {WorkflowStep} from "../../../../enums/WorkflowStep";
import {FileType} from "../../../../enums/FileType";
import {FileRecord} from "../../../../models/file/FileRecord";

@Component({
  selector: 'app-index-pcr-setup',
  templateUrl: './index-pcr-setup.component.html',
  styleUrls: ['./index-pcr-setup.component.scss']
})
export class IndexPcrSetupComponent implements OnInit {

  PlateMode = PlateMode;
  BatchType = BatchType;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepIndexPcrSetupModel;
  @Input() readOnly: boolean;

  plateSuggestion: IndexPrimerPlate;
  plateSuggestionOptions: SelectItem[];

  selectedPosition: PositionModel;

  plateSuggestionForm: UntypedFormGroup;
  cyclerRunThroughFormArray: FormArray<FormGroup<CyclerRunTroughFormGroup & { suffix: AbstractControl<string> } & { dnaEntered: AbstractControl<boolean> }>>;

  batchFormArray: UntypedFormArray;
  batchTypes: BatchType[] = [];
  selectedPlate: PlateModel;

  protected readonly faTimes = faTimes;
  protected readonly faCheck = faCheck;

  constructor(private cyclerRunThroughService: CyclerRunThroughService,
              private batchService: BatchService,
              public workflowDetailClientService: WorkflowDetailClientService,
              private workflowInstanceService: WorkflowInstanceService,
              private workflowInstanceSteIndexPcrSetupService: WorkflowInstanceStepIndexPcrSetupService) {
  }

  ngOnInit(): void {
    this.fetchCyclerRunThroughList();
    this.batchTypes = BatchType.getAllForIndexPcr();
    this.batchFormArray = BatchUtils.initBatchFormArray(this.workflowStep.batches, this.batchTypes, this.readOnly);
    this.workflowInstanceService.getPlateSuggestion().subscribe({
      next: (resp) => {
        this.plateSuggestion = resp;
      },
      error: (error) => {
        console.log(error);
      }
    });
    this.plateSuggestionOptions = IndexPrimerPlate.getAllAsSelectItem();
    this.initPlateSuggestionForm();
    this.validateStep();
  }

  getFormGroupFromAbstractControl(form: AbstractControl): UntypedFormGroup {
    return form as UntypedFormGroup;
  }

  validateStep(): void {
    this.workflowStep.batches = BatchModel.getBatchesFromBatchSelectionFormArray(this.batchFormArray);
    for (let i = 0; i < this.cyclerRunThroughFormArray?.controls.length; i++) {
      if (this.cyclerRunThroughFormArray.controls[i].get('dnaEntered').value) {
        this.workflowStep.plates[i].dnaEntered = this.cyclerRunThroughFormArray.controls[i].controls.dnaEntered.value;
      }

    }
    const allConfirmedAndOk = this.cyclerRunThroughFormArray?.getRawValue().every(cr => cr.confirmed && cr.state === CyclerRunThroughState.OK);
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid() && allConfirmedAndOk);
  }

  startIndexPcrSetup() {
    if (this.plateSuggestionForm.get('plateSuggestion').value) {
      this.workflowInstance.indexPrimerPlate = this.plateSuggestionForm.get('plateSuggestion').value;
    } else {
      this.workflowInstance.indexPrimerPlate = this.plateSuggestion;
    }

    const dto: WorkflowInstanceStepIndexPcrSetupPlateEditModel =
      {indexPrimerPlate: this.workflowInstance.indexPrimerPlate, workflowInstanceId: this.workflowInstance.id};
    this.workflowInstanceSteIndexPcrSetupService.StartIndexPcrSetup(dto).subscribe({
      next: (resp) => {
        this.workflowInstance = resp;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  onCellClick(position: PositionModel) {
    this.selectedPosition = position;
  }

  onDnaEntered(cyclerRunThroughForm: any) {
    cyclerRunThroughForm.get('dnaEntered').patchValue(true);
    cyclerRunThroughForm.get('cyclerId').enable();
    this.validateStep();
  }

  clearSelection(): void {
    this.selectedPlate = null;
  }

  getWorkflowNameByIndex(i: number): string {
    return this.workflowInstance?.workflowNumber + (this.workflowStep.plates[i]?.suffix ?  '_' + this.workflowStep.plates[i]?.suffix : '');
  }

  onPlateClicked(plate: PlateModel) {
    this.selectedPlate = plate;
  }

  onFileUploadedToSelectedPlate(file: FileRecord): void {
    const index = this.workflowStep.plates.findIndex(pw => pw.id === this.selectedPlate.id);
    this.workflowStep.plates[index].uploadedFile = file;
    this.selectedPlate.uploadedFile = file;
    this.validateStep();
  }

  onFileRemovedFromSelectedPlate(): void {
    if (this.selectedPlate && this.selectedPlate.id) {
      const index = this.workflowStep.plates.findIndex(p => p.id === this.selectedPlate.id);
      this.workflowStep.plates[index].uploadedFile = null;
      this.selectedPlate.uploadedFile = null;
      this.validateStep();
    }
  }

  private initPlateSuggestionForm() {
    this.plateSuggestionForm = new UntypedFormGroup({
      plateSuggestion: new UntypedFormControl('')
    });
  }

  private fetchCyclerRunThroughList(): void {
    this.cyclerRunThroughService.getAllByWorkflowStepId(this.workflowStep.id)
      .subscribe({
        next: (resp) => {
          let runThroughModels: { cyclerRunThrough: CyclerRunThroughModel, plateSuffix: string, dnaEntered: boolean }[] = [];
          // check for all plate if run through already exist
          this.workflowStep.plates.forEach(plate => {
            const crt = resp.find(c => c.plateId === plate.id);
            // cycler run through already exist
            if (crt) {
              runThroughModels.push({
                cyclerRunThrough: crt,
                plateSuffix: this.workflowInstance.workflowNumber + (plate.suffix ? '_' + plate.suffix : ''),
                dnaEntered: plate.dnaEntered
              });
            } else {
              // no cycler run through exist -> create new
              const newCrt = new CyclerRunThroughModel();
              newCrt.plateId = plate.id;
              newCrt.workflowStepId = this.workflowStep.id;
              runThroughModels.push({
                cyclerRunThrough: newCrt,
                plateSuffix: this.workflowInstance.workflowNumber + (plate.suffix ? '_' + plate.suffix : ''),
                dnaEntered: plate.dnaEntered
              });
            }
          });
          this.cyclerRunThroughFormArray = CyclerRunThroughModel.initFormWithDna(runThroughModels);
          this.validateStep();
        }
      });
  }

  protected readonly WorkflowStep = WorkflowStep;
  protected readonly FileType = FileType;
}
