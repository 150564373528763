import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {WorkflowStepLibraryAgilantModel} from "../workflow-step-library-agilant.model";
import {BatchModel} from "../../batch/batch.model";

export class WorkflowInstanceStepLibraryAgilantEditModel extends WorkflowInstanceStepEditModel {

  batches: BatchModel[];
  fileId: number;

  static initFromStep(step: WorkflowStepLibraryAgilantModel): WorkflowInstanceStepLibraryAgilantEditModel {
    const ret = new WorkflowInstanceStepLibraryAgilantEditModel();
    ret.workflowStep = WorkflowStep.QUALITY_ASSURANCE_AGILENT;
    ret.workflowInstanceStepId = step.id;
    ret.fileId = step.file?.id;
    ret.batches = step.batches.filter(BatchModel.isValid);
    return ret;
  }

}
