import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {WorkflowStepLibraryPostSequencingModel} from "../workflow-step-library-post-sequencing.model";

export class WorkflowInstanceStepLibraryPostSequencingEditModel extends WorkflowInstanceStepEditModel {

  static initFromStep(step: WorkflowStepLibraryPostSequencingModel): WorkflowInstanceStepEditModel {
    const ret = new WorkflowInstanceStepLibraryPostSequencingEditModel();
    ret.workflowStep = WorkflowStep.POST_SEQUENCING;
    ret.workflowInstanceStepId = step.id;
    return ret;
  }

}
