import {EnumUtils} from "./EnumUtils";

export enum WorkflowInstanceState {
  WAITING_FOR_FILE = 'WAITING_FOR_FILE',
  WAITING_FOR_USER_INPUT = 'WAITING_FOR_USER_INPUT'
}

export namespace WorkflowInstanceState {

  export function getStateReadable(state: WorkflowInstanceState): string {
    if (!state) return ''

    switch (state) {
      case WorkflowInstanceState.WAITING_FOR_FILE:
        return 'Warten auf Dateiimport';
      case WorkflowInstanceState.WAITING_FOR_USER_INPUT:
        return 'Benutzerinteraktion notwendig';
    }
  }

  export function getStateColor(state: WorkflowInstanceState): string {
    if (!state) return ''

    switch (state) {
      case WorkflowInstanceState.WAITING_FOR_FILE:
        return 'rgb(160, 160, 160, 0.90)';
      case WorkflowInstanceState.WAITING_FOR_USER_INPUT:
        return 'rgba(220, 100, 5, 0.90)';
    }
  }

  export function getEnumByValue(value: string): WorkflowInstanceState {
    return EnumUtils.getEnumByValue<WorkflowInstanceState>(WorkflowInstanceState, 'string', value);
  }

  export function getAll(): WorkflowInstanceState[] {
    return EnumUtils.getAll<WorkflowInstanceState>(WorkflowInstanceState, 'string');
  }

}
