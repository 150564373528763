import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BatchFilterForm, BatchFilterModel} from "../../models/batch/batch-filter.model";
import {BatchState} from "../../enums/BatchState";
import {BatchType} from "../../enums/BatchType";
import {BatchService} from "../../services/batch.service";
import {PageableRequestModel} from "../../models/pagable/pageable-request.model";
import {TableColumnModel} from "../../models/table-column.model";
import {BatchModel} from "../../models/batch/batch.model";
import {SortEvent} from "primeng/api";
import {debounceTime, filter, takeUntil} from "rxjs";
import {Globals} from "../../global/globals";
import {BatchStateUpdateModel} from "../../models/batch/batch-state-update.model";
import {BatchPageModel} from "../../models/pagable/batch-page.model";
import {MessageService} from "../../services/message.service";
import {faMessage} from "@fortawesome/free-solid-svg-icons";
import {SelectItem} from "../../models/select-item.model";
import {PaginationBaseComponent} from "../../components/pagination-base.component";

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.scss', '../../../assets/table.scss']
})
export class BatchesComponent extends PaginationBaseComponent implements OnInit {
  BatchFilterModel = BatchFilterModel;
  BatchState = BatchState;
  showConfirmDialog = false;
  optionBatchType: SelectItem[] = BatchType.getAllAsSelectItem();

  faMessage = faMessage;

  batchFilterForm: FormGroup<BatchFilterForm>;
  batchTableColumns: TableColumnModel[];
  batches: BatchModel[];

  successMsgText: string;

  showClearButton: boolean = false;
  batchStateToUpdate: BatchStateUpdateModel;
  form: FormGroup;
  header: string;
  saveButtonText: string;
  message: string;

  newBatchDialogVisible = false;

  constructor(private batchService: BatchService,
              public globals: Globals,
              private messageService: MessageService,
              private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.batchFilterForm = BatchFilterModel.initFilterForm();
    this.batchTableColumns = TableColumnModel.initBatchTableColumns();
    this.fetchBatch(0);

    this.batchFilterForm.valueChanges
      .pipe(
        debounceTime(1000),
        takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.showClearButton = true;
        this.first = 0;
        this.fetchBatch(0);
      });

    this.batchService.refreshNeed$.pipe(
      takeUntil(this.unSubscribe),
      filter(value => value === true))
      .subscribe(() => this.fetchBatch(0));
  }

  // region getter
  get filterExpirationDateFrom(): Date {
    return this.batchFilterForm.get(BatchFilterModel.EXPIRATION_DATE_FROM).value;
  }

  get filterExpirationDateTo(): Date {
    return this.batchFilterForm.get(BatchFilterModel.EXPIRATION_DATE_TO).value;
  }

  // end region

  changePage(event: any) {
    const page = event ? event.first / event.rows : 0;
    this.fetchBatch(page);
  }

  onSort(sortEvent: SortEvent) {
    this.sortField.initFromSortEvent(sortEvent);
    this.fetchBatch(0);
  }

  showButton(batch: BatchModel, state: BatchState): boolean {
    return batch.batchState === state;
  }

  resetFilter(): void {
    this.batchFilterForm.reset({}, {emitEvent: false});
    this.batchFilterForm.get(BatchFilterModel.STATE).patchValue([BatchState.NEW, BatchState.IN_USE], {emitEvent: false});
    setTimeout(() => this.fetchBatch(0), 500);
    this.showClearButton = false;
  }

  showConfirmation(batch: BatchModel, state: BatchState) {
    this.batchStateToUpdate = new BatchStateUpdateModel(batch.id, batch.version, state);
    this.initForm(this.batchStateToUpdate);
    this.initText();
    this.showConfirmDialog = true;
  }

  private initText(): void {
    if (this.batchStateToUpdate?.batchState === BatchState.INACTIVE) {
      this.header = 'Chargen Inaktiv Setzen';
      this.saveButtonText = 'Inaktiv Setzen';
      this.message = 'Möchten Sie die Charge Inaktiv setzen?'
    } else if (this.batchStateToUpdate?.batchState === BatchState.BLOCKED) {
      this.header = 'Chargen Sperren';
      this.saveButtonText = 'Sperren';
      this.message = 'Möchten Sie die Charge sperren?'
    } else if (this.batchStateToUpdate?.batchState === BatchState.IN_USE) {
      this.header = 'Chargen Aktiv Setzen'
      this.saveButtonText = 'Aktiv Setzen'
      this.message = 'Möchten Sie die Charge wieder Aktiv setzen?';
    }
  }

  initForm(batchState: BatchStateUpdateModel) {
    if (batchState) {
      this.form = this.formBuilder.group({
        id: [batchState.id, [Validators.required]],
        batchState: [batchState.batchState, [Validators.required]],
        version: [batchState.version, [Validators.required]],
        comment: [batchState.comment, [Validators.maxLength(1000)]]
      })
    }
  }

  createNewBatch() {
    this.newBatchDialogVisible = true;
  }

  private fetchBatch(page: number): void {
    const pageAble = new PageableRequestModel(page, this.pageSize);
    const filter = this.initFilterDtoFromFormGroup();
    this.isLoading = true;
    this.batchService.getAll(pageAble, filter)
      .subscribe({
        next: (batchPage: BatchPageModel) => {
          this.batches = batchPage.content;
          this.totalElements = batchPage.totalElements;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  private initFilterDtoFromFormGroup(): BatchFilterModel {
    const filter = new BatchFilterModel();

    filter.batchNumber = this.batchFilterForm.get(BatchFilterModel.BATCH_NUMBER).value;
    filter.type = BatchType.getEnumByValue(this.batchFilterForm.get(BatchFilterModel.TYPE).value);
    filter.stateList = this.batchFilterForm.get(BatchFilterModel.STATE).value;
    filter.expirationDateFrom = this.batchFilterForm.get(BatchFilterModel.EXPIRATION_DATE_FROM).value;
    filter.expirationDateTo = this.batchFilterForm.get(BatchFilterModel.EXPIRATION_DATE_TO).value;

    filter.sortDto = this.sortField;

    return filter;
  }

  onCloseDialog(event: boolean) {
    this.showConfirmDialog = false;
    this.batchStateToUpdate = null;
  }

  saveChanges(form: FormGroup): void {
    let batchStateToUpdate = form.getRawValue();
    if (batchStateToUpdate && batchStateToUpdate.id && batchStateToUpdate.batchState) {
      this.batchService.updateState(batchStateToUpdate)
        .subscribe({
          next: () => {
            this.messageService.addSuccessMessage(this.successMsgText);
            this.resetBatchStateToUpdate();
            this.fetchBatch(0);
          },
          error: () => {
            this.resetBatchStateToUpdate();
          }
        })
    }
  }

  private resetBatchStateToUpdate(): void {
    this.batchStateToUpdate = null;
    this.successMsgText = null;
  }
}
