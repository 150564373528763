import {IWorkflowStepBase} from './workflow-step-base.model';
import {WorkflowStep} from '../../enums/WorkflowStep';
import {PlateModel} from '../Plate/plate.model';
import {IWorkflowStepValidation} from './workflow-step-validation.model';
import {WorkflowInstanceModel} from '../workflow/workflow-instance.model';
import {Workflow} from '../../enums/Workflow';

export class WorkflowStepSampleSelectionModel implements IWorkflowStepBase, IWorkflowStepValidation {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  plates: PlateModel[];
  isSkipped: boolean;

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, plates: PlateModel[], isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    if (workflowInstance.workflow === Workflow.WORKFLOW_96) {
      this.plates = plates?.length > 0 ? plates.map(PlateModel.fromData) : new Array(1).fill(new PlateModel());
    } else if (workflowInstance.workflow === Workflow.WORKFLOW_384) {
      this.plates = plates?.length > 0 ? plates.map(PlateModel.fromData) : new Array(4).fill(null);
    }
  }

  isValid(): boolean {
    if (this.workflowInstance.workflow === Workflow.WORKFLOW_96) {
      return this.plates.length === 1 && this.plates.every(plate => plate?.id != null);
    } else {
      return this.plates.length === 4 && this.plates.every(plate => plate?.id != null);
    }
  }
}
