import {WorkflowInstanceBaseModel} from "./workflow-instance-base.model";
import {Workflow} from "../../enums/Workflow";
import {WorkflowInstanceStepDto} from "./workflow-instance-step-dto";
import {WorkflowStep} from "../../enums/WorkflowStep";

export class WorkflowInstanceModel extends WorkflowInstanceBaseModel {

  negativeControlPosX: number;
  negativeControlPosY: number;
  positiveControlPosX: number;
  positiveControlPosY: number;
  sharedToLibrary: boolean;
  steps: WorkflowInstanceStepDto[];

  libraryWorkflowInstances: WorkflowInstanceModel[];

  getWorkflowTypeByLibraryType(): Workflow {
    switch (this.workflow) {
      case Workflow.LIBRARY_96:
        return Workflow.WORKFLOW_96;
      case Workflow.LIBRARY_384:
        return Workflow.WORKFLOW_384;
      default:
        return null;
    }
  }

  static isLastStepBeforeFinish(workflowStep: WorkflowStep, workflow: Workflow): boolean {
    switch (workflow) {
      case Workflow.WORKFLOW_96:
      case Workflow.WORKFLOW_384:
        return workflowStep === WorkflowStep.MEASUREMENT_GLOMAX_SECOND;
      case Workflow.LIBRARY_96:
      case Workflow.LIBRARY_384:
        return workflowStep === WorkflowStep.POST_SEQUENCING;
      default:
        return false;
    }
  }

  static fromData(data: WorkflowInstanceModel): WorkflowInstanceModel {
    if (!data) { return null;}
    return Object.assign(new WorkflowInstanceModel(), data);
  }

}
