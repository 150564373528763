import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFieldsContainerComponent } from './search-fields-container.component';



@NgModule({
    declarations: [
        SearchFieldsContainerComponent
    ],
    exports: [
        SearchFieldsContainerComponent
    ],
    imports: [
        CommonModule
    ]
})
export class SearchFieldsContainerModule { }
