import {IWorkflowStepBase} from "./workflow-step-base.model";
import {IWorkflowStepValidation} from "./workflow-step-validation.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {WorkflowInstanceBaseModel} from "../workflow/workflow-instance-base.model";
import {FileRecord} from "../file/FileRecord";
import {BatchModel} from "../batch/batch.model";
import {DetailedPlateModel} from "../Plate/detailed-plate.model";

export class WorkflowStepLibraryNormalisationAndPooling implements IWorkflowStepBase, IWorkflowStepValidation {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  selectedWorkflowInstances: WorkflowInstanceBaseModel[];

  batches: BatchModel[];
  plates: DetailedPlateModel[];

  factorStandard: number;
  finalConcentration: number;
  cutoffMin: number;
  cutoffMax: number;

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, file: FileRecord, batches: BatchModel[],
              factorStandard: number, finalConcentration: number, plates: DetailedPlateModel[],
              cutoffMin: number, cutoffMax: number, isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.selectedWorkflowInstances = workflowInstance?.libraryWorkflowInstances ?? [];
    this.batches = batches.map(BatchModel.fromData);
    this.plates = plates?.map(DetailedPlateModel.fromData);
    this.factorStandard = factorStandard;
    this.finalConcentration = finalConcentration;
    this.cutoffMin = cutoffMin;
    this.cutoffMax = cutoffMax;
  }

  isValid(): boolean {
    return this.batches != null &&
      this.batches.length === 1 &&
      this.plates?.every(p => p.plate.exportedFile != null && p.plate.uploadedFile != null);
  }
}
