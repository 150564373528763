/**
 * Class representing SPRING FRAMEWORK
 *                    PAGE<T> object
 */
export class PageableResponseModel<T> {

  empty: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: any;
  size: number;
  totalElements: number;
  totalPages: number;
  content: T[];

  mapFieldFromData(data: PageableResponseModel<T>): void {

    this.empty = data.empty;
    this.last = data.last;
    this.number = data.number;
    this.numberOfElements = data.numberOfElements;
    this.pageable = data.pageable;
    this.size = data.size;
    this.totalElements = data.totalElements;
    this.totalPages = data.totalPages;

  }

}
