import {NgModule} from "@angular/core";
import {BatchesSelectorComponent} from "./batches-selector.component";
import {BatchSelectorModule} from "../batch-selector/batch-selector.module";
import {NgForOf, NgIf} from "@angular/common";

@NgModule({
  declarations: [BatchesSelectorComponent],
  imports: [
    BatchSelectorModule,
    NgIf,
    NgForOf
  ],
  exports: [BatchesSelectorComponent]
})
export class BatchesSelectorModule {

}
