import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {Workflow} from "../enums/Workflow";

@Component({
  template: ''
})
export abstract class UnsubscribeBaseComponent implements OnDestroy {
  Workflow = Workflow;

  protected unSubscribe = new Subject<any>();

  ngOnDestroy(): void {
    this.unSubscribe.next(null);
    this.unSubscribe.complete();
  }
}
