import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CyclerRunThroughComponent } from './cycler-run-through.component';
import {InputModule} from "../input/input.module";
import {CyclerSelectorModule} from "../cycler-selector/cycler-selector.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CustomPipeModule} from "../../pipes/custom-pipe.module";



@NgModule({
  declarations: [
    CyclerRunThroughComponent
  ],
  exports: [
    CyclerRunThroughComponent
  ],
    imports: [
        CommonModule,
        InputModule,
        CyclerSelectorModule,
        FormsModule,
        ReactiveFormsModule,
        CustomPipeModule
    ]
})
export class CyclerRunThroughModule { }
