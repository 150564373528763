import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {PositionModel} from "../../../../models/Plate/position.model";
import {WorkflowStepSampleSelectionModel} from "../../../../models/workflow-step/workflow-step-sample-selection.model";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {UnsubscribeBaseComponent} from "../../../unsubscribe-base.component";
import {PlateService} from "../../../../services/plate.service";
import {PlateModel} from "../../../../models/Plate/plate.model";
import {PlateMode} from "../../../../enums/PlateMode";
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {DatePipe} from "@angular/common";
import {MessageService} from "../../../../services/message.service";
import {Workflow} from "../../../../enums/Workflow";
import {inOutAnimation} from "../../../../animations/in-out.animation";

@Component({
  selector: 'app-sample-selection',
  templateUrl: './sample-selection.component.html',
  styleUrls: ['./sample-selection.component.scss'],
  animations: [inOutAnimation]
})
export class SampleSelectionComponent extends UnsubscribeBaseComponent implements OnInit {

  PlateMode = PlateMode;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() sampleSelection: WorkflowStepSampleSelectionModel;
  @Input() readOnly: boolean;

  plateList: string[] = [];

  selectedPosition: PositionModel;

  selectPositiveControl: boolean = false;
  selectNegativeControl: boolean = false;

  form: UntypedFormGroup;

  selectedPlate: PlateModel;

  constructor(private workflowDetailClientService: WorkflowDetailClientService,
              private plateService: PlateService,
              private datePipe: DatePipe,
              private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.validateStep();
    this.plateList = this.sampleSelection?.plates?.map(p => p?.importedFile != null ?
      this.datePipe.transform(p?.importedFile?.created, 'dd.MM.yy HH:mm') + ' - ' + p?.importedFile?.name
      : '');
    if (Workflow.WORKFLOW_96 === this.workflowInstance.workflow) {
      if (this.getSinglePlate()) {
        this.selectedPlate = this.getSinglePlate();
      } else {
        this.selectedPlate = new PlateModel();
      }
    }
  }

  getPositionForControlByCoordinates(x: number, y: number): PositionModel {
    if (!x || !y) return null;
    return {posX: x, posY: y} as any;
  }

  onPositionSelected(position: PositionModel) {
    if (!position) {
      this.selectedPosition = null;
      return;
    }
    if (this.selectPositiveControl) {
      // verify it is not the same as the negative control
      if (this.selectedPlate.negativeControlPosX === position.posX &&
        this.selectedPlate.negativeControlPosY === position.posY) {
        this.messageService.addErrorMessage('Auf dieser Position befindet sich bereits die Negativkontrolle!')
        return;
      }
      this.selectedPlate.positiveControlPosX = position.posX;
      this.selectedPlate.positiveControlPosY = position.posY;
      this.selectPositiveControl = false;
      this.validateStep();
    } else if (this.selectNegativeControl) {
      // verify it is not the same as the positiv control
      if (this.selectedPlate.positiveControlPosX === position.posX &&
        this.selectedPlate.positiveControlPosY === position.posY) {
        this.messageService.addErrorMessage('Auf dieser Position befindet sich bereits die Positivkontrolle!')
        return;
      }
      this.selectedPlate.negativeControlPosX = position.posX;
      this.selectedPlate.negativeControlPosY = position.posY;
      this.selectNegativeControl = false;
      this.validateStep();
    } else {
      this.selectedPosition = position;
    }
  }

  onSelectNewNegativeControlPosition(e: boolean) {
    this.selectNegativeControl = e;
    this.validateStep();
  }

  onSelectNewPositiveControlPosition(e: boolean) {
    this.selectPositiveControl = e;
    this.validateStep();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      plateId: new UntypedFormControl(this.getSinglePlate()?.id)
    });
  }

  private validateStep() {
    this.workflowDetailClientService.isStepValid$.next(this.sampleSelection.isValid() && !this.selectPositiveControl && !this.selectNegativeControl);
  }

  private assignPlateToWorkflowStep(plate: PlateModel): void {
    if (!plate) {
      return;
    }

    // workflow 96 can only have a single plate
    if (this.workflowInstance.workflow === Workflow.WORKFLOW_96) {
      this.sampleSelection.plates.splice(0, this.sampleSelection.plates.length);
      this.sampleSelection.plates.push(plate);
      this.selectedPlate = plate;

      // 384 should have exactly 4
    } else {
      const existIndex = this.sampleSelection.plates.findIndex(p => p?.id === plate.id);
      // check if plate is already in the selection
      if (existIndex > -1) {
        this.messageService.addErrorMessage("Swabsortier-Datei wurde bereits ausgwählt!", "Fehler", false);
        return;
      }

      // put the selection into the next empty position
      const emptyIndex = this.sampleSelection.plates.findIndex(plate => plate == null || plate.id == null);

      if (emptyIndex > -1) {
        this.sampleSelection.plates[emptyIndex] = plate;
      }
    }
  }


  private getSinglePlate(): PlateModel {
    const plates = this.sampleSelection?.plates;
    if (plates && plates.length === 1 && !!plates[0]) return plates[0];
    return null;
  }

}
