<div class="hla-file">
  <fa-icon class="hla-file-icon" [icon]="fileService.getFileIconBasedOnMimeType(file?.contentType)"></fa-icon>
  <div
    (click)="downloadFile()"
    class="hla-file-name"
    [ngClass]="{downloadable: file?.id}">
    {{ (file?.name ? file?.name : file?.filename) | truncate: maxNameLength }}
  </div>
  <fa-icon
    *ngIf="deletable"
    (click)="onRemove.emit(file)"
    [icon]="faTimes"
    class="hla-remove-file">
  </fa-icon>
</div>
