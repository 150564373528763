import {WorkflowStep} from "../../../enums/WorkflowStep";
import {Workflow} from "../../../enums/Workflow";

export class WorkflowInstanceStepEditModel {

  workflowInstanceId: number;
  workflowInstanceStepId: number;
  workflowStep: WorkflowStep;
  workflow: Workflow;

}
