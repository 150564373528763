import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PositionDetailComponent} from "./position-detail.component";
import {DialogModule} from "primeng/dialog";
import {InputModule} from "../input/input.module";
import {CustomPipeModule} from "../../pipes/custom-pipe.module";
import {ReactiveFormsModule} from "@angular/forms";
import {
  StateUpdateWithCommentDialogModule
} from "../dialog/state-update-with-comment-dialog/state-update-with-comment-dialog.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {TooltipModule} from "primeng/tooltip";


@NgModule({
  declarations: [
    PositionDetailComponent
  ],

  exports: [
    PositionDetailComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    InputModule,
    CustomPipeModule,
    ReactiveFormsModule,
    StateUpdateWithCommentDialogModule,
    FontAwesomeModule,
    TooltipModule
  ]
})
export class PositionDetailDialogModule {
}
