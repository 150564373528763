import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkflowDetailDialogComponent} from './workflow-detail-dialog.component';
import {DialogModule} from "primeng/dialog";
import {InputModule} from "../../input/input.module";
import {WorkflowStepCommonModule} from "../../workflow-step-common/workflow-step-common.module";
import {CustomPipeModule} from "../../../pipes/custom-pipe.module";
import {WorkflowInstanceStepCardModule} from "../../worklflow-instance-step-card/workflow-instance-step-card.module";
import {WorkflowDetailClientService} from "./workflow-detail-client.service";
import {ScrollToRightDirective} from "../../../directives/ScrollToRightDirective";
import {CheckboxModule} from "primeng/checkbox";
import {FormsModule} from "@angular/forms";
import {InputSwitchModule} from "primeng/inputswitch";


@NgModule({
  declarations: [
    WorkflowDetailDialogComponent,
    ScrollToRightDirective
  ],
  exports: [
    WorkflowDetailDialogComponent
  ],
    imports: [
        CommonModule,
        DialogModule,
        InputModule,
        WorkflowStepCommonModule,
        CustomPipeModule,
        WorkflowInstanceStepCardModule,
        CheckboxModule,
        FormsModule,
        InputSwitchModule
    ],
  providers: [WorkflowDetailClientService]
})
export class WorkflowDetailDialogModule {
}
