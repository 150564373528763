import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";

@Component({
  selector: 'core-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss', '../input.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputCheckboxComponent,
    multi: true
  }]
})
export class InputCheckboxComponent implements ControlValueAccessor, OnInit {

  @Input() id: string;
  @Input() label: string;
  @Input() topLabel: string;
  @Input() placeholder = '';
  @Input() showClearButton = true;

  @Input() removeDefaultBottomErrorMargin = false;

  @Output() onKeyUpEnter = new EventEmitter<string>();
  control: NgControl;
  value: string;
  disabled = false;
  private onTouched: Function;
  private onChanged: Function;

  constructor(private inj: Injector) {
    if (!this.id) {
      this.id = 'core-input-text-' + Math.random();
    }
  }

  onClear() {
    this.valueChanged(null);
    this.onKeyUpEnter.emit(null);
  }

  ngOnInit() {
    this.control = this.inj.get(NgControl);
  }

  valueChanged(value: string) {
    this.onTouched();
    this.value = value;
    this.onChanged(value);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
