import {IWorkflowStepBase} from "./workflow-step-base.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";

export class WorkflowStepFinishedModel implements IWorkflowStepBase {
  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;
  finishedAt: Date;
  finishedBy: string;


  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, finishedAt: Date, finishedBy: string, isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.finishedAt = finishedAt;
    this.finishedBy = finishedBy;
  }
}
