import {Pipe, PipeTransform} from '@angular/core';
import {Workflow} from "../enums/Workflow";
import {WorkflowStep} from "../enums/WorkflowStep";

@Pipe({
  name: 'plateSize'
})
export class PlateSizePipe implements PipeTransform {

  transform(workflow: Workflow, currentStep: WorkflowStep): Workflow {
    if (workflow === Workflow.WORKFLOW_384) {
      switch (currentStep) {
        case WorkflowStep.PREPARATION:
        case WorkflowStep.SAMPLE_SELECTION:
        case WorkflowStep.DNA_EXTRACTION_SWAB_LYSIS:
        case WorkflowStep.DNA_EXTRACTION_SWAB:
        case WorkflowStep.ADDING_CHECKS:
        case WorkflowStep.QUANTIFICATION_FIRST:
        case WorkflowStep.MEASUREMENT_GLOMAX_FIRST:
        case WorkflowStep.PURIFICATION:
        case WorkflowStep.INDEX_PCR:
        case WorkflowStep.QUANTIFICATION_SECOND:
        case WorkflowStep.MEASUREMENT_GLOMAX_SECOND:
          return Workflow.WORKFLOW_96;

        case WorkflowStep.NORMALIZATION:
        case WorkflowStep.NORMALIZATION_PREPARATION:
        case WorkflowStep.TARGET_PCR:
        case WorkflowStep.TARGET_POOLING:
          return Workflow.WORKFLOW_384;
      }
    }

    return Workflow.WORKFLOW_96;
  }

}
