import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {faList, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FileUploadContainer} from "../../../models/file/file-upload-container";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {

  faUpload = faUpload;
  faList = faList;

  allFiles: FileUploadContainer;

  @Input() clearFilesOnInput: boolean = false;

  @Output() onFileSelect = new EventEmitter<FileList>();

  onChange: Function;
  onTouch: Function;

  onlineFileBrowserDisplayDialog: boolean;

  ngOnInit(): void {
    this.allFiles = {
      filesToUpload: [],
      chosenExistingFiles: [],
      allExistingFiles: null
    };
  }

  writeValue(obj: any): void {
    if (obj) {
      this.allFiles = obj;
    } else {
      this.ngOnInit();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  onFilesAdded(filesDropped: FileList): void {
    if (this.onTouch) this.onTouch();
    const newFiles = Array.from(filesDropped);
    if (newFiles.length){
      this.allFiles.filesToUpload = this.allFiles.filesToUpload.concat(newFiles);
      if (this.onChange) this.onChange(this.allFiles);
      this.onFileSelect.emit(filesDropped);
      if (this.clearFilesOnInput) {
        this.ngOnInit();
      }
    }
  }

  castEvent(target: EventTarget) {
    return (<HTMLInputElement> target);
  }

  getAllChosenFilesLength(): number {
    return this.allFiles.filesToUpload?.length + this.allFiles.chosenExistingFiles?.length;
  }

  removeFile(fileToRemove: any): void {
    if (fileToRemove instanceof File) {
      this.allFiles.filesToUpload = this.allFiles.filesToUpload.filter(file => file !== fileToRemove);
    } else {
      this.allFiles.chosenExistingFiles = this.allFiles.chosenExistingFiles.filter(file => file !== fileToRemove);
    }
    this.onChange(this.allFiles);
  }

  openOnlineFileBrowser(): void {
    this.onlineFileBrowserDisplayDialog = true;
  }

}
