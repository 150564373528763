import {Component, OnInit} from '@angular/core';
import {WorkflowStep} from '../../enums/WorkflowStep';
import {WorkflowInstanceService} from '../../services/workflow-instance.service';
import {WorkflowInstanceModel} from '../../models/workflow/workflow-instance.model';
import {FormGroup} from "@angular/forms";
import {WorkflowInstanceDtoModel} from "../../models/workflow/workflow-instance-dto.model";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {filter, takeUntil} from "rxjs";
import {UnsubscribeBaseComponent} from "../../components/unsubscribe-base.component";
import {
  WorkflowDetailClientService
} from "../../components/dialog/workflow-detail-dialog/workflow-detail-client.service";
import {Workflow} from "../../enums/Workflow";
import {SseService} from "../../services/sse.service";
import {SseEventType} from "../../enums/SseEventType";

@Component({
  selector: 'app-dashboard-96-384',
  template: `
    <div class="dashboard-container">
      <div class="dashboard-item">
        <div class="arrow" (click)="openWorkFlowDialog()">
          <div class="arrow-container">
            <fa-icon class="arrow-content arrow-content-icon" [icon]='faPlus'></fa-icon>
            <span class="arrow-content">
        Neuen Workflow starten
      </span>
          </div>
        </div>
      </div>
      <app-workflow-step
        *ngFor="let workFlow of workflowStepsWorkflow96And384"
        [workFlowInstances]="workflowInstanceModels"
        (showWorkflowDetailDialog)="openWorkflowDetailDialog($event)"
        [workFlowSteps]="workFlow">
      </app-workflow-step>
    </div>

    <app-workflow-detail-dialog
      (closeDialog)="onCloseDialog($event)"
      *ngIf="showDetail"
      [showDetail]="showDetail">
    </app-workflow-detail-dialog>

    <app-new-workflow-dialog
      (closeDialog)="onCloseNewWorkflowDialog($event)"
      *ngIf="showWorkFlowDialog"
      [showWorkFlowDialog]="showWorkFlowDialog"
      [workflowInstanceDto]="workflowInstanceDto">
    </app-new-workflow-dialog>
  `,
  styleUrls: ['./dashboard.component.scss']
})
export class Dashboard96384Component extends UnsubscribeBaseComponent implements OnInit {

  workflowStepsWorkflow96And384: WorkflowStep[][];
  faPlus = faPlus;

  workflowInstanceModels: WorkflowInstanceModel[];
  showDetail = false;

  showWorkFlowDialog = false;
  form: FormGroup;
  workflowInstanceDto: WorkflowInstanceDtoModel;

  constructor(private workflowInstanceService: WorkflowInstanceService,
              private workflowDetailClientService: WorkflowDetailClientService,
              private sseService: SseService) {
    super();
    this.workflowStepsWorkflow96And384 = WorkflowStep.getAllForDashboardInWorkflow96And384();
  }

  ngOnInit() {
    this.fetchWorkflowInstances();

    this.workflowInstanceService.refreshNeed$.pipe(
      takeUntil(this.unSubscribe),
      filter(value => value === true)
    ).subscribe(() => this.fetchWorkflowInstances());

    this.sseService.sseEvent$.pipe(
      takeUntil(this.unSubscribe),
      filter(sseEvent => sseEvent != null && sseEvent.type === SseEventType.REFRESH_NEEDED)
    ).subscribe(() => this.fetchWorkflowInstances());
  }

  private fetchWorkflowInstances(): void {
    this.workflowInstanceService.getInProgressWorkFlowInstances([Workflow.WORKFLOW_96, Workflow.WORKFLOW_384])
      .subscribe((wfi: WorkflowInstanceModel[]) => {
        this.workflowInstanceModels = wfi;
      });
  }

  openWorkflowDetailDialog(event: WorkflowInstanceModel) {
    this.workflowDetailClientService.workflowInstance = event;
    this.showDetail = true;
  }

  onCloseDialog(event: boolean) {
    this.showDetail = false;
  }

  openWorkFlowDialog() {
    this.workflowInstanceDto = new WorkflowInstanceDtoModel();
    this.showWorkFlowDialog = true;
  }

  onCloseNewWorkflowDialog(event: boolean) {
    this.showWorkFlowDialog = false;
  }
}
