<app-workflow-step-layout>
  <ng-container info>
    <div>
      <h3>Verwendete Chargen</h3>

      <app-batches-selector
        [readOnly]="readOnly"
        [forms]="batchFormArray"
        (onChange)="validateStep()">
      </app-batches-selector>

      <div class="container">
        <h3>Check Runs</h3>
        <div class="data-container-check-run" *ngFor="let plate of workflowStep?.plates">
          <b>{{workflowInstance.workflowNumber + (plate.plate.suffix ? '_' + plate.plate.suffix : '')}}</b>
          <div>Check Run:</div>
          <div [ngClass]="plate?.checkRun === TecanStatus.OK ? 'ok' : 'not-ok'" class="status-indicator">
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container plate>
    <app-plate-view
      [plateMode]="PlateMode.POSITION_EXIST"
      [workflowInstance]="workflowInstance"
      [plates]="getPlateList()">
    </app-plate-view>
  </ng-container>
</app-workflow-step-layout>
