<div class="workflow-step-container">
  <!-- Header -->
  <div class="workflow-step-header">
    {{ workFlowHeader }}
  </div>
  <!-- Content -->
  <div class="workflow-step-content">
    <ng-container *ngIf="workFlowInstances | workflowInstanceFilter:workFlowSteps; let filteredList">
      <ng-container *ngIf="filteredList.length > 0; else emptyStep">
        <app-workflow-item
          (click)="showWorkflowDetailDialog.emit(wfi)"
          *ngFor="let wfi of filteredList"
          [workflowInstance]="wfi">
        </app-workflow-item>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #emptyStep>
  <div class="workflow-step-empty">
    Keine Platte
  </div>
</ng-template>
