<app-header *ngIf="authService.isLoggedIn()"></app-header>

<p-toast position="top-right"></p-toast>

<p-confirmDialog
  [baseZIndex]="10000"
  [style]="{width: '50vw'}"
  rejectButtonStyleClass="p-button-text p-button-secondary">
</p-confirmDialog>

<router-outlet></router-outlet>
