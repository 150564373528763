import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceStepError} from "../../enums/WorkflowInstanceStepError";

export class WorkflowInstanceStepDto {

  id: number;
  error: WorkflowInstanceStepError;
  step: WorkflowStep;
  workflowNumber: string;
  isSkipped: boolean;

  static fromData(data: WorkflowInstanceStepDto): WorkflowInstanceStepDto {
    return Object.assign(new WorkflowInstanceStepDto(), data);
  }

}
