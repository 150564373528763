<app-workflow-step-layout>
  <div info>
    <ng-container *ngIf="!readOnly; else readOnlyBatches">
      <ng-container *ngFor="let plate of workflowStep.plates; let i = index">
        <hr *ngIf="i > 0">
        <app-adding-controls-batch-selector
          [name]="workflowStep | workflowName:plate"
          [(batch)]="plate.positiveProbe"
          [positiveControl]="plate.positions[0]"
          [readOnly]="readOnly || (selectedPlate && selectedPlate.id != plate.id)"
          (batchChange)="validateStep()">
        </app-adding-controls-batch-selector>
      </ng-container>
    </ng-container>
  </div>

  <ng-container plate>
    <app-plate-view
      [plateMode]="PlateMode.POSITION_EXIST"
      [workflowInstance]="workflowInstance"
      [plates]="workflowStep?.plates"
      (onPlateSelected)="selectedPlate = $event">
    </app-plate-view>
  </ng-container>
</app-workflow-step-layout>

<ng-template #readOnlyBatches>
  <h3>Verwendete Chargen</h3>
  <div class="batch-readonly-view">
    <ng-container *ngFor="let batch of workflowStep?.batches">
      <ul>
        <li>
          {{ BatchType.getBatchTypeReadable(batch.type) }}
        </li>
      </ul>
      <b>{{ batch?.batchNumber }}</b>
    </ng-container>
  </div>
</ng-template>
