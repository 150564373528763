import {EnumUtils} from "./EnumUtils";

export enum PrinterState {
  NOT_PRINTED = 'NOT_PRINTED',
  PRINTER_TEMPLATE_ERROR = 'PRINTER_TEMPLATE_ERROR',
  PRINTER_CONFIGURATION_ERROR = 'PRINTER_CONFIGURATION_ERROR',
  PRINTER_NOT_REACHABLE = 'PRINTER_NOT_REACHABLE',
  GENERIC_PRINTER_ERROR = 'GENERIC_PRINTER_ERROR',
  COULD_NOT_PRINT = 'COULD_NOT_PRINT',
  PRINTED = 'PRINTED',
}

export namespace PrinterState {

  export function getPrinterStateReadable(state: PrinterState): string {
    if (!state) return ''

    switch (state) {
      case PrinterState.NOT_PRINTED:
        return 'Nicht gedruckt';
      case PrinterState.PRINTER_TEMPLATE_ERROR:
        return 'Drucker Template Error';
      case PrinterState.PRINTER_CONFIGURATION_ERROR:
        return 'Drucker Konfigurations Error';
      case PrinterState.PRINTER_NOT_REACHABLE:
        return 'Drucker nicht erreichbar';
      case PrinterState.GENERIC_PRINTER_ERROR:
        return 'Fehler beim Drucken';
      case PrinterState.COULD_NOT_PRINT:
        return 'Fehler beim Drucken';
      case PrinterState.PRINTED:
        return 'Gedruckt';
    }
  }

  export function getPrinterStateColor(state: PrinterState): string {
    if (!state) return ''

    switch (state) {
      case PrinterState.PRINTED:
        return 'green';
      case PrinterState.NOT_PRINTED:
        return 'orange';
      case PrinterState.PRINTER_TEMPLATE_ERROR:
      case PrinterState.PRINTER_CONFIGURATION_ERROR:
      case PrinterState.PRINTER_NOT_REACHABLE:
      case PrinterState.GENERIC_PRINTER_ERROR:
      case PrinterState.COULD_NOT_PRINT:
        return 'red';
    }
  }

  export function getEnumByValue(value: string): PrinterState {
    return EnumUtils.getEnumByValue<PrinterState>(PrinterState, 'string', value);
  }

  export function getAll(): PrinterState[] {
    return EnumUtils.getAll<PrinterState>(PrinterState, 'string');
  }

}
