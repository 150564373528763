import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appScrollToRight]'
})
export class ScrollToRightDirective implements AfterViewInit {
  @Input() appScrollToRight: boolean;

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    if (this.appScrollToRight) {
      setTimeout(() => {
        const container = this.el.nativeElement.parentElement;
        container.scrollLeft = container.scrollWidth;
      }, 10); // Adjust the delay as needed
    }
  }
}
