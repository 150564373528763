import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {
  WorkflowStepDnaExtractionSwabLysisModel
} from "../../../../models/workflow-step/workflow-step-dna-extraction-swab-lysis.model";
import {PositionModel} from "../../../../models/Plate/position.model";
import {BatchType} from 'src/app/enums/BatchType';
import {PlateMode} from 'src/app/enums/PlateMode';
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {WorkflowStep} from "../../../../enums/WorkflowStep";
import {BatchModel} from "../../../../models/batch/batch.model";
import {UntypedFormArray} from "@angular/forms";

@Component({
  selector: 'app-dna-extraction-swab-lysis',
  templateUrl: './dna-extraction-swab-lysis.component.html',
  styleUrls: ['./dna-extraction-swab-lysis.component.scss']
})
export class DnaExtractionSwabLysisComponent implements OnInit {
  BatchType = BatchType;
  PlateMode = PlateMode;
  batchFormArray: UntypedFormArray;
  WorkflowStep = WorkflowStep;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepDnaExtractionSwabLysisModel;
  @Input() readOnly: boolean = false;

  showDetail = false;
  selectedPosition: PositionModel;

  constructor(public workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.batchFormArray = BatchModel.initFormArrayForBatchSelection(this.workflowStep.batches, true, false);
    this.validateStep();
  }

  validateStep() {
    this.workflowStep.batches = BatchModel.getBatchesFromBatchSelectionFormArray(this.batchFormArray);
    // the next button is always invalid
    // however the validation is needed for the custom next button
    this.workflowDetailClientService.isStepValid$.next(this.workflowDetailClientService.workflowInstance.currentStep !== WorkflowStep.DNA_EXTRACTION_SWAB_LYSIS);
  }

  openPositionDetail(position: PositionModel) {
    this.selectedPosition = position;
    this.showDetail = true;
  }

  onCellClick(position: PositionModel) {
    this.selectedPosition = position;
  }

  switchPlateView() {
    this.workflowDetailClientService.fetchWorkflowInstanceStepByStep(WorkflowStep.DNA_EXTRACTION_SWAB);
  }

  switchToSwabImport() {
    this.workflowDetailClientService.nextStep();
  }
}
