import { Pipe, PipeTransform } from '@angular/core';
import {PathType} from "../enums/PathType";

@Pipe({
  name: 'pathTypeReadable'
})
export class PathTypeReadablePipe implements PipeTransform {

  transform(value: PathType): string {
    return PathType.getPathTypeReadAble(value);
  }

}
