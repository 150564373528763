import {BatchState} from "../../enums/BatchState";

export class BatchStateUpdateModel {

  id: number;
  version: number;
  batchState: BatchState;
  comment: string;


  constructor(id: number, version: number, batchState: BatchState) {
    this.id = id;
    this.version = version;
    this.batchState = batchState;
    // this.comment = comment;
  }

}
