import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {BatchModel} from "../../batch/batch.model";
import {WorkflowStepLibraryNgsSequencingModel} from "../workflow-step-library-ngs-sequencing.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";

export class WorkflowInstanceStepLibraryNgsSequencingEditModel extends WorkflowInstanceStepEditModel {

  batches: BatchModel[];

  static initFromStep(step: WorkflowStepLibraryNgsSequencingModel): WorkflowInstanceStepLibraryNgsSequencingEditModel {
    const ret = new WorkflowInstanceStepLibraryNgsSequencingEditModel();
    ret.workflowStep = WorkflowStep.NGS_SEQUENCING;
    ret.workflowInstanceStepId = step.id;
    ret.batches = step.batches.filter(BatchModel.isValid);
    return ret;
  }
}
