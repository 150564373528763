import {Pipe, PipeTransform} from "@angular/core";
import {getPositionStatusReadable, PositionStatus} from "../enums/PositionStatus";

@Pipe({
  name: 'getPositionStatusReadable'
})
export class PositionStateReadablePipe implements PipeTransform {

  transform(value: PositionStatus): string {
    return getPositionStatusReadable(value);
  }

}
