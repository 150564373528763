import {Component, Input, OnInit} from '@angular/core';
import {WorkFlowStepClass} from '../../models/workflow-step/workflow-step-base.model';
import {WorkflowDetailClientService} from '../dialog/workflow-detail-dialog/workflow-detail-client.service';
import {faFileImport, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
import {WorkflowStep} from '../../enums/WorkflowStep';
import {Workflow} from '../../enums/Workflow';
import {filter} from 'rxjs';
import {PlateMode} from '../../enums/PlateMode';
import {PlateModel} from '../../models/Plate/plate.model';

@Component({
  selector: 'app-workflow-step-common',
  templateUrl: './workflow-step-common.component.html',
  styleUrls: ['./workflow-step-common.component.scss']
})
export class WorkflowStepCommonComponent implements OnInit {
  WorkFlowStepClass = WorkFlowStepClass;
  Workflow = Workflow;
  WorkflowStep = WorkflowStep;
  PlateMode = PlateMode;
  faFileImport = faFileImport;
  faTriangleExclamation = faTriangleExclamation;

  @Input() readOnly: boolean = false;

  isStepSkipped = false;

  constructor(public workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.workflowDetailClientService.selectedStep$.pipe(filter(v => !!v)).subscribe((v) => {
      this.workflowDetailClientService.isStepValid$.next(false);
      this.isStepSkipped = v.isSkipped;
    });
   this.workflowDetailClientService.initPlatePlaceholders();
  }

  switchPlateViewDnaExtractionSwabLysis() {
    this.workflowDetailClientService.fetchWorkflowInstanceStepByStep(WorkflowStep.DNA_EXTRACTION_SWAB_LYSIS);
  }

  switchPlateViewToCalculation() {
    this.workflowDetailClientService.fetchWorkflowInstanceStepByStep(WorkflowStep.NORMALIZATION_PREPARATION);
  }
}
