<app-workflow-step-layout>
  <ng-container [formGroup]="form" info>
    <div>
      <h3>
        <ng-container *ngIf="plateList.length == 1">
          Importierte Swabsortier-Datei
        </ng-container>
        <ng-container *ngIf="plateList.length != 1">
          Importierte Swabsortier-Dateien
        </ng-container>
      </h3>

      <ul>
        <li *ngFor="let plate of plateList">
          {{plate}}
        </li>
      </ul>

      <app-positive-negative-control-selection
        *ngIf="selectedPlate != null"
        [isReadonly]="readOnly"
        (selectNewNegativeControl)="onSelectNewNegativeControlPosition($event)"
        (selectNewPositiveControl)="onSelectNewPositiveControlPosition($event)"
        [positionNegative]="getPositionForControlByCoordinates(selectedPlate.negativeControlPosX, selectedPlate.negativeControlPosY)"
        [positionPositive]="getPositionForControlByCoordinates(selectedPlate.positiveControlPosX, selectedPlate.positiveControlPosY)">
      </app-positive-negative-control-selection>

    </div>

    <app-position-detail
      *ngIf="selectedPosition"
      [position]="selectedPosition"
      [showChangeStateButton]="false"
      [showBloodDrawNumber]="true">
    </app-position-detail>
  </ng-container>

  <ng-container plate>
    <app-plate-view
      [selectedPlate]="selectedPlate"
      [plateMode]="PlateMode.POSITION_EXIST"
      [workflowInstance]="workflowInstance"
      [plates]="sampleSelection?.plates"
      (onPositionSelected)="onPositionSelected($event)"
      (onClearPositionSelected)="onPositionSelected(null)"
      (onPlateSelected)="selectedPlate = $event"
      [showPositiveControlInOrange]="true">
    </app-plate-view>
  </ng-container>
</app-workflow-step-layout>
