import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {Observable} from "rxjs";
import {CyclerRunThroughModel} from "../models/cycler-run-trough/cycler-run-through.model";
import {CyclerRunThroughEditModel} from "../models/cycler-run-trough/cycler-run-through-edit.model";

@Injectable({
  providedIn: 'root'
})
export class CyclerRunThroughService {

  private readonly baseUri: string;

  constructor(private http: HttpClient,
              private globals: Globals) {
    this.baseUri = this.globals.backendUri + '/cycler-run-through';
  }

  getAllByWorkflowStepId(id: number): Observable<CyclerRunThroughModel[]> {
    return this.http.get<CyclerRunThroughModel[]>(`${this.baseUri}/all-by-workflow-step/${id}`);
  }

  createNew(cyclerRunThrough: CyclerRunThroughModel): Observable<CyclerRunThroughModel> {
    return this.http.post<CyclerRunThroughModel>(`${this.baseUri}/create-new`, cyclerRunThrough);
  }

  confirmState(cyclerRunThroughEdit: CyclerRunThroughEditModel): Observable<CyclerRunThroughModel> {
    return this.http.put<CyclerRunThroughModel>(`${this.baseUri}/confirm-state`, cyclerRunThroughEdit);
  }
}
