import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DialogModule} from "primeng/dialog";
import {TableModule} from "primeng/table";
import {CheckboxModule} from "primeng/checkbox";
import { FileComponent } from './file/file.component';
import {DirectivesModule} from "../../directives/directives.module";
import {CustomPipeModule} from "../../pipes/custom-pipe.module";
import { PlateFileComponent } from './plate-file/plate-file.component';
import {ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    FileUploadComponent,
    FileComponent,
    PlateFileComponent
  ],
  exports: [
    FileUploadComponent,
    FileComponent,
    PlateFileComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    DialogModule,
    TableModule,
    CheckboxModule,
    DirectivesModule,
    CustomPipeModule,
    ReactiveFormsModule
  ]
})
export class FileUploadModule { }
