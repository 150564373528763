import {IWorkflowStepBase} from "./workflow-step-base.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {BatchModel} from "../batch/batch.model";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {Workflow} from "../../enums/Workflow";
import {PlateWithBatchModel} from "../Plate/plate-with-batch.model";
import {PositionModel} from "../Plate/position.model";
import {PositionStatus} from "../../enums/PositionStatus";

export class WorkflowStepAddingChecksModel implements IWorkflowStepBase {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  plates: PlateWithBatchModel[] = [];
  batches: BatchModel[] = [];

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, batches: BatchModel[], plates: PlateWithBatchModel[], isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.batches = batches.map(BatchModel.fromData);
    this.plates = plates.map(PlateWithBatchModel.fromData);
    this.initPositiveControlPositions();
  }

  initPositiveControlPositions() {
    for (let plate of this.plates) {
      const positiveControl = new PositionModel();
      positiveControl.posY = plate.positiveControlPosY;
      positiveControl.posX = plate.positiveControlPosX;
      positiveControl.status = PositionStatus.OK;
      plate.positions = [];
      plate.positions.push(positiveControl);
    }
  }

  isValid(): boolean {
    // check batch counter
    if (this.workflowInstance.workflow === Workflow.WORKFLOW_96) {
      return this.plates.length === 1 && this.plates.every(plate => plate?.id != null && plate.positiveProbe != null);
    } else {
      return this.plates.length === 4 && this.plates.every(plate => plate?.id != null && plate.positiveProbe != null);
    }
  }

}
