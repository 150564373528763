<div class="plate-container" #plateContainer (window:resize)="onResize(plateContainer)"
     [ngClass]="hasValidationMessage ? 'plate-container-with-validation-msg' : 'plate-container-with-validation-msg'">
  <!-- Plate -->
  <div [ngStyle]="{'width.px': plateWidth + 26}">
    <!-- Back button -->
    <div *ngIf="showBackButton" (click)="onBackButtonClicked.emit()" class="back-button">
      <fa-icon [icon]="faArrowLeft"></fa-icon>
      Zurück zur Übersicht
    </div>

    <div class="plate border">
      <div *ngIf="name" class="plate-name">
        {{name | truncate: plateWidth / 12}}
      </div>
      <div
        *ngIf="showNameLink"
        class="plate-name-link"
        [ngClass]="nameLink ? 'library-link' : ''"
        (click)="onNameLinkClick.emit($event)">
        {{ nameLink ?? '---' }}
      </div>
      <div *ngIf="plate?.importedFile" class="file-name">
        <app-file [file]="plate.importedFile" [maxNameLength]="plateWidth / 12"></app-file>
      </div>
      <!-- Header -->
      <div class="header">

        <!-- following element is a dummy used to have the same size in the header as in the content -->
        <div class="header-item"
             [ngClass]="{'header-item-96': workflow === Workflow.WORKFLOW_96 || workflow === Workflow.LIBRARY_96,
                       'header-item-384': workflow === Workflow.WORKFLOW_384 || workflow === Workflow.LIBRARY_384,
                       'row-label-small': isParentContainerSmall,
                       'row-label-medium': isParentContainerMedium,
                       'row-label-large': !isParentContainerSmall && !isParentContainerMedium
                       }">
        </div>

        <div *ngFor="let header of columns" class="header-item"
             [ngClass]="{'header-item-96': workflow === Workflow.WORKFLOW_96 || workflow === Workflow.LIBRARY_96,
                       'header-item-384': workflow === Workflow.WORKFLOW_384 || workflow === Workflow.LIBRARY_384,
                       'row-label-small': isParentContainerSmall,
                       'row-label-medium': isParentContainerMedium,
                       'row-label-large': !isParentContainerSmall && !isParentContainerMedium
                       }">
          {{header}}
        </div>
      </div>


      <div >
        <!-- Content / row -->
        <div *ngFor="let row of rows" class="row" [ngStyle]="{'margin-top.px': dynamicMarginTop, 'gap.px': dynamicGap}">
          <div class="row-label"
               [ngClass]="{'row-label-96': workflow === Workflow.WORKFLOW_96 || workflow === Workflow.LIBRARY_96,
                       'row-label-384': workflow === Workflow.WORKFLOW_384 || workflow === Workflow.LIBRARY_384,
                          'row-label-small': isParentContainerSmall,
                       'row-label-medium': isParentContainerMedium,
                       'row-label-large': !isParentContainerSmall && !isParentContainerMedium
                       }">
            {{row | positionYToLetter }}
          </div>
          <div
            (click)="onCellClick(findSelectedPositionInPlate(plate, column, row))"
               *ngFor="let column of columns"
               [ngClass]="plate?.getPositionByCoordinates(column, row) != null || setNegativeControl || setPositiveControl ? 'clickable' : ''"
               class="sample-container">
            <ng-container [ngSwitch]="plateMode">

              <ng-container *ngSwitchCase="PlateMode.DNA_WATER">
                <div
                  style="position: relative; overflow: visible;"
                  class="sample"
                     [ngClass]="{
                 'not-ok': plate?.getPositionByCoordinates(column, row)?.status === PositionStatus.NOT_OK,
                 'sample-large': !isParentContainerSmall,
                 'sample-small': isParentContainerSmall,
                 'dna-warning': plate?.getPositionByCoordinates(column, row)?.water === 0
              }">
                  <div  style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" class="numerical_value"
                       [ngClass]="{
                       'row-value-small': isParentContainerSmall || plate?.getPositionByCoordinates(column, row)?.water != null,
                       'row-value-medium': isParentContainerMedium && plate?.getPositionByCoordinates(column, row)?.water == null,
                       'row-value-large': !isParentContainerSmall && !isParentContainerMedium && plate?.getPositionByCoordinates(column, row)?.water == null
                       }">
                    <div>{{plate?.getPositionByCoordinates(column, row)?.dna?.toFixed(2)}}</div>
                    <div class="smaller-text">{{plate?.getPositionByCoordinates(column, row)?.water?.toFixed(2)}}</div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="PlateMode.STATUS">
                <div
                  [ngClass]="{
                 'ok':  plate?.getPositionByCoordinates(column, row)?.status === PositionStatus.OK,
                 'not-ok': plate?.getPositionByCoordinates(column, row)?.status === PositionStatus.NOT_OK,
                 'sample-large': !isParentContainerSmall,
                 'sample-small': isParentContainerSmall
              }"
                  class="sample clickable">
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="PlateMode.PIP_STATUS">
                <div [ngClass]="{
                 'ok':  plate?.getPositionByCoordinates(column, row)?.pipettingStatus === PositionStatus.OK,
                 'not-ok': plate?.getPositionByCoordinates(column, row)?.pipettingStatus === PositionStatus.NOT_OK,
                 'sample-large': !isParentContainerSmall,
                 'sample-small': isParentContainerSmall
              }"
                     class="sample">
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="PlateMode.POSITION_EXIST">
                <div class="sample"
                     [ngClass]="{
                 'ok':  plate?.getPositionByCoordinates(column, row) != null,
                 'positive-probe':  plate?.isPositiveProbe(column, row) && showPositiveControlInOrange,
                 'sample-large': !isParentContainerSmall,
                 'sample-small': isParentContainerSmall
              }">
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="PlateMode.RESULT">
                <div class="sample"
                     [ngClass]="{
                 'not-ok': plate?.getPositionByCoordinates(column, row)?.status === PositionStatus.NOT_OK,
                 'sample-large': !isParentContainerSmall,
                 'sample-small': isParentContainerSmall
              }">
                  <div *ngIf="plate && plate?.getPositionByCoordinates(column, row)?.status === PositionStatus.OK"
                       class="numerical_value"
                       [ngClass]="{
                       'row-value-small': isParentContainerSmall,
                       'row-value-medium': isParentContainerMedium,
                       'row-value-large': !isParentContainerSmall && !isParentContainerMedium
                       }">
                    <div>{{plate?.getPositionByCoordinates(column, row).value}}</div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="PlateMode.MEASURED">
                <div class="sample"
                     [ngClass]="{
                 'not-ok': plate?.getPositionByCoordinates(column, row)?.cutoffStatus === PositionStatus.NOT_OK,
                 'sample-large': !isParentContainerSmall,
                 'sample-small': isParentContainerSmall
              }">
                  <div *ngIf="plate && plate?.getPositionByCoordinates(column, row)?.measured"
                       class="numerical_value"
                       [ngClass]="{
                       'row-value-small': isParentContainerSmall,
                       'row-value-medium': isParentContainerMedium,
                       'row-value-large': !isParentContainerSmall && !isParentContainerMedium
                       }">
                    <div>{{convertNumber(plate?.getPositionByCoordinates(column, row)?.measured)}}</div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Validation message -->
  <ng-content></ng-content>
</div>
