import { Pipe, PipeTransform } from '@angular/core';
import {WorkflowStep} from "../enums/WorkflowStep";
import {WorkflowInstanceStepDto} from "../models/workflow/workflow-instance-step-dto";

@Pipe({
  name: 'isWorkflowStepSkipped'
})
export class IsWorkflowStepSkippedPipe implements PipeTransform {

  transform(workflowSteps: WorkflowInstanceStepDto[], stepToCheck: WorkflowStep): boolean {
    if (!workflowSteps || !workflowSteps.length || !stepToCheck) {
      return false;
    }

    return workflowSteps.find(ws => ws.step === stepToCheck)?.isSkipped;
  }

}
