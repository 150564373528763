import { Component } from '@angular/core';

@Component({
  selector: 'app-search-fields-container',
  template: `
    <div class="container">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./search-fields-container.component.scss']
})
export class SearchFieldsContainerComponent {

}
