<app-workflow-step-layout>
  <!-- Cycler -->
  <ng-container info>

    <ng-container *ngIf="batchFormArray && !selectedPlate">
      <h3>Verwendete Chargen</h3>
      <app-batches-selector
        [readOnly]="readOnly"
        [forms]="batchFormArray"
        (onChange)="validateStep()">
      </app-batches-selector>
    </ng-container>

    <div *ngIf="cyclerRunThroughFormArray && !selectedPlate">
      <ng-container *ngFor="let cyclerRunThroughFormGroup of cyclerRunThroughFormArray.controls">
        <ng-container *ngIf="cyclerRunThroughFormArray">
          <div class="data-row">
            <!-- workflow number -->
            <div class="plate-number">{{ cyclerRunThroughFormGroup.controls.suffix?.value }}</div>
            <!-- cycler -->
            <app-cycler-run-through
              class="cycler-run-through-margin"
              [readOnly]="readOnly"
              [cyclerRunThroughFormGroup]="cyclerRunThroughFormGroup"
              (cyclerRunThoughChanged)="validateStep()">
            </app-cycler-run-through>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <app-position-detail
      *ngIf="selectedPosition"
      [position]="selectedPosition"
      [showChangeStateButton]="!readOnly"
      [showPipettingStatus]="true">
    </app-position-detail>
  </ng-container>

  <!-- Plates -->
  <ng-container plate>
    <app-plate-view
      [showBackButton]="true"
      [plateMode]="PlateMode.PIP_STATUS"
      [workflowInstance]="workflowInstance"
      [plateSize]="workflowInstance.workflow"
      [plates]="workflowStep?.plates"
      (onPositionSelected)="onCellClick($event)"
      (onClearPositionSelected)="onCellClick(null)">
    </app-plate-view>
  </ng-container>

</app-workflow-step-layout>
