import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {PositionModel} from "../../models/Plate/position.model";

@Component({
  selector: 'app-positive-negative-control-selection',
  templateUrl: './positive-negative-control-selection.component.html',
  styleUrls: ['./positive-negative-control-selection.component.scss']
})
export class PositiveNegativeControlSelectionComponent implements OnChanges {

  @Input() positionPositive: PositionModel;
  @Input() positionNegative: PositionModel;

  @Input() isReadonly: boolean = false;

  @Output() selectNewNegativeControl: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectNewPositiveControl: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectPositive: boolean = false;
  selectNegative: boolean = false;

  isPositiveEditable: boolean = true;
  isNegativeEditable: boolean = true;

  ngOnChanges(changes: SimpleChanges): void {
    if ((!changes.positionPositive?.firstChange &&
        (changes.positionPositive?.currentValue?.posX !== changes.positionPositive?.previousValue?.posX ||
          changes.positionPositive?.currentValue?.posY !== changes.positionPositive?.previousValue?.posY)) ||
      (!changes.positionNegative?.firstChange &&
        (changes.positionNegative?.currentValue?.posX !== changes.positionNegative?.previousValue?.posX ||
          changes.positionNegative?.currentValue?.posY !== changes.positionNegative?.previousValue?.posY))) {
      this.selectPositive = false;
      this.selectNegative = false;
    }
  }

  changeNegativeControl() {
    if (!this.isReadonly && !this.selectPositive) {
      this.selectNewPositiveControl.emit(false);
      this.selectNegative = !this.selectNegative;
      this.selectNewNegativeControl.emit(this.selectNegative);
    }
  }

  changePositiveControl() {
    if (!this.isReadonly && !this.selectNegative) {
      this.selectNewNegativeControl.emit(false);
      this.selectPositive = !this.selectPositive;
      this.selectNewPositiveControl.emit(this.selectPositive);
    }
  }

}
