import {Component, Input} from "@angular/core";
import {AbstractControl, FormArray, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-pcr-tube-viewer',
  template: `
    <div [ngStyle]="getGridStyle()">
      <app-pcr-tube-icon
        *ngFor="let group of getForms(); let i = index"
        [readOnly]="readOnly"
        [form]="group ? getForm(group) : null"
        [ngStyle]="getChildStyle()">
      </app-pcr-tube-icon>
    </div>
  `,
  styles: []
})
export class PcrTubeViewerComponent {

  @Input() readOnly: boolean = false;
  @Input() form: FormArray;
  @Input() count: number = 1;

  // Get either the form controls or create an array based on count
  getForms(): (AbstractControl | null)[] {
    if (this.form) {
      return this.form.controls;
    } else {
      return Array(this.count).fill(null);
    }
  }

  // Cast the form control to FormGroup
  getForm(form: AbstractControl): FormGroup {
    return form as FormGroup;
  }

  // Dynamically set the grid layout based on the presence of form
  getGridStyle(): { [klass: string]: any } {
    if (this.form) {
      // If form exists, use 1 column and 4 rows (25% height each)
      return {
        'display': 'grid',
        'grid-template-columns': '100%',
        'grid-template-rows': 'repeat(4, 25%)',
        'height': '100%'
      };
    } else {
      if (this.count === 1)
        // If count is 1, center the single element with 25% size
        return {
          'display': 'flex',
          'justify-content': 'center',
          'align-items': 'center',
          'height': '100%',
          'width': '100%'
        };

      // If form is null, use 2x2 grid
      return {
        'display': 'grid',
        'grid-template-columns': '50% 50%',
        'grid-template-rows': '50% 50%',
        'height': '100%'
      };
    }
  }

  // Apply margin of 5rem when in 2x2 grid layout
  getChildStyle(): { [klass: string]: any } {
    if (!this.form) {
      if (this.count === 1) {
        // When count is 1, set the size to 25% and center it
        return {
          'width': '50%',
          'height': '50%',
          'padding': '2rem'
        };
      }
      // When using the 2x2 layout, apply margin to each child
      return {
        'padding': '2rem'
      };
    }
    return {}; // No margin when form is present
  }
}
