import {FileRecord} from "../file/FileRecord";

export class WorkflowStepLibraryPostSequencingSavSummaryModel {

  yieldTotal: number;
  aligned: number;
  total: number;
  density: string;
  clusterPf: string;
  clusterCountRaw: number;
  clusterCountPf: number;

  importedFile: FileRecord;

  static fromData(data: WorkflowStepLibraryPostSequencingSavSummaryModel): WorkflowStepLibraryPostSequencingSavSummaryModel {
    if (!data) return null;
    const obj = Object.assign(new WorkflowStepLibraryPostSequencingSavSummaryModel(), data);
    obj.importedFile = FileRecord.fromData(data.importedFile);
    return obj;
  }

}
