import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Globals} from "../global/globals";
import {map, Observable} from "rxjs";
import {PlateModel} from "../models/Plate/plate.model";
import {FileType} from "../enums/FileType";
import {WorkflowStep} from "../enums/WorkflowStep";

@Injectable({
  providedIn: 'root'
})
export class PlateService {

  private readonly baseUri: string;

  constructor(private http: HttpClient,
              private globals: Globals) {
    this.baseUri = this.globals.backendUri + '/plates'
  }

  getPlate(id: number): Observable<PlateModel> {
    return this.http.get<PlateModel>(`${this.baseUri}/` + id)
      .pipe(map(response => PlateModel.fromData(response)));
  }

  /**
   * gets all unused swab plates
   */
  getAllNotAssignedPlatesByFileType(): Observable<PlateModel[]> {
    return this.http.get<PlateModel[]>(`${this.baseUri}/all-not-assigned`)
      .pipe(map(resp => resp.map(PlateModel.fromData)));
  }

  exportFileByWorkflowAndStepAndPlate(workflowInstanceId: number, workflowStep: WorkflowStep, plateId: number): Observable<PlateModel> {
    return this.http.get<PlateModel>(`${this.baseUri}/export-file-by-instance-and-step-and-plate?workflowInstanceId=${workflowInstanceId}&workflowStep=${workflowStep}&plateId=${plateId}`)
      .pipe(map(resp => PlateModel.fromData(resp)));
  }

  uploadFileToPlate(plateId: number, fileType: FileType, newFile: FormData): Observable<PlateModel> {
    return this.http.post<PlateModel>(`${this.baseUri}/uploadFile?plateId=${plateId}&fileType=${fileType}`, newFile)
      .pipe(map(resp => PlateModel.fromData(resp)));
  }

  removeUploadedFileFromPlate(plateId: number): Observable<PlateModel> {
    return this.http.delete<PlateModel>(`${this.baseUri}/uploadFile/${plateId}`)
      .pipe(map(resp => PlateModel.fromData(resp)));
  }

}
