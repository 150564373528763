<div class="cycler-list-container">
  <ng-container *ngIf="cyclerRunThroughFormGroup && !readOnly" [formGroup]="cyclerRunThroughFormGroup">
    <ng-container *ngIf="!cyclerRunThroughFormGroup.get('confirmed').value">
      <app-cycler-selector
        [form]="cyclerRunThroughFormGroup"
        controlName="cyclerId">
      </app-cycler-selector>
    </ng-container>
    <ng-container *ngIf="cyclerRunThroughFormGroup.get('confirmed').value">
      <core-input-text
        formControlName="cyclerName"
        [topLabel]="null"
        [removeDefaultBottomErrorMargin]="true"
        [showClearButton]="false">
      </core-input-text>
    </ng-container>
    <!-- Cycler State -->
    <core-input-dropdown
      formControlName="state"
      [disabled]="readOnly"
      [options]="cyclerStateOptions"
      [removeDefaultBottomErrorMargin]="true"
      [showClearButton]="false">
    </core-input-dropdown>
    <!-- Start -->
    <core-button
      [disabled]="cyclerRunThroughFormGroup.get('cyclerId').invalid || cyclerRunThroughFormGroup.disabled || readOnly"
      *ngIf="cyclerRunThroughFormGroup.get('id').value === null"
      classList="p-button-success"
      (onClick)="createNew()"
      [text]="'Starten'"
      [width]="'100%'">
    </core-button>
    <!-- Confirm -->
    <core-button
      *ngIf="showConfirmButton()"
      classList="p-button-success"
      (onClick)="confirmState()"
      [disabled]="readOnly"
      [text]="'Bestätigen'">
    </core-button>
    <!-- Reset -->
    <core-button
      *ngIf="cyclerRunThroughFormGroup.get('state').value === CyclerState.NOT_OK && cyclerRunThroughFormGroup.get('confirmed').value"
      classList="p-button-secondary"
      [disabled]="readOnly"
      (onClick)="reset()"
      [text]="'Zurücksetzen'">
    </core-button>
  </ng-container>

  <ng-container *ngIf="cyclerRunThroughFormGroup && readOnly">
    <div  style="grid-column: span 2;">{{ cyclerRunThroughFormGroup.get('cyclerName')?.value }}</div>
    <div>{{ cyclerRunThroughFormGroup.get('state')?.value | crtStateReadable }}</div>
  </ng-container>
</div>
