import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'core-button',
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() id: string;

  @Input() disabled: boolean;
  @Input() text: string;
  @Input() icon: IconProp;
  @Input() width: string;
  @Input() backgroundColor: string;
  @Input() color: string;
  @Input() classList: string;
  @Input() fontSize: string = '0.85rem';

  @Output() onClick = new EventEmitter<any>();

  constructor() {
    if (!this.id) {
      this.id = 'core-button-' + Math.random();
    }
  }

  ngOnInit(): void {
    this.classList = this.classList + ' core-button-flex';
  }

}
