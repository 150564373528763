import {BatchState} from "../../enums/BatchState";
import {DateUtils} from "../../utils/date.utils";
import {BatchType} from "../../enums/BatchType";
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";

export interface BatchFormGroup {
  id: FormControl<number>,
  version: FormControl<number>,
  batchNumber: FormControl<string>,
  type: FormControl<BatchType>,
  batchState: FormControl<BatchState>,
  expirationDate: FormControl<Date>,
  approvedBy: FormControl<string>,
  approvedAt: FormControl<Date>,
  comment: FormControl<string>
}

export class BatchModel {

  id: number;
  version: number;
  batchNumber: string;
  type: BatchType;
  batchState: BatchState;
  expirationDate: Date;
  approvedBy: string;
  approvedAt: Date;
  comment: string;

  static fromData(data: BatchModel): BatchModel {
    if (data == null) return null;
    const ret = Object.assign(new BatchModel(), data);
    ret.approvedAt = DateUtils.fromDate(ret.approvedAt);
    ret.expirationDate = DateUtils.fromDate(ret.expirationDate);
    return ret;
  }

  static initForm(batchList: BatchModel[]): FormArray<FormGroup<BatchFormGroup>> {
    const ret: FormArray<FormGroup> = new FormArray<FormGroup>([]);
    batchList.forEach(batch => {
      ret.push(this.initFormGroup(batch));
    });
    return ret;
  }

  static initFormGroup(batch?: BatchModel): FormGroup<BatchFormGroup> {
    const ret = new FormGroup<BatchFormGroup>({
      id: new FormControl<number>(batch?.id),
      version: new FormControl<number>(batch?.version),
      batchNumber: new FormControl<string>(batch?.batchNumber, Validators.required),
      type: new FormControl<BatchType>(batch?.type, Validators.required),
      batchState: new FormControl<BatchState>(batch?.batchState),
      expirationDate: new FormControl<Date>(batch?.expirationDate, Validators.required),
      approvedBy: new FormControl<string>(batch?.approvedBy),
      approvedAt: new FormControl<Date>(batch?.approvedAt),
      comment: new FormControl<string>(batch?.comment)
    });

    if (batch?.id && batch.batchState !== BatchState.NEW) {
      ret.get('expirationDate').disable();
    }

    return ret;
  }

  static initFormArrayForBatchSelection(batchList: BatchModel[], disableBatchSelection: boolean = false, onlyShowNotSetExpirationDate: boolean = false): UntypedFormArray {
    const ret: UntypedFormArray = new FormArray<UntypedFormGroup>([]);
    // verify list is not null and not empty
    if (!batchList || batchList.length === 0) return ret;
    // sort by expiration date set first, then type
    batchList.sort((a, b) =>
      (a.type > b.type ? 1 : -1)
    );
    // create form controls
    batchList.forEach(batch => {
      ret.push(this.initFormForBatchSelection(batch, disableBatchSelection, onlyShowNotSetExpirationDate));
    });
    return ret;
  }

  static initFormForBatchSelection(batch: BatchModel, disableBatchSelection: boolean = false, onlyShowNotSetExpirationDate: boolean = false): UntypedFormGroup {
    const ret = new UntypedFormGroup({
      batch: new UntypedFormControl(batch),
      newExpirationDate: new UntypedFormControl(null),
      type: new UntypedFormControl(batch?.type),
      hideExpirationDate: new UntypedFormControl(onlyShowNotSetExpirationDate && batch?.expirationDate)
    });

    if (disableBatchSelection) {
      ret.get('batch').disable();
    }

    if (batch?.id) {
      ret.get('newExpirationDate').setValue(DateUtils.fromDate(batch.expirationDate));
    }
    return ret;
  }

  static getBatchesFromBatchSelectionFormArray(formArray: UntypedFormArray): BatchModel[] {
    const ret: BatchModel[] = [];
    for (const form of formArray.controls) {
      const batch = new BatchModel();
      batch.id = form.get('batch').value?.id;
      batch.batchNumber = form.get('batch').value?.batchNumber;
      batch.type = form.get('type').value;
      batch.expirationDate = form.get('newExpirationDate').value;
      ret.push(batch);
    }
    return ret;
  }

  static getBatchesFromBatchSelectionFormGroup(formGroup: UntypedFormGroup): BatchModel {
    const ret: BatchModel = new BatchModel();
    ret.id = formGroup.get('batch').value?.id;
    ret.batchNumber = formGroup.get('batch').value?.batchNumber;
    ret.type = formGroup.get('type').value;
    ret.expirationDate = formGroup.get('newExpirationDate').value;
    return ret;
  }

  static isValid(batch: BatchModel): boolean {
    if (!batch) return false;
    return batch.batchNumber != null && batch.expirationDate != null;
  }

}
