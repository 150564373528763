<div class="content">
  <ng-container *ngIf="!workflowDetailClientService.isLoading$.getValue()">
    <ng-container *ngIf="workflowDetailClientService.selectedStep$.getValue(); else stepPending">
      <ng-container *ngIf="!isStepSkipped; else stepSkippedContent">
        <ng-container [ngSwitch]="workflowDetailClientService.selectedStep$?.getValue()?.constructor.name">
          <app-preparation
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepPreparationModel"
            [workFlowStep]="(workflowDetailClientService.selectedStep$.getValue()) | workflowStepCast"
            [workflowInstance]="workflowDetailClientService.workflowInstance">
          </app-preparation>

          <app-sample-selection
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepSampleSelectionModel"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [sampleSelection]="(workflowDetailClientService.selectedStep$.getValue()) | workflowStepCast"
            [readOnly]="readOnly">
          </app-sample-selection>

          <app-adding-checks
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepAddingChecksModel"
            [readOnly]="readOnly"
            [workflowStep]="(workflowDetailClientService.selectedStep$.getValue()) | workflowStepCast"
            [workflowInstance]="workflowDetailClientService.workflowInstance">
          </app-adding-checks>

          <app-dna-extraction
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepDnaExtractionModel"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="(workflowDetailClientService.selectedStep$.getValue()) | workflowStepCast">
          </app-dna-extraction>

          <app-dna-extraction-swab-lysis
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepDnaExtractionSwabLysisModel"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="(workflowDetailClientService.selectedStep$.getValue()) | workflowStepCast">
          </app-dna-extraction-swab-lysis>

          <app-quantification-first
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepQuantificationFirstModel"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="(workflowDetailClientService.selectedStep$.getValue()) | workflowStepCast">
          </app-quantification-first>

          <app-measurement-on-glomax-first
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepMeasurementOnGlomaxFirstModel"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast">
          </app-measurement-on-glomax-first>

          <app-target-pcr-setup
            *ngSwitchCase="WorkFlowStepClass.WorkFlowStepTargetPcrModel"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="(workflowDetailClientService.selectedStep$.getValue()) | workflowStepCast">
          </app-target-pcr-setup>

          <app-normalisation-preparation
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepNormalisationPreparationModel"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="(workflowDetailClientService.selectedStep$.getValue() | workflowStepCast)">
          </app-normalisation-preparation>

          <app-normalisation
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepNormalisation"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast">
          </app-normalisation>

          <app-target-pooling
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepTargetPoolingModel"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast">
          </app-target-pooling>

          <app-purification
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepPurificationModel"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast">
          </app-purification>

          <app-index-pcr-setup
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepIndexPcrSetupModel"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast">
          </app-index-pcr-setup>

          <app-quantification-second
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepQuantificationSecondModel"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast"
          ></app-quantification-second>
          <app-measurement-on-glomax-second
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepMeasurementOnGlomaxSecondModel"
            [readOnly]="readOnly"
            [workflowInstance]="workflowDetailClientService.workflowInstance"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast">
          </app-measurement-on-glomax-second>

          <!-- library -->
          <app-library-plate-selection
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepLibraryPlateSelectionModel"
            [readOnly]="readOnly"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast"
            [workflowInstance]="workflowDetailClientService.workflowInstance">
          </app-library-plate-selection>

          <app-library-normalisation-and-pooling
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepLibraryNormalisationAndPooling"
            [readOnly]="readOnly"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast"
            [workflowInstance]="workflowDetailClientService.workflowInstance">
          </app-library-normalisation-and-pooling>

          <app-library-index-purification
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepLibraryIndexPurificationModel"
            [readOnly]="readOnly"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast"
            [workflowInstance]="workflowDetailClientService.workflowInstance">
          </app-library-index-purification>

          <app-library-agilant
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepLibraryAgilantModel"
            [readOnly]="readOnly"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast"
            [workflowInstance]="workflowDetailClientService.workflowInstance">
          </app-library-agilant>

          <app-library-qubit
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepLibraryQubitModel"
            [readOnly]="readOnly"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast"
            [workflowInstance]="workflowDetailClientService.workflowInstance">
          </app-library-qubit>

          <app-library-pooling
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepLibraryPoolingModel"
            [readOnly]="readOnly"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast"
            [workflowInstance]="workflowDetailClientService.workflowInstance">
          </app-library-pooling>

          <app-library-ngs-sequencing
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepLibraryNgsSequencingModel"
            [readOnly]="readOnly"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast"
            [workflowInstance]="workflowDetailClientService.workflowInstance">
          </app-library-ngs-sequencing>

          <app-library-post-sequencing
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepLibraryPostSequencingModel"
            [readOnly]="readOnly"
            [workflowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast"
            [workflowInstance]="workflowDetailClientService.workflowInstance">
          </app-library-post-sequencing>

          <app-finished
            *ngSwitchCase="WorkFlowStepClass.WorkflowStepFinishedModel"
            [workFlowStep]="workflowDetailClientService.selectedStep$.getValue() | workflowStepCast">
          </app-finished>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<!-- Template for pending step -->
<ng-template #stepPending>
  <app-workflow-step-layout>
    <div info class="pending-step-info">
      <fa-icon [icon]="faFileImport"></fa-icon>
      <div>Warten auf das Empfangen der Datei</div>

      <!-- this button should be shown if the current step is DNA_EXTRACTION_SWAB_LYSIS -->
      <div
        class="plate-swap-button"
        *ngIf="workflowDetailClientService.workflowInstance?.currentStep === WorkflowStep.DNA_EXTRACTION_SWAB">
        <core-button
          [text]="'Zur Swab-Lysis Platte wechseln'"
          [backgroundColor]="'#607D8B'"
          (onClick)="switchPlateViewDnaExtractionSwabLysis()">
        </core-button>
      </div>

      <!-- this button should be shown if the current step is NORMALIZATION -->
      <div
        class="plate-swap-button"
        *ngIf="workflowDetailClientService.workflowInstance?.currentStep === WorkflowStep.NORMALIZATION">
        <core-button
          [text]="'Zur Berechnung wechseln'"
          [backgroundColor]="'#607D8B'"
          (onClick)="switchPlateViewToCalculation()">
        </core-button>
      </div>

    </div>
    <div plate class="pending-step-plate">
      <app-plate-view
        *ngIf="workflowDetailClientService?.workflowInstance?.workflow === Workflow.WORKFLOW_96 ||
        workflowDetailClientService?.workflowInstance?.workflow === Workflow.WORKFLOW_384"
        [workflowInstance]="workflowDetailClientService?.workflowInstance"
        [plateMode]="PlateMode.PIP_STATUS"
        [plateSize]="workflowDetailClientService?.workflowInstance?.workflow | plateSize: workflowDetailClientService?.workflowInstance?.currentStep"
        [plates]="workflowDetailClientService?.platePlaceholders">
      </app-plate-view>
      <app-pcr-tube-viewer
        [count]="workflowDetailClientService?.workflowInstance?.workflow | pcrTubeSize: workflowDetailClientService?.workflowInstance?.currentStep"
        *ngIf="workflowDetailClientService?.workflowInstance?.workflow === Workflow.LIBRARY_96 ||
      workflowDetailClientService?.workflowInstance?.workflow === Workflow.LIBRARY_384">
      </app-pcr-tube-viewer>
    </div>
  </app-workflow-step-layout>
</ng-template>

<!-- template for skipped step! -->
<ng-template #stepSkippedContent>
  <app-workflow-step-layout>
    <div info class="pending-step-info">
      <fa-icon [icon]="faTriangleExclamation"></fa-icon>
      <div>Dieser Schritt wurde übersprungen!</div>
    </div>
    <div plate class="pending-step-plate">
      <app-plate-view
        *ngIf="workflowDetailClientService?.workflowInstance?.workflow === Workflow.WORKFLOW_96 ||
        workflowDetailClientService?.workflowInstance?.workflow === Workflow.WORKFLOW_384"
        [workflowInstance]="workflowDetailClientService?.workflowInstance"
        [plateMode]="PlateMode.PIP_STATUS"
        [plateSize]="workflowDetailClientService?.workflowInstance?.workflow | plateSize: workflowDetailClientService?.workflowInstance?.currentStep"
        [plates]="workflowDetailClientService?.platePlaceholders">
      </app-plate-view>
      <app-pcr-tube-viewer
        [count]="workflowDetailClientService?.workflowInstance?.workflow | pcrTubeSize: workflowDetailClientService?.workflowInstance?.currentStep"
        *ngIf="workflowDetailClientService?.workflowInstance?.workflow === Workflow.LIBRARY_96 ||
      workflowDetailClientService?.workflowInstance?.workflow === Workflow.LIBRARY_384">
      </app-pcr-tube-viewer>
    </div>
  </app-workflow-step-layout>
</ng-template>


