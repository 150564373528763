import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {PositionModel} from "../../../../models/Plate/position.model";
import {BatchModel} from "../../../../models/batch/batch.model";
import {BatchType} from "../../../../enums/BatchType";
import {PlateMode} from "../../../../enums/PlateMode";
import {WorkflowStepNormalisation} from "../../../../models/workflow-step/workflow-step-normalization.model";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {WorkflowStep} from "../../../../enums/WorkflowStep";
import {UntypedFormArray} from "@angular/forms";

@Component({
  selector: 'app-normalisation',
  templateUrl: './normalisation.component.html',
  styleUrls: ['./normalisation.component.scss']
})
export class NormalisationComponent implements OnInit {
  BatchType = BatchType;
  PlateMode = PlateMode;
  batchFormArray: UntypedFormArray;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepNormalisation;
  @Input() readOnly: boolean = false;

  selectedPosition: PositionModel;

  constructor(private workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.batchFormArray = BatchModel.initFormArrayForBatchSelection(this.workflowStep.batches, true, false);
    this.validateStep();
  }

  validateStep() {
    this.workflowStep.batches = BatchModel.getBatchesFromBatchSelectionFormArray(this.batchFormArray);
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

  onCellClick(position: PositionModel) {
    this.selectedPosition = position;
  }

  switchPlateView() {
    this.workflowDetailClientService.fetchWorkflowInstanceStepByStep(WorkflowStep.NORMALIZATION_PREPARATION);
  }

}
