<div class="container">

  <div>
    <h3>SAV Summary</h3>
    <div *ngIf="workflowStep.savSummary" class="grid">
      <div>Yield Total (G)</div>
      <div>{{workflowStep.savSummary?.yieldTotal}}</div>
      <div>Aligned (%)</div>
      <div>{{workflowStep.savSummary?.aligned}}</div>
      <div>Total (%)</div>
      <div>{{workflowStep.savSummary?.total}}</div>
      <div>Density (K/mm2)</div>
      <div>{{workflowStep.savSummary?.density}}</div>
      <div>Cluster PF (%)</div>
      <div>{{workflowStep.savSummary?.clusterPf}}</div>
      <div>Cluster Count Raw (M)</div>
      <div>{{workflowStep.savSummary?.clusterCountRaw}}</div>
      <div>Cluster Count PF (M)</div>
      <div>{{workflowStep.savSummary?.clusterCountPf}}</div>
    </div>
    <div *ngIf="!workflowStep.savSummary" class="missing-import">
      <fa-icon [icon]="faFileImport"></fa-icon>
      <div>Warten auf das Empfangen der Datei</div>
    </div>
  </div>

  <div [ngClass]="{'grid-span-run-params': workflowStep.runParameters}">
    <h3>Run Parameters</h3>
    <div *ngIf="workflowStep.runParameters" class="grid">
      <div>Application Version</div>
      <div>{{workflowStep.runParameters?.applicationVersion}}</div>
      <div>Run ID</div>
      <div>{{workflowStep.runParameters?.runID}}</div>
      <div>RunNumber</div>
      <div>{{workflowStep.runParameters?.runNumber}}</div>
      <div>MCSVersion</div>
      <div>{{workflowStep.runParameters?.mcsVersion}}</div>
      <div>RTAVersion</div>
      <div>{{workflowStep.runParameters?.rtaVersion}}</div>
      <div>Barcode</div>
      <div>{{workflowStep.runParameters?.barcode}}</div>
      <div>PR2BottleBarcode</div>
      <div>{{workflowStep.runParameters?.pr2BottleBarcode}}</div>
      <div>ReagentKitPartNumberEntered</div>
      <div>{{workflowStep.runParameters?.reagentKitPartNumberEntered}}</div>
      <div>ReagentKitVersion</div>
      <div>{{workflowStep.runParameters?.reagentKitVersion}}</div>
      <div>ReagentKitBarcode</div>
      <div>{{workflowStep.runParameters?.reagentKitBarcode}}</div>
      <div>ExperimentName</div>
      <div>{{workflowStep.runParameters?.experimentName}}</div>
      <div>LibraryPrepKit</div>
      <div>{{workflowStep.runParameters?.libraryPrepKit}}</div>
      <div>IndexKit</div>
      <div>{{workflowStep.runParameters?.indexKit}}</div>
      <div>Chemistry</div>
      <div>{{workflowStep.runParameters?.chemistry}}</div>
      <div>Username</div>
      <div>{{workflowStep.runParameters?.username}}</div>
      <div>SampleSheetName</div>
      <div>{{workflowStep.runParameters?.sampleSheetName}}</div>
    </div>
    <div *ngIf="!workflowStep.runParameters" class="missing-import">
      <fa-icon [icon]="faFileImport"></fa-icon>
      <div>Warten auf das Empfangen der Datei</div>
    </div>
  </div>

  <div
    *ngIf="!readOnly"
    [ngClass]="{'grid-span-file-drop': !workflowStep.runParameters}">
    <app-file-upload [clearFilesOnInput]="true" (onFileSelect)="onUploadNewFile($event)"></app-file-upload>
  </div>

</div>
