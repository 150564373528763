export class TableColumnModel {
  label: string;
  field: string;
  subField: string;
  width: string;

  constructor(label: string, field: string, subField: string, width?: string) {
    this.label = label;
    this.field = field;
    this.subField = subField;
    this.width = width;
  }

  static initBatchTableColumns(): TableColumnModel[] {
    const ret: TableColumnModel[] = [];
    ret.push(new TableColumnModel('Chargennumer', 'batchNumber', null));
    ret.push(new TableColumnModel('Typ', 'type', null));
    ret.push(new TableColumnModel('Status', 'batchState', null));
    ret.push(new TableColumnModel('Ablaufdatum', 'expirationDate', null));
    ret.push(new TableColumnModel('Freigegeben Von', 'approvedBy', null));
    ret.push(new TableColumnModel('Freigegeben am', 'approvedAt', null));
    return ret;
  }

  static initFileProcessingHistoryTableColumns(): TableColumnModel[] {
    const ret: TableColumnModel[] = [];
    ret.push(new TableColumnModel('Datum', 'created', null, '10%'));
    ret.push(new TableColumnModel('Pfad', 'path', null, '50%'));
    ret.push(new TableColumnModel('Fehler', 'errorType', null, '25%'));
    ret.push(new TableColumnModel('Bestätigt', 'confirmed', null, '15%'));
    return ret;
  }

  static initWorkflowInstanceArchiveColumns(): TableColumnModel[] {
    const ret: TableColumnModel[] = [];
    ret.push(new TableColumnModel('Barcode', 'workflowNumber', null));
    ret.push(new TableColumnModel('Workflow', 'workflow', null));
    ret.push(new TableColumnModel('Aktueller Schritt', 'currentStep', null));
    ret.push(new TableColumnModel('Zuletzt geändert am', 'updated', null));
    ret.push(new TableColumnModel('Zuletzt geändert von', 'updatedBy', null));
    return ret;
  }
}
