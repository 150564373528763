<button
  (click)="onClick.emit($event)"
  [classList]="classList"
  [disabled]="disabled"
  [id]="id"
  [ngStyle]="{width: width, backgroundColor: backgroundColor, borderColor: backgroundColor, color: color, 'font-size': fontSize}"
  pButton>
  <fa-icon
    *ngIf="icon"
    [icon]=icon
    [ngClass]="icon && text ? 'core-button-space-between-icon-and-text' : ''">
  </fa-icon>
  {{text}}
</button>
