import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'positionYToLetter'
})
export class PositionYToLetterPipe implements PipeTransform {

  /**
   * currently only one upper case letter supported
   * @param index index started with one
   * return letter string
   */

  transform(index: number): string {
    if (index == null) return '';
    const decimalASCIICode = index + 64;
    if (decimalASCIICode < 64 || decimalASCIICode > 90) return '';
    return String.fromCharCode(decimalASCIICode);
  }

}
