import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {inOutAnimation} from "../../animations/in-out.animation";
import {CyclerRunThroughModel} from "../../models/cycler-run-trough/cycler-run-through.model";
import {SelectItem} from "../../models/select-item.model";
import {CyclerRunThroughState} from "../../enums/CyclerRunThroughState";
import {CyclerRunThroughService} from "../../services/cycler-run-through.service";
import {MessageService} from "../../services/message.service";
import {CyclerRunThroughEditModel} from "../../models/cycler-run-trough/cycler-run-through-edit.model";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-cycler-run-through',
  templateUrl: './cycler-run-through.component.html',
  styleUrls: ['./cycler-run-through.component.scss'],
  animations: [inOutAnimation]
})
export class CyclerRunThroughComponent implements OnInit {

  CyclerState = CyclerRunThroughState;

  @Input() readOnly: boolean = false;
  @Input() cyclerRunThroughFormGroup: FormGroup;
  @Output() cyclerRunThoughChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  cyclerStateOptions: SelectItem[];

  constructor(private cyclerRunThroughService: CyclerRunThroughService,
              private messageService: MessageService) {}

  ngOnInit(): void {
    this.cyclerStateOptions = CyclerRunThroughState.getAllAsSelectItem();
  }

  createNew(): void {
    const cyclerRunThrough = this.cyclerRunThroughFormGroup.getRawValue() as CyclerRunThroughModel;
    cyclerRunThrough.state = CyclerRunThroughState.OK;
    this.cyclerRunThroughService.createNew(cyclerRunThrough).subscribe({
      next: (resp) =>  {
        this.messageService.addSuccessMessage("Cycler erfolgreich gestartet!")
        CyclerRunThroughModel.updateFormGroupFromResponse(this.cyclerRunThroughFormGroup, resp);
        this.cyclerRunThoughChanged.emit(true);
      }
    })
  }

  confirmState(): void {
    const cyclerRunThrough = this.cyclerRunThroughFormGroup.getRawValue() as CyclerRunThroughModel;
    const editModel = CyclerRunThroughEditModel.fromCyclerRunThrough(cyclerRunThrough);
    this.cyclerRunThroughService.confirmState(editModel).subscribe({
      next: (resp) =>  {
        this.messageService.addSuccessMessage("Status erfolgreich bestätigt!")
        CyclerRunThroughModel.updateFormGroupFromResponse(this.cyclerRunThroughFormGroup, resp);
        this.cyclerRunThoughChanged.emit(true);
      }
    })
  }

  reset() {
    this.cyclerRunThroughFormGroup.get('id').setValue(null);
    this.cyclerRunThroughFormGroup.get('version').setValue(null);

    this.cyclerRunThroughFormGroup.get('cyclerId').setValue(null);
    this.cyclerRunThroughFormGroup.get('cyclerId').enable();

    this.cyclerRunThroughFormGroup.get('state').setValue(null);
    this.cyclerRunThroughFormGroup.get('state').updateValueAndValidity();

    this.cyclerRunThroughFormGroup.get('confirmed').setValue(null);
    this.cyclerRunThoughChanged.emit(true);
  }

  showConfirmButton() {
    return !this.cyclerRunThroughFormGroup.value.confirmed && this.cyclerRunThroughFormGroup.value.state;
  }

}
