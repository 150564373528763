import {PositionModel} from "./position.model";
import {FileRecord} from "../file/FileRecord";
import {Workflow} from "../../enums/Workflow";
import {WorkflowStep} from "../../enums/WorkflowStep";

const workflow384With96Plate: WorkflowStep[] = [WorkflowStep.NORMALIZATION_PREPARATION, WorkflowStep.NORMALIZATION, WorkflowStep.TARGET_PCR, WorkflowStep.TARGET_POOLING]

export class PlateModel {

  id: number;
  version: number;
  suffix: string;
  positions: PositionModel[];
  plateIndex: number;

  negativeControlPosX: number;
  negativeControlPosY: number;
  positiveControlPosX: number;
  positiveControlPosY: number;
  dnaEntered: boolean;

  importedFile: FileRecord;
  exportedFile: FileRecord;
  uploadedFile: FileRecord;

  static fromData(data: PlateModel): PlateModel {
    if (!data) return null;
    const d = Object.assign(new PlateModel(), data);
    if (d.exportedFile) d.exportedFile = FileRecord.fromData(d.exportedFile);
    if (d.importedFile) d.importedFile = FileRecord.fromData(d.importedFile);
    if (d.uploadedFile) d.uploadedFile = FileRecord.fromData(d.uploadedFile);
    return d;
  }

  static initPlaceHolderArray(workflow: Workflow, currentStep: WorkflowStep): PlateModel[] {
    if (workflow === Workflow.WORKFLOW_96 || workflow384With96Plate.includes(currentStep)) {
      return new Array(1).fill(new PlateModel());
    }

    if (workflow === Workflow.WORKFLOW_384) {
      return new Array(4).fill(new PlateModel());
    }

    return [];
  }

  getPositionByCoordinates(posX: number, posY: number): PositionModel {
    const positionIndex = this.positions?.findIndex(po => po.posX === posX && po.posY === posY);
    return positionIndex > -1 ? this.positions[positionIndex] : null;
  }

  isPositiveProbe(posX: number, posY: number): boolean {
    return this.positions?.filter(p => p.posX === posX && p.posY === posY).length > 0 &&
      posX == this.positiveControlPosX && posY == this.positiveControlPosY;
  }

}
