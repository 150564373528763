import {IWorkflowStepBase} from "./workflow-step-base.model";
import {IWorkflowStepValidation} from "./workflow-step-validation.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {PlateModel} from "../Plate/plate.model";
import {Workflow} from "../../enums/Workflow";

export class WorkflowStepQuantificationSecondModel implements IWorkflowStepBase, IWorkflowStepValidation {
  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  plates: PlateModel[];

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, plates: PlateModel[], isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.plates = plates.map(PlateModel.fromData);
  }

  isValid(): boolean {
    // check plate counter
    if (this.workflowInstance.workflow === Workflow.WORKFLOW_96) {
      return this.plates.length === 1 && this.plates.every(plate => plate?.id != null && plate?.uploadedFile != null);
    } else {
      return this.plates.length === 4 && this.plates.every(plate => plate?.id != null && plate?.uploadedFile != null);
    }
  }

}
