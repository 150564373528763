import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {WorkflowStepFinishedModel} from "../../../../models/workflow-step/workflow-step-finished.model";
import {Globals} from "../../../../global/globals";
import {WorkflowInstanceService} from "../../../../services/workflow-instance.service";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";

@Component({
  selector: 'app-finished',
  templateUrl: './finished.component.html',
  styleUrls: ['./finished.component.scss']
})
export class FinishedComponent implements OnInit{

  @Input() workFlowStep: WorkflowStepFinishedModel;

  usedInLibrary: WorkflowInstanceModel;

  constructor(public globals: Globals,
              private workflowInstanceService: WorkflowInstanceService,
              public workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.fetchUsedInLibrary();
  }

  private fetchUsedInLibrary(): void {
    this.workflowInstanceService.getUsedInLibrary(this.workFlowStep.workflowInstance.id)
      .subscribe(resp => this.usedInLibrary = resp);
  }

  navigateToLibrary(): void {
    if (this.usedInLibrary) {
      this.workflowDetailClientService.jumpToWorkflowInstance(this.usedInLibrary);
    }
  }

}
