import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { PlateMode } from '../../enums/PlateMode';
import { PositionStatus } from '../../enums/PositionStatus';
import { Workflow } from '../../enums/Workflow';
import { WorkflowStep } from '../../enums/WorkflowStep';
import { PlateModel } from '../../models/Plate/plate.model';
import { PositionModel } from '../../models/Plate/position.model';
import { WorkFlowStepClass } from '../../models/workflow-step/workflow-step-base.model';
import { WorkflowDetailClientService } from '../dialog/workflow-detail-dialog/workflow-detail-client.service';

@Component({
  selector: 'app-plate',
  templateUrl: './plate.component.html',
  styleUrls: ['./plate.component.scss']
})
export class PlateComponent implements OnInit, AfterViewInit {

  PlateMode = PlateMode;
  Workflow = Workflow;

  faArrowLeft = faArrowLeft;

  @Input() name: string;
  @Input() plateMode: PlateMode = PlateMode.POSITION_EXIST;
  @Input() workflow: Workflow = Workflow.WORKFLOW_96;
  @Input() plate: PlateModel;
  @Input() positiveControl: PositionModel;
  @Input() negativeControl: PositionModel;
  @Input() setNegativeControl: boolean = false;
  @Input() setPositiveControl: boolean = false;
  @Input() showPositiveControlInOrange: boolean = false;
  @Input() ignoreHeightBoundary: boolean = false;
  @Input() hasValidationMessage: boolean = false;

  @Input() nameLink: string;
  @Input() showNameLink: boolean;
  @Output() onNameLinkClick = new EventEmitter<Event>();

  @Input() showBackButton: boolean = false;
  @Output() onBackButtonClicked = new EventEmitter<Event>();

  @Output() onPositionClick = new EventEmitter<PositionModel>();

  @ViewChild('plateContainer', {static: false}) plateContainer: ElementRef;

  columns: number[];
  rows: number[];
  PositionStatus = PositionStatus;
  WorkflowStep = WorkflowStep;
  WorkFlowStepClass = WorkFlowStepClass;
  showDetail = false;

  // stores if the calc got done the first time
  firstCalc = true;
  isParentContainerSmall: boolean = false;
  isParentContainerMedium: boolean = false;
  plateHeight: number;
  plateWidth: number;

  constructor(private cd: ChangeDetectorRef,
              private workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.initPlateByWorkflow();
  }

  ngAfterViewInit() {
    this.onResize(this.plateContainer.nativeElement);
  }

  onCellClick(position: PositionModel) {
    if (position) {
      this.workflowDetailClientService.positionClicked$.next(true);
    }
    this.onPositionClick.emit(position);
  }

  findSelectedPositionInPlate(plate: PlateModel, column: number, row: number) {
    // if the user selects a positive or negative control just return the x and y
    if (this.setPositiveControl || this.setNegativeControl) {
      return {posX: column, posY: row} as any;
    }
    // otherwise search for position and return null if it cant be foundr
    return plate?.getPositionByCoordinates(column, row);
  }

  private initPlateByWorkflow(): void {
    switch (this.workflow) {
      case Workflow.WORKFLOW_96:
      case Workflow.LIBRARY_96:
        this.columns = Array.from({length: 12}, (_, i) => i + 1);
        this.rows = Array.from({length: 8}, (_, i) => i + 1);
        break;
      case Workflow.WORKFLOW_384:
      case Workflow.LIBRARY_384:
        this.columns = Array.from({length: 24}, (_, i) => i + 1);
        this.rows = Array.from({length: 16}, (_, i) => i + 1);
        break;
    }
  }

  onResize(plateContainer: HTMLElement): void {
    let wholeWidth = plateContainer.clientWidth;
    let wholeHeight = plateContainer.clientHeight;

    // calc font size
    this.isParentContainerSmall =
      wholeWidth <=
      (Workflow.WORKFLOW_96 === this.workflow ? 360 : 600) ||
      (!this.ignoreHeightBoundary &&
        wholeHeight <=
        (Workflow.WORKFLOW_96 === this.workflow ? 320 : 480));
    if (!this.isParentContainerSmall) {
      this.isParentContainerMedium =
        wholeWidth <=
        (Workflow.WORKFLOW_96 === this.workflow ? 650 : 1300) ||
        (!this.ignoreHeightBoundary &&
          wholeHeight <=
          (Workflow.WORKFLOW_96 === this.workflow ? 500 : 1000));
    }

    if (this.plateMode === PlateMode.DNA_WATER) {
      this.isParentContainerSmall = wholeHeight < 480;
      this.isParentContainerMedium = wholeHeight >= 480;
    }

    const rows = Workflow.WORKFLOW_96 === this.workflow ? 8 : 16;
    const columns = Workflow.WORKFLOW_96 === this.workflow ? 13 : 25;
    // ratio should be 13 : 8
    // remove some space to have a nicer margin
    let width = wholeWidth * 0.82;
    let height = plateContainer.clientHeight * 0.82;

    // - header size
    height = height - 30;
    // - imported file
    if (this.plate?.importedFile) {
      height = height - 50;
    }
    // - plate name
    if (this.name) {
      height = height - 20;
    }
    // - plate link name
    if (this.showNameLink) {
      height = height - 20;
    }
    const calcWidth = height / rows * columns;
    const calcHeight = width / columns * rows;
    if (width < calcWidth || this.ignoreHeightBoundary) {
      this.plateWidth = width;
      this.plateHeight = calcHeight;
    } else if (height < calcHeight) {
      this.plateWidth = calcWidth;
      this.plateHeight = height;
    }
    // then add some padding
    // 10% of the plate height and width
    this.plateHeight = this.plateHeight - 10;
    this.plateWidth = this.plateWidth - 20;
    this.cd.detectChanges();
  }

  convertNumber(num: number | null): string {
    if (!num) return null;
    return num.toFixed(0);
  }

}
