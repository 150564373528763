import {Component, Input} from '@angular/core';
import {PlateMode} from "../../../../enums/PlateMode";
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {
  WorkflowStepNormalisationPreparationModel
} from "../../../../models/workflow-step/workflow-step-normalisation-preparation.model";
import {PositionModel} from "../../../../models/Plate/position.model";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {WorkflowStep} from "../../../../enums/WorkflowStep";
import {$e} from "codelyzer/angular/styles/chars";

@Component({
  selector: 'app-normalisation-preparation',
  templateUrl: './normalisation-preparation.component.html',
  styleUrls: ['./normalisation-preparation.component.scss']
})
export class NormalisationPreparationComponent {
  WorkflowStep = WorkflowStep;
  PlateMode = PlateMode;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepNormalisationPreparationModel;
  @Input() readOnly: boolean = false;

  selectedPosition: PositionModel;

  constructor(public workflowDetailClientService: WorkflowDetailClientService) {
    // this is always false
    this.workflowDetailClientService.isStepValid$.next(
      this.workflowDetailClientService.workflowInstance.currentStep !== WorkflowStep.NORMALIZATION_PREPARATION);
  }

  onCellClick(position: PositionModel) {
    this.selectedPosition = position;
  }

  switchToPlateImport() {
    this.workflowDetailClientService.nextStep();
  }

  switchToPlateView() {
    this.workflowDetailClientService.fetchWorkflowInstanceStepByStep(WorkflowStep.NORMALIZATION);
  }

  exportCalculation() {
    this.workflowDetailClientService.nextStep();
  }

  reExportCalculation() {
    this.workflowDetailClientService.reExportFile(WorkflowStep.NORMALIZATION_PREPARATION, this.workflowStep?.plates[0]?.id);
  }

  protected readonly $e = $e;
}
