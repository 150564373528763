<app-workflow-step-layout>
  <div class="info" info>
    <ng-container *ngIf="!selectedPlate">
      <h4>Wählen Sie eine Platte aus, um die Dateien hochzuladen</h4>
    </ng-container>

    <ng-container *ngIf="selectedPlate">
      <app-plate-file
        header="Dateien"
        [plate]="selectedPlate"
        [deletable]="workflowDetailClientService.workflowInstance.currentStep === WorkflowStep.QUANTIFICATION_SECOND"
        [readonly]="readOnly"
        [fileType]="FileType.INTEGRA"
        (fileUploaded)="onFileUploadedToSelectedPlate($event)"
        (fileRemoved)="onFileRemovedFromSelectedPlate()">
      </app-plate-file>
    </ng-container>
  </div>
  <ng-container plate>
    <ng-container *ngIf="!selectedPlate">
      <div class="plate-list-container-q2">
        <div *ngFor="let plate of workflowStep?.plates let i = index"
             class="plate-container-q2"
             (click)="onPlateClicked(plate)">
          <app-plate
            [plateMode]="PlateMode.POSITION_EXIST"
            [plate]="plate"
            [ignoreHeightBoundary]="true"
            [name]="getWorkflowNameByIndex(i)">
          </app-plate>
          <div class="validation_container">
            <div *ngIf="!plate.uploadedFile" class="error-hint">
              <fa-icon [icon]="faTimes"></fa-icon>
              Datei noch nicht hochgeladen
            </div>
            <div *ngIf="plate.uploadedFile" class="success-hint">
              <fa-icon [icon]="faCheck"></fa-icon>
              Datei bereits hochgeladen
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Selected Plate for detail -->
    <ng-container *ngIf="this.selectedPlate">
      <app-plate
        [showBackButton]="workflowStep?.plates.length > 1"
        [plate]="this.selectedPlate"
        [plateMode]="PlateMode.POSITION_EXIST"
        [name]="workflowInstance?.workflowNumber + (selectedPlate?.suffix ?  '_' + selectedPlate?.suffix : '')"
        (onBackButtonClicked)="clearSelection()">
      </app-plate>
    </ng-container>
  </ng-container>
</app-workflow-step-layout>
