<ng-container *ngIf="position">
  <hr class="hr-margin">

  <div class="container">
    <!--
        <h3>Position Detail</h3>
        -->
    <!-- Position -->
    <div class="data-container">
      <div>Position:</div>
      <b class="data">{{(position?.posY | positionYToLetter) + position?.posX}}</b>

      <!-- blood draw number -->
      <ng-container *ngIf="showBloodDrawNumber">
        <div>Abnahme Nr.</div>
        <div>{{ position?.code }}</div>
      </ng-container>

      <!-- Measurement -->
      <ng-container *ngIf="showMeasurement">
        <div>Messung:</div>
        <div>{{position?.measured}} ng/µL</div>
      </ng-container>

      <!-- Dna/Water  -->
      <ng-container *ngIf="showDnaWater">
        <div>DNA:</div>
        <div>{{position?.dna}} µL</div>
      </ng-container>
      <ng-container *ngIf="showDnaWater">
        <div>Wasser:</div>
        <div>{{position.water ? (position.water + ' µL') : '---'}}</div>
      </ng-container>

      <!-- Pipetting state  -->
      <ng-container *ngIf="showPipettingStatus">
        <div>Pipettierstatus:</div>
        <div *ngIf="position"
             [ngClass]="position?.pipettingStatus === PositionStatus.OK ? 'ok' : 'not-ok'"
             class="status-indicator">
        </div>
        <ng-container *ngIf="position?.pipettingError">
          <div>Error:</div>
          <div class="data">{{position?.pipettingError}}</div>
        </ng-container>
      </ng-container>

      <!-- State -->
      <ng-container *ngIf="showState">
        <div>Status:</div>
        <div>
          <div>{{position?.status | getPositionStatusReadable}}</div>
          <div *ngIf="position"
               [ngClass]="position?.status === PositionStatus.OK ? 'ok' : 'not-ok'"
               class="status-indicator">
          </div>
        </div>
      </ng-container>

      <!-- updatedBy -->
      <ng-container *ngIf="position?.updatedBy">
        <div>Aktualisiert:</div>
        <div>
          <fa-icon
            class="info-message-icon"
            *ngIf="position?.comment !== null"
            [icon]="faMessage"
            [pTooltip]="position?.comment"
            [tooltipPosition]="'top'"
            [tooltipStyleClass]="'tooltip'">
          </fa-icon>
          {{position?.updatedBy}}
        </div>
      </ng-container>

      <!-- status update button -->
      <div *ngIf="showChangeStateButton" class="button-container grid-two-cells">
        <core-button
          style="width: 100%"
          classList="p-button-secondary"
          (onClick)="changePositionState()"
          [disabled]="isReadonly"
          [text]="getPositionStatus() === PositionStatus.OK ? 'Position als Nicht OK markieren' : 'Position als OK markieren'"
          [width]="'100%'">
        </core-button>
      </div>

    </div>

  </div>

</ng-container>

<app-state-update-with-comment-dialog
  (closeDialog)="onCloseDialog($event)"
  (onSaveChanges)="updateState($event)"
  [form]="form"
  [header]="header"
  [saveButtonText]="saveButtonText"
  [message]="message"
  [showStateUpdateDialog]="showConfirmDialog"
></app-state-update-with-comment-dialog>
