import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BatchFormGroup, BatchModel} from '../../../models/batch/batch.model';
import {FormGroup} from '@angular/forms';
import {BatchType} from '../../../enums/BatchType';
import {UnsubscribeBaseComponent} from '../../unsubscribe-base.component';
import {takeUntil} from 'rxjs';
import {BatchService} from '../../../services/batch.service';
import {MessageService} from '../../../services/message.service';
import {SelectItem} from "../../../models/select-item.model";

@Component({
  selector: 'app-new-batch-dialog',
  templateUrl: './new-batch-dialog.component.html',
  styleUrls: ['./new-batch-dialog.component.scss']
})
export class NewBatchDialogComponent extends UnsubscribeBaseComponent implements OnInit {

  @Input() workflowInstanceId: number;
  @Input() batchType: BatchType;
  @Input() showDialog = false;
  @Input() isCreationWithApproval = false;
  @Output() closeDialog: EventEmitter<BatchModel> = new EventEmitter<BatchModel>();

  batchForm: FormGroup<BatchFormGroup>;

  minDate: Date;
  batchTypeOptions: SelectItem[];

  constructor(private batchService: BatchService,
              private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.minDate = new Date();
    this.batchForm = BatchModel.initFormGroup();
    this.batchForm.get('type').setValue(this.batchType);

    if (this.isCreationWithApproval) {
      this.batchTypeOptions = BatchType.getAllAsSelectItem()
        .sort((a, b) => a.name.localeCompare(b.name));
    }

    this.batchForm.get('expirationDate').valueChanges
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(value => {
        const val = value as Date;
        if (val?.getHours() !== 12) {
          val.setHours(12);
          this.batchForm.get('expirationDate').patchValue(val);
        }
      });
  }

  onCloseDialog(newBatch: BatchModel | null) {
    this.closeDialog.emit(newBatch);
  }

  onSaveNewBatch() {
    const newBatch = this.batchForm.value as BatchModel;
    if (this.isCreationWithApproval) {
      this.batchService.createNewWithApproval(newBatch).subscribe(resp => {
        if (resp) {
          this.messageService.addSuccessMessage('Charge wurde erfolgreich erstellet!');
          this.onCloseDialog(resp);
          this.batchService.refreshNeed$.next(true);
        }
      });
    } else {
      this.batchService.createNew(newBatch, this.workflowInstanceId).subscribe(resp => {
        if (resp) {
          this.messageService.addSuccessMessage('Charge wurde erfolgreich erstellet!');
          this.onCloseDialog(resp);
        }
      });
    }
  }
}
