import {IWorkflowStepBase} from "./workflow-step-base.model";
import {IWorkflowStepValidation} from "./workflow-step-validation.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {Workflow} from "../../enums/Workflow";


export class WorkflowStepLibraryPlateSelectionModel implements IWorkflowStepBase, IWorkflowStepValidation {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  selectedWorkflowInstances: WorkflowInstanceModel[];
  takeOverWorkflowInstance: WorkflowInstanceModel;


  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.selectedWorkflowInstances = workflowInstance?.libraryWorkflowInstances ?? [];
  }

  isValid(): boolean {
    if (!this.selectedWorkflowInstances) { return false; }

    if (this.workflowInstance.workflow === Workflow.LIBRARY_96) {
      return this.selectedWorkflowInstances.length > 1;
    } else if (this.workflowInstance.workflow === Workflow.LIBRARY_384) {
      return this.selectedWorkflowInstances.length === 1;
    } else {
      return false;
    }
  }

}
