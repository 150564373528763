import { UntypedFormGroup } from "@angular/forms";

export class FormUtil {

  /**
   * Converts a form value into a FormData object.
   *
   * This method is designed to be used with a file uploader. It processes the form's values
   * and separates file fields from other properties:
   * - Files are added under their own name (matching the form group key) as multipart file[].
   * - All other properties are serialized into a single object and stored under the 'properties' key.
   *
   * @param formValue The object representing form values.
   * @returns A FormData object containing file inputs and other properties.
   */
  static toFormData<T>(formValue: T): FormData {
    const formData = new FormData();
    const properties = {};

    for (const key of Object.keys(formValue)) {
      // @ts-ignore
      const value = formValue[key];

      // Check if the value contains files to upload
      if (value instanceof Object && value.filesToUpload?.every((innerVal: any) => innerVal instanceof File)){
        value.filesToUpload.forEach((file: string | Blob) => formData.append(key, file));
      } else {
        // Store non-file properties in the properties object
        // @ts-ignore
        properties[key] = value;
      }
    }

    // Serialize non-file properties into a single JSON field
    formData.append('properties', JSON.stringify(properties));
    return formData;
  }

  /**
   * Converts a FileList to FormData, using only the first file.
   *
   * This method is useful for single-file uploads, ensuring only the first file in the list
   * is included in the FormData object.
   *
   * @param fileList The FileList object from an input event.
   * @param fieldName The name of the field in FormData (default: 'file').
   * @returns FormData containing only the first file, or an empty FormData if no file is present.
   */
  static createFormDataFromFirstFile(fileList: FileList, fieldName: string = 'file'): FormData {
    const formData = new FormData();

    if (fileList && fileList.length > 0) {
      formData.append(fieldName, fileList.item(0)!); // Use only the first file
    } else {
      console.warn("No file found in FileList.");
    }

    return formData;
  }

  /**
   * Marks all form controls as touched and dirty.
   *
   * This ensures validation messages are triggered immediately after form submission
   * by marking every control in the form as both touched and dirty.
   *
   * @param form The reactive form to update.
   */
  static markFormControlsAsTouchedAndDirty(form: UntypedFormGroup): void {
    Object.values(form.controls)
      .forEach(control => {
        control.markAsTouched();
        control.markAsDirty();
      });
  }

}
