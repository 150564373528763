import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {WorkflowStepSampleSelectionModel} from "../workflow-step-sample-selection.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {PlateModel} from "../../Plate/plate.model";

export class WorkflowInstanceStepSampleSelectionEditModel extends WorkflowInstanceStepEditModel {

  plates: PlateModel[];

  static initFromStep(step: WorkflowStepSampleSelectionModel): WorkflowInstanceStepEditModel {
    const ret = new WorkflowInstanceStepSampleSelectionEditModel();
    ret.workflowStep = WorkflowStep.SAMPLE_SELECTION;
    ret.workflowInstanceStepId = step.id;
    ret.workflow = step.workflowInstance.workflow;
    ret.plates = step.plates;
    return ret;
  }

}
