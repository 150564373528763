import {IWorkflowStepBase} from "./workflow-step-base.model";
import {IWorkflowStepValidation} from "./workflow-step-validation.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {BatchModel} from "../batch/batch.model";
import {TecanStatus} from "../../enums/TecanStatus";
import {Workflow} from "../../enums/Workflow";
import {DetailedPlateModel} from "../Plate/detailed-plate.model";

export class WorkflowStepPurificationModel implements IWorkflowStepBase, IWorkflowStepValidation {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  batches: BatchModel[];
  checkRun: TecanStatus;
  plates: DetailedPlateModel[];

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, batches: BatchModel[],
              plates: DetailedPlateModel[], isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.batches = batches?.map(BatchModel.fromData);
    this.plates = plates.map(DetailedPlateModel.fromData);
  }

  isValid(): boolean {
    // valid if a plate exists and all batches have an expiration date set
    for (const batch of this.batches) {
      if (!batch.expirationDate) {
        return false;
      }
    }
    // check plate counter
    if (this.workflowInstance.workflow === Workflow.WORKFLOW_96) {
      return this.plates.length === 1 && this.plates.every(plate => plate?.id != null);
    } else {
      return this.plates.length === 4 && this.plates.every(plate => plate?.id != null);
    }
  }

}
