import {IWorkflowStepBase} from "./workflow-step-base.model";
import {IWorkflowStepValidation} from "./workflow-step-validation.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {WorkflowInstanceBaseModel} from "../workflow/workflow-instance-base.model";
import {FileRecord} from "../file/FileRecord";

export class WorkflowStepLibraryPoolingModel implements IWorkflowStepBase, IWorkflowStepValidation {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  indexPoolsCombined: boolean;

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, indexPoolsCombined: boolean, isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.indexPoolsCombined = indexPoolsCombined;
  }

  isValid(): boolean {
    return !!this.indexPoolsCombined;
  }
}
