import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'core-input-text',
  templateUrl: 'input-text.component.html',
  styleUrls: ['input-text.component.scss', '../input.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputTextComponent,
    multi: true
  }]
})
export class InputTextComponent implements ControlValueAccessor, OnInit {
  faInfoCircle = faInfoCircle;

  @Input() id: string;
  @Input() label: string;
  @Input() topLabel: string;
  @Input() placeholder = '';
  @Input() showClearButton = true;
  @Input() showRevertButton = false;
  @Input() readonly = false;
  @Input() required = false;
  @Input() infoLabel: string;

  @Input() removeDefaultBottomErrorMargin = false;

  @Output() onKeyUpEnter = new EventEmitter<string>();
  @Output() onRevert = new EventEmitter<any>();

  control: NgControl;

  value: string;
  disabled = false;
  private onTouched: Function;
  private onChanged: Function;

  constructor(private inj: Injector) {
    if (!this.id) {
      this.id = 'core-input-text-' + Math.random();
    }
  }

  ngOnInit() {
    this.control = this.inj.get(NgControl);
  }

  onClear() {
    this.valueChanged(null);
    this.onKeyUpEnter.emit(null);
  }

  valueChanged(value: string) {
    this.onTouched();
    this.value = value;
    this.onChanged(value);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onRevertPressed() {
    this.onRevert.emit();
  }

}
