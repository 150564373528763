<app-workflow-step-layout>
  <ng-container info>
    <div>
      <div *ngIf="batchFormArray && !readOnly">
        <ng-container *ngFor="let batchFormGroup of batchFormArray.controls">
          <app-batch-selector
            [readOnly]="readOnly"
            [form]="getFormGroupFromAbstractControl(batchFormGroup)"
            (onChange)="validateStep()"
            [showExpirationDate]="false">
          </app-batch-selector>
        </ng-container>
      </div>

      <div *ngIf="readOnly">
        <h3>Verwendete Chargen</h3>

        <app-batches-selector
          [readOnly]="readOnly"
          [forms]="batchFormArray">
        </app-batches-selector>
      </div>

      <form *ngIf="!readOnly && !workflowStep.file" [formGroup]="filesForm">
        <app-file-upload [formControlName]="NEW_FILES"
                         (onFileSelect)="saveUpload()">
        </app-file-upload>
      </form>
      <div *ngIf="workflowStep.file">
        <h4>Integra Datei</h4>
        <app-file
          [file]="workflowStep.file"
          [deletable]="workflowDetailClientService.workflowInstance.currentStep === WorkflowStep.QUALITY_ASSURANCE_AGILENT"
          (onRemove)="onRemoveFile()">
        </app-file>
      </div>
    </div>
  </ng-container>

  <ng-container plate>
    <app-pcr-tube-viewer [count]="4"></app-pcr-tube-viewer>
  </ng-container>

</app-workflow-step-layout>
