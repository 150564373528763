import {Component, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-pcr-tube-icon',
  template: `
      <div style="height: 100%; display: flex; justify-content: center; align-items: center; margin-right: 10px">
          <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 512 512"
               preserveAspectRatio="xMidYMid meet"
               style="height: 100%; max-width: 100%; max-height: 100%;">

              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                 fill="#000000" stroke="none">
                  <path d="M3033 4715 l7 -195 -178 6 c-97 3 -183 7 -189 10 -7 2 -13 -5 -13
-15 0 -15 -11 -19 -67 -25 -38 -4 -140 -23 -228 -43 -121 -27 -191 -37 -286
-41 l-126 -4 -6 53 c-4 30 -7 71 -7 92 l0 37 -677 0 c-373 0 -722 -3 -775 -7
l-98 -6 0 -109 0 -108 59 0 60 0 4 -1082 4 -1083 126 -865 c120 -814 129 -869
158 -927 35 -72 86 -119 175 -160 77 -36 220 -44 310 -18 107 30 192 100 229
188 8 21 81 438 160 928 l145 890 0 328 c0 180 3 639 7 1019 l6 692 101 -6
c123 -8 236 5 446 51 88 20 188 36 222 38 58 2 63 0 66 -20 4 -24 20 -28 292
-82 459 -90 724 -100 1149 -46 209 27 596 88 604 94 4 4 10 50 14 103 l6 95
-144 6 c-79 4 -197 7 -261 7 l-118 0 0 200 0 200 -592 0 -591 0 6 -195z m1135
-23 l2 -183 -542 7 c-299 4 -545 8 -547 11 -3 2 -7 80 -9 173 l-5 170 244 3
c134 1 381 2 549 2 l305 0 3 -183z m-2260 -209 l3 -73 -349 0 c-192 0 -525 -3
-740 -7 l-392 -6 0 76 0 77 658 3 c361 1 693 2 737 2 l80 0 3 -72z m2082 -8
c294 -3 572 -8 618 -12 l82 -6 0 -58 c0 -32 -5 -61 -11 -65 -14 -8 -393 -68
-614 -96 -242 -31 -610 -31 -810 0 -153 24 -530 98 -546 107 -5 4 -9 37 -9 76
l0 69 378 -5 c207 -3 618 -7 912 -10z m-1330 -43 c0 -29 -1 -29 -83 -41 -45
-6 -156 -27 -247 -47 -174 -38 -340 -53 -443 -40 -54 7 -57 9 -57 36 l0 28
193 5 c174 4 206 8 342 40 167 39 191 43 253 46 40 1 42 0 42 -27z m-874
-1134 l-7 -1073 -110 -680 c-201 -1240 -178 -1136 -260 -1209 -182 -165 -532
-90 -594 128 -8 28 -71 445 -140 926 l-125 875 0 1047 0 1047 73 4 c39 1 319
4 621 5 l549 2 -7 -1072z"/>
                  <path d="M790 4110 c0 -20 7 -20 380 -20 373 0 380 0 380 20 0 20 -7 20 -380 20 -373 0 -380 0 -380 -20z"/>
                  <path d="M790 3260 c0 -20 7 -20 380 -20 373 0 380 0 380 20 0 20 -7 20 -380 20 -373 0 -380 0 -380 -20z"/>
                  <path d="M790 2370 c0 -20 7 -20 380 -20 373 0 380 0 380 20 0 20 -7 20 -380 20 -373 0 -380 0 -380 -20z"/>
                  <path d="M790 1370 c0 -20 7 -20 380 -20 373 0 380 0 380 20 0 20 -7 20 -380 20 -373 0 -380 0 -380 -20z"/>
              </g>
          </svg>
      </div>

      <div *ngIf="form && readOnly" [formGroup]="form" class="data-row">
          <div>Konzentration:</div>
          <div>{{form.controls.concentration.value}} ng/µL</div>
          <div>Verdünnung:</div>
          <div>{{form.controls.dilution.value}} µL</div>
          <div>AD:</div>
          <div>{{form.controls.ad.value}} µL</div>
      </div>

      <div *ngIf="form && !readOnly" [formGroup]="form" style="width: 50%;">
          <core-input-number
                  [formControlName]="'concentration'"
                  [required]="true"
                  [maxFractionDigits]="2"
                  [showClearButton]="false"
                  topLabel="Konzentration">
          </core-input-number>
      </div>
  `,
  styles: [`
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  `]
})
export class PcrTubeComponent {

  @Input() readOnly: boolean = false;
  @Input() form: FormGroup;

}
