import {EnumUtils} from "./EnumUtils";

export enum TecanStatus {
  OK = 'OK',
  nOK = 'nOK'
}

export namespace TecanStatus {
  export function getTecanStatusReadable(status: TecanStatus): string {
    if (!status) return '';

    switch (status) {
      case TecanStatus.OK:
        return 'OK';
      case TecanStatus.nOK:
        return 'Nicht OK';
    }
  }

  export function getEnumByValue(value: string): TecanStatus {
    return EnumUtils.getEnumByValue<TecanStatus>(TecanStatus, 'string', value);
  }
}
