import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlateComponent} from './plate.component';
import {
  PositiveNegativeControlSelectionModule
} from "../positive-negative-control-selection/positive-negative-control-selection.module";
import {PositionDetailDialogModule} from "../position-detail/position-detail-dialog.module";
import {CustomPipeModule} from "../../pipes/custom-pipe.module";
import {FileUploadModule} from "../file-upload/file-upload.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";


@NgModule({
  declarations: [
    PlateComponent
  ],
  exports: [
    PlateComponent
  ],
    imports: [
        CommonModule,
        PositionDetailDialogModule,
        PositiveNegativeControlSelectionModule,
        CustomPipeModule,
        FileUploadModule,
        FontAwesomeModule
    ]
})
export class PlateModule {
}
