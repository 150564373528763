import {Pipe, PipeTransform} from '@angular/core';
import {WorkflowInstanceModel} from '../models/workflow/workflow-instance.model';
import {WorkflowStep} from '../enums/WorkflowStep';

@Pipe({
  name: 'workflowInstanceFilter'
})
export class WorkflowInstanceFilterPipe implements PipeTransform {

  transform(value: WorkflowInstanceModel[], workflowSteps: WorkflowStep[]): WorkflowInstanceModel[] {
    if (!value || value.length === 0) {
      return [];
    }
    // filter all values with matching workflow steps
    return value.filter(v => workflowSteps.find(s => s === v.currentStep));
  }

}
