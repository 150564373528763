import {Component, Input, OnInit} from '@angular/core';
import { Workflow } from 'src/app/enums/Workflow';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {WorkflowStepLibraryNgsSequencingModel} from "../../../../models/workflow-step/workflow-step-library-ngs-sequencing.model";
import {AbstractControl, UntypedFormArray, UntypedFormGroup} from "@angular/forms";
import {BatchType} from "../../../../enums/BatchType";
import {BatchModel} from "../../../../models/batch/batch.model";
import {BatchUtils} from "../../../../utils/batch.utils";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";

@Component({
  selector: 'app-library-ngs-sequencing',
  templateUrl: './library-ngs-sequencing.component.html',
  styleUrls: ['./library-ngs-sequencing.component.scss']
})
export class LibraryNgsSequencingComponent implements OnInit {
  Workflow = Workflow;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepLibraryNgsSequencingModel;
  @Input() readOnly: boolean = false;

  batchFormArray: UntypedFormArray;
  batchTypes: BatchType[] = [];
  batchModels: BatchModel[] = [];
  BatchType = BatchType;

  constructor(private workflowDetailClientService: WorkflowDetailClientService) {}

  ngOnInit(): void {
    this.batchTypes = BatchType.getAllForNgsSequencing();
    this.batchFormArray = BatchUtils.initBatchFormArray(this.workflowStep.batches, this.batchTypes, this.readOnly);
    this.validateStep();
  }

  getFormGroupFromAbstractControl(form: AbstractControl): UntypedFormGroup {
    return form as UntypedFormGroup;
  }

  validateStep(): void{
    this.workflowStep.batches = BatchModel.getBatchesFromBatchSelectionFormArray(this.batchFormArray);
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

}
