import {Injectable} from '@angular/core';
import {BehaviorSubject, finalize, Observable, Subject} from 'rxjs';
import {IWorkflowStepBase, WorkFlowStepClass} from '../../../models/workflow-step/workflow-step-base.model';
import {WorkflowInstanceService} from '../../../services/workflow-instance.service';
import {WorkflowInstanceStepService} from '../../../services/workflow-instance-step.service';
import {WorkflowInstanceModel} from '../../../models/workflow/workflow-instance.model';
import {
  WorkflowInstanceStepSampleSelectionEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-sample-selection-edit.model';
import {WorkflowStepSampleSelectionModel} from '../../../models/workflow-step/workflow-step-sample-selection.model';
import {WorkflowInstanceStepEditModel} from '../../../models/workflow-step/edit/workflow-instance-step-edit.model';
import {WorkflowStep} from '../../../enums/WorkflowStep';
import {WorkflowStepAddingChecksModel} from '../../../models/workflow-step/workflow-step-adding-checks.model';
import {
  WorkflowInstanceStepAddingChecksEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-adding-checks-edit.model';
import {WorkFlowStepTargetPcrModel} from '../../../models/workflow-step/work-flow-step-target-pcr.model';
import {
  WorkflowStepMeasurementOnGlomaxFirstModel
} from '../../../models/workflow-step/workflow-step-measurement-on-glomax-first.model';
import {
  WorkflowInstanceStepMeasurementOnGlomaxFirstEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-measurement-on-glomax-first-edit.model';
import {
  WorkflowStepNormalisationPreparationModel
} from '../../../models/workflow-step/workflow-step-normalisation-preparation.model';
import {
  WorkflowStepLibraryPlateSelectionModel
} from '../../../models/workflow-step/workflow-step-library-plate-selection.model';
import {
  WorkflowInstanceStepLibraryPlateSelectionEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-library-plate-selection-edit.model';
import {
  WorkflowStepQuantificationSecondModel
} from '../../../models/workflow-step/workflow-step-quantification-second.model';
import {
  WorkflowInstanceStepQuantificationSecondEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-quantification-second-edit.model';
import {
  WorkflowInstanceStepMeasurementOnGlomaxSecondEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-measurement-on-glomax-second-edit.model';
import {WorkflowStepIndexPcrSetupModel} from '../../../models/workflow-step/workflow-step-index-pcr-setup.model';
import {
  WorkflowInstanceStepIndexPcrSetupEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-index-pcr-setup-edit.model';
import {
  WorkflowStepLibraryNormalisationAndPooling
} from '../../../models/workflow-step/workflow-step-library-normalisation-and-pooling';
import {
  WorkflowInstanceStepLibraryNormalisationAndPoolingEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-library-normalisation-and-pooling-edit.model';
import {WorkflowStepLibraryAgilantModel} from '../../../models/workflow-step/workflow-step-library-agilant.model';
import {
  WorkflowInstanceStepLibraryAgilantEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-library-agilant-edit.model';
import {WorkflowStepLibraryQubitModel} from '../../../models/workflow-step/workflow-step-library-qubit.model';
import {
  WorkflowInstanceStepLibraryQubitEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-library-qubit-edit.model';
import {
  WorkflowStepLibraryNgsSequencingModel
} from '../../../models/workflow-step/workflow-step-library-ngs-sequencing.model';
import {
  WorkflowInstanceStepLibraryNgsSequencingEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-library-ngs-sequencing-edit.model';
import {
  WorkflowStepMeasurementOnGlomaxSecondModel
} from '../../../models/workflow-step/workflow-step-measurement-on-glomax-second.model';
import {WorkflowStepFinishedModel} from '../../../models/workflow-step/workflow-step-finished.model';
import {WorkflowStepDnaExtractionModel} from '../../../models/workflow-step/workflow-step-dna-extraction.model';
import {
  WorkflowInstanceStepDnaExtractionEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-dna-extraction-edit.model';
import {
  WorkflowInstanceStepDnaExtractionSwabLysisEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-dna-extraction-swab-lysis-edit.model';
import {
  WorkflowStepDnaExtractionSwabLysisModel
} from '../../../models/workflow-step/workflow-step-dna-extraction-swab-lysis.model';
import {
  WorkflowInstanceStepNormalizationEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-normalization-edit.model';
import {WorkflowStepNormalisation} from '../../../models/workflow-step/workflow-step-normalization.model';
import {
  WorkflowInstanceStepQuantificationFirstEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-quantification-first-edit.model';
import {
  WorkflowStepQuantificationFirstModel
} from '../../../models/workflow-step/workflow-step-quantification-first.model';
import {
  WorkflowInstanceStepTargetPcrEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-target-pcr-edit.model';
import {WorkflowStepPurificationModel} from '../../../models/workflow-step/workflow-step-purification.model';
import {
  WorkflowInstanceStepPurificationEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-purification-edit.model';
import {
  WorkflowStepLibraryPostSequencingModel
} from '../../../models/workflow-step/workflow-step-library-post-sequencing.model';
import {
  WorkflowInstanceStepLibraryPostSequencingEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-library-post-sequencing-edit.model';
import {PlateModel} from '../../../models/Plate/plate.model';
import {ConfirmationService} from "primeng/api";
import {
  WorkflowStepLibraryIndexPurificationModel
} from "../../../models/workflow-step/workflow-step-library-index-purification.model";
import {
  WorkflowInstanceStepLibraryIndexPurificationEditModel
} from "../../../models/workflow-step/edit/workflow-instance-step-library-index-purification-edit.model";
import {WorkflowStepLibraryPoolingModel} from "../../../models/workflow-step/workflow-step-library-pooling.model";
import {
  WorkflowInstanceStepLibraryPoolingEditModel
} from "../../../models/workflow-step/edit/workflow-instance-step-library-pooling-edit.model";

@Injectable()
export class WorkflowDetailClientService {

  isStepValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isReadOnly$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedStep$: BehaviorSubject<IWorkflowStepBase> = new BehaviorSubject(null);

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  navMenuItems$: BehaviorSubject<WorkflowStep[][]> = new BehaviorSubject([]);
  navMenuItemObservable$: Observable<WorkflowStep[][]> = this.navMenuItems$.asObservable();

  positionClicked$: Subject<boolean> = new Subject<boolean>();

  workflowInstance: WorkflowInstanceModel;
  platePlaceholders: PlateModel[] = [];

  hideScrollbarInHeader = true;

  constructor(private workflowInstanceService: WorkflowInstanceService,
              private workflowInstanceStepService: WorkflowInstanceStepService,
              private confirmationService: ConfirmationService) {
  }

  nextStep(suppressNotOkPositionCheck: boolean = false): void {
    if (this.selectedStep$.getValue().step === this.workflowInstance.currentStep) {
      const updateDto = this.initUpdateDto();

      this.workflowInstanceService.changeToNextStep(updateDto, suppressNotOkPositionCheck)
        .subscribe((resp) => {
          this.workflowInstance = resp;
          this.workflowInstanceService.refreshNeed$.next(true);
          this.saveRecentlyCalled = true;
          setTimeout(() => this.saveRecentlyCalled = false, 1500);
          this.fetchWorkInstanceStep();
          this.initHistorySteps();
          this.initPlatePlaceholders();
        }, error => {
          if (error && error.status === 901) {
            this.initPositionNotSuppressConfirmation();
          }
        });
    } else {
      const historyList = this.navMenuItems$.getValue();
      const indexOfCurrentStep = historyList.findIndex(ws => ws.find(e => e === this.selectedStep$.getValue().step));
      if (historyList[indexOfCurrentStep].length == 1) {
        this.fetchWorkflowInstanceStepByStep(historyList[indexOfCurrentStep + 1][0]);
      } else if (historyList[indexOfCurrentStep].findIndex(e => e === this.selectedStep$.getValue().step) === historyList[indexOfCurrentStep].length - 1) {
        this.fetchWorkflowInstanceStepByStep(historyList[indexOfCurrentStep + 1][0]);
      } else {
        this.fetchWorkflowInstanceStepByStep(historyList[indexOfCurrentStep][
        historyList[indexOfCurrentStep].findIndex(e => e === this.selectedStep$.getValue().step) + 1]);
      }
    }
  }

  silentSave() {
    const updateDto = this.initUpdateDto();
    this.workflowInstanceStepService.storeStepChanges(updateDto)
      .subscribe((resp) => {
        console.log('Silently saved!');
      });
  }

  shortenWorkFlow(): void {
    if (this.workflowInstance?.id) {
      this.workflowInstanceService.shortenWorkflowInstance(this.workflowInstance.id)
        .subscribe(resp => {
          this.workflowInstance = resp;
          this.workflowInstanceService.refreshNeed$.next(true);
          this.saveRecentlyCalled = true;
          setTimeout(() => this.saveRecentlyCalled = false, 1500);
          this.fetchWorkInstanceStep();
          this.initHistorySteps();
          this.initPlatePlaceholders();
        });
    }
  }

  fetchWorkflowInstanceStepByStep(step: WorkflowStep): void {
    if (step !== this.workflowInstance.currentStep && this.selectedStep$.getValue()?.step === this.workflowInstance.currentStep) {
      this.saveStepChangesBeforeNavigationOrDialogClose();
    }

    this.isLoading$.next(true);
    this.workflowInstanceStepService.getWorkflowInstanceStepByStep(this.workflowInstance.id, step)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe((resp) => {
        this.selectedStep$.next(resp);
        this.isReadOnly$.next(resp?.workflowInstance.currentStep !== resp?.step);
        if (resp?.isSkipped) {
          this.isStepValid$.next(true);
        } else {
          this.isStepValid$.next(false);
        }
      });
  }

  fetchWorkflowInstanceStepByStepForStepCard(step: WorkflowStep): void {
    if (step === WorkflowStep.NORMALIZATION) {
      step = this.workflowInstance.currentStep === WorkflowStep.NORMALIZATION_PREPARATION ? WorkflowStep.NORMALIZATION_PREPARATION : WorkflowStep.NORMALIZATION;
    }

    if (step === WorkflowStep.DNA_EXTRACTION_SWAB) {
      step = this.workflowInstance.currentStep === WorkflowStep.DNA_EXTRACTION_SWAB_LYSIS ? WorkflowStep.DNA_EXTRACTION_SWAB_LYSIS : WorkflowStep.DNA_EXTRACTION_SWAB;
    }

    if (step !== this.workflowInstance.currentStep && this.selectedStep$.getValue()?.step === this.workflowInstance.currentStep) {
      this.saveStepChangesBeforeNavigationOrDialogClose();
    }

    this.isLoading$.next(true);
    this.workflowInstanceStepService.getWorkflowInstanceStepByStep(this.workflowInstance.id, step)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe((resp) => {
        this.selectedStep$.next(resp);
        this.isReadOnly$.next(resp?.workflowInstance?.currentStep !== resp?.step);
        if (resp?.isSkipped) {
          this.isStepValid$.next(true);
        } else {
          this.isStepValid$.next(false);
        }
      });
  }

  // timeout after 1 second for sse update events
  public saveRecentlyCalled = false;

  fetchWorkInstanceStep(): void {
    this.isStepValid$.next(false);
    this.isLoading$.next(true);

    this.workflowInstanceStepService.getLastWorkStepByInstanceId(this.workflowInstance.id)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe({
        next: (resp) => {
          this.selectedStep$.next(resp);

          if (resp && resp.workflowInstance) {
            this.workflowInstance = resp.workflowInstance;
          }

          if (resp?.workflowInstance?.currentStep === WorkflowStep.ABORTED) {
            this.initAbortedSteps(resp.step);
          } else {
            this.initHistorySteps();
          }
        },
        error: (error) => {
          console.log(error);
        }
      });
  }

  cleanUp(withAutoSave: boolean = true): void {
    // save the data
    if (withAutoSave && this.selectedStep$?.getValue()?.step === this.workflowInstance.currentStep) {
      this.saveStepChangesBeforeNavigationOrDialogClose();
    }
    this.isStepValid$.next(false);
    this.selectedStep$.next(null);
    this.isReadOnly$.next(false);
    this.navMenuItems$.next([]);
    this.workflowInstance = null;
  }

  // function used to jump between library <-> workflow instance and vica versa
  jumpToWorkflowInstance(wi: WorkflowInstanceModel): void {
    this.cleanUp();
    this.workflowInstance = wi;
    this.fetchWorkInstanceStep();
  }

  reExportFile(step: WorkflowStep, plateId: number) {
    this.workflowInstanceStepService.reExportFileForWorkflowInstanceAndStep(this.workflowInstance.id, step, plateId);
  }

  initPlatePlaceholders() {
    const currentStep = this.workflowInstance.currentStep;
    this.platePlaceholders = PlateModel.initPlaceHolderArray(this.workflowInstance?.workflow, currentStep);
  }

  private initHistorySteps(): void {
    this.hideScrollbarInHeader = true;
    // get list based on current workflow
    const stepArray: WorkflowStep[][] =
      this.workflowInstance.isLibrary() ?
        WorkflowStep.getAllForDashboardInLibrary() :
        WorkflowStep.getAllForDashboardInWorkflow96And384();

    // add the finished state
    stepArray.push([WorkflowStep.FINISHED]);

    const indexOfCurrentStep = stepArray.findIndex(ws => ws.find(s => s === this.workflowInstance.currentStep));
    if (indexOfCurrentStep > -1) {
      // using the latest entry is fine cause as defined the steps have the same name
      this.navMenuItems$.next(stepArray.slice(0, indexOfCurrentStep + 1));
    }
    // also hide the scrollbar for some time when the header gets initialized
    setTimeout(() => this.hideScrollbarInHeader = false, 100);
  }

  private initAbortedSteps(lastStepBeforeAbort: WorkflowStep): void {
    this.hideScrollbarInHeader = true;
    // get list based on current workflow
    const stepArray: WorkflowStep[][] =
      this.workflowInstance.isLibrary() ?
        WorkflowStep.getAllForDashboardInLibrary() :
        WorkflowStep.getAllForDashboardInWorkflow96And384();

    const indexOfCurrentStep = stepArray.findIndex(ws => ws.find(s => s === lastStepBeforeAbort));
    if (indexOfCurrentStep > -1) {
      // using the latest entry is fine cause as defined the steps have the same name
      this.navMenuItems$.next(stepArray.slice(0, indexOfCurrentStep + 1));
    }
    // also hide the scrollbar for some time when the header gets initialized
    setTimeout(() => this.hideScrollbarInHeader = false, 100);
  }

  /**
   * in some steps the user can fill data like batch, positive control or some concentration
   * instead of throw away the unsaved changes by navigation to a histroy step or close the dialog
   * we update the step with the filled data
   */
  private saveStepChangesBeforeNavigationOrDialogClose(): void {
    const updateDto = this.initUpdateDto();
    if (this.selectedStep$.getValue() == null || updateDto.workflowStep === WorkflowStep.ABORTED || updateDto.workflowStep === WorkflowStep.FINISHED)
      return;
    this.workflowInstanceStepService.storeStepChanges(updateDto)
      .subscribe(() => {});
  }

  private initUpdateDto(): WorkflowInstanceStepEditModel {
    let updateDto: WorkflowInstanceStepEditModel = new WorkflowInstanceStepEditModel();
    let currentStep = null;

    switch (this.selectedStep$?.getValue()?.constructor.name) {
      case WorkFlowStepClass.WorkflowStepPreparationModel:
        updateDto.workflowStep = WorkflowStep.PREPARATION;
        updateDto.workflowInstanceStepId = this.selectedStep$?.getValue().id;
        break;
      case WorkFlowStepClass.WorkflowStepDnaExtractionModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepDnaExtractionModel;
        updateDto = WorkflowInstanceStepDnaExtractionEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepDnaExtractionSwabLysisModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepDnaExtractionSwabLysisModel;
        updateDto = WorkflowInstanceStepDnaExtractionSwabLysisEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepNormalisation:
        currentStep = this.selectedStep$.getValue() as WorkflowStepNormalisation;
        updateDto = WorkflowInstanceStepNormalizationEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepQuantificationFirstModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepQuantificationFirstModel;
        updateDto = WorkflowInstanceStepQuantificationFirstEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkFlowStepTargetPcrModel:
        currentStep = this.selectedStep$.getValue() as WorkFlowStepTargetPcrModel;
        updateDto = WorkflowInstanceStepTargetPcrEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepSampleSelectionModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepSampleSelectionModel;
        updateDto = WorkflowInstanceStepSampleSelectionEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepAddingChecksModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepAddingChecksModel;
        updateDto = WorkflowInstanceStepAddingChecksEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepMeasurementOnGlomaxFirstModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepMeasurementOnGlomaxFirstModel;
        updateDto = WorkflowInstanceStepMeasurementOnGlomaxFirstEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepNormalisationPreparationModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepNormalisationPreparationModel;
        break;
      case WorkFlowStepClass.WorkflowStepPurificationModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepPurificationModel;
        updateDto = WorkflowInstanceStepPurificationEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepQuantificationSecondModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepQuantificationSecondModel;
        updateDto = WorkflowInstanceStepQuantificationSecondEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepMeasurementOnGlomaxSecondModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepMeasurementOnGlomaxSecondModel;
        updateDto = WorkflowInstanceStepMeasurementOnGlomaxSecondEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepIndexPcrSetupModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepIndexPcrSetupModel;
        updateDto = WorkflowInstanceStepIndexPcrSetupEditModel.initFromStep(currentStep);
        break;
      //  Library
      case WorkFlowStepClass.WorkflowStepLibraryPlateSelectionModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepLibraryPlateSelectionModel;
        updateDto = WorkflowInstanceStepLibraryPlateSelectionEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepLibraryNormalisationAndPooling:
        currentStep = this.selectedStep$.getValue() as WorkflowStepLibraryNormalisationAndPooling;
        updateDto = WorkflowInstanceStepLibraryNormalisationAndPoolingEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepLibraryIndexPurificationModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepLibraryIndexPurificationModel;
        updateDto = WorkflowInstanceStepLibraryIndexPurificationEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepLibraryAgilantModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepLibraryAgilantModel;
        updateDto = WorkflowInstanceStepLibraryAgilantEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepLibraryQubitModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepLibraryQubitModel;
        updateDto = WorkflowInstanceStepLibraryQubitEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepLibraryPoolingModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepLibraryPoolingModel;
        updateDto = WorkflowInstanceStepLibraryPoolingEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepLibraryNgsSequencingModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepLibraryNgsSequencingModel;
        updateDto = WorkflowInstanceStepLibraryNgsSequencingEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepLibraryPostSequencingModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepLibraryPostSequencingModel;
        updateDto = WorkflowInstanceStepLibraryPostSequencingEditModel.initFromStep(currentStep);
        break;
      case WorkFlowStepClass.WorkflowStepFinishedModel:
        currentStep = this.selectedStep$.getValue() as WorkflowStepFinishedModel;
        break;
    }

    updateDto.workflowInstanceStepId = this.selectedStep$.getValue()?.id;
    updateDto.workflowInstanceId = this.workflowInstance.id;
    updateDto.workflowStep = this.workflowInstance.currentStep;
    return updateDto;
  }

  private initPositionNotSuppressConfirmation(): void  {
    this.confirmationService.confirm({
      header: 'Warnung!',
      message: 'Der aktuelle Schritt enthält Position(en) mit Status NOT_OK.\nSind Sie sicher, dass Sie zum nächsten Schritt übergehen möchten?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Weiter zum nächsten Schritt',
      rejectLabel: 'Abbrechen',
      accept: this.nextStep.bind(this, true)
    });
  }

}
