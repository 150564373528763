import {Component, Input} from '@angular/core';
import {faFileCircleQuestion, faUserPen} from '@fortawesome/free-solid-svg-icons';
import {WorkflowInstanceState} from "../../enums/WorkflowInstanceState";

@Component({
  selector: 'app-workflow-instance-status-icon',
  template: `
    <ng-container [ngSwitch]="workflowState">
      <ng-container *ngSwitchCase="WorkflowInstanceState.WAITING_FOR_USER_INPUT">
        <fa-icon [icon]="faUserPen"></fa-icon>
      </ng-container>
      <ng-container *ngSwitchCase="WorkflowInstanceState.WAITING_FOR_FILE">
        <fa-icon [icon]="faFileCircleQuestion"></fa-icon>
      </ng-container>
    </ng-container>
  `
})
export class WorkflowInstanceStateIconComponent {

  faUserPen = faUserPen;
  faFileCircleQuestion = faFileCircleQuestion;

  WorkflowInstanceState = WorkflowInstanceState;

  @Input() workflowState: WorkflowInstanceState;

}
