import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom, map, Observable, Subject} from 'rxjs';
import {WorkflowInstanceModel} from '../models/workflow/workflow-instance.model';
import {Injectable} from '@angular/core';
import {Globals} from "../global/globals";
import {WorkflowInstanceDtoModel} from "../models/workflow/workflow-instance-dto.model";
import {WorkflowInstanceStepEditModel} from "../models/workflow-step/edit/workflow-instance-step-edit.model";
import {Workflow} from "../enums/Workflow";
import {PageableRequestModel} from "../models/pagable/pageable-request.model";
import {WorkflowInstanceFilterModel} from "../models/workflow/workflow-instance-filter.model";
import {WorkflowInstancePageModel} from "../models/pagable/workflow-instance-page.model";
import {WorkflowInstanceWithBatchListDtoModel} from "../models/workflow/workflow-instance-with-batch-list-dto.model";
import {IndexPrimerPlate} from "../enums/IndexPrimerPlate";

@Injectable({
  providedIn: 'root'
})
export class WorkflowInstanceService {

  refreshNeed$: Subject<boolean> = new Subject<boolean>();

  private readonly baseUri: string;

  constructor(private http: HttpClient,
              private globals: Globals) {
    this.baseUri = this.globals.backendUri + '/workflow-instances';
  }

  getWorkFlowInstances(): Observable<WorkflowInstanceModel[]> {
    return this.http.get<WorkflowInstanceModel[]>(this.baseUri)
      .pipe(map((wfi: WorkflowInstanceModel[]) => wfi.map(WorkflowInstanceModel.fromData)));
  }

  getInProgressWorkFlowInstances(workflows: Workflow[]): Observable<WorkflowInstanceModel[]> {
    let params = new HttpParams();
    workflows.forEach((workflow, index) => {
      params = params.append(`workflows`, workflow.toString());
    });
    return this.http.get<WorkflowInstanceModel[]>(this.baseUri + '/in-progress', {params: params})
      .pipe(map((wfi: WorkflowInstanceModel[]) => wfi.map(WorkflowInstanceModel.fromData)));
  }

  getAllForArchive(pageableRequestModel: PageableRequestModel, filter: WorkflowInstanceFilterModel): Observable<WorkflowInstancePageModel> {
    const httpParams = PageableRequestModel.toHttpParams(pageableRequestModel);
    return this.http.post<WorkflowInstancePageModel>(this.baseUri + '/all-for-archive', filter, {params: httpParams});
  }

  /* needs for the archive user filter - users from LDAP are not synchronized
   * should only use for workflow instance archive filter!
   */
  getAllCreatorUser(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUri + '/creator-users');
  }

  getPlateSuggestion(): Observable<IndexPrimerPlate> {
    return this.http.get<IndexPrimerPlate>(this.baseUri + '/get-plate-suggestion');
  }

  getAllApprovedForLibrary(workflow: Workflow): Observable<WorkflowInstanceModel[]> {
    return this.http.get<WorkflowInstanceModel[]>(`${this.baseUri}/all-approved-for-library?workflow=${workflow}`)
      .pipe(map((wfi: WorkflowInstanceModel[]) => wfi.map(WorkflowInstanceModel.fromData)));
  }

  getUsedInLibrary(workflowInstanceId: number): Observable<WorkflowInstanceModel> {
    return this.http.get<WorkflowInstanceModel>(`${this.baseUri}/used-in-library?workflowId=${workflowInstanceId}`)
      .pipe(map((wfi: WorkflowInstanceModel) => WorkflowInstanceModel.fromData(wfi)));
  }

  createWorkFlowInstance(workflow: WorkflowInstanceDtoModel): Observable<WorkflowInstanceDtoModel> {
    return this.http.post<WorkflowInstanceDtoModel>(`${this.baseUri}`, workflow)
      .pipe(map(WorkflowInstanceDtoModel.fromData));
  }


  createNewLibrary(workflow: WorkflowInstanceDtoModel): Observable<WorkflowInstanceDtoModel> {
    return this.http.post<WorkflowInstanceDtoModel>(this.baseUri + '/new-library', workflow);
  }

  changeToNextStep(workflow: WorkflowInstanceStepEditModel, suppressPositionNotOkWarning: boolean): Observable<WorkflowInstanceModel> {
    return this.http.put<WorkflowInstanceModel>(`${this.baseUri}/next-step?suppressPositionNotOkWarning=${suppressPositionNotOkWarning}`, workflow)
      .pipe(map(WorkflowInstanceModel.fromData));
  }

  shortenWorkflowInstance(workflowInstanceId: number): Observable<WorkflowInstanceModel> {
    return this.http.put<WorkflowInstanceModel>(this.baseUri + '/shorten-workflow',{workflowInstanceId: workflowInstanceId})
      .pipe(map(WorkflowInstanceModel.fromData));
  }

  abortWorkFlowInstance(abortWorkflow: WorkflowInstanceWithBatchListDtoModel): Observable<WorkflowInstanceDtoModel> {
    return this.http.put<WorkflowInstanceDtoModel>(`${this.baseUri}/abort`, abortWorkflow);
  }

  shareToLibrary(workflow: WorkflowInstanceWithBatchListDtoModel, suppressPositionNotOkWarning: boolean): Observable<WorkflowInstanceDtoModel> {
    return this.http.put<WorkflowInstanceDtoModel>(`${this.baseUri}/share-to-library?suppressPositionNotOkWarning=${suppressPositionNotOkWarning}`, workflow);
  }

  finishLibrary(editDto: WorkflowInstanceWithBatchListDtoModel): Observable<WorkflowInstanceDtoModel> {
    return this.http.put<WorkflowInstanceDtoModel>(`${this.baseUri}/finish-library`, editDto);
  }
}
