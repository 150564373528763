import {IWorkflowStepBase} from './workflow-step-base.model';
import {IWorkflowStepValidation} from './workflow-step-validation.model';
import {WorkflowStep} from '../../enums/WorkflowStep';
import {WorkflowInstanceModel} from '../workflow/workflow-instance.model';
import {PlateModel} from '../Plate/plate.model';
import {BatchModel} from '../batch/batch.model';
import {BatchType} from '../../enums/BatchType';
import {Workflow} from '../../enums/Workflow';

export class WorkflowStepMeasurementOnGlomaxSecondModel implements IWorkflowStepBase, IWorkflowStepValidation {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  plates: PlateModel[];
  batches: BatchModel[];
  cutoffValue: number;
  shareToLibrary: boolean;
  glomaxSlope: number;
  glomaxConstant: number;
  dnaSetIn: number;

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, plates: PlateModel[], batches: BatchModel[],
              cutoffValue: number, glomaxSlope: number, glomaxConstant: number, dnaSetIn: number, isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.plates = plates.map(PlateModel.fromData);
    this.batches = batches;
    this.cutoffValue = cutoffValue;
    this.glomaxSlope = glomaxSlope;
    this.glomaxConstant = glomaxConstant;
    this.dnaSetIn = dnaSetIn;
  }

  isValid(): boolean {
    if (this.batches?.filter(batch => batch.id).length !== BatchType.getAllForGlomax().length) {
      return false;
    }

    if (this.workflowInstance.workflow === Workflow.WORKFLOW_96) {
      return this.plates.length === 1 && this.plates.every(plate => plate?.id != null);
    } else {
      return this.plates.length === 4 && this.plates.every(plate => plate?.id != null);
    }
  }

}
