import {SortEvent} from 'primeng/api';

export enum SortOrder {
  ASC,
  DESC
}


export class SortModel {
  field: string;
  sortOrder: SortOrder;

  initFromSortEvent(sortEvent: SortEvent): void {
    this.field = sortEvent.field;
    this.sortOrder = sortEvent.order === 1 ? SortOrder.ASC : SortOrder.DESC;
  }
}
