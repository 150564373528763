import {NgModule} from '@angular/core';
import {WorkflowStepComponent} from './workflow-step/workflow-step.component';
import {WorkflowItemComponent} from './workflow-item/workflow-item.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomPipeModule} from '../../pipes/custom-pipe.module';
import {
  WorkflowInstanceStateIconModule
} from '../../components/workflow-instance-status-icon/workflow-instance-state-icon.module';
import {DialogModule} from "primeng/dialog";
import {WorkflowStepCommonModule} from "../../components/workflow-step-common/workflow-step-common.module";
import {InputModule} from "../../components/input/input.module";
import {WorkflowDetailDialogModule} from "../../components/dialog/workflow-detail-dialog/workflow-detail-dialog.module";
import {NewWorkflowDialogModule} from "../../components/dialog/new-workflow-dialog/new-workflow-dialog.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DashboardLibraryComponent} from "./dashboard-library.component";
import {Dashboard96384Component} from "./dashboard-96-384.component";

@NgModule({
  declarations: [
    Dashboard96384Component,
    DashboardLibraryComponent,
    WorkflowStepComponent,
    WorkflowItemComponent],
  imports: [
    CommonModule,
    FormsModule,
    CustomPipeModule,
    WorkflowInstanceStateIconModule,
    DialogModule,
    WorkflowStepCommonModule,
    InputModule,
    WorkflowDetailDialogModule,
    ReactiveFormsModule,
    NewWorkflowDialogModule,
    FontAwesomeModule
  ],
  exports: [
    Dashboard96384Component,
    DashboardLibraryComponent
  ]
})
export class DashboardModule {
}
