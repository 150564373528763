<app-workflow-step-layout [infoWidth]="'32rem'">
  <ng-container info>
    <div>
      <h3>Verwendete Chargen</h3>

      <app-batches-selector
        [readOnly]="readOnly"
        [forms]="batchFormArray"
        (onChange)="validateStep()"
        [showExpirationDate]="false">
      </app-batches-selector>

      <h3>Verwendete statische Werte</h3>

      <div class="cutoff-value">
        <div>Cutoff:</div>
        <div>{{workflowStep.cutoffValue}} ng/µL</div>
        <div>Steigung (m):</div>
        <div>{{workflowStep.glomaxSlope}}</div>
        <div>Konstante (b):</div>
        <div>{{workflowStep.glomaxConstant}}</div>
        <div>µL eingesetzte DNA:</div>
        <div>{{workflowStep.dnaSetIn}}</div>
      </div>

      <h3>Verwendete Formeln</h3>

      <div>Glomax Wert in ng/µl = (m * RFU + b) / µL eingesetzte DNA</div>

    </div>

    <app-position-detail
      *ngIf="selectedPosition"
      [showMeasurement]="true"
      [showChangeStateButton]="!readOnly"
      [position]="selectedPosition">
    </app-position-detail>

  </ng-container>

  <ng-container plate>
    <app-plate-view
      [plateMode]="PlateMode.MEASURED"
      [workflowInstance]="workflowInstance"
      [plates]="workflowStep?.plates"
      (onPositionSelected)="onCellClick($event)"
      (onClearPositionSelected)="onCellClick(null)">
    </app-plate-view>
  </ng-container>
</app-workflow-step-layout>


