import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {TecanStatus} from "../../../../enums/TecanStatus";
import {WorkflowStepTargetPoolingModel} from "../../../../models/workflow-step/workflow-step-target-pooling.model";
import {PlateMode} from "../../../../enums/PlateMode";

@Component({
  selector: 'app-target-pooling',
  templateUrl: './target-pooling.component.html',
  styleUrls: ['./target-pooling.component.scss']
})
export class TargetPoolingComponent implements OnInit {

  readonly TecanStatus = TecanStatus;
  readonly PlateMode = PlateMode;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepTargetPoolingModel;
  @Input() readOnly: boolean = false;

  constructor(private workflowDetailClientService: WorkflowDetailClientService) {
    this.workflowDetailClientService.isStepValid$.next(true);
  }

  ngOnInit(): void {
    console.log(this.workflowStep);
  }

}
