import {NgModule} from "@angular/core";
import {PlateViewComponent} from "./plate-view.component";
import {PlateModule} from "../plate/plate.module";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [PlateViewComponent],
  imports: [
    PlateModule,
    CommonModule
  ],
  exports: [PlateViewComponent],
})
export class PlateViewModule{

}
