import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {Observable} from "rxjs";
import {WorkflowInstanceDtoModel} from "../models/workflow/workflow-instance-dto.model";
import {WorkflowInstanceModel} from "../models/workflow/workflow-instance.model";
import {
  WorkflowInstanceStepIndexPcrSetupPlateEditModel
} from "../models/workflow-step/edit/workflow-instance-step-index-pcr-setup-plate-edit.model";

@Injectable({
  providedIn: 'root'
})
export class WorkflowInstanceStepIndexPcrSetupService {

  private readonly baseUri: string;

  constructor(private http: HttpClient,
              private globals: Globals) {
    this.baseUri = globals.backendUri + '/workflow-instance-steps/index-pcr-setup';
  }

  StartIndexPcrSetup(workflowStep: WorkflowInstanceStepIndexPcrSetupPlateEditModel): Observable<WorkflowInstanceModel> {
    return this.http.put<WorkflowInstanceModel>(`${this.baseUri}/start-setup`, workflowStep);
  }
}
