import {WorkflowStep} from "../../enums/WorkflowStep";
import {Workflow} from "../../enums/Workflow";
import {IndexPrimerPlate} from "../../enums/IndexPrimerPlate";
import {WorkflowInstanceState} from "../../enums/WorkflowInstanceState";

export class WorkflowInstanceBaseModel {
  id: number;
  version: number;
  workflowNumber: string;
  state: WorkflowInstanceState;
  currentStep: WorkflowStep;
  workflow: Workflow;
  indexPrimerPlate: IndexPrimerPlate;

  isLibrary(): boolean {
    return this.workflow === Workflow.LIBRARY_96 || this.workflow === Workflow.LIBRARY_384;
  }

  isFinishedOrAborted(): boolean {
    return this.currentStep === WorkflowStep.FINISHED || this.currentStep === WorkflowStep.ABORTED;
  }
}
