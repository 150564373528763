import {Component, Input, OnInit} from '@angular/core';
import {Workflow} from "../../../../enums/Workflow";
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {WorkflowInstanceService} from "../../../../services/workflow-instance.service";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {AbstractControl, FormControl, FormGroup, UntypedFormArray, UntypedFormGroup, Validators} from "@angular/forms";
import {FileType} from "../../../../enums/FileType";
import {FileService} from "../../../../services/file.service";
import {
  WorkflowStepLibraryNormalisationAndPooling
} from "../../../../models/workflow-step/workflow-step-library-normalisation-and-pooling";
import {MessageService} from "../../../../services/message.service";
import {BatchModel} from "../../../../models/batch/batch.model";
import {BatchType} from "../../../../enums/BatchType";
import {PositionModel} from "../../../../models/Plate/position.model";
import {PlateMode} from 'src/app/enums/PlateMode';
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {inOutAnimation} from "../../../../animations/in-out.animation";
import {PlateService} from "../../../../services/plate.service";
import {WorkflowStep} from "../../../../enums/WorkflowStep";
import {DetailedPlateModel} from "../../../../models/Plate/detailed-plate.model";
import {IndexPrimerPlate} from "../../../../enums/IndexPrimerPlate";
import {FileRecord} from "../../../../models/file/FileRecord";

@Component({
  selector: 'app-library-normalisation-and-pooling',
  templateUrl: './library-normalisation-and-pooling.component.html',
  styleUrls: ['./library-normalisation-and-pooling.component.scss'],
  animations: [inOutAnimation]
})
export class LibraryNormalisationAndPoolingComponent implements OnInit {

  faCheck = faCheck;
  faTime = faTimes;

  Workflow = Workflow;
  WorkflowStep = WorkflowStep;
  BatchType = BatchType;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepLibraryNormalisationAndPooling;
  @Input() readOnly: boolean = false;

  selectedPlateWaterName: string;
  selectedPlateWater: DetailedPlateModel;
  selectedPosition: PositionModel;
  PlateMode = PlateMode;

  batchForm: UntypedFormGroup;
  batchFormArray: UntypedFormArray;

  constructor(private workflowInstanceService: WorkflowInstanceService,
              private fileService: FileService,
              private messageService: MessageService,
              public workflowDetailClientService: WorkflowDetailClientService,
              private plateService: PlateService) {
  }

  ngOnInit() {
    this.initBatchFormGroup();
    this.validateStep();
  }

  getFormGroupAbstractControl(form: AbstractControl): UntypedFormGroup {
    return form as UntypedFormGroup;
  }

  onPlateClicked(plateWater: DetailedPlateModel, plateName: string): void {
    this.selectedPlateWater = plateWater;
    this.selectedPlateWaterName = plateName;
  }

  clearSelection(): void {
    this.selectedPlateWater = null;
    this.selectedPosition = null;
    this.selectedPlateWaterName = null;
  }

  initBatchFormGroup(): void {
    let batchModel;
    if (this.workflowStep && this.workflowStep.batches.length) {
      batchModel = this.workflowStep.batches[0];
    } else {
      batchModel = new BatchModel();
      batchModel.type = BatchType.WATER;
    }
    this.batchForm = BatchModel.initFormForBatchSelection(batchModel);
    if (this.workflowStep.batches)
      this.batchFormArray = BatchModel.initFormArrayForBatchSelection([this.workflowStep.batches[0]], true, false);

  }

  getSelectedWorkflowByIndex(index: number): string {
    if (this.workflowInstance?.workflow === Workflow.LIBRARY_96) {
      return this.workflowStep.selectedWorkflowInstances[index]?.workflowNumber || '---';
    }

    if (this.workflowInstance?.workflow === Workflow.LIBRARY_384) {
      return this.workflowStep.selectedWorkflowInstances[0]?.workflowNumber + '_' + IndexPrimerPlate.getByIndex(index);
    }

    return '';
  }

  onFileUploadedToSelectedPlate(file: FileRecord): void {
    const index = this.workflowStep.plates.findIndex(pw => pw.plate.id === this.selectedPlateWater.plate.id);
    this.workflowStep.plates[index].plate.uploadedFile = file;
    this.selectedPlateWater.plate.uploadedFile = file;
    this.validateStep();
  }

  onCellClick(position: PositionModel) {
    this.selectedPosition = position;
  }

  exportCalculation(): void {
    if (this.selectedPlateWater.plate) {
      this.plateService.exportFileByWorkflowAndStepAndPlate(this.workflowInstance.id, this.workflowStep.step, this.selectedPlateWater.plate.id)
        .subscribe((resp) => {
          if (resp && resp.exportedFile) {
            const index = this.workflowStep.plates.findIndex(pw => pw.plate.id === this.selectedPlateWater.plate.id);
            this.workflowStep.plates[index].plate.exportedFile = resp.exportedFile;
            this.selectedPlateWater.plate.exportedFile = resp.exportedFile;
            this.validateStep();
          }
        });
    }
  }

  reExportCalculation(): void {
    if (this.selectedPlateWater) {
      this.workflowDetailClientService.reExportFile(WorkflowStep.NORMALIZATION_AND_POOLING, this.selectedPlateWater.plate.id);
    }
  }

  validateStep(): void {
    this.workflowStep.batches = [BatchModel.getBatchesFromBatchSelectionFormGroup(this.batchForm)];
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

  onFileRemovedFromSelectedPlate() {
    if (this.selectedPlateWater?.plate && this.selectedPlateWater?.plate.id) {
      const index = this.workflowStep.plates.findIndex(p => p.id === this.selectedPlateWater.plate.id);
      this.workflowStep.plates[index].plate.uploadedFile = null;
      this.selectedPlateWater.plate.uploadedFile = null;
      this.validateStep();
    }
  }

  protected readonly FileType = FileType;
}
