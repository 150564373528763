import {FileRecord} from "../file/FileRecord";

export class WorkflowStepLibraryPostSequencingRunParametersModel {

  applicationVersion: string;
  runID: string;
  runNumber: string;
  mcsVersion: string;
  rtaVersion: string;
  barcode: string;
  pr2BottleBarcode: string;
  reagentKitPartNumberEntered: string;
  reagentKitVersion: string;
  reagentKitBarcode: string;
  experimentName: string;
  libraryPrepKit: string;
  indexKit: string;
  chemistry: string;
  username: string;
  sampleSheetName: string;

  importedFile: FileRecord;

  static fromData(data: WorkflowStepLibraryPostSequencingRunParametersModel): WorkflowStepLibraryPostSequencingRunParametersModel {
    if (!data) return null;
    const obj = Object.assign(new WorkflowStepLibraryPostSequencingRunParametersModel(), data);
    obj.importedFile = FileRecord.fromData(data.importedFile);
    return obj;
  }

}
