import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CyclerSelectorComponent } from './cycler-selector.component';
import {InputModule} from "../input/input.module";
import {ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    CyclerSelectorComponent
  ],
  exports: [
    CyclerSelectorComponent
  ],
  imports: [
    CommonModule,
    InputModule,
    ReactiveFormsModule
  ]
})
export class CyclerSelectorModule { }
