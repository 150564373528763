import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {lastValueFrom, map, Observable} from "rxjs";
import {IWorkflowStepBase} from "../models/workflow-step/workflow-step-base.model";
import {WorkflowStepUtils} from "../utils/workflow-step.utils";
import {WorkflowInstanceDtoModel} from "../models/workflow/workflow-instance-dto.model";
import {WorkflowStep} from "../enums/WorkflowStep";
import {WorkflowInstanceStepEditModel} from "../models/workflow-step/edit/workflow-instance-step-edit.model";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class WorkflowInstanceStepService {

  private readonly baseUri: string;

  constructor(private http: HttpClient,
              private globals: Globals) {
    this.baseUri = this.globals.backendUri + '/workflow-instance-steps';
  }

  getLastWorkStepByInstanceId(workFlowInstanceId: number): Observable<IWorkflowStepBase> {
    return this.http.get<IWorkflowStepBase>(`${this.baseUri}/current-step?workflowInstanceId=${workFlowInstanceId}`)
      .pipe(map(WorkflowStepUtils.initWorkflowStepFromData));
  }

  getWorkflowInstanceStepByStep(workflowInstanceId: number, workflowStep: WorkflowStep): Observable<IWorkflowStepBase> {
    return this.http.get<WorkflowInstanceDtoModel>(`${this.baseUri}/get-by-instance-and-step?workflowInstanceId=${workflowInstanceId}&workflowStep=${workflowStep}`)
      .pipe(map(WorkflowStepUtils.initWorkflowStepFromData));
  }

  reExportFileForWorkflowInstanceAndStep(workflowInstanceId: number, workflowStep: WorkflowStep, plateId?: number) {
    let url = `${this.baseUri}/re-export-file-by-instance-and-step?workflowInstanceId=${workflowInstanceId}&workflowStep=${workflowStep}`;
    if (plateId != null) {
      url += `&plateId=${plateId}`;
    }
    lastValueFrom(this.http.get<boolean>(url));
  }


  storeStepChanges(workflowStep: WorkflowInstanceStepEditModel): Observable<IWorkflowStepBase> {
    return this.http.put<IWorkflowStepBase>(`${this.baseUri}/store-step-changes`, workflowStep);
  }

}
