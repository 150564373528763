<div class="container">
  <!-- Search Fields -->
  <app-search-fields-container>
    <div class="search-fields-container" [formGroup]="filterForm">
      <!-- Barcode -->
      <core-input-text
        topLabel="Barcode"
        [showClearButton]="false"
        [removeDefaultBottomErrorMargin]="true"
        [formControlName]="WorkflowInstanceFilterModel.WORKFLOW_NUMBER">
      </core-input-text>

      <!-- Current Step -->
      <core-input-dropdown
        topLabel="Aktueller Schritt"
        [options]="workflowSteps"
        [showClearButton]="false"
        [removeDefaultBottomErrorMargin]="true"
        [formControlName]="WorkflowInstanceFilterModel.CURRENT_STEP">
      </core-input-dropdown>

      <!-- Date from -->
      <core-date-picker
        topLabel="Datum - Von"
        [showClearButton]="false"
        [maxDate]="filterDateTo"
        [removeDefaultBottomErrorMargin]="true"
        [formControlName]="WorkflowInstanceFilterModel.DATE_FROM">
      </core-date-picker>

      <!-- Date to -->
      <core-date-picker
        topLabel="Datum - Bis"
        [showClearButton]="false"
        [minDate]="filterDateFrom"
        [removeDefaultBottomErrorMargin]="true"
        [formControlName]="WorkflowInstanceFilterModel.DATE_TO">
      </core-date-picker>

      <!-- User -->
      <core-input-dropdown
        topLabel="Benutzer"
        [options]="creatorUsers"
        [showClearButton]="false"
        [removeDefaultBottomErrorMargin]="true"
        [formControlName]="WorkflowInstanceFilterModel.USER_NAME">
      </core-input-dropdown>

      <!-- Batch -->
      <div>
        <app-archive-batch-selector
          topLabel="Charge"
          [removeDefaultBottomErrorMargin]="true"
          [formGroup]="filterForm"
          [formControlName]="WorkflowInstanceFilterModel.BATCH_ID"
          [showClearButton]="false"
          [forceSelection]="true">
        </app-archive-batch-selector>
      </div>

      <!-- Barcode -->
      <core-input-text
        topLabel="Cycler Name"
        [showClearButton]="false"
        [removeDefaultBottomErrorMargin]="true"
        [formControlName]="WorkflowInstanceFilterModel.CYCLER_NAME">
      </core-input-text>

      <div class="reset-btn">
        <core-button
          (onClick)="resetFilter()"
          *ngIf="showClearButton"
          classList="p-button-secondary"
          text="Filter zurücksetzen">
        </core-button>
      </div>
    </div>
  </app-search-fields-container>

  <div class="table-container">
    <p-table
      (onPage)="changePage($event)"
      [(first)]="first"
      [lazy]="true"
      [paginator]="true"
      (onSort)="onSort($event)"
      [totalRecords]="totalElements"
      [columns]="tableColumns"
      [customSort]="true"
      [responsive]="true"
      [rows]="this.pageSize"
      selectionMode="single"
      [scrollHeight]="'flex'"
      [scrollable]="true"
      [value]="workflowInstances"
      [loading]="isLoading"
      responsiveLayout="scroll">
      <ng-template let-columns pTemplate="header">
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.label}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template let-columns="columns" let-rowData pTemplate="body">
        <tr (click)="onRowSelected(rowData)">
          <td *ngFor="let col of columns">
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'currentStep'">
                {{rowData[col.field] | workflowStepReadable }}
              </ng-container>

              <ng-container *ngSwitchCase="'updated'">
                {{rowData[col.field] | date:globals.dateTimeFormat }}
              </ng-container>

              <ng-container *ngSwitchCase="'workflow'">
                {{rowData[col.field] | workflowReadAble }}
              </ng-container>

              <ng-container *ngSwitchDefault>
                {{rowData[col.field]}}
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-workflow-detail-dialog
  *ngIf="showDetail"
  (closeDialog)="onCloseDialog()"
  [showDetail]="showDetail">
</app-workflow-detail-dialog>
