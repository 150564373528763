import { FileRecord } from 'src/app/models/file/FileRecord';
import {IWorkflowStepBase} from "./workflow-step-base.model";
import {IWorkflowStepValidation} from "./workflow-step-validation.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {BatchModel} from "../batch/batch.model";
import {WorkflowInstanceBaseModel} from "../workflow/workflow-instance-base.model";
import {BatchType} from "../../enums/BatchType";

export class WorkflowStepLibraryNgsSequencingModel implements IWorkflowStepBase, IWorkflowStepValidation {
  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  selectedWorkflowInstances: WorkflowInstanceBaseModel[];

  batches: BatchModel[];
  sampleSheet: FileRecord;

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, batches: BatchModel[], sampleSheet: FileRecord, isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.selectedWorkflowInstances = workflowInstance.libraryWorkflowInstances ?? [];
    this.batches = batches;
    this.sampleSheet = FileRecord.fromData(sampleSheet);
  }

  isValid(): boolean {
    return this.batches?.filter(batch => batch.id).length === BatchType.getAllForNgsSequencing().length;
  }


}
