import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './services/authentication.service';
import {PrimeNGConfig} from "primeng/api";
import {SseService} from "./services/sse.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(public authService: AuthenticationService,
              private sseService: SseService,
              private config: PrimeNGConfig) {
    this.setPrimeNgTranslations();
  }

  ngOnInit() {
    window.onunload = () => this.sseService.unloadSse();

    if (this.authService.isLoggedIn()) {
      this.sseService.connectToSse();
    }
  }

  private setPrimeNgTranslations(): void {
    this.config.setTranslation({
      "startsWith": "Startet mit",
      "contains": "Beinhaltet",
      "notContains": "Beinhaltet nicht",
      "endsWith": "Endet mit",
      "equals": "Gleicht",
      "notEquals": "Gleicht nicht",
      "noFilter": "Kein Filter",
      "lt": "Weniger als",
      "lte": "Weniger als oder gleich zu",
      "gt": "Größer als",
      "gte": "Größer als oder gleich zu",
      "is": "Ist",
      "isNot": "Ist nicht",
      "before": "Davor",
      "after": "Danach",
      "clear": "Leeren",
      "apply": "Übernehmen",
      "matchAll": "Match All",
      "matchAny": "Match Any",
      "addRule": "Add Rule",
      "removeRule": "Remove Rule",
      "accept": "Ja",
      "reject": "Nein",
      "choose": "Choose",
      "upload": "Upload",
      "cancel": "Abbrechen",
      "dayNames": [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag"
      ],
      "dayNamesShort": [
        "Son",
        "Mon",
        "Die",
        "Mit",
        "Don",
        "Fri",
        "Sat"
      ],
      "dayNamesMin": [
        "So",
        "Mo",
        "Di",
        "Mi",
        "Do",
        "Fr",
        "Sa"
      ],
      "monthNames": [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "July",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
      ],
      "monthNamesShort": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez"
      ],
      "today": "Heute",
      "weekHeader": "Woche",
      "weak": "Schwaches",
      "medium": "Mittel",
      "strong": "Sicheres",
      "passwordPrompt": "Geben Sie ein Passwort ein",
      "emptyMessage": "Keine Ergebnisse gefunden",
      "emptyFilterMessage": "Keine Ergebnisse gefunden",
      "dateFormat": "dd.mm.yy"
    });
  }

}
