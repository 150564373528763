import {NgModule} from '@angular/core';
import {HeaderComponent} from './header.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FileProcessingHistoryModule} from "../file-history/file-processing-history.module";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {DialogModule} from "primeng/dialog";
import {InputModule} from "../input/input.module";

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    RouterModule,
    FileProcessingHistoryModule,
    OverlayPanelModule,
    DialogModule,
    InputModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {
}
