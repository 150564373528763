import {IWorkflowStepBase} from "./workflow-step-base.model";
import {IWorkflowStepValidation} from "./workflow-step-validation.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {WorkflowInstanceBaseModel} from "../workflow/workflow-instance-base.model";
import {BatchModel} from "../batch/batch.model";
import {BatchType} from "../../enums/BatchType";
import {WorkflowStepLibraryQubitConcentrationsModel} from "./workflow-step-library-qubit-concentrations.model";

export class WorkflowStepLibraryQubitModel implements IWorkflowStepBase, IWorkflowStepValidation {
  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  selectedWorkflowInstances: WorkflowInstanceBaseModel[];

  batches: BatchModel[];

  factorStandard: number;
  volumeForFourNmPool: number;

  concentrations: WorkflowStepLibraryQubitConcentrationsModel[];

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, batches: BatchModel[], factorStandard: number, volumeForFourNmPool: number, concentrations: WorkflowStepLibraryQubitConcentrationsModel[], isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.selectedWorkflowInstances = workflowInstance?.libraryWorkflowInstances ?? [];
    this.batches = batches;
    this.factorStandard = factorStandard;
    this.volumeForFourNmPool = volumeForFourNmPool;
    this.concentrations = [];
    if (concentrations != null) {
      for (let con of concentrations) {
        this.concentrations.push(
          new WorkflowStepLibraryQubitConcentrationsModel(
            con.id,
            con.concentration,
            con.ad,
            con.dilution));
      }
    }
  }

  isValid(): boolean {
    return this.batches?.length === BatchType.getAllForQubit().length &&
      this.concentrations?.length === 4 &&
      this.concentrations.filter(c => c != null && c.concentration > 0).length === 4;
  }

}
