import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowStepSampleSelectionModel} from "./workflow-step-sample-selection.model";
import {WorkflowStepQuantificationFirstModel} from "./workflow-step-quantification-first.model";
import {WorkflowStepDnaExtractionModel} from "./workflow-step-dna-extraction.model";
import {WorkFlowStepTargetPcrModel} from "./work-flow-step-target-pcr.model";
import {WorkflowStepAddingChecksModel} from "./workflow-step-adding-checks.model";
import {WorkflowStepMeasurementOnGlomaxFirstModel} from "./workflow-step-measurement-on-glomax-first.model";
import {WorkflowStepNormalisationPreparationModel} from "./workflow-step-normalisation-preparation.model";
import {WorkflowStepNormalisation} from "./workflow-step-normalization.model";
import {WorkflowStepTargetPoolingModel} from "./workflow-step-target-pooling.model";
import {WorkflowStepPurificationModel} from "./workflow-step-purification.model";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {WorkflowStepIndexPcrSetupModel} from "./workflow-step-index-pcr-setup.model";
import {WorkflowStepLibraryPlateSelectionModel} from "./workflow-step-library-plate-selection.model";
import {WorkflowStepLibraryNormalisationAndPooling} from "./workflow-step-library-normalisation-and-pooling";
import {WorkflowStepLibraryAgilantModel} from "./workflow-step-library-agilant.model";
import {WorkflowStepLibraryQubitModel} from "./workflow-step-library-qubit.model";
import {WorkflowStepLibraryNgsSequencingModel} from "./workflow-step-library-ngs-sequencing.model";
import {WorkflowStepLibraryPostSequencingModel} from "./workflow-step-library-post-sequencing.model";
import {WorkflowStepDnaExtractionSwabLysisModel} from "./workflow-step-dna-extraction-swab-lysis.model";
import {WorkflowStepFinishedModel} from "./workflow-step-finished.model";
import {Workflow} from "../../enums/Workflow";
import {WorkflowStepLibraryPoolingModel} from "./workflow-step-library-pooling.model";

export const WorkFlowStepClass = {
  WorkflowStepPreparationModel: 'WorkflowStepPreparationModel',
  WorkflowStepSampleSelectionModel: 'WorkflowStepSampleSelectionModel',
  WorkflowStepDnaExtractionModel: 'WorkflowStepDnaExtractionModel',
  WorkflowStepDnaExtractionSwabLysisModel: 'WorkflowStepDnaExtractionSwabLysisModel',
  WorkflowStepAddingChecksModel: 'WorkflowStepAddingChecksModel',
  WorkflowStepQuantificationFirstModel: 'WorkflowStepQuantificationFirstModel',
  WorkFlowStepTargetPcrModel: 'WorkFlowStepTargetPcrModel',
  WorkflowStepMeasurementOnGlomaxFirstModel: 'WorkflowStepMeasurementOnGlomaxFirstModel',
  WorkflowStepNormalisationPreparationModel: 'WorkflowStepNormalisationPreparationModel',
  WorkflowStepNormalisation: 'WorkflowStepNormalisation',
  WorkflowStepTargetPoolingModel: 'WorkflowStepTargetPoolingModel',
  WorkflowStepPurificationModel: 'WorkflowStepPurificationModel',
  WorkflowStepIndexPcrSetupModel: 'WorkflowStepIndexPcrSetupModel',
  WorkflowStepLibraryPlateSelectionModel: 'WorkflowStepLibraryPlateSelectionModel',
  WorkflowStepQuantificationSecondModel: 'WorkflowStepQuantificationSecondModel',
  WorkflowStepMeasurementOnGlomaxSecondModel: 'WorkflowStepMeasurementOnGlomaxSecondModel',
  WorkflowStepLibraryNormalisationAndPooling: 'WorkflowStepLibraryNormalisationAndPooling',
  WorkflowStepLibraryIndexPurificationModel: 'WorkflowStepLibraryIndexPurificationModel',
  WorkflowStepLibraryAgilantModel: 'WorkflowStepLibraryAgilantModel',
  WorkflowStepLibraryQubitModel: 'WorkflowStepLibraryQubitModel',
  WorkflowStepLibraryPoolingModel: 'WorkflowStepLibraryPoolingModel',
  WorkflowStepLibraryNgsSequencingModel: 'WorkflowStepLibraryNgsSequencingModel',
  WorkflowStepLibraryPostSequencingModel: 'WorkflowStepLibraryPostSequencingModel',
  WorkflowStepFinishedModel: 'WorkflowStepFinishedModel'
}

export interface IWorkflowStepBase {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

}
