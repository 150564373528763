<p-dialog
  [(visible)]="showDialog"
  (onHide)="onCloseDialog(null)"
  [closable]="true"
  header="Neue Charge erstellen"
  [modal]="true">
  <div *ngIf="batchForm" [formGroup]="batchForm" class="create-batch-content">
    <label>Typ</label>
    <ng-container *ngIf="isCreationWithApproval">
      <core-input-dropdown
        [formGroup]="batchForm"
        [formControlName]="'type'"
        [options]="batchTypeOptions"
        [removeDefaultBottomErrorMargin]="true"
        [showClearButton]="false">
      </core-input-dropdown>
    </ng-container>
    <ng-container *ngIf="!isCreationWithApproval">
      <div>{{ batchType | batchTypeReadable }}</div>
    </ng-container>
    <label for="batch_number">Chargennummer</label>
    <core-input-text
      id="batch_number"
      [showClearButton]="false"
      [formControlName]="'batchNumber'"
      [removeDefaultBottomErrorMargin]="true">
    </core-input-text>

    <label for="batch_number">Ablaufdatum</label>
    <core-date-picker
      [showClearButton]="false"
      [formControlName]="'expirationDate'"
      [removeDefaultBottomErrorMargin]="true"
      [minDate]="minDate">
    </core-date-picker>
  </div>
  <p-footer>
    <core-button-submit
      (onClick)="onSaveNewBatch()"
      [disabled]="batchForm.invalid"
      [backgroundColor]="'#189d18'"
      [text]="'Neue Charge erstellen'"
      id="button_submit">
    </core-button-submit>
  </p-footer>
</p-dialog>

