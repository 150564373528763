import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {WorkflowStepLibraryPlateSelectionModel} from "../workflow-step-library-plate-selection.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {WorkflowForLibraryModel} from "./workflow-for-library.model";

export class WorkflowInstanceStepLibraryPlateSelectionEditModel extends WorkflowInstanceStepEditModel {

  workflowForLibraryList: WorkflowForLibraryModel[];
  takeOverWorkflowInstanceWorkflowNumberId: number;

  static initFromStep(step: WorkflowStepLibraryPlateSelectionModel): WorkflowInstanceStepLibraryPlateSelectionEditModel {
    const ret = new WorkflowInstanceStepLibraryPlateSelectionEditModel();
    ret.workflowStep = WorkflowStep.WORKFLOW_SELECTION;
    ret.workflowInstanceStepId = step.id;
    ret.workflowForLibraryList = step.selectedWorkflowInstances.map(WorkflowForLibraryModel.fromWorkFlowBaseModel);
    ret.takeOverWorkflowInstanceWorkflowNumberId = step.takeOverWorkflowInstance?.id;
    return ret;
  }
}
