import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkflowInstanceStateIconComponent} from './workflow-instance-state-icon.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    WorkflowInstanceStateIconComponent
  ],
  exports: [
    WorkflowInstanceStateIconComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule
  ]
})
export class WorkflowInstanceStateIconModule {
}
