import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {observable, Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MessageService} from '../services/message.service';
import {NavigationExtras, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private INVALID_TOKEN_MESSAGE = 'Der Login ist abgelaufen oder ungültig!';

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private messageService: MessageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((httpError: HttpErrorResponse) => {
          // get server-error message
          let message = null;

          // error 901 -> Position NOT_OK confirmation => no error message needed
          if (httpError.status === 901) {
            return throwError(httpError);
          }

          if (httpError.error &&
            httpError.error.length > 0) {
            message = httpError.error;
          }
          // show server message if not empty
          if (httpError.status !== 503 && message && message.length > 0) {
            this.messageService.addErrorMessage(message);
            // check if the token is invalid
            if (message === this.INVALID_TOKEN_MESSAGE) {
              this.authenticationService.logoutUser();
            }
          } else {
            if (httpError.status >= 400 && httpError.status < 500) {
              this.messageService.addErrorMessage(message);
            } else if (httpError.status >= 500 && httpError.status < 600) {
              this.router.navigate(['/error'], {queryParams: {errorStatus: httpError.status}});
            } else if (httpError.status >= 900) {
              this.messageService.addErrorMessage('Unbekannter Server Error');
            } else {
              this.messageService.addErrorMessage('Unbekannter Error');
            }
          }
          return throwError(httpError);
        }));
  }

}
