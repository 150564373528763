<p-dialog
  [(visible)]="showWorkFlowDialog"
  (onHide)="onCloseDialog()"
  [closable]="true"
  [modal]="true"
  [header]="isForLibrary ? 'Neue Library' : 'Neuer Durchlauf'">
  <ng-container *ngIf="form" [formGroup]="form">
    <div style="min-width: 25vw; padding-top: 2rem">
      <!-- Workflow Options -->
      <core-input-dropdown
        [options]="isForLibrary ? libraryTypeOptions : optionsWorkflows"
        [showClearButton]="false"
        [required]="true"
        formControlName="workflow"
        id="workflow"
        [label]="isForLibrary ? 'Library' : 'Workflow'">
      </core-input-dropdown>
    </div>
  </ng-container>
  <p-footer>
    <core-button-submit
      (onClick)="submitChanges()"
      [disabled]="form.invalid || form.disabled"
      [backgroundColor]="'#189d18'"
      [text]=" isForLibrary ? 'Anlegen' : 'Anlegen und Etikett drucken'"
      id="button_submit"
    >
    </core-button-submit>
  </p-footer>
</p-dialog>
