import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {InputModule} from "../input/input.module";
import {PlateModule} from "../plate/plate.module";
import {
  PositiveNegativeControlSelectionModule
} from "../positive-negative-control-selection/positive-negative-control-selection.module";
import {PositionDetailDialogModule} from "../position-detail/position-detail-dialog.module";
import {WorkflowStepCommonComponent} from "./workflow-step-common.component";
import {PreparationComponent} from "./workflow-steps/preparation/preparation.component";
import {DnaExtractionComponent} from './workflow-steps/dna-extraction/dna-extraction.component';
import {CustomPipeModule} from "../../pipes/custom-pipe.module";
import {SampleSelectionComponent} from './workflow-steps/sample-selection/sample-selection.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WorkflowStepLayoutComponent} from "./worklflow-step-plate-layout/workflow-step-layout.component";
import {AddingChecksComponent} from './workflow-steps/adding-checks/adding-checks.component';
import {
  AddingControlsBatchSelectorModule
} from "../adding-controls-batch-selector/adding-controls-batch-selector.module";
import {QuantificationFirstComponent} from './workflow-steps/quantification-first/quantification-first.component';
import {TargetPcrSetupComponent} from './workflow-steps/target-pcr-setup/target-pcr-setup.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {CyclerSelectorModule} from "../cycler-selector/cycler-selector.module";
import {
  MeasurementOnGlomaxFirstComponent
} from './workflow-steps/measurement-on-glomax-first/measurement-on-glomax-first.component';
import {
  NormalisationPreparationComponent
} from './workflow-steps/normalisation-preparation/normalisation-preparation.component';
import {NormalisationComponent} from './workflow-steps/normalisation/normalisation.component';
import {TargetPoolingComponent} from './workflow-steps/target-pooling/target-pooling.component';
import {PurificationComponent} from './workflow-steps/purification/purification.component';
import {
  LibraryPlateSelectionComponent
} from './workflow-steps/library-plate-selection/library-plate-selection.component';
import {IndexPcrSetupComponent} from './workflow-steps/index-pcr-setup/index-pcr-setup.component';
import {AutoCompleteModule} from "primeng/autocomplete";
import {CyclerRunThroughModule} from "../cycler-run-through/cycler-run-through.module";
import {BatchSelectorModule} from "../batch-selector/batch-selector.module";
import {DialogModule} from "primeng/dialog";
import {InputSwitchModule} from "primeng/inputswitch";
import {QuantificationSecondComponent} from './workflow-steps/quantification-second/quantification-second.component';
import {FileUploadModule} from "../file-upload/file-upload.module";
import {
  MeasurementOnGlomaxSecondComponent
} from "./workflow-steps/measurement-on-glomax-second/measurement-on-glomax-second.component";
import {
  LibraryNormalisationAndPoolingComponent
} from './workflow-steps/library-normalisation-and-pooling/library-normalisation-and-pooling.component';
import {LibraryAgilantComponent} from './workflow-steps/library-agilant/library-agilant.component';
import {LibraryQubitComponent} from './workflow-steps/library-qubit/library-qubit.component';
import {LibraryNgsSequencingComponent} from './workflow-steps/library-ngs-sequencing/library-ngs-sequencing.component';
import {
  LibraryPostSequencingComponent
} from './workflow-steps/library-post-sequencing/library-post-sequencing.component';
import {
  DnaExtractionSwabLysisComponent
} from './workflow-steps/dna-extraction-swab-lysis/dna-extraction-swab-lysis.component';
import {FinishedComponent} from './workflow-steps/finished/finished.component';
import {BatchesSelectorModule} from "../batches-selector/batches-selector.module";
import {PcrComponentModule} from "../pcr-tube/pcr-component.module";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {NewBatchDialogModule} from "../dialog/new-batch-dialog/new-batch-dialog.module";
import {PlateViewModule} from "../plate-view/plate-view.module";
import {
  LibraryIndexPurificationComponent
} from "./workflow-steps/library-index-purification/library-index-purification.component";
import {LibraryPoolingComponent} from "./workflow-steps/library-pooling/library-pooling.component";


@NgModule({
  declarations: [
    WorkflowStepCommonComponent,
    WorkflowStepLayoutComponent,
    // Components
    PreparationComponent,
    DnaExtractionComponent,
    SampleSelectionComponent,
    AddingChecksComponent,
    QuantificationFirstComponent,
    TargetPcrSetupComponent,
    MeasurementOnGlomaxFirstComponent,
    NormalisationPreparationComponent,
    NormalisationComponent,
    TargetPcrSetupComponent,
    TargetPoolingComponent,
    PurificationComponent,
    TargetPcrSetupComponent,
    IndexPcrSetupComponent,
    LibraryPlateSelectionComponent,
    MeasurementOnGlomaxSecondComponent,
    PurificationComponent,
    QuantificationSecondComponent,
    LibraryNormalisationAndPoolingComponent,
    LibraryIndexPurificationComponent,
    LibraryAgilantComponent,
    LibraryQubitComponent,
    LibraryPoolingComponent,
    LibraryNgsSequencingComponent,
    LibraryPostSequencingComponent,
    DnaExtractionSwabLysisComponent,
    FinishedComponent
  ],
  imports: [
    CommonModule,
    InputModule,
    PlateModule,
    PositiveNegativeControlSelectionModule,
    PositionDetailDialogModule,
    ReactiveFormsModule,
    AddingControlsBatchSelectorModule,
    CustomPipeModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CyclerSelectorModule,
    AutoCompleteModule,
    CyclerRunThroughModule,
    BatchSelectorModule,
    BatchesSelectorModule,
    DialogModule,
    InputSwitchModule,
    FileUploadModule,
    PcrComponentModule,
    ProgressSpinnerModule,
    NewBatchDialogModule,
    PlateViewModule
  ],
  exports: [WorkflowStepCommonComponent],
  providers: [DatePipe]
})
export class WorkflowStepCommonModule {
}
