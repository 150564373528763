<app-workflow-step-layout>
  <ng-container info>
    <div class="container">
      <label>Abgeschlossen am:</label>
      <div>{{ workFlowStep?.finishedAt | date:globals.dateFormat }}</div>

      <label>Abgeschossen von:</label>
      <div>{{ workFlowStep?.finishedBy }}</div>

      <ng-container *ngIf="!workflowDetailClientService.workflowInstance.isLibrary()">
        <label>Für Library Freigegeben:</label>
        <div>{{ workFlowStep?.workflowInstance.sharedToLibrary ? 'Ja' : 'Nein'}}</div>

        <label>Verwendet in Library:</label>
        <div
          [ngClass]="usedInLibrary ? 'library-link' : ''"
          (click)="navigateToLibrary()">
          {{ usedInLibrary?.workflowNumber || '---' }}
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container plate>
    <app-plate
      *ngIf="!workflowDetailClientService.workflowInstance.isLibrary()"
      [workflow]="workflowDetailClientService?.workflowInstance?.workflow">
    </app-plate>
    <app-pcr-tube-viewer
      *ngIf="workflowDetailClientService.workflowInstance.isLibrary()">
    </app-pcr-tube-viewer>
  </ng-container>
</app-workflow-step-layout>
