import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArchiveBatchSelectorComponent } from './archive-batch-selector.component';
import {AutoCompleteModule} from "primeng/autocomplete";
import {ButtonModule} from "primeng/button";



@NgModule({
    declarations: [
        ArchiveBatchSelectorComponent
    ],
    exports: [
        ArchiveBatchSelectorComponent
    ],
  imports: [
    CommonModule,
    AutoCompleteModule,
    ButtonModule
  ]
})
export class ArchiveBatchSelectorModule { }
