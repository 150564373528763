import {CyclerRunThroughState} from "../../enums/CyclerRunThroughState";
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from "@angular/forms";

export interface CyclerRunTroughFormGroup {
  id: FormControl<number>,
  version: FormControl<number>
  plateId: FormControl<number>,
  cyclerId: FormControl<number>,
  workflowStepId: FormControl<number>,
  state: FormControl<CyclerRunThroughState>,
  confirmed: FormControl<boolean>,
  cyclerName: FormControl<string>
}

export class CyclerRunThroughModel {
  id: number;
  version: number;
  plateId: number;
  cyclerId: number;
  workflowStepId: number;
  state: CyclerRunThroughState;
  confirmed: boolean;
  cyclerName: string;

  static initFormWithDna(cyclerRunThroughList: { cyclerRunThrough: CyclerRunThroughModel, plateSuffix: string, dnaEntered: boolean }[]): FormArray<FormGroup<CyclerRunTroughFormGroup & { suffix: AbstractControl<string> } & { dnaEntered: AbstractControl<boolean> }>> {
    const ret: FormArray<FormGroup> = new FormArray<FormGroup>([]);
    cyclerRunThroughList.forEach(crt => {
      ret.push(this.initFormGroupWithSuffixAndDna(crt));
    });
    return ret;
  }

  static initForm(cyclerRunThroughList: { cyclerRunThrough: CyclerRunThroughModel, plateSuffix: string }[]): FormArray<FormGroup<CyclerRunTroughFormGroup & { suffix: AbstractControl<string> }>> {
    const ret: FormArray<FormGroup> = new FormArray<FormGroup>([]);
    cyclerRunThroughList.forEach(crt => {
      ret.push(this.initFormGroupWithSuffix(crt));
    });
    return ret;
  }

  static initFormGroupWithSuffixAndDna(crt: { cyclerRunThrough: CyclerRunThroughModel, plateSuffix: string, dnaEntered: boolean }): FormGroup<CyclerRunTroughFormGroup & { suffix: AbstractControl<string> } & { dnaEntered: AbstractControl<boolean> }> {
    const form: FormGroup<CyclerRunTroughFormGroup & { suffix: AbstractControl<string> } & { dnaEntered: AbstractControl<boolean> }> = this.initFormGroup(crt.cyclerRunThrough) as any;
    form.addControl('suffix', new FormControl<string>(crt.plateSuffix));
    form.addControl('dnaEntered', new FormControl<boolean>(crt.dnaEntered));
    if (!crt.dnaEntered) {
      form.disable();
    }
    return form;
  }

  static initFormGroupWithSuffix(crt: { cyclerRunThrough: CyclerRunThroughModel, plateSuffix: string }): FormGroup<CyclerRunTroughFormGroup & { suffix: AbstractControl<string> }> {
    const form: FormGroup<CyclerRunTroughFormGroup & { suffix: AbstractControl<string> }> = this.initFormGroup(crt.cyclerRunThrough) as any;
    form.addControl('suffix', new FormControl<string>(crt.plateSuffix));
    return form;
  }

  static initFormGroup(crt: CyclerRunThroughModel): FormGroup<CyclerRunTroughFormGroup> {
    const ret = new FormGroup<CyclerRunTroughFormGroup>({
      id: new FormControl<number>(crt.id),
      version: new FormControl<number>(crt.version),
      plateId: new FormControl<number>(crt.plateId, Validators.required),
      cyclerId: new FormControl<number>(crt.cyclerId, Validators.required),
      workflowStepId: new FormControl<number>(crt.workflowStepId, Validators.required),
      state: new FormControl<CyclerRunThroughState>(crt.state, Validators.required),
      confirmed: new FormControl<boolean>(crt.confirmed),
      cyclerName: new FormControl<string>(crt.cyclerName)
    });

    if (crt.id) {
      ret.get('cyclerId').disable();
    }

    if (!crt.id) {
      ret.get('state').disable();
    }

    if (crt.confirmed) {
      ret.disable();
    }
    return ret;
  }

  static updateFormGroupFromResponse(formGroup: FormGroup<CyclerRunTroughFormGroup>, resp: CyclerRunThroughModel): void {
    formGroup.get('id').setValue(resp.id);
    formGroup.get('version').setValue(resp.version);
    formGroup.get('plateId').setValue(resp.plateId);
    formGroup.get('cyclerId').setValue(resp.cyclerId);
    formGroup.get('workflowStepId').setValue(resp.workflowStepId);
    formGroup.get('state').setValue(resp.state);
    formGroup.get('confirmed').setValue(resp.confirmed);
    formGroup.get('cyclerName').setValue(resp.cyclerName);

    if (resp.id) {
      formGroup.get('cyclerId').disable();
      formGroup.get('cyclerId').updateValueAndValidity();
    }

    if (!resp.id) {
      formGroup.get('state').disable();
    } else {
      formGroup.get('state').enable();
    }
    formGroup.get('state').updateValueAndValidity();

    if (resp.confirmed) {
      formGroup.disable();
    }

    formGroup.updateValueAndValidity();
  }
}
