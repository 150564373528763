import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {map, Observable} from "rxjs";
import {FileProcessingHistoryModel} from "../models/file/file-processing-history.model";
import {PageableRequestModel} from "../models/pagable/pageable-request.model";
import {FileProcessingHistoryPageModel} from "../models/pagable/file-processing-history-page.model";

@Injectable({
  providedIn: 'root'
})
export class FileProcessingHistoryService {

  private readonly baseUri: string;

  constructor(private http: HttpClient,
              private globals: Globals) {
    this.baseUri = this.globals.backendUri + '/file-processing-history';
  }

  findById(id: number): Observable<FileProcessingHistoryModel> {
    return this.http.get<FileProcessingHistoryModel>(this.baseUri + '/' + id)
      .pipe(map(response => FileProcessingHistoryModel.fromData(response)));
  }

  findAll(pageableRequestModel: PageableRequestModel, onlyUnconfirmed: boolean): Observable<FileProcessingHistoryPageModel> {
    let httpParams = PageableRequestModel.toHttpParams(pageableRequestModel);
    httpParams = httpParams.append('onlyUnconfirmed', String(onlyUnconfirmed));
    return this.http.get<FileProcessingHistoryPageModel>(this.baseUri, {params: httpParams})
      .pipe(map((cm: FileProcessingHistoryPageModel) => FileProcessingHistoryPageModel.fromData(cm)));
  }

  countAllUnconfirmed(): Observable<number> {
    return this.http.get<number>(this.baseUri + '/count-unconfirmed');
  }

  confirm(id: number): Observable<FileProcessingHistoryModel> {
    return this.http.put<FileProcessingHistoryModel>(this.baseUri, {id: id})
      .pipe(map(response => FileProcessingHistoryModel.fromData(response)));
  }

}
