import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {Globals} from "../../../global/globals";

@Component({
  selector: 'core-input-number',
  templateUrl: 'input-number.component.html',
  styleUrls: ['input-number.component.scss', '../input.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputNumberComponent,
    multi: true
  }]
})
export class InputNumberComponent implements ControlValueAccessor, OnInit {
  faInfoCircle = faInfoCircle;

  @Input() id: string;
  @Input() label: string;
  @Input() topLabel: string;
  @Input() placeholder = '';
  @Input() showClearButton = true;
  @Input() showRevertButton = false;
  @Input() required = false;
  @Input() removeDefaultBottomErrorMargin = false;
  @Input() useGrouping = true;
  @Input() infoLabel: string;

  @Input() min = 0;
  @Input() max: number = Number.POSITIVE_INFINITY;

  @Input() maxFractionDigits = 0;
  @Input() minFractionDigits = 0;

  // this only impacts the key up and key down value changes
  @Input() step = 1;

  @Output() onKeyUpEnter = new EventEmitter<number>();
  @Output() onRevert = new EventEmitter<any>();

  control: NgControl;

  value: number;
  disabled = false;
  private onTouched: Function;
  private onChanged: Function;

  constructor(private inj: Injector,
              public globals: Globals) {
    if (!this.id) {
      this.id = 'core-input-number-' + Math.random();
    }
  }

  ngOnInit() {
    this.control = this.inj.get(NgControl);
  }

  onClear() {
    this.valueChanged(null);
    this.onKeyUpEnter.emit(null);
  }

  valueChanged(value: number) {
    // check if value is inside min/max range
    if (value < this.min) {
      value = this.min;
    }
    if (value > this.max) {
      value = this.max;
    }
    this.onTouched();
    this.value = value;
    this.onChanged(value);
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onRevertPressed() {
    this.onRevert.emit();
  }

}
