<app-workflow-step-layout *ngIf="!workflowInstance?.indexPrimerPlate">
  <div info>
    <div [formGroup]="plateSuggestionForm" class="container" *ngIf="!readOnly">
      <div *ngIf="workflowInstance?.workflow === Workflow.WORKFLOW_96" class="data-row" style="margin-bottom: 1rem">
        <div>Vorgeschlagene Platte:</div>
        <core-input-dropdown
          [placeholder]="plateSuggestion"
          [options]="plateSuggestionOptions"
          [formGroup]="plateSuggestionForm"
          [removeDefaultBottomErrorMargin]="true"
          [showClearButton]="false"
          [formControlName]="'plateSuggestion'">
        </core-input-dropdown>
      </div>
      <core-button
        [text]="'Index PCR Setup starten'"
        [width]="'100%'"
        classList="p-button-success"
        (onClick)="startIndexPcrSetup()">
      </core-button>
    </div>
  </div>
  <ng-container plate>
    <app-plate-view
      [plateMode]="PlateMode.RESULT"
      [workflowInstance]="workflowInstance"
      [plates]="workflowStep?.plates"
      (onPlateSelected)="selectedPlate = $event"
      (onPositionSelected)="onCellClick($event)"
      (onClearPositionSelected)="onCellClick(null)">
    </app-plate-view>
  </ng-container>
</app-workflow-step-layout>

<app-workflow-step-layout *ngIf="workflowInstance?.indexPrimerPlate">
  <div info>
    <ng-container *ngIf="!selectedPlate">
      <div *ngIf="workflowInstance?.workflow === Workflow.WORKFLOW_96" class="data-row" style="margin-bottom: 1rem">
        <div>Verwendete Index-Primer Plate: <b>{{ workflowInstance?.indexPrimerPlate }}</b></div>
      </div>

      <ng-container *ngIf="!readOnly">
        <div *ngIf="batchFormArray">
          <ng-container *ngFor="let batchFormGroup of batchFormArray.controls">
            <app-batch-selector
              [showExpirationDate]="false"
              [readOnly]="readOnly"
              [form]="getFormGroupFromAbstractControl(batchFormGroup)"
              (onChange)="validateStep()">
            </app-batch-selector>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="readOnly">
        <h3>Verwendete Chargen</h3>
        <app-batches-selector
          [showExpirationDate]="false"
          [readOnly]="readOnly"
          [forms]="batchFormArray"
          (onChange)="validateStep()">
        </app-batches-selector>
      </ng-container>

      <div *ngIf="cyclerRunThroughFormArray">
        <ng-container *ngFor="let cyclerRunThroughFormGroup of cyclerRunThroughFormArray.controls; let i = index">
          <hr>
          <div *ngIf="cyclerRunThroughFormArray" class="card-container">
            <!-- workflow number -->
            <b class="plate-number">{{ cyclerRunThroughFormGroup.controls.suffix?.value }}</b>
            <div class="data-row">
              <div>
                DNA hinzufügen
              </div>
              <!-- dna entered -->
              <core-button
                [width]="'100%'"
                [text]="'DNA hinzugefügt'"
                classList="p-button-success"
                [disabled]="readOnly || cyclerRunThroughFormGroup.controls?.dnaEntered?.value"
                (onClick)="onDnaEntered(cyclerRunThroughFormGroup)">
              </core-button>
            </div>
            <!-- cycler -->
            <app-cycler-run-through
              [readOnly]="readOnly"
              [cyclerRunThroughFormGroup]="cyclerRunThroughFormGroup"
              (cyclerRunThoughChanged)="validateStep()">
            </app-cycler-run-through>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedPlate">
      <app-plate-file
        header="Dateien"
        [plate]="selectedPlate"
        [deletable]="workflowDetailClientService.workflowInstance.currentStep === WorkflowStep.INDEX_PCR"
        [readonly]="readOnly"
        [fileType]="FileType.INTEGRA"
        (fileUploaded)="onFileUploadedToSelectedPlate($event)"
        (fileRemoved)="onFileRemovedFromSelectedPlate()">
      </app-plate-file>
    </ng-container>
  </div>

  <ng-container plate>
    <ng-container *ngIf="!selectedPlate">
      <div class="plate-list-container-ipcrs">
        <div *ngFor="let plate of workflowStep?.plates let i = index"
             class="plate-container-ipcrs"
             (click)="onPlateClicked(plate)">
          <app-plate
            [plateMode]="PlateMode.POSITION_EXIST"
            [plate]="plate"
            [ignoreHeightBoundary]="true"
            [name]="getWorkflowNameByIndex(i)">
          </app-plate>
          <div class="validation_container">
            <div *ngIf="!plate.uploadedFile" class="error-hint">
              <fa-icon [icon]="faTimes"></fa-icon>
              Datei noch nicht hochgeladen
            </div>
            <div *ngIf="plate.uploadedFile" class="success-hint">
              <fa-icon [icon]="faCheck"></fa-icon>
              Datei bereits hochgeladen
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Selected Plate for detail -->
    <ng-container *ngIf="this.selectedPlate">
      <app-plate
        [showBackButton]="true"
        [plate]="this.selectedPlate"
        [plateMode]="PlateMode.POSITION_EXIST"
        [name]="workflowInstance?.workflowNumber + (selectedPlate?.suffix ?  '_' + selectedPlate?.suffix : '')"
        (onBackButtonClicked)="clearSelection()">
      </app-plate>
    </ng-container>
  </ng-container>
</app-workflow-step-layout>
