<app-workflow-step-layout>
  <ng-container info>
    <div>
      <h3>Verwendete Chargen bei Swab Lysis Platte</h3>

      <app-batches-selector
        [readOnly]="readOnly"
        [forms]="batchFormArray"
        (onChange)="validateStep()">
      </app-batches-selector>

      <core-button
        [disabled]="!workflowStep?.isValid()"
        *ngIf="workflowDetailClientService.workflowInstance.currentStep === WorkflowStep.DNA_EXTRACTION_SWAB_LYSIS"
        [text]="'Weiter zur Extraktionsplatte'"
        [width]="'100%'"
        [backgroundColor]="'#189D18FF'"
        (onClick)="switchToSwabImport()">
      </core-button>

      <div class="plate-swap-button">
        <core-button
          *ngIf="workflowDetailClientService.workflowInstance.currentStep !== WorkflowStep.DNA_EXTRACTION_SWAB_LYSIS"
          [text]="'Zur Extraktionsplatte wechseln'"
          [width]="'100%'"
          [backgroundColor]="'#607D8B'"
          (onClick)="switchPlateView()">
        </core-button>
      </div>
    </div>

    <app-position-detail
      [position]="selectedPosition"
      [showChangeStateButton]="!readOnly"
      [showPipettingStatus]="true">
    </app-position-detail>

  </ng-container>
  <ng-container plate>
    <app-plate-view
      [plateMode]="PlateMode.PIP_STATUS"
      [workflowInstance]="workflowInstance"
      [plates]="workflowStep?.plates"
      (onPositionSelected)="onCellClick($event)"
      (onClearPositionSelected)="onCellClick(null)">
    </app-plate-view>
  </ng-container>

</app-workflow-step-layout>
