import {UntypedFormGroup} from "@angular/forms";

export class FormUtil {

  // To be used together with the file uploader
  // Files will be under their own name (formgroup key) as multipart file[], all other properties will be in one object under the 'properties' key
  static toFormData<T>(formValue: T): FormData {
    const formData = new FormData();
    const properties = {};

    for (const key of Object.keys(formValue)) {
      // @ts-ignore
      const value = formValue[key];

      if (value instanceof Object && value.filesToUpload?.every((innerVal: any) => innerVal instanceof File)){
        value.filesToUpload.forEach((file: string | Blob) => formData.append(key, file));
      } else {
        // @ts-ignore
        properties[key] = value;
      }
    }
    formData.append('properties', JSON.stringify(properties));
    return formData;
  }

  static markFormControlsAsTouchedAndDirty(form: UntypedFormGroup): void {
    Object.values(form.controls)
      .forEach(control => {
        control.markAsTouched();
        control.markAsDirty();
      })
  }
}
