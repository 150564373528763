import {Pipe, PipeTransform} from '@angular/core';
import {Workflow} from "../enums/Workflow";
import {WorkflowStep} from "../enums/WorkflowStep";

@Pipe({
  name: 'pcrTubeSize'
})
export class PcrTubeSizePipe implements PipeTransform {

  transform(workflow: Workflow, currentStep: WorkflowStep): number {
    switch (currentStep) {
      case WorkflowStep.INDEX_PURIFICATION:
      case WorkflowStep.QUALITY_ASSURANCE_AGILENT:
      case WorkflowStep.QUALITY_ASSURANCE_QUBIT:
        return 4;
    }

    return 1;
  }

}
