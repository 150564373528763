import {Component, Input, OnInit} from '@angular/core';
import {UnsubscribeBaseComponent} from "../../../unsubscribe-base.component";
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {Workflow} from 'src/app/enums/Workflow';
import {WorkflowStep} from 'src/app/enums/WorkflowStep';
import {WorkflowInstanceService} from "../../../../services/workflow-instance.service";
import {FileService} from "../../../../services/file.service";
import {MessageService} from "../../../../services/message.service";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {WorkflowStepLibraryPoolingModel} from "../../../../models/workflow-step/workflow-step-library-pooling.model";

@Component({
  selector: 'app-library-pooling',
  templateUrl: './library-pooling.component.html',
  styleUrls: ['./library-pooling.component.scss']
})
export class LibraryPoolingComponent extends UnsubscribeBaseComponent implements OnInit {

  Workflow = Workflow;
  WorkflowStep = WorkflowStep;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepLibraryPoolingModel;
  @Input() readOnly: boolean = false;

  constructor(private workflowInstanceService: WorkflowInstanceService,
              private fileService: FileService,
              private messageService: MessageService,
              public workflowDetailClientService: WorkflowDetailClientService) {
    super();
  }

  ngOnInit() {
    this.validateStep();
  }

  validateStep(): void {
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

  onIndexPoolCombined() {
    this.workflowStep.indexPoolsCombined = true;
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

}
