import {FormControl, FormGroup} from "@angular/forms";
import {SortModel} from "../sort.model";
import {Device} from "../../enums/Device";
import {WorkflowStep} from "../../enums/WorkflowStep";

export interface WorkflowInstanceFilterForm {
  workflowNumber: FormControl<string>,
  deviceList: FormControl<Device[]>,
  dateFrom: FormControl<Date>,
  dateTo: FormControl<Date>,
  userName: FormControl<string>,
  batchId: FormControl<number>,
  currentStep: FormControl<WorkflowStep>,
  cyclerName: FormControl<string>
}


export class WorkflowInstanceFilterModel {

  static readonly WORKFLOW_NUMBER = 'workflowNumber';
  static readonly DEVICE_LIST = 'deviceList';
  static readonly DATE_FROM = 'dateFrom';
  static readonly DATE_TO = 'dateTo';
  static readonly USER_NAME = 'userName';
  static readonly BATCH_ID = 'batchId';
  static readonly CURRENT_STEP = 'currentStep';
  static readonly CYCLER_NAME = 'cyclerName';

  workflowNumber: string;
  deviceList: Device[];
  dateFrom: Date;
  dateTo: Date;
  userName: string;
  batchId: number;
  currentStep: WorkflowStep;
  cyclerName: string;

  sortDto: SortModel;

  static initFilterForm(): FormGroup<WorkflowInstanceFilterForm> {
    return new FormGroup<WorkflowInstanceFilterForm>({
      workflowNumber: new FormControl<string>(null),
      deviceList: new FormControl<any[]>(null),
      dateFrom: new FormControl<Date>(null),
      dateTo: new FormControl<Date>(null),
      userName: new FormControl<string>(null),
      batchId: new FormControl<number>(null),
      currentStep: new FormControl<WorkflowStep>(null),
      cyclerName: new FormControl<string>(null)
    });
  }

  static initFilterDtoFromFormGroup(form: FormGroup<WorkflowInstanceFilterForm>): WorkflowInstanceFilterModel {
    const ret = new WorkflowInstanceFilterModel();
    ret.workflowNumber = form.get(this.WORKFLOW_NUMBER).value;
    ret.deviceList = form.get(this.DEVICE_LIST).value;
    ret.dateFrom = form.get(this.DATE_FROM).value;
    ret.dateTo = form.get(this.DATE_TO).value;
    ret.userName = form.get(this.USER_NAME).value;
    ret.batchId = form.get(this.BATCH_ID).value;
    ret.currentStep = form.get(this.CURRENT_STEP).value;
    ret.cyclerName = form.get(this.CYCLER_NAME).value;
    return ret;
  }

}
