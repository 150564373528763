import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WorkflowStep} from '../../../enums/WorkflowStep';
import {WorkflowInstanceModel} from '../../../models/workflow/workflow-instance.model';

@Component({
  selector: 'app-workflow-step',
  templateUrl: './workflow-step.component.html',
  styleUrls: ['./workflow-step.component.scss']
})
export class WorkflowStepComponent {

  @Input() workFlowSteps: WorkflowStep[];
  @Input() workFlowInstances: WorkflowInstanceModel[];
  @Output() showWorkflowDetailDialog = new EventEmitter<WorkflowInstanceModel>();

  get workFlowHeader(): string {
    // it is fine to get the first one readable as per definition the steps are the same for the user
    return WorkflowStep.getWorkFlowStepReadable(this.workFlowSteps[0]);
  }

}
