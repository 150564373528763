<label *ngIf="topLabel" [for]="id" class="core_input_top_label">{{topLabel}}</label>
<div class="core-input-label-container">
  <label *ngIf="label">{{label}}</label>
  <div class="p-inputgroup core-input-container core_input_flex_between core-input-check-box-container">
    <ng-content></ng-content>
  </div>
</div>
<div
  [ngClass]="[label ? 'core_input_error_container_with_label' : '', removeDefaultBottomErrorMargin ? '' : 'core_input_error_container_min_height']"
  class="core_input_error">
  <small *ngIf="control.touched && control.errors?.required">
    Dieses Feld ist erforderlich!
  </small>
</div>


