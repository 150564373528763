import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {WorkflowStepQuantificationSecondModel} from "../workflow-step-quantification-second.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";

export class WorkflowInstanceStepQuantificationSecondEditModel extends WorkflowInstanceStepEditModel {

  static initFromStep(step: WorkflowStepQuantificationSecondModel): WorkflowInstanceStepEditModel {
    const ret = new WorkflowInstanceStepQuantificationSecondEditModel();
    ret.workflowStep = WorkflowStep.QUANTIFICATION_SECOND;
    ret.workflowInstanceStepId = step.id;
    return ret;
  }

}
