<div class="batch-selector-container">
  <ng-container *ngIf="form" [formGroup]="form">

    <label>{{type | batchTypeReadable}}</label>

    <div class="batch-selector-auto-complete">
      <p-autoComplete
        (completeMethod)="filterBatches($event)"
        (onSelect)="onSelectBatch($event)"
        [dropdown]="true"
        [suggestions]="batches"
        [formControlName]="'batch'"
        [disabled]="readOnly"
        [forceSelection]="true"
        appendTo="body"
        dataKey="id"
        field="batchNumber"
        id="batchNumber"
        [style]="{width: '100%'}">
      </p-autoComplete>

      <app-plus-button
        *ngIf="!form.get('batch')?.disabled"
        [tooltip]="'Neue Charge erstellen'"
        (clicked)="onNewBatchClicked()">
      </app-plus-button>
    </div>

    <ng-container *ngIf="!form.controls.hideExpirationDate?.value && showExpirationDate">

      <div></div>

      <core-date-picker
        [formControlName]="'newExpirationDate'"
        [showClearButton]="false"
        [placeholder]="'Ablaufdatum'"
        [removeDefaultBottomErrorMargin]="true"
        [minDate]="minDate"
        id="expirationDate"
        (ngModelChange)="onSetExpirationDate()">
      </core-date-picker>

    </ng-container>

  </ng-container>

</div>

<app-new-batch-dialog
  *ngIf="newBatchDialogVisible"
  [showDialog]="newBatchDialogVisible"
  [workflowInstanceId]="workflowDetailClientService.workflowInstance?.id"
  [batchType]="type"
  (closeDialog)="createBatchDialogClosed($event)">
</app-new-batch-dialog>

