import {Component, EventEmitter, Input, Output} from "@angular/core";
import {BatchType} from "../../enums/BatchType";
import {AbstractControl, UntypedFormArray, UntypedFormGroup} from "@angular/forms";
import {WorkflowDetailClientService} from "../dialog/workflow-detail-dialog/workflow-detail-client.service";

@Component({
  selector: 'app-batches-selector',
  templateUrl: 'batches-selector.component.html',
  styleUrls: ['batches-selector.component.scss']
})
export class BatchesSelectorComponent {
  BatchType = BatchType;

  @Input() forms: UntypedFormArray;
  @Input() readOnly: boolean = false;
  @Input() showExpirationDate: boolean = true;
  @Output() onChange = new EventEmitter();

  constructor(private workflowDetailClientService: WorkflowDetailClientService) {
  }

  getFormGroupFromAbstractControl(form: AbstractControl): UntypedFormGroup {
    return form as UntypedFormGroup;
  }

  getBatchNumber(form: AbstractControl) {
    return this.getFormGroupFromAbstractControl(form).get('batch')?.value?.batchNumber;
  }

  getBatchType(form: AbstractControl) {
    return this.getFormGroupFromAbstractControl(form).get('type')?.value;
  }

  debounceTimer: number = null;

  onSomeChange() {
    // Clear the existing timer if there is one
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    // Set a new timer
    this.debounceTimer = setTimeout(() => {
      this.onChange.emit();
      this.workflowDetailClientService.silentSave();
    }, 100); // Wait for 100ms of inactivity then emit the onchange and save silently
  }

}
