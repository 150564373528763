<div class="login-container">

  <form [formGroup]="form" class="login-form">

    <img alt="Logo" src="assets/image/logo-rk.png">

    <core-input-text
      [required]="true"
      [showClearButton]="false"
      formControlName="username"
      id="username"
      topLabel="Benutzername*">
    </core-input-text>

    <core-input-password
      [required]="true"
      [enableBrowserSuggestion]="true"
      [showClearButton]="false"
      formControlName="password"
      id="password"
      topLabel="Passwort*">
    </core-input-password>

    <core-button-submit
      (onClick)="loginUser()"
      [text]="'Login'"
      [width]="'50%'"
      [backgroundColor]="'#189d18'"
      id="submit"
      style="text-align: center;">
    </core-button-submit>

  </form>

</div>
