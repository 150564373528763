<p-dialog
  (onHide)="onCloseDialog()"
  [(visible)]="showStateUpdateDialog"
  [modal]="true"
  [style]="{width: '50vw'}"
  [header]="header"
  class="batch-update-dialog">
  <p>{{ message }}</p>
  <form *ngIf="form" [formGroup]="form">
    <core-input-text-area
      [showClearButton]="false"
      formControlName="comment"
      id="comment"
      label="Kommentar">
    </core-input-text-area>
  </form>

  <p-footer class="footer-container">
    <div>
      <core-button
        (onClick)="onCloseDialog()"
        [backgroundColor]="'white'"
        [color]="'#607D8B'"
        [icon]="faTimes"
        [text]="'Abbrechen'">
      </core-button>
      <core-button
        (onClick)="saveChanges()"
        [backgroundColor]="'#34A835'"
        [icon]="faCheck"
        [text]="saveButtonText"
        id="button_submit">
      </core-button>
    </div>
  </p-footer>
</p-dialog>
