<div *ngIf="forms && !readOnly">
  <ng-container *ngIf="forms.controls.length > 0; else emptyBatch">
    <ng-container *ngFor="let batchFormGroup of forms.controls">
      <app-batch-selector
        [showExpirationDate]="showExpirationDate"
        [readOnly]="readOnly"
        [form]="getFormGroupFromAbstractControl(batchFormGroup)"
        (onChange)="onSomeChange()">
      </app-batch-selector>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="forms && readOnly" class="grid-read-only">
  <ng-container *ngIf="forms.controls.length > 0; else emptyBatch">
    <ng-container *ngFor="let batchFormGroup of forms.controls">
      <ul>
        <li>
          {{ BatchType.getBatchTypeReadable(getBatchType(batchFormGroup)) }}
        </li>
      </ul>
      <b>{{ getBatchNumber(batchFormGroup) }}</b>
    </ng-container>
  </ng-container>
</div>


<ng-template #emptyBatch>
  Keine Chargen
</ng-template>
