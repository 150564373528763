import {PageableResponseModel} from "./pageable-response.model";
import {FileProcessingHistoryModel} from "../file/file-processing-history.model";

export class FileProcessingHistoryPageModel extends PageableResponseModel<FileProcessingHistoryModel> {

  static fromData(data: PageableResponseModel<FileProcessingHistoryModel>): FileProcessingHistoryPageModel {
    const page = new FileProcessingHistoryPageModel();
    page.mapFieldFromData(data);
    page.content = data.content.map(FileProcessingHistoryModel.fromData);
    return page;
  }

}
