import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BatchesComponent} from './batches.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {CheckboxModule} from "primeng/checkbox";
import {CalendarModule} from "primeng/calendar";
import {InputModule} from "../../components/input/input.module";
import {TableModule} from "primeng/table";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {CustomPipeModule} from "../../pipes/custom-pipe.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {TooltipModule} from "primeng/tooltip";
import {DialogModule} from "primeng/dialog";
import {
  StateUpdateWithCommentDialogModule
} from "../../components/dialog/state-update-with-comment-dialog/state-update-with-comment-dialog.module";
import {SearchFieldsContainerModule} from "../../components/search-fields-container/search-fields-container.module";
import {NewBatchDialogModule} from "../../components/dialog/new-batch-dialog/new-batch-dialog.module";


@NgModule({
  declarations: [
    BatchesComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InputTextModule,
        CheckboxModule,
        CalendarModule,
        InputModule,
        TableModule,
        ConfirmDialogModule,
        CustomPipeModule,
        FontAwesomeModule,
        TooltipModule,
        DialogModule,
        StateUpdateWithCommentDialogModule,
        SearchFieldsContainerModule,
        NewBatchDialogModule,
    ]
})
export class BatchesModule {
}
