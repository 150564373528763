import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {
  WorkflowInstanceFilterForm,
  WorkflowInstanceFilterModel
} from "../../models/workflow/workflow-instance-filter.model";
import {TableColumnModel} from "../../models/table-column.model";
import {PageableRequestModel} from "../../models/pagable/pageable-request.model";
import {WorkflowInstanceService} from "../../services/workflow-instance.service";
import {WorkflowInstanceArchiveModel} from "../../models/workflow/workflow-instance-archive.model";
import {WorkflowInstancePageModel} from "../../models/pagable/workflow-instance-page.model";
import {PaginationBaseComponent} from "../../components/pagination-base.component";
import {SortEvent} from "primeng/api";
import {debounceTime, takeUntil} from "rxjs";
import {Globals} from "../../global/globals";
import {
  WorkflowDetailClientService
} from "../../components/dialog/workflow-detail-dialog/workflow-detail-client.service";
import {SelectItem} from "../../models/select-item.model";
import {WorkflowStep} from "../../enums/WorkflowStep";

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss', '../../../assets/table.scss']
})
export class ArchiveComponent extends PaginationBaseComponent implements OnInit {

  WorkflowInstanceFilterModel = WorkflowInstanceFilterModel;

  filterForm: FormGroup<WorkflowInstanceFilterForm>;
  tableColumns: TableColumnModel[];
  workflowInstances: WorkflowInstanceArchiveModel[];

  showDetail: boolean = false;

  showClearButton: boolean = false;
  creatorUsers: SelectItem[];
  workflowSteps: SelectItem[];

  constructor(private workflowInstanceService: WorkflowInstanceService,
              public globals: Globals,
              private workflowDetailClientService: WorkflowDetailClientService) {
    super();
    this.filterForm = WorkflowInstanceFilterModel.initFilterForm();
    this.tableColumns = TableColumnModel.initWorkflowInstanceArchiveColumns();
  }

  ngOnInit(): void {
    this.getAllCreatorUser();
    this.fetchWorkflowInstances(0);
    this.workflowSteps = WorkflowStep.getAllAsSelectItem();

    this.filterForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.showClearButton = true;
        this.fetchWorkflowInstances(0);
      });

    this.workflowInstanceService.refreshNeed$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.fetchWorkflowInstances(0);
      });
  }

  changePage(event: any): void {
    const page = event ? event.first / event.rows : 0;
    this.fetchWorkflowInstances(page);
  }

  onSort(sortEvent: SortEvent): void {
    this.sortField.initFromSortEvent(sortEvent);
    this.fetchWorkflowInstances(0);
  }

  get filterDateFrom(): Date {
    return this.filterForm.get(WorkflowInstanceFilterModel.DATE_FROM).value;
  }

  get filterDateTo(): Date {
    return this.filterForm.get(WorkflowInstanceFilterModel.DATE_TO).value;
  }


  private fetchWorkflowInstances(page: number): void {
    const pageable = new PageableRequestModel(page, this.pageSize);
    const filter = WorkflowInstanceFilterModel.initFilterDtoFromFormGroup(this.filterForm);

    filter.sortDto = this.sortField;

    this.isLoading = true;
    this.workflowInstanceService.getAllForArchive(pageable, filter)
      .subscribe({
        next: (resp: WorkflowInstancePageModel) => {
          this.workflowInstances = resp.content;
          this.totalElements = resp.totalElements;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      })
  }


  private getAllCreatorUser(): void {
    this.workflowInstanceService.getAllCreatorUser().subscribe({
      next: (resp) => {
        this.creatorUsers = resp.map(user => ({id: user, name: user}));
      }
    });
  }

  onCloseDialog() {
    this.showDetail = false;
  }

  onRowSelected(rowData: WorkflowInstanceArchiveModel) {
    this.showDetail = true;
    this.workflowDetailClientService.workflowInstance = WorkflowInstanceArchiveModel.toWorkflowInstanceModel(rowData);
  }

  resetFilter() {
    this.filterForm.reset({}, {emitEvent: false});
    setTimeout(() => this.fetchWorkflowInstances(0), 500);
    this.showClearButton = false;
  }

}
