import {IWorkflowStepBase} from "./workflow-step-base.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {PlateModel} from "../Plate/plate.model";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {BatchModel} from "../batch/batch.model";

export class WorkFlowStepTargetPcrModel implements IWorkflowStepBase {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  batches: BatchModel[];
  plates: PlateModel[];

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, plates: PlateModel[], batches: BatchModel[], isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.plates = plates?.map(PlateModel.fromData);
    this.batches = batches?.map(BatchModel.fromData);
    this.isSkipped = isSkipped;
  }

  isValid(): boolean {
    // valid if a plate exists and all batches have an expiration date set
    for (const batch of this.batches) {
      if (!batch.expirationDate) {
        return false;
      }
    }
    return this.plates && this.plates.length > 0;
  }

}
