import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BatchSelectorComponent } from './batch-selector.component';
import {ReactiveFormsModule} from "@angular/forms";
import {AutoCompleteModule} from "primeng/autocomplete";
import {InputModule} from "../input/input.module";
import {NewBatchDialogModule} from "../dialog/new-batch-dialog/new-batch-dialog.module";
import {CustomPipeModule} from "../../pipes/custom-pipe.module";

@NgModule({
  declarations: [
    BatchSelectorComponent
  ],
  exports: [
    BatchSelectorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    InputModule,
    NewBatchDialogModule,
    CustomPipeModule
  ]
})
export class BatchSelectorModule { }
