import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {map, Observable} from "rxjs";
import {CyclerModel} from "../models/cycler/cycler.model";

@Injectable({
  providedIn: 'root'
})
export class CyclerService {

  private readonly baseUri: string;

  constructor(private http: HttpClient,
              private globals: Globals) {
    this.baseUri = this.globals.backendUri + '/cycler';
  }

  getCycler(): Observable<CyclerModel[]> {
    return this.http.get<CyclerModel[]>(this.baseUri)
      .pipe(map((cm: CyclerModel[]) => cm.map(CyclerModel.fromData)));
  }

  updateCycler(cycler: CyclerModel): Observable<CyclerModel> {
    return this.http.put<CyclerModel>(this.baseUri, cycler)
      .pipe(map(response => CyclerModel.fromData(response)));
  }

  findById(id: number): Observable<CyclerModel> {
    return this.http.get<CyclerModel>(this.baseUri + '/' + id)
      .pipe(map(response => CyclerModel.fromData(response)));
  }
}
