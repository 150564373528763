import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {WorkflowStepPurificationModel} from "../../../../models/workflow-step/workflow-step-purification.model";
import {TecanStatus} from "../../../../enums/TecanStatus";
import {BatchType} from "../../../../enums/BatchType";
import {BatchModel} from "../../../../models/batch/batch.model";
import {UntypedFormArray} from "@angular/forms";
import {PlateMode} from "../../../../enums/PlateMode";
import {PlateModel} from "../../../../models/Plate/plate.model";

@Component({
  selector: 'app-purification',
  templateUrl: './purification.component.html',
  styleUrls: ['./purification.component.scss']
})
export class PurificationComponent implements OnInit {

  PlateMode = PlateMode;
  TecanStatus = TecanStatus;
  BatchType = BatchType;
  batchFormArray: UntypedFormArray;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepPurificationModel;
  @Input() readOnly: boolean = false;

  constructor(private workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.batchFormArray = BatchModel.initFormArrayForBatchSelection(this.workflowStep.batches, true, false);
    this.validateStep();
  }

  validateStep() {
    this.workflowStep.batches = BatchModel.getBatchesFromBatchSelectionFormArray(this.batchFormArray);
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

  getPlateList(): PlateModel[] {
    return this.workflowStep?.plates.map(p => p.plate);
  }

}
