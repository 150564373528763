export enum FileType {
  SWAB_96 = 'SWAB',
  HAMILTON = "HAMILTON",
  HAMILTON_NORMALIZATION = "HAMILTON_NORMALIZATION",
  GLOMAX = 'GLOMAX',
  INTEGRA = 'INTEGRA',
  INDEX_PURIFICATION = 'INDEX_PURIFICATION',
  MI_SEQ_RUN_PARAMETERS = 'MI_SEQ_RUN_PARAMETERS',
  MI_SEQ_SAV_SUMMARY = 'MI_SEQ_SAV_SUMMARY',
  TECAN_TARGET_POOLING = 'TECAN_TARGET_POOLING',
  TECAN_PURIFICATION = 'TECAN_PURIFICATION',
  SAMPLE_SHEET = 'SAMPLE_SHEET'
}
