import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-error-page',
  template: `
    <div class="error-page-container">
      <b>{{ errorMessage}}</b>
      <core-button
        class="reset-btn"
        (click)="onNavigateToDashboard()"
        [backgroundColor]="'#607D8B'"
        [color]="'#ffffff'"
        classList="p-button-outlined"
        text="Zurück zum Dasboard">
      </core-button>
    </div>
  `,
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  errorMessage: string;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    const errorCode = +this.activatedRoute.snapshot.queryParamMap.get('errorStatus');

    if (errorCode && errorCode === 503) {
      this.errorMessage = 'Die Datenbankkommunikation ist aufgrund eines schwerwiegenden Fehlers unterbrochen. Kontaktieren Sie einen Admin!'
    } else {
      this.errorMessage = 'Die Serverkommunikation ist aufgrund eines schwerwiegenden Fehlers unterbrochen. Kontaktieren Sie einen Admin!'
    }

  }


  onNavigateToDashboard() {
    this.router.navigate(['/dashboard-96-384'])
  }
}
