import { Pipe, PipeTransform } from '@angular/core';
import {CyclerRunThroughState} from "../enums/CyclerRunThroughState";

@Pipe({
  name: 'crtStateReadable'
})
export class CrtStateReadablePipe implements PipeTransform {

  transform(value: CyclerRunThroughState): string {
    return CyclerRunThroughState.getCyclerStateReadable(value) ?? '';
  }

}
