<p-dialog
  [maximizable]="true"
  class="workflow-detail-dialog"
  [(visible)]="showDetail"
  [closable]="true"
  [header]="this.workflowInstance?.workflowNumber + ' - ' + (workflowInstance?.currentStep | workflowStepReadable)"
  (onHide)="onCloseDialog()"
  [modal]="true">
  <!-- Workflow Instance Step Navigator  -->
  <div #workflowStepNavigator
       [class.hide-scrollbar]="workflowDetailClientService.hideScrollbarInHeader"
       class="step-history-container">
    <ng-container
      *ngFor="let historyStep of workflowDetailClientService.navMenuItemObservable$ | async; let last = last; let i = index">
      <app-workflow-instance-step-card
        [id]="'stepCard_' + i"
        [error]="getErrorFromWorkflowStepByStepEnums(workflowInstance, historyStep)"
        [currentStep]="workflowInstance.currentStep"
        [workflowSteps]="historyStep"
        [selectedStep]="workflowDetailClientService.selectedStep$.getValue()?.step"
        [isSkipped]="workflowDetailClientService.workflowInstance?.steps | isWorkflowStepSkipped:historyStep[0]"
        (stepClicked)="workflowDetailClientService.fetchWorkflowInstanceStepByStepForStepCard($event)">
      </app-workflow-instance-step-card>
      <!-- Add this element at the end of the loop to know when rendering is complete -->
      <div *ngIf="last && historyStep" [appScrollToRight]="last"></div>
    </ng-container>
  </div>


  <app-workflow-step-common [readOnly]="isReadonly"></app-workflow-step-common>

  <p-footer>
    <div class="footer-container">
      <div>
        <core-button
          *ngIf="workflowInstance.currentStep !== WorkflowStep.FINISHED && workflowInstance?.currentStep !== WorkflowStep.ABORTED"
          (onClick)="showAbortWorkFlowConfirm()"
          classList="p-button-danger"
          text="Vorgang abbrechen">
        </core-button>
      </div>

      <div>
        <core-button
          (onClick)="showPreviousStep()"
          [disabled]="isBackButtonDisabled"
          classList="p-button-secondary"
          text="Vorheriger Schritt">
        </core-button>
        <core-button
          (onClick)="nextStep()"
          [disabled]="isNextButtonDisabled"
          classList="p-button-success"
          [text]="nextButtonText">
        </core-button>
      </div>
    </div>
  </p-footer>

  <p-dialog
    [style]="{width: '50vw'}"
    [(visible)]="confirmationDialogVisible"
    [closable]="true"
    [modal]="true"
    [header]="'Durchlauf abschließen'"
    (onHide)="closeFinishDialog()">

    <div class="row" *ngIf="!workflowInstance.isLibrary()" style="padding-top: 1rem">
      <label>Für Library freigeben?</label>
      <div class="library-switch-container">
        <b>Nein</b>
        <p-inputSwitch [(ngModel)]="shareToLibrary"></p-inputSwitch>
        <b>Ja</b>
      </div>
    </div>

    <div *ngIf="hasFinishPositionError" class="workflow-detail-library-share-confirm-position-nok-warning">
      Der aktuelle Schritt enthält Position(en) mit Status NOT_OK.
      Sind Sie sicher, dass Sie den Workflow abschließen möchten?
    </div>

    <p>
      Wollen Sie die folgende Chargen freigeben?
      <br>
      Nicht freigegebene Chargen werden auf Blockiert gesetzt.
    </p>

    <div class="checkbox">
      <div *ngFor="let batch of batchesFinishedOrCancelled">
        <p-checkbox
          [binary]="true"
          [(ngModel)]="batch.share">
        </p-checkbox>
        {{BatchType.getBatchTypeReadable(batch.type)}} <b>{{batch.batchNumber}}</b>
      </div>
    </div>

    <div class="row-buttons space-between">
      <core-button
        [text]="'Abbrechen'"
        classList="p-button-secondary"
        (onClick)="closeFinishDialog()">
      </core-button>
      <core-button
        [text]="hasFinishPositionError ? 'Abschließen mit fehlerhaften Positionen' : 'Abschließen'"
        classList="p-button-success"
        (onClick)="onConfirmWorkflowFinish()">
      </core-button>
    </div>

  </p-dialog>

  <p-dialog
    [style]="{width: '50vw'}"
    [(visible)]="showCancelWorkflowDialog"
    [closable]="true"
    [modal]="true"
    [header]="'Aktuellen Workflow abbrechen und Platte archivieren'"
    (onHide)="closeWorkflowCancelDialog()">

    <div style="padding-top: 1rem">
      <p>Folgende Chargen wurden neu erstellt und sind bereit zur Freigabe:</p>
      <p>Nicht freigegebene Chargen werden auf Blockiert gesetzt.</p>
    </div>

    <div class="checkbox">
      <div *ngFor="let batch of batchesFinishedOrCancelled">
        <p-checkbox
          [binary]="true"
          [(ngModel)]="batch.share">
        </p-checkbox>
        {{BatchType.getBatchTypeReadable(batch.type)}} <b>{{batch.batchNumber}}</b>
      </div>
    </div>

    <div class="row-buttons space-between">
      <core-button
        [text]="'Abbrechen'"
        classList="p-button-secondary"
        (onClick)="closeWorkflowCancelDialog()">
      </core-button>
      <core-button
        [text]="'Bestätigen'"
        classList="p-button-success"
        (onClick)="abortWorkFlow()">
      </core-button>
    </div>

  </p-dialog>

  <p-dialog
    [style]="{width: '30vw'}"
    [(visible)]="showShortenWorkflowDialog"
    [closable]="true"
    [modal]="true"
    [header]="'Workflow abkürzen'"
    (onHide)="showShortenWorkflowDialog = false">

    <div style="padding-top: 1rem">
      <p>Sollen die Extrakte normalisiert werden?</p>
    </div>

    <div class="row-buttons end-with-gap">
      <core-button
        [text]="'Nein'"
        classList="p-button-secondary"
        (onClick)="this.shortenWorkflowInstance(); this.showShortenWorkflowDialog = false">
      </core-button>
      <core-button
        [text]="'Ja'"
        classList="p-button-success"
        (onClick)="this.workflowDetailClientService.nextStep(); this.showShortenWorkflowDialog = false">
      </core-button>
    </div>
  </p-dialog>

</p-dialog>
