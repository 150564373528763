import {NgModule} from "@angular/core";
import {PcrTubeComponent} from "./pcr-tube/pcr-tube.component";
import {PcrTubeViewerComponent} from "./pcr-tube-viewer/pcr-tube-viewer.component";
import {ReactiveFormsModule} from "@angular/forms";
import {InputModule} from "../input/input.module";
import {NgForOf, NgIf, NgStyle} from "@angular/common";

@NgModule({
  declarations: [PcrTubeComponent, PcrTubeViewerComponent],
  imports: [
    ReactiveFormsModule,
    InputModule,
    NgIf,
    NgForOf,
    NgStyle
  ],
  exports: [PcrTubeComponent, PcrTubeViewerComponent]
})
export class PcrComponentModule {}
