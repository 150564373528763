import {AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BatchModel} from "../../models/batch/batch.model";
import {BatchService} from "../../services/batch.service";
import {BatchType} from "../../enums/BatchType";
import {BatchFilterModel} from "../../models/batch/batch-filter.model";
import {BatchState} from "../../enums/BatchState";
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {UnsubscribeBaseComponent} from "../unsubscribe-base.component";
import {filter, takeUntil} from "rxjs";
import {AutoComplete} from "primeng/autocomplete";

@Component({
  selector: 'app-archive-batch-selector',
  template: `
    <label *ngIf="topLabel" [for]="id" class="core_input_top_label">{{topLabel}}</label>
    <div class="core-input-label-container">
      <label *ngIf="label">{{label}}</label>
      <div class="p-inputgroup core-input-container core_input_flex_between">
        <p-autoComplete
          #batchSelectorAC
          class="batch-selector"
          (onSelect)="valueChanged($event)"
          (completeMethod)="onSearch($event)"
          [dropdown]="true"
          [suggestions]="batches"
          [forceSelection]="forceSelection"
          appendTo="body"
          [field]="optionLabel"
          [style]="{width: '100%'}">
        </p-autoComplete>
        <button
          (click)="onClear()"
          *ngIf="showClearButton"
          [disabled]="disabled"
          class="p-button-secondary core-input-button-clear p-inputgroup-addon"
          icon="pi pi-times"
          pButton
          tabindex="-1">
        </button>
      </div>
    </div>
    <div
      [ngClass]="[label ? 'core_input_error_container_with_label' : '', removeDefaultBottomErrorMargin ? '' : 'core_input_error_container_min_height']"
      class="core_input_error">
      <small *ngIf="control.touched && control.errors?.required">
        Dieses Feld ist erforderlich!
      </small>
    </div>
  `,
  styleUrls: ['./archive-batch-selector.component.scss', '../input/input.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ArchiveBatchSelectorComponent,
    multi: true
  }]
})
export class ArchiveBatchSelectorComponent extends UnsubscribeBaseComponent implements ControlValueAccessor, OnInit, AfterViewInit {

  @Input() id: string;
  @Input() label: string;
  @Input() topLabel: string;
  @Input() placeholder = '';
  @Input() showClearButton = true;

  @Input() removeDefaultBottomErrorMargin = false;

  @Input() forceSelection: boolean;
  @Input() batchType: BatchType;
  @Input() batchStates: BatchState[];
  @Input() optionLabel: string = 'batchNumber'
  @Input() optionValue: string = 'id';

  @ViewChild("batchSelectorAC") batchSelectorAC: AutoComplete;

  @Output() onKeyUpEnter = new EventEmitter<string>();
  control: NgControl;
  value: string;
  disabled = false;
  private onTouched: Function;
  private onChanged: Function;

  batches: BatchModel[];

  constructor(private batchService: BatchService,
              private inj: Injector) {
    super();
    if (!this.id) {
      this.id = 'batch-selector-' + Math.random();
    }
  }

  ngOnInit() {
    this.control = this.inj.get(NgControl);
  }

  ngAfterViewInit(): void {
    /*
     * for some reason the autocomplete doesn't clear the input label on form.reset()
     * the value in the control is null but the selected object[optionLabel] is still visible
     * the function below is the workaround to clear also the label
     */
    this.control.control
      .valueChanges
      .pipe(
        takeUntil(this.unSubscribe),
        filter(v => !v)
      )
      .subscribe(() => this.batchSelectorAC.clear());
  }


  onSearch(event: any) {
    const filter = this.initFilterDtoFromFormGroup(event.query);
    this.batchService.getAllUnpaged(filter)
      .subscribe({
        next: (resp: BatchModel[]) => {
          this.batches = resp;
        }
      });
  }

  onClear() {
    this.valueChanged(null);
    this.onKeyUpEnter.emit(null);
  }

  valueChanged(value: any) {
    this.onTouched();
    this.value = value[this.optionValue];
    this.onChanged(value[this.optionValue]);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  private initFilterDtoFromFormGroup(batchNumber: string): BatchFilterModel {
    const filter = new BatchFilterModel();
    filter.batchNumber = batchNumber;
    filter.type = this.batchType;
    filter.stateList = this.batchStates;
    return filter;
  }
}
