import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {
  WorkflowStepLibraryPostSequencingModel
} from "../../../../models/workflow-step/workflow-step-library-post-sequencing.model";
import {faFileImport} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-library-post-sequencing',
  templateUrl: './library-post-sequencing.component.html',
  styleUrls: ['./library-post-sequencing.component.scss']
})
export class LibraryPostSequencingComponent implements OnInit {
  faFileImport = faFileImport;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepLibraryPostSequencingModel;
  @Input() readOnly: boolean = false;

  constructor(private workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.validateStep();
  }

  validateStep() {
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

}
