import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceStepError} from "../../enums/WorkflowInstanceStepError";
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-workflow-instance-step-card',
  template: `
      <div class="container"
           (click)="onStepClicked()"
           [ngClass]="{
           'active': isActiveStep(),
           'selected' : isSelectedStep(),
           'skipped': isSkipped
           }">
          {{ workflowSteps[0] | workflowStepReadable }}
        <fa-icon *ngIf="error" class="error-icon" [icon]="faCircleExclamation"></fa-icon>

      </div>
  `,
  styleUrls: ['./workflow-instance-step-card.component.scss']
})
export class WorkflowInstanceStepCardComponent {

  faCircleExclamation = faCircleExclamation;

  @Input() workflowSteps: WorkflowStep[];
  @Input() error: WorkflowInstanceStepError;
  @Input() currentStep: WorkflowStep;
  @Input() selectedStep: WorkflowStep;
  @Input() isSkipped: boolean;
  @Output() stepClicked: EventEmitter<WorkflowStep> = new EventEmitter();

  onStepClicked(): void {
    this.stepClicked.emit(this.workflowSteps[this.workflowSteps.length - 1]);
  }

  isSelectedStep() {
    return this.workflowSteps.find(e => e === this.selectedStep);
  }

  isActiveStep() {
    return this.workflowSteps.find(e => e === this.currentStep);
  }

}
