import {EnumUtils} from "./EnumUtils";

export enum PathType {
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT'
}

export namespace PathType {

  export function getPathTypeReadAble(type: PathType): string {
    switch (type) {
      case PathType.IMPORT:
        return 'Import';
      case PathType.EXPORT:
        return 'Export';
    }
  }


  export function getEnumByValue(value: string): PathType {
    return EnumUtils.getEnumByValue<PathType>(PathType, 'string', value);
  }

  export function getAll(): PathType[] {
    return EnumUtils.getAll<PathType>(PathType, 'string');
  }
}
