import {HttpClient, HttpParams} from "@angular/common/http";
import {Globals} from "../global/globals";
import {Injectable} from "@angular/core";
import {PageableRequestModel} from "../models/pagable/pageable-request.model";
import {BatchFilterModel} from "../models/batch/batch-filter.model";
import {map, Observable, Subject} from "rxjs";
import {BatchPageModel} from "../models/pagable/batch-page.model";
import {BatchModel} from "../models/batch/batch.model";
import {BatchStateUpdateModel} from "../models/batch/batch-state-update.model";
import {BatchType} from "../enums/BatchType";
import {BatchState} from "../enums/BatchState";

@Injectable({
  providedIn: 'root'
})
export class BatchService {

  refreshNeed$: Subject<boolean> = new Subject<boolean>();

  private readonly baseUri: string;

  constructor(private http: HttpClient, private globals: Globals) {
    this.baseUri = globals.backendUri + "/batches";
  }

  getAll(pageableRequestModel: PageableRequestModel, filter: BatchFilterModel): Observable<BatchPageModel> {
    const httpParams = PageableRequestModel.toHttpParams(pageableRequestModel);
    return this.http.post<BatchPageModel>(this.baseUri + '/all', filter, {params: httpParams})
      .pipe(map(response => BatchPageModel.fromData(response)));
  }

  getAllUnpaged(filter: BatchFilterModel): Observable<BatchModel[]> {
    return this.http.post<BatchModel[]>(this.baseUri + '/all-unpaged', filter)
      .pipe(map(response => response.map(BatchModel.fromData)));
  }

  createNew(batchModel: BatchModel, workflowInstanceId: number): Observable<BatchModel> {
    const body = (batchModel as any);
    body.workflowInstanceId = workflowInstanceId;
    return this.http.post<BatchModel>(this.baseUri, body)
      .pipe(map(BatchModel.fromData));
  }

  createNewWithApproval(batchModel: BatchModel): Observable<BatchModel> {
    return this.http.post<BatchModel>(`${this.baseUri}/create-with-approval`, batchModel);
  }

  updateState(batchStateUpdateModel: BatchStateUpdateModel): Observable<BatchModel> {
    return this.http.put<BatchModel>(this.baseUri + "/update-state", batchStateUpdateModel);
  }

  getAllBatchesForWorkflowInstance(workflowInstanceId: number, state: BatchState): Observable<BatchModel[]> {
    let endpoint = `${this.baseUri}/get-all-batches-for-workflow-instance`;

    const params: HttpParams = new HttpParams()
      .set('workflowInstanceId', workflowInstanceId)
      .set('state', state);

    return this.http.get<BatchModel[]>(endpoint, {params})
      .pipe(map(resp => resp.map(BatchModel.fromData)));
  }

  getAllBatchesSelectableForWorkflowInstance(workflowInstanceId: number, type: BatchType, batchNumber: string): Observable<BatchModel[]> {
    let endpoint = `${this.baseUri}/get-all-batches-for-batch-selection`;

    const params: HttpParams = new HttpParams()
      .set('workflowInstanceId', workflowInstanceId)
      .set('type', type)
      .set('batchNumber', batchNumber);

    return this.http.get<BatchModel[]>(endpoint, {params})
      .pipe(map(resp => resp.map(BatchModel.fromData)));
  }

}
