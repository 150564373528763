import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {WorkflowInstanceModel} from '../../../../models/workflow/workflow-instance.model';
import {WorkflowInstanceService} from '../../../../services/workflow-instance.service';
import {Workflow} from '../../../../enums/Workflow';
import {
  WorkflowStepLibraryPlateSelectionModel
} from '../../../../models/workflow-step/workflow-step-library-plate-selection.model';
import {WorkflowDetailClientService} from '../../../dialog/workflow-detail-dialog/workflow-detail-client.service';
import {AutoComplete} from 'primeng/autocomplete';
import {MessageService} from '../../../../services/message.service';
import {IndexPrimerPlate} from '../../../../enums/IndexPrimerPlate';
import {SelectItem} from "primeng/api";

@Component({
  selector: 'app-library-plate-selection',
  templateUrl: './library-plate-selection.component.html',
  styleUrls: ['./library-plate-selection.component.scss']
})
export class LibraryPlateSelectionComponent implements OnInit {

  Workflow = Workflow;

  @ViewChild('wiAutocomplete', {static: true}) wiAutocomplete: AutoComplete;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepLibraryPlateSelectionModel;
  @Input() readOnly = false;

  selectedWorkflow: WorkflowInstanceModel;
  workflowSuggestions: WorkflowInstanceModel[];
  maxPlateCount: number[] = Array.from({length: 4});
  workflowNumberTakeOverList: WorkflowInstanceModel[] = [];

  constructor(private workflowInstanceService: WorkflowInstanceService,
              private workflowDetailClientService: WorkflowDetailClientService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.validateWorkflowStep();
  }

  onWorkflowSelected(workflowInstance: WorkflowInstanceModel) {
    if (workflowInstance.workflow === Workflow.WORKFLOW_96) {
      this.on96WorkflowSelected(workflowInstance);
    }

    if (workflowInstance.workflow === Workflow.WORKFLOW_384) {
      this.on384WorkflowSelected(workflowInstance);
    }
  }

  filterWorkflows($event: any) {
    this.fetchAllPossibleForLibrary();
  }

  /**
   *  96er library -> user select 2 - 4 different workflow instances with different indexPrimer
   *  we can just display the select workflow and primer index
   */
  getSelectedWorkflowByIndex(index: number): string {
    const workflow = this.workflowStep.selectedWorkflowInstances[index];
    return workflow ? (this.workflowStep.selectedWorkflowInstances[index]?.workflowNumber + '_' + this.workflowStep.selectedWorkflowInstances[index]?.indexPrimerPlate) : null;
  }

  /**
   *  384er library -> user select one work flow. the workflow has already 4 saved plates from GLOMAX 2
   *  as a placeholder we just display the same workflow number with the placeholder index primer
   */
  getSelectedWorkflowByIndexForLibrary(index: number): string {
    const workflow = this.workflowStep.selectedWorkflowInstances[0];
    return workflow ? (this.workflowStep.selectedWorkflowInstances[0]?.workflowNumber + '_' + IndexPrimerPlate.getByIndex(index)) : null;

  }

  get disableInput(): boolean {
    return this.workflowInstance.workflow === Workflow.WORKFLOW_384 ?
      this.workflowStep.selectedWorkflowInstances.length > 1 :
      this.workflowStep.selectedWorkflowInstances.length === 4;
  }

  resetSelection() {
    this.workflowStep.selectedWorkflowInstances = [];
    this.workflowStep.takeOverWorkflowInstance = null;
    this.validateWorkflowStep();
  }

  private on96WorkflowSelected(workflowInstance: WorkflowInstanceModel): void {
    const existingWithPlateSuggestion = this.workflowStep.selectedWorkflowInstances.find(wi => wi.indexPrimerPlate === workflowInstance.indexPrimerPlate);

    if (existingWithPlateSuggestion) {
      this.messageService.addWarnMessage(`Platte mit Index ${workflowInstance.indexPrimerPlate} wurde bereits ausgewählt!`);
    } else {
      this.workflowStep.selectedWorkflowInstances.push(workflowInstance);

      // first workflow instance selected => set automatically to take over
      if(this.workflowStep.selectedWorkflowInstances.length === 1) {
        this.workflowStep.takeOverWorkflowInstance = workflowInstance;
      }

      this.initWorkflowNumberTakeOverList();
      this.validateWorkflowStep();
    }

    this.selectedWorkflow = null;
    this.wiAutocomplete.clear();
  }

  private on384WorkflowSelected(workflowInstance: WorkflowInstanceModel): void {
    this.workflowStep.selectedWorkflowInstances.push(workflowInstance);
    this.workflowStep.takeOverWorkflowInstance = workflowInstance;
    this.validateWorkflowStep();
    this.selectedWorkflow = null;
    this.wiAutocomplete.clear();
  }

  private validateWorkflowStep(): void {
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep?.isValid());
  }

  private fetchAllPossibleForLibrary(): void {
    const workflowType = this.workflowInstance.getWorkflowTypeByLibraryType();
    if (workflowType) {
      this.workflowInstanceService.getAllApprovedForLibrary(workflowType)
        .subscribe(resp => {
          this.workflowSuggestions = resp.filter(wi => this.workflowStep.selectedWorkflowInstances.every(swi => swi.id !== wi.id));
        });
    }
  }

  navigateToWorkFlow($event: Event, i: number) {
    $event.stopPropagation();
    const navigateTo = this.workflowStep.selectedWorkflowInstances[i];
    if (navigateTo) {
      this.workflowDetailClientService.jumpToWorkflowInstance(this.workflowStep.selectedWorkflowInstances[i]);
    }
  }

  initWorkflowNumberTakeOverList(): void {
    this.workflowNumberTakeOverList = [...this.workflowStep.selectedWorkflowInstances];
  }

  onClearTakeOverworkflow(): void {
    this.workflowStep.takeOverWorkflowInstance = null;
  }
}
