import {Component, Input} from '@angular/core';
import {CyclerService} from "../../services/cycler.service";
import {FormGroup} from "@angular/forms";
import {SelectItem} from "../../models/select-item.model";

@Component({
  selector: 'app-cycler-selector',
  template: `
    <ng-container *ngIf="form" [formGroup]="form">
      <core-input-dropdown
        [placeholder]="'Cycler'"
        [formControlName]="controlName"
        [options]="cyclerOptions"
        [removeDefaultBottomErrorMargin]="true"
        [showClearButton]="false">
      </core-input-dropdown>
    </ng-container>
  `,
  styleUrls: ['./cycler-selector.component.scss']
})
export class CyclerSelectorComponent {

  @Input() form: FormGroup;
  @Input() controlName: string;

  cyclerOptions: SelectItem[];


  constructor(private cyclerService: CyclerService) {
    this.cyclerService.getCycler().subscribe({
      next: (resp) => {
        this.cyclerOptions = resp.map(cm => {
          const item = new SelectItem();
          item.id = cm.id;
          item.name = cm.name;
          return item;
        })
      }
    })
  }

}
