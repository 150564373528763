import {IWorkflowStepBase} from './workflow-step-base.model';
import {WorkflowStep} from '../../enums/WorkflowStep';
import {PrinterState} from '../../enums/PrinterState';
import {WorkflowInstanceModel} from '../workflow/workflow-instance.model';
import {Workflow} from '../../enums/Workflow';

export class WorkflowStepPreparationModel implements IWorkflowStepBase {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  workflow: Workflow;
  isSkipped: boolean;

  printerState: PrinterState;

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, printerState: PrinterState,
              isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.printerState = printerState;
    this.workflow = workflowInstance.workflow;
  }

}
