<div #tableParentDiv class="parent-div">
  <div class="show-all-switch">
    <div>Nur fehlerhafte Verarbeitungen anzeigen</div>
    <p-inputSwitch [(ngModel)]="onlyUnconfirmed" (ngModelChange)="fetchPage(0)"></p-inputSwitch>
  </div>
  <p-table
    (onPage)="changePage($event)"
    [(first)]="first"
    [lazy]="true"
    [paginator]="true"
    [totalRecords]="totalElements"
    [columns]="columns"
    [customSort]="true"
    [responsive]="true"
    [rows]="this.pageSize"
    [scrollHeight]="'flex'"
    [scrollable]="true"
    [value]="entries"
    [loading]="isLoading"
    responsiveLayout="scroll">
    <ng-template let-columns pTemplate="header">
      <tr>
        <th *ngFor="let col of columns">
          {{col.label}}
        </th>
      </tr>
    </ng-template>
    <ng-template let-columns="columns" let-rowData pTemplate="body">
      <tr>
        <td *ngFor="let col of columns" [width]="col.width">
          <ng-container [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="'created'">
              {{ rowData[col.field] | date:globals.dateTimeFormat }}
            </ng-container>
            <div *ngSwitchCase="'path'" [pTooltip]="rowData[col.field]" tooltipPosition="top">
              {{ rowData[col.field] }}
            </div>
            <ng-container *ngSwitchCase="'errorType'">
              <ng-container *ngIf="rowData[col.field]">
                <fa-icon
                  class="icon-error"
                  [icon]="errorIcon"
                  [pTooltip]="FileErrorCode.getFileErrorCodeReadable(rowData?.errorCode)"
                  tooltipPosition="top">
                </fa-icon>
                {{ FileErrorType.getFileErrorTypeReadable(rowData[col.field]) }}
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'confirmed'">
              <div class="action-btns" *ngIf="!rowData[col.field]">
                <core-button
                  [text]="'Bestätigen'"
                  (onClick)="onConfirm(rowData)"
                  classList="p-button-outlined">
                </core-button>
              </div>
              <div *ngIf="rowData[col.field] && rowData.confirmedBy">
                Bestätigt von {{rowData.confirmedBy}}
              </div>
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>

</div>
