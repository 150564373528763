import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DialogModule} from "primeng/dialog";
import {InputModule} from "../input/input.module";
import {CustomPipeModule} from "../../pipes/custom-pipe.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FileProcessingHistoryComponent} from "./file-processing-history.component";
import {TableModule} from "primeng/table";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {InputSwitchModule} from "primeng/inputswitch";
import {TooltipModule} from "primeng/tooltip";

@NgModule({
  declarations: [
    FileProcessingHistoryComponent
  ],
  exports: [
    FileProcessingHistoryComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    InputModule,
    CustomPipeModule,
    ReactiveFormsModule,
    TableModule,
    FontAwesomeModule,
    InputSwitchModule,
    FormsModule,
    TooltipModule
  ]
})
export class FileProcessingHistoryModule {

}
