import {Workflow} from "../../enums/Workflow";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {WorkflowInstanceModel} from "./workflow-instance.model";

export class WorkflowInstanceDtoModel {

  id: number;
  version: number;
  workflow: Workflow;
  currentStep: WorkflowStep;

  static fromData(data: WorkflowInstanceDtoModel): WorkflowInstanceDtoModel {
    return Object.assign(new WorkflowInstanceDtoModel(), data);
  }

  static fromWorkFlowInstanceModel(wim: WorkflowInstanceModel): WorkflowInstanceDtoModel {
    return Object.assign(new WorkflowInstanceDtoModel(), wim);
  }

}
