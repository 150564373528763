import {HttpParams} from "@angular/common/http";

export class PageableRequestModel {

  page: number;
  size: number;

  constructor(page: number, size: number) {
    this.page = page;
    this.size = size;
  }

  static toHttpParams(pageableRequestModel: PageableRequestModel): HttpParams {
    let ret = new HttpParams();
    ret = ret.append('page', pageableRequestModel.page);
    ret = ret.append('size', pageableRequestModel.size);
    return ret;
  }

}
