import {WorkflowInstanceDtoModel} from "./workflow-instance-dto.model";
import {BatchStateUpdateModel} from "../batch/batch-state-update.model";
import {
  WorkflowInstanceStepMeasurementOnGlomaxSecondEditModel
} from "../workflow-step/edit/workflow-instance-step-measurement-on-glomax-second-edit.model";
import {BatchModel} from "../batch/batch.model";
import {
  WorkflowInstanceStepLibraryPostSequencingEditModel
} from "../workflow-step/edit/workflow-instance-step-library-post-sequencing-edit.model";

export class WorkflowInstanceWithBatchListDtoModel {

  workflowInstanceDto: WorkflowInstanceDtoModel;
  workflowInstanceStepMeasurementGlomaxSecondEditDto: WorkflowInstanceStepMeasurementOnGlomaxSecondEditModel;
  workflowInstanceStepLibraryPostSequencingEditDto: WorkflowInstanceStepLibraryPostSequencingEditModel;
  batches: BatchModel[];

  static initWithGlomaxSecondStep(workflowInstanceDto: WorkflowInstanceDtoModel, batches: BatchModel[],
                                  glomaxTwoEditDto: WorkflowInstanceStepMeasurementOnGlomaxSecondEditModel): WorkflowInstanceWithBatchListDtoModel {
    const ret = new WorkflowInstanceWithBatchListDtoModel();
    ret.workflowInstanceDto = workflowInstanceDto;
    ret.batches = batches;
    ret.workflowInstanceStepMeasurementGlomaxSecondEditDto = glomaxTwoEditDto;
    return ret;
  }

  static initWithLibraryPostSequencingEdit(workflowInstanceDto: WorkflowInstanceDtoModel, batches: BatchModel[],
                                           workflowInstanceStepLibraryPostSequencingEditModel: WorkflowInstanceStepLibraryPostSequencingEditModel) {
    const ret = new WorkflowInstanceWithBatchListDtoModel();
    ret.workflowInstanceDto = workflowInstanceDto;
    ret.workflowInstanceStepLibraryPostSequencingEditDto = workflowInstanceStepLibraryPostSequencingEditModel;
    ret.batches = batches;
    return ret;
  }

  static initForAbort(workflowInstanceDto: WorkflowInstanceDtoModel, batches: BatchModel[]): WorkflowInstanceWithBatchListDtoModel {
    const ret = new WorkflowInstanceWithBatchListDtoModel();
    ret.workflowInstanceDto = workflowInstanceDto;
    ret.batches = batches;
    return ret;
  }
}
