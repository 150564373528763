import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {httpInterceptorProviders} from './interceptors';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginModule} from './pages/login/login.module';
import {DashboardModule} from './pages/dashboard/dashboard.module';
import {HeaderModule} from './components/header/header.module';
import {BatchesModule} from "./pages/batches/batches.module";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ArchiveModule} from "./pages/archive/archive.module";
import {PositionYToLetterPipe} from "./pipes/position-y-to-letter.pipe";
import { ErrorComponent } from './pages/error/error.component';
import {InputModule} from "./components/input/input.module";


@NgModule({
  declarations: [AppComponent, ErrorComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        ToastModule,

        // Pages
        DashboardModule,
        LoginModule,
        HeaderModule,
        BatchesModule,
        ConfirmDialogModule,
        ArchiveModule,
        InputModule

    ],
  providers: [
    httpInterceptorProviders,
    MessageService,
    ConfirmationService,
    PositionYToLetterPipe],
  bootstrap: [AppComponent]
})
export class AppModule {
}
