import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {BatchModel} from "../../models/batch/batch.model";
import {BatchFilterForm, BatchFilterModel} from "../../models/batch/batch-filter.model";
import {BatchService} from "../../services/batch.service";
import {UnsubscribeBaseComponent} from "../unsubscribe-base.component";
import {WorkflowDetailClientService} from "../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {BatchType} from "../../enums/BatchType";
import {PositionModel} from "../../models/Plate/position.model";

@Component({
  selector: 'app-adding-controls-batch-selector',
  templateUrl: './adding-controls-batch-selector.component.html',
  styleUrls: ['./adding-controls-batch-selector.component.scss']
})
export class AddingControlsBatchSelectorComponent extends UnsubscribeBaseComponent implements OnInit {
  BatchType = BatchType;

  @Input() name: string;
  @Input() batch: BatchModel;
  @Input() positiveControl: PositionModel;
  @Input() readOnly: boolean = false;
  @Output() batchChange = new EventEmitter<BatchModel>();
  batchFilterForm: FormGroup<BatchFilterForm>;

  minDate: Date;

  batches: BatchModel[];
  isLoading: boolean;
  newBatchDialogVisible: boolean;

  constructor(private formBuilder: FormBuilder,
              private batchService: BatchService,
              public workflowDetailClientService: WorkflowDetailClientService) {
    super();
  }

  ngOnInit(): void {
    this.minDate = new Date();
    this.fetchBatches()
    this.batchFilterForm = BatchFilterModel.initFilterForm();
  }

  filterBatches(event?: any) {
    this.fetchBatches(event.query);
  }

  onSelectBatch() {
    this.batchChange.emit(this.batch);
  }

  private fetchBatches(batchNumber?: string): void {
    this.isLoading = true;
    this.batchService.getAllBatchesSelectableForWorkflowInstance(
      this.workflowDetailClientService.workflowInstance?.id,
      BatchType.POSITIVE_CONTROL,
      batchNumber)
      .subscribe({
        next: (resp: BatchModel[]) => {
          this.batches = resp;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  onNewBatchClicked() {
    this.newBatchDialogVisible = true;
  }

  createBatchDialogClosed(newBatch: BatchModel): void {
    if (newBatch && newBatch.id) {
      this.batch = newBatch;
      this.batchChange.emit(this.batch);
      this.batches.push(newBatch);
    }
    this.newBatchDialogVisible = false;
  }

}
