export class MenuModel {

  name: string;
  path: string;


  constructor(name: string, path: string) {
    this.name = name;
    this.path = path;
  }

  public static initHeaderMenuAdmin(): MenuModel[] {
    const ret: MenuModel[] = [];
    ret.push(new MenuModel('96 / 384', 'dashboard-96-384'));
    ret.push(new MenuModel('Library', 'dashboard-library'));
    ret.push(new MenuModel('Suche', 'archive'));
    ret.push(new MenuModel('Chargen', 'batches'));
    ret.push(new MenuModel('Einstellungen', 'settings'));
    return ret;
  }

  public static initHeaderMenuUser(): MenuModel[] {
    const ret: MenuModel[] = [];
    ret.push(new MenuModel('96 / 384', 'dashboard-96-384'));
    ret.push(new MenuModel('Library', 'dashboard-library'));
    ret.push(new MenuModel('Suche', 'archive'));
    ret.push(new MenuModel('Chargen', 'batches'));
    return ret;
  }

  public static initSettingsMenu(): MenuModel[] {
    const ret: MenuModel[] = [];
    ret.push(new MenuModel('Cycler Verwaltung', 'cycler-management'));
    ret.push(new MenuModel('Datei Pfade', 'configuration-paths'));
    ret.push(new MenuModel('Drucker Verwaltung', 'printer-configuration'));
    ret.push(new MenuModel('Statische Werte', 'static-values'));
    return ret;
  }
}
