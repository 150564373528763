<div class="container">
  <div class="controls">
    <b>{{name}}</b>
  </div>

  <div class="controls">
    <div>Position</div>
    <div class="data position-info">{{positiveControl?.posY | positionYToLetter}}{{positiveControl?.posX}}</div>
  </div>

  <div class="row">
    <div>Charge</div>
    <div class="batch-selector">
      <p-autoComplete
        [forceSelection]="true"
        [(ngModel)]="batch"
        (completeMethod)="filterBatches($event)"
        (onSelect)="onSelectBatch()"
        [dropdown]="true"
        [suggestions]="batches"
        [disabled]="readOnly"
        appendTo="body"
        dataKey="id"
        field="batchNumber"
        id="batchNumber"
        [style]="{width: '100%'}">
      </p-autoComplete>
      <app-plus-button [tooltip]="'Neue Charge erstellen'" (clicked)="onNewBatchClicked()"
                       [disabled]="readOnly"></app-plus-button>
    </div>
  </div>
  <div class="container" *ngIf="false">
    <div></div>
    <core-date-picker
      [(ngModel)]="batch.expirationDate"
      [showClearButton]="false"
      [disabled]="true"
      [minDate]="minDate"
      id="expirationDate">
    </core-date-picker>
  </div>
</div>

<app-new-batch-dialog
  *ngIf="newBatchDialogVisible"
  [showDialog]="newBatchDialogVisible"
  [workflowInstanceId]="workflowDetailClientService.workflowInstance?.id"
  [batchType]="BatchType.POSITIVE_CONTROL"
  (closeDialog)="createBatchDialogClosed($event)">
</app-new-batch-dialog>
