import {PositionStatus} from "../../enums/PositionStatus";

export class PositionModel {

  id: number;
  status: PositionStatus;
  pipettingError: string;
  pipettingStatus: PositionStatus;
  cutoffStatus: PositionStatus;

  posX: number;
  posY: number;

  water: number;
  dna: number;

  value: number;
  measured: number;

  code: string;

  comment: string;
  updatedBy: string;

  static fromData(data: PositionModel): PositionModel {
    return Object.assign(new PositionModel(), data);
  }

}
