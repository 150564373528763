<app-workflow-step-layout>
  <ng-container info>
    <div>

      <h3>Verwendete statische Werte</h3>

      <div class="data-row">
        <div>Zielkonzentration:</div>
        <div>{{workflowStep.concentration}} ng/µL</div>
        <div>Zielvolumen:</div>
        <div>{{workflowStep.targetVolume}} µL</div>
      </div>

      <h3>Verwendete Formeln</h3>

      <div>µL DNA = Konzentration * Zielvolumen / Glomax Wert in ng/µL</div>
      <div>µL AD = Zielvolumen - µL DNA</div>

      <ng-container *ngIf="workflowStep?.exportFiles && workflowStep?.exportFiles.length > 0">
        <h3>Exportdatei(en) für die Normalisierung</h3>
        <div *ngFor="let file of workflowStep?.exportFiles">
          <app-file [file]="file"></app-file>
        </div>
      </ng-container>

      <div class="plate-swap-button">

        <core-button
          *ngIf="!workflowInstance.isFinishedOrAborted() && !workflowStep?.plates[0]?.exportedFile"
          [width]="'100%'"
          [text]="'Berechnung exportieren'"
          [backgroundColor]="'#189D18FF'"
          (onClick)="exportCalculation()">
        </core-button>

        <core-button
          *ngIf="!workflowInstance.isFinishedOrAborted() && workflowStep?.plates[0]?.exportedFile"
          [width]="'100%'"
          [text]="'Berechnung erneut exportieren'"
          [backgroundColor]="'#189D18FF'"
          (onClick)="reExportCalculation()">
        </core-button>

        <!-- if the current step === NORMALIZATION_PREPARATION show the next button here -->
        <core-button
          *ngIf="workflowDetailClientService.workflowInstance.currentStep === WorkflowStep.NORMALIZATION_PREPARATION && workflowStep?.plates[0]?.exportedFile "
          [width]="'100%'"
          [text]="'Weiter zum Platten Import'"
          [backgroundColor]="'#189D18FF'"
          (onClick)="switchToPlateImport()">
        </core-button>

        <!-- if the current step === NORMALIZATION_PREPARATION show the next button here -->
        <core-button
          *ngIf="workflowDetailClientService.workflowInstance.currentStep !== WorkflowStep.NORMALIZATION_PREPARATION"
          [text]="'Zur importierten Platte wechseln'"
          [backgroundColor]="'#607D8B'"
          [width]="'100%'"
          (onClick)="switchToPlateView()">
        </core-button>

      </div>

    </div>
    <app-position-detail
      [position]="selectedPosition"
      [showChangeStateButton]="false"
      [showDnaWater]="true">
    </app-position-detail>
  </ng-container>
  <ng-container plate>
    <app-plate-view
      [workflowInstance]="workflowInstance"
      [plateMode]="PlateMode.DNA_WATER"
      [plates]="workflowStep?.plates"
      [plateSize]="workflowInstance?.workflow"
      (onClearPositionSelected)="onCellClick(null)"
      (onPositionSelected)="onCellClick($event)">
    </app-plate-view>
  </ng-container>
</app-workflow-step-layout>
