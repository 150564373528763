import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {
  WorkflowStepQuantificationSecondModel
} from "../../../../models/workflow-step/workflow-step-quantification-second.model";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {WorkflowStep} from "../../../../enums/WorkflowStep";
import {PlateModel} from "../../../../models/Plate/plate.model";
import {PlateMode} from "../../../../enums/PlateMode";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FileType} from "../../../../enums/FileType";
import {Workflow} from "../../../../enums/Workflow";
import {FileRecord} from "../../../../models/file/FileRecord";
import {$e} from "codelyzer/angular/styles/chars";

@Component({
  selector: 'app-quantification-second',
  templateUrl: './quantification-second.component.html',
  styleUrls: ['./quantification-second.component.scss']
})
export class QuantificationSecondComponent implements OnInit {

  WorkflowStep = WorkflowStep;
  protected readonly PlateMode = PlateMode;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepQuantificationSecondModel;
  @Input() readOnly: boolean = false;

  selectedPlate: PlateModel;

  faCheck = faCheck;
  faTimes = faTimes;

  constructor(public workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());

    if (this.workflowInstance?.workflow === Workflow.WORKFLOW_96 && this.workflowStep.plates.length === 1) {
      this.selectedPlate = this.workflowStep.plates[0];
    }
  }

  onFileUploadedToSelectedPlate(file: FileRecord): void {
    const index = this.workflowStep.plates.findIndex(p => p.id === this.selectedPlate.id);
    this.workflowStep.plates[index].uploadedFile = file;
    this.validateStep();
  }

  onFileRemovedFromSelectedPlate(): void {
    if (this.selectedPlate && this.selectedPlate.id) {
      const index = this.workflowStep.plates.findIndex(p => p.id === this.selectedPlate.id);
      this.workflowStep.plates[index].uploadedFile = null;
      this.selectedPlate.uploadedFile = null;
      this.validateStep();
    }
  }

  onPlateClicked(plate: PlateModel) {
    this.selectedPlate = plate;
  }

  clearSelection(): void {
    this.selectedPlate = null;
  }

  getWorkflowNameByIndex(i: number): string {
    return this.workflowInstance?.workflowNumber + (this.workflowStep.plates[i]?.suffix ?  '_' + this.workflowStep.plates[i]?.suffix : '');
  }

  private validateStep(): void {
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

  protected readonly Workflow = Workflow;
  protected readonly FileType = FileType;
  protected readonly $e = $e;
}
