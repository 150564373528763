<div>
  <h3>Probenauswahl</h3>
  <div class="data-container">
    <div>Negativkontrolle:</div>
    <div class="data">
      <ng-container *ngIf="positionNegative">
        {{ (positionNegative.posY | positionYToLetter) + positionNegative.posX}}
      </ng-container>
    </div>
    <div>
      <core-button
        class="btn"
        classList="p-button-secondary"
        *ngIf="isNegativeEditable"
        (onClick)="changeNegativeControl()"
        [disabled]="isReadonly || selectPositive || !positionNegative"
        [width]="'120px'"
        [text]="selectNegative ? 'abbrechen' : 'ändern'">
      </core-button>
    </div>

    <div>Positivkontrolle:</div>
    <div class="data">
      <ng-container *ngIf="positionPositive">
        {{(positionPositive.posY | positionYToLetter) + (positionPositive.posX ?? '')}}
      </ng-container>
    </div>
    <div>
      <core-button
        class="btn"
        classList="p-button-secondary"
        *ngIf="isPositiveEditable"
        (onClick)="changePositiveControl()"
        [disabled]="isReadonly || selectNegative || !positionPositive"
        [width]="'120px'"
        [text]="selectPositive ? 'abbrechen' : 'ändern'">
      </core-button>
    </div>
  </div>
</div>
