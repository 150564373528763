import {SelectItem} from "../models/select-item.model";
import {EnumUtils} from "./EnumUtils";

export enum BatchType {

  PROTEINASE_K = 'PROTEINASE_K',
  THIOGLYCEROL = 'THIOGLYCEROL',
  TLA = 'TLA',

  LYSIS_BUFFER = 'LYSIS_BUFFER',
  BINDING_BUFFER = 'BINDING_BUFFER',
  CRESIN_BEADS = 'CRESIN_BEADS',
  WASH_BUFFER = 'WASH_BUFFER',
  ELUTION_BUFFER = 'ELUTION_BUFFER',
  ETHANOL = 'ETHANOL',

  POSITIVE_CONTROL = 'POSITIVE_CONTROL',
  TE_PUFFER = 'TE_PUFFER',
  DYE = 'DYE',
  WATER = 'WATER',
  PCR_MASTER_MIX = 'PCR_MASTER_MIX',
  FLEXI_PUFFER = 'FLEXI_PUFFER',
  MGCL2 = 'MGCL2',
  PRIMERMIX = 'PRIMERMIX',
  BEADS = 'BEADS',
  POLYMERASE = 'POLYMERASE',
  DNTPS = 'DNTPS',
  INDEX_PRIMER = 'INDEX_PRIMER',
  SCREEN_TAPES = 'SCREEN_TAPES',
  SAMPLE_BUFFER = 'SAMPLE_BUFFER',
  LADDER = 'LADDER',
  DS_DNA_HS_REAGENT = 'DS_DNA_HS_REAGENT',
  DS_DNA_HS_BUFFER = 'DS_DNA_HS_BUFFER',
  DS_DNA_HS_STANDART_1 = 'DS_DNA_HS_STANDART_1',
  DS_DNA_HS_STANDART_2 = 'DS_DNA_HS_STANDART_2',
  PHIX_CONTROL = 'PHIX_CONTROL',
  MISEQ_REAGENT_KIT = 'MISEQ_REAGENT_KIT',
  MISEQ_REAGENT_KIT_FLOW_CELL = 'MISEQ_REAGENT_KIT_FLOW_CELL',
  PRONEX_BEADS = 'PRONEX_BEADS',
  PRONEX_ELUTION = 'PRONEX_ELUTION',
  PRONEX_WASH = 'PRONEX_WASH'

}

export namespace BatchType {

  export function getBatchTypeReadable(batchType: BatchType): string {
    if (!batchType) return ''

    switch (batchType) {
      case BatchType.LYSIS_BUFFER:
        return 'Lysis Buffer';
      case BatchType.PROTEINASE_K:
        return 'Proteinase K';
      case BatchType.BINDING_BUFFER:
        return 'Binding Buffer';
      case BatchType.CRESIN_BEADS:
        return 'C Resin';
      case BatchType.WASH_BUFFER:
        return 'Wash Buffer';
      case BatchType.ELUTION_BUFFER:
        return 'Elution Buffer';
      case BatchType.THIOGLYCEROL:
        return '1-Thioglycerol';
      case BatchType.ETHANOL:
        return 'Ethanol';
      case BatchType.POSITIVE_CONTROL:
        return 'Positivkontrolle';
      case BatchType.TE_PUFFER:
        return 'TE Buffer';
      case BatchType.DYE:
        return 'QuantiFluor® dsDNA Dye';
      case BatchType.WATER:
        return 'Wasser';
      case BatchType.PCR_MASTER_MIX:
        return 'PCR Master Mix';
      case BatchType.FLEXI_PUFFER:
        return 'Flexi Puffer';
      case BatchType.MGCL2:
        return 'MgCl2';
      case BatchType.PRIMERMIX:
        return 'Primermix';
      case BatchType.BEADS:
        return 'Beads';
      case BatchType.POLYMERASE:
        return 'Flexi Polymerase';
      case BatchType.DNTPS:
        return 'dNTPs';
      case BatchType.INDEX_PRIMER:
        return 'Indexprimer';
      case BatchType.SCREEN_TAPES:
        return 'ScreenTapes';
      case BatchType.SAMPLE_BUFFER:
        return 'Sample Buffer';
      case BatchType.LADDER:
        return 'Ladder';
      case BatchType.DS_DNA_HS_REAGENT:
        return 'dsDNA HS Reagent (Dye)';
      case BatchType.DS_DNA_HS_BUFFER:
        return 'dsDNA HS Buffer';
      case BatchType.DS_DNA_HS_STANDART_1:
        return 'dsDNA HS Standard 1';
      case BatchType.DS_DNA_HS_STANDART_2:
        return 'dsDNA HS Standard 2';
      case BatchType.PHIX_CONTROL:
        return 'PhiX Control';
      case BatchType.MISEQ_REAGENT_KIT:
        return 'MiSeq Reagent Kit v3, Cartridge';
      case BatchType.MISEQ_REAGENT_KIT_FLOW_CELL:
        return 'MiSeq Reagent Kit v3, Flow Cell';
      case BatchType.PRONEX_BEADS:
        return 'ProNex Beads';
      case BatchType.PRONEX_ELUTION:
        return 'ProNex Elution';
      case BatchType.PRONEX_WASH:
        return 'ProNex Wash';
      case BatchType.TLA:
        return 'TLA'
    }
  }

  export function getEnumByValue(value: string): BatchType {
    return EnumUtils.getEnumByValue<BatchType>(BatchType, 'string', value);
  }

  export function getAll(): BatchType[] {
    return EnumUtils.getAll<BatchType>(BatchType, 'string');
  }

  export function getAllForIndexPcr(): BatchType[] {
    const batches = [];
    batches.push(
      BatchType.POLYMERASE,
      BatchType.FLEXI_PUFFER,
      BatchType.MGCL2,
      BatchType.DNTPS,
      BatchType.INDEX_PRIMER,
      BatchType.WATER);
    return batches;
  }

  export function getAllForGlomax(): BatchType[] {
    const batches = [];
    batches.push(
      BatchType.DYE,
      BatchType.TE_PUFFER,
      BatchType.WATER);
    return batches;
  }

  export function getAllForAgilant(): BatchType[] {
    const batches = [];
    batches.push(BatchType.SAMPLE_BUFFER, BatchType.SCREEN_TAPES, BatchType.LADDER);
    return batches;
  }

  export function getAllForQubit(): BatchType[] {
    return [BatchType.DS_DNA_HS_REAGENT, BatchType.DS_DNA_HS_BUFFER, BatchType.DS_DNA_HS_STANDART_1, BatchType.DS_DNA_HS_STANDART_2];
  }

  export function getAllAsSelectItem(): SelectItem[] {
    return BatchType.getAll().map(batchType => ({id: batchType, name: BatchType.getBatchTypeReadable(batchType)}));
  }

  export function getAllForNgsSequencing(): BatchType[] {
    const batches = [];
    batches.push(
      BatchType.MISEQ_REAGENT_KIT_FLOW_CELL,
      BatchType.MISEQ_REAGENT_KIT,
      BatchType.PHIX_CONTROL);
    return batches;
  }

}
