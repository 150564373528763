import {Pipe, PipeTransform} from '@angular/core';
import {WorkflowStep} from "../enums/WorkflowStep";

@Pipe({
  name: 'workflowStepReadable'
})
export class WorkflowStepReadablePipe implements PipeTransform {

  transform(value: WorkflowStep): unknown {
    return WorkflowStep.getWorkFlowStepReadable(value);
  }

}
