import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {WorkflowStep} from '../../../enums/WorkflowStep';
import {WorkflowInstanceService} from '../../../services/workflow-instance.service';
import {WorkflowInstanceDtoModel} from '../../../models/workflow/workflow-instance-dto.model';
import {WorkflowDetailClientService} from './workflow-detail-client.service';
import {WorkflowInstanceModel} from '../../../models/workflow/workflow-instance.model';
import {
  WorkflowStepMeasurementOnGlomaxSecondModel
} from '../../../models/workflow-step/workflow-step-measurement-on-glomax-second.model';
import {UnsubscribeBaseComponent} from '../../unsubscribe-base.component';
import {debounceTime, filter, takeUntil} from 'rxjs';
import {SseEventType} from '../../../enums/SseEventType';
import {SseService} from '../../../services/sse.service';
import {BatchModel} from '../../../models/batch/batch.model';
import {BatchService} from '../../../services/batch.service';
import {BatchState} from '../../../enums/BatchState';
import {
  WorkflowInstanceWithBatchListDtoModel
} from '../../../models/workflow/workflow-instance-with-batch-list-dto.model';
import {
  WorkflowInstanceStepMeasurementOnGlomaxSecondEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-measurement-on-glomax-second-edit.model';
import {BatchType} from '../../../enums/BatchType';
import {BatchShareModel} from '../../../models/batch/batch-share.model';
import {MessageService} from '../../../services/message.service';
import {WorkflowInstanceStepError} from '../../../enums/WorkflowInstanceStepError';
import {
  WorkflowStepLibraryPostSequencingModel
} from '../../../models/workflow-step/workflow-step-library-post-sequencing.model';
import {
  WorkflowInstanceStepLibraryPostSequencingEditModel
} from '../../../models/workflow-step/edit/workflow-instance-step-library-post-sequencing-edit.model';

@Component({
  selector: 'app-workflow-detail-dialog',
  templateUrl: './workflow-detail-dialog.component.html',
  styleUrls: ['./workflow-detail-dialog.component.scss']
})
export class WorkflowDetailDialogComponent extends UnsubscribeBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  BatchType = BatchType;
  WorkflowStep = WorkflowStep;

  @Input() showDetail = false;
  @Output() closeDialog = new EventEmitter<boolean>();
  isReadonly = false;
  confirmationDialogVisible = false;

  batchesForStateChange: BatchModel[] = [];
  showCancelWorkflowDialog = false;
  BatchState = BatchState;
  batchesFinishedOrCancelled: BatchShareModel[] = [];
  shareToLibrary = true;

  // for finish the finishing the 96 or 384 workflow the confirmation needed from the extra batch/share library dialog
  hasFinishPositionError = false;
  suppressPositionNotOkWarning = false;

  showShortenWorkflowDialog = false;


  @ViewChild('workflowStepNavigator') workFlowStepNavigatorContainer: ElementRef;

  private wheelEventListener: any;

  constructor(private workflowInstanceService: WorkflowInstanceService,
              public workflowDetailClientService: WorkflowDetailClientService,
              private sseService: SseService,
              private messageService: MessageService,
              private batchService: BatchService,
              private elementRef: ElementRef) {
    super();
  }

  ngOnInit() {
    this.workflowDetailClientService.fetchWorkInstanceStep();
    this.workflowDetailClientService.isReadOnly$.subscribe(value => {
      this.isReadonly = value;
    });

    this.sseService.sseEvent$.pipe(
      takeUntil(this.unSubscribe),
      filter(sseEvent =>
        sseEvent != null &&
        sseEvent.type === SseEventType.REFRESH_NEEDED &&
        sseEvent.workflowId === this.workflowInstance?.id),
      // 1 second debounce time in case multiple files are imported at the same time
      debounceTime(1000))
      .subscribe(() => {
        if (!this.workflowDetailClientService.saveRecentlyCalled) {
          this.workflowDetailClientService.fetchWorkInstanceStep();
        }
      });

    this.workflowDetailClientService.positionClicked$
      .pipe(takeUntil(this.unSubscribe), filter(value => value === true))
      .subscribe(() => {
        // scroll down in the dialog to show the position detail infos
        const element = this.elementRef.nativeElement.querySelector('.p-dialog-content');
        if (element) {
          setTimeout(() => {
            element.scrollTo({top: element.scrollHeight, behavior: 'smooth'});
          }, 0);
        }
      });
  }

  ngAfterViewInit() {
    const element = this.workFlowStepNavigatorContainer.nativeElement as HTMLElement;
    element.scrollLeft = element.scrollWidth - element.clientWidth;
    this.initOnMouseWheelEventListener();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.workFlowStepNavigatorContainer.nativeElement.removeEventListener('wheel', this.wheelEventListener);
  }

  get workflowInstance(): WorkflowInstanceModel {
    return this.workflowDetailClientService.workflowInstance;
  }

  getErrorFromWorkflowStepByStepEnums(workflowInstance: WorkflowInstanceModel, allSteps: WorkflowStep[]): WorkflowInstanceStepError {
    if (!workflowInstance.steps) {
      return null;
    }
    const steps = workflowInstance.steps.filter(s => allSteps.find(a => s.step === a));
    for (const s of steps) {
      if (s.error) {
        return s.error;
      }
    }
    return null;
  }

  onCloseDialog(withAutoSave: boolean = true) {
    this.showDetail = false;

    if (this.workflowInstance.isFinishedOrAborted() || this.workflowInstance.currentStep === WorkflowStep.NORMALIZATION_PREPARATION) {
      withAutoSave = false;
    }

    this.workflowDetailClientService.cleanUp(withAutoSave);
    this.closeDialog.emit(false);
    this.isReadonly = false;
  }

  nextStep(): void {
    if (!this.isLastStep()) {
      if (this.workflowInstance.currentStep === WorkflowStep.ADDING_CHECKS && this.workflowDetailClientService.selectedStep$.getValue().step === this.workflowInstance.currentStep) {
        this.workflowDetailClientService.silentSave();
        this.showShortenWorkflowDialog = true;
      } else {
        this.workflowDetailClientService.nextStep();
        const historyList = this.workflowDetailClientService.navMenuItems$.getValue();
        const step = this.workflowDetailClientService.selectedStep$.getValue()?.step ?? this.workflowDetailClientService.workflowInstance.currentStep;
        let indexOfCurrentStep = historyList.findIndex(ws => ws.find(e => e === step));
        this.scrollToElementById(indexOfCurrentStep++, true);
      }
    } else {
      this.fetchBatchesUsedByWorkflowInstance();
      this.confirmationDialogVisible = true;
    }
  }

  /**
   * switch the previous step
   * as current step either we take the selectedStep from the service or
   * if the step is null the current step of the workflow instance
   * the second use cases needed in case the workflow instance waiting for file process and the step is still not generated
   */
  showPreviousStep() {
    const historyList = this.workflowDetailClientService.navMenuItems$.getValue();
    const step = this.workflowDetailClientService.selectedStep$.getValue()?.step ?? this.workflowDetailClientService.workflowInstance.currentStep;
    let indexOfCurrentStep = historyList.findIndex(ws => ws.find(e => e === step));
    if (indexOfCurrentStep > 0) {
      if (historyList[indexOfCurrentStep].length == 1) {
        this.workflowDetailClientService.fetchWorkflowInstanceStepByStep(historyList[indexOfCurrentStep - 1][historyList[indexOfCurrentStep - 1].length - 1]);
      } else if (historyList[indexOfCurrentStep].findIndex(e => e === step) === 0) {
        this.workflowDetailClientService.fetchWorkflowInstanceStepByStep(historyList[indexOfCurrentStep - 1][historyList[indexOfCurrentStep - 1].length - 1]);
      } else {
        this.workflowDetailClientService.fetchWorkflowInstanceStepByStep(historyList[indexOfCurrentStep][
        historyList[indexOfCurrentStep].findIndex(e => e === step) - 1]);
      }
      this.scrollToElementById(indexOfCurrentStep--, false);
      this.isReadonly = true;
    }
  }

  private fetchBatchesUsedByWorkflowInstance(): void {
    this.batchService.getAllBatchesForWorkflowInstance(
      this.workflowDetailClientService.workflowInstance.id,
      BatchState.NEW)
      .subscribe((resp) => {
        this.batchesForStateChange = resp;
        this.batchesForStateChange.forEach(batch => {
          this.batchesFinishedOrCancelled.push(BatchShareModel.fromData(batch));
        });
        this.batchesFinishedOrCancelled.sort((a, b) => BatchType.getBatchTypeReadable(a.type).localeCompare(BatchType.getBatchTypeReadable(b.type)));
      });
  }

  onConfirmWorkflowFinish(): void {
    // confirmation for library
    if (this.workflowInstance.isLibrary()) {
      this.onFinishLibrary();
    } else {
      this.onConfirmShareToLibrary();
    }
  }

  private onConfirmShareToLibrary() {
    const batchStateUpdateDtos: BatchModel[] = [];
    this.batchesFinishedOrCancelled.forEach(batch => {
      batch.batchState = batch.share ? BatchState.IN_USE : BatchState.BLOCKED;
      batchStateUpdateDtos.push(batch);
    });
    const glomax = this.workflowDetailClientService.selectedStep$.getValue() as WorkflowStepMeasurementOnGlomaxSecondModel;
    const updateDto = WorkflowInstanceStepMeasurementOnGlomaxSecondEditModel.initFromStep(glomax);
    updateDto.workflowInstanceId = glomax.workflowInstance.id;
    updateDto.shareToLibrary = this.shareToLibrary;

    const workflowInstanceWithBatchList = WorkflowInstanceWithBatchListDtoModel.initWithGlomaxSecondStep(
      WorkflowInstanceDtoModel.fromWorkFlowInstanceModel(this.workflowInstance),
      batchStateUpdateDtos,
      updateDto
    );


    this.workflowInstanceService.shareToLibrary(workflowInstanceWithBatchList, this.suppressPositionNotOkWarning)
      .subscribe(() => {
        if (workflowInstanceWithBatchList.workflowInstanceStepMeasurementGlomaxSecondEditDto.shareToLibrary) {
          this.messageService.addSuccessMessage('Workflow wurde abgeschlossen und für eine Library freigegeben!');
        } else {
          this.messageService.addSuccessMessage('Workflow wurde abgeschlossen, aber nicht für eine Library freigegeben!');
        }
        this.confirmationDialogVisible = false;
        this.workflowInstanceService.refreshNeed$.next(true);
        this.onCloseDialog(false);
      }, error => {
        if (error.status === 901) {
          this.hasFinishPositionError = true;
          // by next confirm click no suppress needed
          this.suppressPositionNotOkWarning = true;
        }
      });
  }

  private onFinishLibrary() {
    const batchStateUpdateDtos: BatchModel[] = [];
    this.batchesFinishedOrCancelled.forEach(batch => {
      batch.batchState = batch.share ? BatchState.IN_USE : BatchState.BLOCKED;
      batchStateUpdateDtos.push(batch);
    });

    const postSequencingStep = this.workflowDetailClientService.selectedStep$.getValue() as WorkflowStepLibraryPostSequencingModel;
    const updateDto = WorkflowInstanceStepLibraryPostSequencingEditModel.initFromStep(postSequencingStep);
    updateDto.workflowInstanceId = postSequencingStep.workflowInstance.id;

    const workflowInstanceWithBatchList = WorkflowInstanceWithBatchListDtoModel.initWithLibraryPostSequencingEdit(
      WorkflowInstanceDtoModel.fromWorkFlowInstanceModel(this.workflowInstance),
      batchStateUpdateDtos,
      updateDto
    );

    this.workflowInstanceService.finishLibrary(workflowInstanceWithBatchList)
      .subscribe(() => {
        this.messageService.addSuccessMessage('Library wurde abgeschlossen!');
        this.workflowInstanceService.refreshNeed$.next(true);
        this.confirmationDialogVisible = false;
        this.onCloseDialog(false);
      });
  }

  showAbortWorkFlowConfirm(): void {
    this.fetchBatchesUsedByWorkflowInstance();
    this.showCancelWorkflowDialog = true;
  }

  abortWorkFlow(): void {
    const batchStateUpdateDtos: BatchModel[] = [];
    this.batchesFinishedOrCancelled.forEach(batch => {
      batch.batchState = batch.share ? BatchState.IN_USE : BatchState.BLOCKED;
      batchStateUpdateDtos.push(batch);
    });

    const workflowInstanceWithBatchList = WorkflowInstanceWithBatchListDtoModel.initForAbort(
      WorkflowInstanceDtoModel.fromWorkFlowInstanceModel(this.workflowInstance),
      batchStateUpdateDtos
    );

    this.workflowInstanceService.abortWorkFlowInstance(workflowInstanceWithBatchList)
      .subscribe(() => {
        this.messageService.addSuccessMessage('Workflow wurde abgebrochen und wurde nicht für eine Library freigegeben!');
        this.workflowInstanceService.refreshNeed$.next(true);
        this.onCloseDialog(false);
      });
  }

  shortenWorkflowInstance(): void {
    this.workflowDetailClientService.shortenWorkFlow();
  }

  closeWorkflowCancelDialog() {
    this.showCancelWorkflowDialog = false;
    this.batchesFinishedOrCancelled = [];
    this.batchesForStateChange = [];
  }

  closeFinishDialog() {
    this.confirmationDialogVisible = false;
    this.batchesFinishedOrCancelled = [];
    this.shareToLibrary = true;
    this.hasFinishPositionError = false;
    this.suppressPositionNotOkWarning = false;
  }

  isLastStep(): boolean {
    const workflow = this.workflowInstance.workflow;
    return WorkflowInstanceModel.isLastStepBeforeFinish(this.workflowInstance.currentStep, workflow) &&
      (!this.workflowDetailClientService.selectedStep$.getValue() || WorkflowInstanceModel.isLastStepBeforeFinish(this.workflowDetailClientService.selectedStep$.getValue()?.step, workflow));
  }

  private initOnMouseWheelEventListener(): void {
    if (this.workFlowStepNavigatorContainer) {
      this.wheelEventListener = (ev: WheelEvent) => {
        ev.preventDefault();  // Prevent the default scroll and other directional scrolls

        const scrollAmount = (ev.deltaY + ev.deltaX) * 1; // Adjust sensitivity if needed, but i guess 1 should be fine
        const newScrollPosition = this.workFlowStepNavigatorContainer.nativeElement.scrollLeft + scrollAmount;

        this.workFlowStepNavigatorContainer.nativeElement.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth'  // Use smooth scrolling on dynamic scroll
        });
      };

      this.workFlowStepNavigatorContainer.nativeElement.addEventListener('wheel', this.wheelEventListener);
    }
  }

  private scrollToElementById(indexOfCurrentStep: number, isForward: boolean): void {
    const element = this.elementRef.nativeElement.querySelector('#stepCard_' + indexOfCurrentStep);
    const inlineDirection = isForward ? 'start' : 'end';
    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: inlineDirection});
    }
  }

  get isBackButtonDisabled(): boolean {
    return (this.workflowDetailClientService.selectedStep$.getValue()?.step ?? this.workflowInstance.currentStep) === WorkflowStep.PREPARATION;
  }

  get isNextButtonDisabled(): boolean {
    const selectedStep = this.workflowDetailClientService.selectedStep$.getValue()?.step ?? this.workflowInstance.currentStep;
    return !this.workflowDetailClientService.isStepValid$.getValue() || selectedStep === WorkflowStep.FINISHED;
  }

  get nextButtonText(): string {
    return this.isLastStep() ? 'Workflow Abschließen' : 'Nächster Schritt';
  }
}
