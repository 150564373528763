import {IWorkflowStepBase} from './workflow-step-base.model';
import {PlateModel} from '../Plate/plate.model';
import {WorkflowStep} from '../../enums/WorkflowStep';
import {BatchModel} from '../batch/batch.model';
import {WorkflowInstanceModel} from '../workflow/workflow-instance.model';

export class WorkflowStepNormalisation implements IWorkflowStepBase {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  plates: PlateModel[];
  batches: BatchModel[];

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, plates: PlateModel[],
              batches: BatchModel[], isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.plates = plates.map(PlateModel.fromData);
    this.batches = batches;
  }

  isValid(): boolean {
    // valid if a plate exists and all batches have an expiration date set
    for (const batch of this.batches) {
      if (!batch.expirationDate) {
        return false;
      }
    }
    // check plate counter
    return this.plates.length === 1 && this.plates.every(plate => plate?.id != null);
  }

}
