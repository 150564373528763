<app-workflow-step-layout>
  <ng-container info>
    <div class="info-container">
      <!-- readonly relevant fields -->
      <ng-container *ngIf="readOnly">
        <div>
          Die Indexpools A, B, C, D wurden vereint.
        </div>
      </ng-container>

      <ng-container *ngIf="!readOnly">
        <div class="confirm-index-pool-combined">
          <b>Indexpools A, B, C, D vereint?</b>
          <core-button
            [width]="'100%'"
            [text]="'Bestätigen'"
            classList="p-button-success"
            [disabled]="workflowStep.indexPoolsCombined || readOnly"
            (onClick)="onIndexPoolCombined()">
          </core-button>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container plate>
    <app-pcr-tube-viewer
      [readOnly]="readOnly">
    </app-pcr-tube-viewer>
  </ng-container>
</app-workflow-step-layout>
