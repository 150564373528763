import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {getUserRoleReadable, Role} from '../../enums/Role';
import {faFileImport, faUser} from '@fortawesome/free-solid-svg-icons';
import {MenuModel} from '../../models/menu.model';
import {FileProcessingHistoryService} from "../../services/file-processing-history.service";
import {SseService} from "../../services/sse.service";
import {filter, takeUntil} from "rxjs";
import {SseEventType} from "../../enums/SseEventType";
import {UnsubscribeBaseComponent} from "../unsubscribe-base.component";
import {MessageService} from "../../services/message.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends UnsubscribeBaseComponent implements OnInit {

  // icons
  faUser = faUser;
  faFileImport = faFileImport;

  countOfUnconfirmedFileErrors: number = 0;
  showFileProcessingDialog: boolean = false;

  userName: string;
  userRoleReadable: string;

  headerMenuItems: MenuModel[];

  // debounce to use when subscribing to file errors
  timeoutFileProcessingErrorId: any = null;
  timeoutPathErrorId: any = null;
  timeoutFileExportId: any = null;

  constructor(private authService: AuthenticationService,
              private router: Router,
              private messageService: MessageService,
              private fileProcessingHistoryService: FileProcessingHistoryService,
              private sseService: SseService) {
    super();
    this.userName = authService.getUserName();
    this.userRoleReadable = getUserRoleReadable(authService.getUserRole());
    this.initHeaderMenu();
  }

  ngOnInit(): void {
    this.getCountOfUnconfirmedFileErrors();

    this.sseService.sseEvent$.pipe(
      takeUntil(this.unSubscribe),
      filter(sseEvent => sseEvent != null && sseEvent.type === SseEventType.FILE_PROCESS_ERROR)
    ).subscribe(() => this.onFileProcessingError());

    this.sseService.sseEvent$.pipe(
      takeUntil(this.unSubscribe),
      filter(sseEvent => sseEvent != null && sseEvent.type === SseEventType.FILE_EXPORT_SUCCESS)
    ).subscribe(() => this.onFileExportSuccess())

    this.sseService.sseEvent$.pipe(
      takeUntil(this.unSubscribe),
      filter(sseEvent => sseEvent != null && sseEvent.type === SseEventType.FILE_PATH_ERROR)
    ).subscribe(() => this.onFilePathError());

    this.sseService.sseEvent$.pipe(
      takeUntil(this.unSubscribe),
      filter(sseEvent => sseEvent != null && sseEvent.type === SseEventType.INCORRECT_FILE_TYPE)
    ).subscribe(() => this.onIncorrectFileType());
  }

  onFileProcessingError() {
    if (this.timeoutFileProcessingErrorId) {
      clearTimeout(this.timeoutFileProcessingErrorId);
    }
    this.timeoutFileProcessingErrorId = setTimeout(() => {
      this.getCountOfUnconfirmedFileErrors();
      this.messageService.addErrorMessage('Mindestens ein Datei-Import konnte nicht durchgeführt werden!');
    }, 5000);
  }

  onFilePathError() {
    if (this.timeoutPathErrorId) {
      clearTimeout(this.timeoutPathErrorId);
    }
    this.timeoutPathErrorId = setTimeout(() => {
      this.getCountOfUnconfirmedFileErrors();
      this.messageService.addErrorMessage('Mindestens ein Datei-Pfad konnte nicht gefunden werden!', 'Fehler', false);
    }, 1000);
  }

  onFileExportSuccess() {
    if (this.timeoutFileExportId) {
      clearTimeout(this.timeoutFileExportId);
    }
    this.timeoutFileExportId = setTimeout(() => {
      this.messageService.addSuccessMessage("Datei(en) wurden erfolgreich exportiert!");
    }, 500);
  }

  private onIncorrectFileType() {
    this.messageService.addErrorMessage('Mindestens ein Datei-Import konnte nicht durchgeführt werden!', 'Fehler', false)
  }

  onRedirectToDashboard() {
    this.router.navigate(['']);
  }

  onLogout() {
    this.authService.logoutUser();
    this.sseService.disconnect();
  }

  onShowFileProcessingHistory() {
    this.showFileProcessingDialog = !this.showFileProcessingDialog;
  }

  getCountOfUnconfirmedFileErrors() {
    this.fileProcessingHistoryService.countAllUnconfirmed()
      .subscribe({
        next: (count: number) => {
          this.countOfUnconfirmedFileErrors = count;
        }
      });
  }

  private initHeaderMenu(): void {
    this.headerMenuItems = this.authService.getUserRole() === Role.HLAADMIN ?
      MenuModel.initHeaderMenuAdmin() :
      MenuModel.initHeaderMenuUser();
  }
}


