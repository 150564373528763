import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ArchiveComponent} from './archive.component';
import {InputModule} from "../../components/input/input.module";
import {ReactiveFormsModule} from "@angular/forms";
import {SearchFieldsContainerModule} from "../../components/search-fields-container/search-fields-container.module";
import {TableModule} from "primeng/table";
import {CustomPipeModule} from "../../pipes/custom-pipe.module";
import {ArchiveBatchSelectorModule} from "../../components/archive-batch-selector/archive-batch-selector.module";
import {WorkflowDetailDialogModule} from "../../components/dialog/workflow-detail-dialog/workflow-detail-dialog.module";


@NgModule({
  declarations: [
    ArchiveComponent
  ],
  imports: [
    CommonModule,
    InputModule,
    ReactiveFormsModule,
    SearchFieldsContainerModule,
    TableModule,
    CustomPipeModule,
    ArchiveBatchSelectorModule,
    WorkflowDetailDialogModule
  ]
})
export class ArchiveModule {
}
