import {IWorkflowStepBase} from "./workflow-step-base.model";
import {WorkflowStep} from "../../enums/WorkflowStep";
import {PlateModel} from "../Plate/plate.model";
import {WorkflowInstanceModel} from "../workflow/workflow-instance.model";
import {FileRecord} from "../file/FileRecord";
import {PositionModel} from "../Plate/position.model";

export class WorkflowStepNormalisationPreparationModel implements IWorkflowStepBase {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  plates: PlateModel[];
  concentration: number;
  targetVolume: number;
  exportFiles: FileRecord[];

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel, plates: PlateModel[], concentration: number,
              targetVolume: number, isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.plates = plates.map(PlateModel.fromData);
    // map the exported files to their own list
    this.exportFiles = [];
    for (let plate of this.plates)
      if (plate.exportedFile)
        this.exportFiles.push(plate.exportedFile)
    this.concentration = concentration;
    this.targetVolume = targetVolume;
    // okay so here at this point we calculate a super plate based on the 4 sub plates
    // this is done to have a better user experience, see HLA-516
    if (this.plates && this.plates.length > 1) {
      this.plates = [this.calculateSuperPlateBasedOnIndex(this.plates)];
    }
  }

  // TypeScript version of the calculateSuperPlateBasedOnIndex method
  calculateSuperPlateBasedOnIndex(plates: PlateModel[]): PlateModel {
    // If there is only one plate in the list, return the single plate
    if (plates.length === 1) return plates[0];

    // First verify the plates match the requirements
    let plateIndices = new Set(plates.map(plate => plate.plateIndex));
    let requiredIndices = new Set([1, 2, 3, 4]);
    if (plates.length !== 4 || ![...plateIndices].every(index => requiredIndices.has(+index))) {
      throw new Error("Plate index is not as defined!");
    }

    let positions: PositionModel[] = [];
    for (let plate of plates) {
      for (let position of plate.positions) {
        // Adjusting position based on plate index
        position.posX = (plate.plateIndex % 2 === 0 ? 12 : 0) + position.posX;
        position.posY = (plate.plateIndex >= 3 ? 8 : 0) + position.posY;
        positions.push(position);
      }
    }

    let result = new PlateModel();
    result.exportedFile = plates[0].exportedFile;
    result.positions = positions;
    return result;
  }

}
