import {WorkflowInstanceStepDto} from "../workflow/workflow-instance-step-dto";
import {FileErrorType} from "../../enums/FileErrorType";
import {FileErrorCode} from "../../enums/FileErrorCode";

export class FileProcessingHistoryModel {

  id: number;
  created: Date;
  errorType: FileErrorType;
  errorCode: FileErrorCode;
  fileName: string;
  path: string;
  confirmed: boolean;
  confirmedBy: string;
  workflowInstanceStep: WorkflowInstanceStepDto;

  static fromData(data: FileProcessingHistoryModel): FileProcessingHistoryModel {
    if (!data) return null;
    const result = Object.assign(new FileProcessingHistoryModel(), data);
    if (result.workflowInstanceStep) result.workflowInstanceStep = WorkflowInstanceStepDto.fromData(result.workflowInstanceStep);
    return result;
  }

}
