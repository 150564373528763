export class FileRecord {

  id: number;
  name: string;
  created: Date;
  contentType: string;

  static fromData(data: FileRecord): FileRecord {
    if (!data) return null;
    return Object.assign(new FileRecord(), data);
  }

}
