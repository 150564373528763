import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {BatchModel} from "../../batch/batch.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {WorkflowStepMeasurementOnGlomaxSecondModel} from "../workflow-step-measurement-on-glomax-second.model";

export class WorkflowInstanceStepMeasurementOnGlomaxSecondEditModel extends WorkflowInstanceStepEditModel {

  shareToLibrary: boolean;
  batches: BatchModel[];

  static initFromStep(step: WorkflowStepMeasurementOnGlomaxSecondModel): WorkflowInstanceStepMeasurementOnGlomaxSecondEditModel {
    const ret = new WorkflowInstanceStepMeasurementOnGlomaxSecondEditModel();
    ret.workflowStep = WorkflowStep.MEASUREMENT_GLOMAX_SECOND;
    ret.workflowInstanceStepId = step.id;
    ret.shareToLibrary = step.shareToLibrary;
    ret.batches = step.batches.filter(BatchModel.isValid);
    return ret;
  }
}
