import {Pipe, PipeTransform} from '@angular/core';
import {Workflow} from '../enums/Workflow';

@Pipe({
  name: 'workflowReadAble'
})
export class WorkflowReadablePipe implements PipeTransform {
    transform(value: Workflow): string {
      return Workflow.getWorkFlowReadable(value);
    }

}
