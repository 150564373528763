import {CyclerRunThroughState} from "../../enums/CyclerRunThroughState";
import {CyclerRunThroughModel} from "./cycler-run-through.model";

export class CyclerRunThroughEditModel {

  id: number;
  version: number;
  state: CyclerRunThroughState;

  static fromCyclerRunThrough(crt: CyclerRunThroughModel): CyclerRunThroughEditModel {
    const ret: CyclerRunThroughEditModel = new CyclerRunThroughEditModel();
    ret.id = crt.id;
    ret.version = crt.version;
    ret.state = crt.state;
    return ret;
  }

}
