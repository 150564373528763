import {EnumUtils} from "./EnumUtils";

export enum FileErrorCode {
  FILE_IS_EMPTY = 'FILE_IS_EMPTY',
  FILE_HAS_MORE_THAN_1_ROW = 'FILE_HAS_MORE_THAN_1_ROW',
  FILE_TYPE_NOT_SUPPORTED = 'FILE_TYPE_NOT_SUPPORTED',
  FILE_DOES_NOT_BELONG_TO_WORKFLOW_NUMBER = 'FILE_DOES_NOT_BELONG_TO_WORKFLOW_NUMBER',
  HEADER_IS_NOT_AS_SPECIFIED = 'HEADER_IS_NOT_AS_SPECIFIED',
  INVALID_DATA_FORMAT = 'INVALID_DATA_FORMAT',
  BATCHES_NOT_AS_EXPECTED = 'BATCHES_NOT_AS_EXPECTED',
  SOURCE_AND_TARGET_PLATE_DO_NOT_MATCH = 'SOURCE_AND_TARGET_PLATE_DO_NOT_MATCH',
  PLATE_COUNT_NOT_AS_EXPECTED = 'PLATE_COUNT_NOT_AS_EXPECTED',
  UNEXPECTED_FILE_FORMAT_GIVEN = 'UNEXPECTED_FILE_FORMAT_GIVEN',
  UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_SWAB = 'UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_SWAB',
  UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_SWAB_LYSIS = 'UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_SWAB_LYSIS',
  UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_QUANTIFICATION_FIRST = 'UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_QUANTIFICATION_FIRST',
  UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_TARGET_PCR = 'UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_TARGET_PCR',
  WRONG_WORKFLOW_STEP_GIVEN = 'WRONG_WORKFLOW_STEP_GIVEN',
  WORKFLOW_NUMBER_MISSING = 'WORKFLOW_NUMBER_MISSING',
  WORKFLOW_NUMBER_FORMAT_EXCEPTION = 'WORKFLOW_NUMBER_FORMAT_EXCEPTION',
  WORKFLOW_NUMBER_NOT_MATCHING_EXCEPTION = 'WORKFLOW_NUMBER_NOT_MATCHING_EXCEPTION',
  WORKFLOW_NUMBER_NOT_FOUND = 'WORKFLOW_NUMBER_NOT_FOUND',
  IO_EXCEPTION = 'IO_EXCEPTION',
  PATH_ERROR = 'PATH_ERROR',
}

export namespace FileErrorCode {

  export function getFileErrorCodeReadable(type: FileErrorCode): string {
    if (!type) return '';

    switch (type) {
      case FileErrorCode.FILE_IS_EMPTY:
        return 'Datei enthält keine Daten!';
      case FileErrorCode.FILE_HAS_MORE_THAN_1_ROW:
        return 'Datei enthält mehr als eine Zeile!';
      case FileErrorCode.FILE_TYPE_NOT_SUPPORTED:
        return 'Datei wird nicht unterstützt!';
      case FileErrorCode.FILE_DOES_NOT_BELONG_TO_WORKFLOW_NUMBER:
        return 'Datei kann keinem Workflow zugeordnet werden!';
      case FileErrorCode.HEADER_IS_NOT_AS_SPECIFIED:
        return 'Fehler bei der Auswertung des Headers!';
      case FileErrorCode.INVALID_DATA_FORMAT:
        return 'Fehler beim Verarbeiten der Daten innerhalb der Datei!';
      case FileErrorCode.SOURCE_AND_TARGET_PLATE_DO_NOT_MATCH:
        return 'Die Source Platte und Target Platte stimmen nicht überein!';
      case FileErrorCode.BATCHES_NOT_AS_EXPECTED:
        return 'Die Chargen entsprechen nicht der Erwartung!';
      case FileErrorCode.PLATE_COUNT_NOT_AS_EXPECTED:
        return 'Plattenanzahl entspricht nicht der Erwartung!';
      case FileErrorCode.UNEXPECTED_FILE_FORMAT_GIVEN:
        return 'Das Dateiformat ist nicht wie erwartet!';
      case FileErrorCode.UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_SWAB:
        return 'Es wurde die Methode \"DNA_Extraction_from_Buccal_Swabs\" erwartet!';
      case FileErrorCode.UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_SWAB_LYSIS:
        return 'Es wurde die Methode \"DNA_Extraction_from_Buccal_Swabs-Lysis\" erwartet!';
      case FileErrorCode.UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_QUANTIFICATION_FIRST:
        return 'Es wurde die Methode \"Quantifizierung_Vorbereitung_1\" erwartet!';
      case FileErrorCode.UNEXPECTED_FILE_FORMAT_GIVEN_EXPECTING_TARGET_PCR:
        return 'Es wurde die Methode \"Target_PCR_Setup\" erwartet!';
      case FileErrorCode.WRONG_WORKFLOW_STEP_GIVEN:
        return 'Datei kann nicht dem aktuellen Workflow Schritt zugeordnet werden!';
      case FileErrorCode.WORKFLOW_NUMBER_MISSING:
        return 'Workflow Nummer fehlt oder ist nicht lesbar!';
      case FileErrorCode.WORKFLOW_NUMBER_FORMAT_EXCEPTION:
        return 'Workflow Nummer fehlt oder ist nicht lesbar!';
      case FileErrorCode.WORKFLOW_NUMBER_NOT_MATCHING_EXCEPTION:
        return 'Die Workflow Nummer unterscheidet sich zwischen Dateinamen und Dateiinhalt!';
      case FileErrorCode.WORKFLOW_NUMBER_NOT_FOUND:
        return 'Datei kann keinem Workflow zugeordnet werden!';
      case FileErrorCode.IO_EXCEPTION:
        return 'Problem beim Öffnen/Verschieben der Datei!';
      case FileErrorCode.PATH_ERROR:
        return 'Fehler beim Öffnen des Pfades!';
    }
  }

  export function getEnumByValue(value: string): FileErrorCode {
    return EnumUtils.getEnumByValue<FileErrorCode>(FileErrorCode, 'string', value);
  }

  export function getAll(): FileErrorCode[] {
    return EnumUtils.getAll<FileErrorCode>(FileErrorCode, 'string');
  }

}
