import {SelectItem} from "../models/select-item.model";
import {EnumUtils} from "./EnumUtils";

export enum CyclerRunThroughState {
  OK = 'OK',
  NOT_OK = 'NOT_OK'
}

export namespace CyclerRunThroughState {

  export function getCyclerStateReadable(cyclerState: CyclerRunThroughState): string {
    if (!cyclerState) {
      return '';
    }
    switch (cyclerState) {
      case CyclerRunThroughState.OK:
        return 'Erfolgreich';
      case CyclerRunThroughState.NOT_OK:
        return 'Fehler';
      default: {
        return '';
      }
    }
  }

  export function getAll(): CyclerRunThroughState[] {
    return EnumUtils.getAll<CyclerRunThroughState>(CyclerRunThroughState, 'string');
  }


  export function getAllAsSelectItem(): SelectItem[] {
    return CyclerRunThroughState.getAll().map(cs => ({id: cs, name: CyclerRunThroughState.getCyclerStateReadable(cs)}));
  }
}
