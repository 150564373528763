import {Injectable, NgZone} from "@angular/core";
import {Globals} from "../global/globals";
import {Subject} from "rxjs";
import {AuthenticationService} from "./authentication.service";
import {SseEventModel} from "../models/sse-event.model";
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: "root"
})
export class SseService {

  private readonly baseUri: string;

  private sse: EventSource;

  private sseUUID: string;

  private reconnectTrySecond: number = 1;

  sseEvent$: Subject<SseEventModel> = new Subject<SseEventModel>();

  constructor(private globals: Globals,
              private authService: AuthenticationService,
              private zone: NgZone) {
    this.baseUri = `${this.globals.backendUri}/sse-events`;
  }

  public connectToSse(): void {
    this.sseUUID = uuid();
    this.sse = new EventSource(`${this.baseUri}/register?ACCESS_TOKEN=${this.authService.getToken()}&tabId=${this.sseUUID}`);
    this.sse.onmessage = (event) => {
      if (event.data) {
        const sseEvent = JSON.parse(event.data) as SseEventModel;
        this.zone.run(() => this.sseEvent$.next(sseEvent));
      }
    }

    // Firefox is not try to reconnection automatically like Chrome
    const isFirefox = navigator.userAgent.indexOf("Firefox") > -1;
    if (isFirefox) {
      this.calculateReconnectSecond();
      // on sse error try to reconnect again
      this.sse.onerror = () => setTimeout(() => {
        this.disconnect();
        this.connectToSse();
      }, this.reconnectTrySecond * 1000);
    }
  }

  public unloadSse(): void {
    this.disconnect();
    const removeSse = new EventSource(`${this.baseUri}/remove?ACCESS_TOKEN=${this.authService.getToken()}&tabId=${this.sseUUID}`);
    removeSse.onopen = () => removeSse.close();
  }

  public disconnect(): void {
    if (this.sse) {
      this.sse.close();
    }
  }

  private calculateReconnectSecond(): void {
    if (this.reconnectTrySecond < 60) {
      this.reconnectTrySecond *= 2;
    }
  }

}
