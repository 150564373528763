import {PageableResponseModel} from "./pageable-response.model";
import {BatchModel} from "../batch/batch.model";

export class BatchPageModel extends PageableResponseModel<BatchModel> {

  static fromData(data: PageableResponseModel<BatchModel>): BatchPageModel {
    const page = new BatchPageModel();
    page.mapFieldFromData(data);
    page.content = data.content.map(BatchModel.fromData);
    return page;
  }

}
