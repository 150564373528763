import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {BatchModel} from "../../batch/batch.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {WorkflowStepQuantificationFirstModel} from "../workflow-step-quantification-first.model";

export class WorkflowInstanceStepQuantificationFirstEditModel extends WorkflowInstanceStepEditModel {

  batches: BatchModel[];

  static initFromStep(step: WorkflowStepQuantificationFirstModel): WorkflowInstanceStepEditModel {
    const ret = new WorkflowInstanceStepQuantificationFirstEditModel();
    ret.workflowStep = WorkflowStep.QUANTIFICATION_FIRST;
    ret.workflowInstanceStepId = step.id;
    ret.batches = step.batches;
    return ret;
  }

}
