import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {BatchModel} from "../../batch/batch.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {WorkflowStepLibraryQubitModel} from "../workflow-step-library-qubit.model";
import {WorkflowStepLibraryPoolingModel} from "../workflow-step-library-pooling.model";

export class WorkflowInstanceStepLibraryPoolingEditModel extends WorkflowInstanceStepEditModel {

  indexPoolsCombined: boolean;

  static initFromStep(step: WorkflowStepLibraryPoolingModel): WorkflowInstanceStepLibraryPoolingEditModel {
    const ret = new WorkflowInstanceStepLibraryPoolingEditModel();
    ret.workflowStep = WorkflowStep.QUALITY_ASSURANCE_QUBIT;
    ret.workflowInstanceStepId = step.id;
    ret.indexPoolsCombined = step.indexPoolsCombined;
    return ret;
  }

}
