import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {BatchModel} from "../../batch/batch.model";
import {WorkflowStepDnaExtractionModel} from "../workflow-step-dna-extraction.model";

export class WorkflowInstanceStepDnaExtractionEditModel extends WorkflowInstanceStepEditModel {

  batches: BatchModel[];

  static initFromStep(step: WorkflowStepDnaExtractionModel): WorkflowInstanceStepEditModel {
    const ret = new WorkflowInstanceStepDnaExtractionEditModel();
    ret.workflowStep = WorkflowStep.DNA_EXTRACTION_SWAB;
    ret.workflowInstanceStepId = step.id;
    ret.batches = step.batches;
    return ret;
  }

}
