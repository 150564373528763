import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StateUpdateWithCommentDialog} from './state-update-with-comment-dialog.component';
import {DialogModule} from "primeng/dialog";
import {InputModule} from "../../input/input.module";
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
  declarations: [
    StateUpdateWithCommentDialog
  ],
  exports: [
    StateUpdateWithCommentDialog
  ],
  imports: [
    CommonModule,
    DialogModule,
    InputModule,
    ReactiveFormsModule
  ]
})
export class StateUpdateWithCommentDialogModule {
}
