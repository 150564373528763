import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {Observable} from "rxjs";
import {PrinterState} from "../enums/PrinterState";

@Injectable({
  providedIn: 'root'
})
export class WorkflowInstanceStepPreparationService {

  private readonly baseUri: string;

  constructor(private http: HttpClient,
              private globals: Globals) {
    this.baseUri = this.globals.backendUri + '/workflow-instance-steps/preparation'
  }

  printTicket(workflowInstanceStepId: number): Observable<PrinterState> {
    return this.http.get<PrinterState>(`${this.baseUri}/reprint-ticket/` + workflowInstanceStepId);
  }

}
