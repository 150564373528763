import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {WorkflowStepAddingChecksModel} from "../workflow-step-adding-checks.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";

export class WorkflowInstanceStepAddingChecksEditModel extends WorkflowInstanceStepEditModel {

  batches: { plateId: number; id: number }[];

  static initFromStep(step: WorkflowStepAddingChecksModel): WorkflowInstanceStepEditModel {
    const ret = new WorkflowInstanceStepAddingChecksEditModel();
    ret.workflowStep = WorkflowStep.ADDING_CHECKS;
    ret.workflowInstanceStepId = step.id;
    ret.batches = step.plates.map(p => ({id: p.positiveProbe?.id, plateId: p.id}));
    return ret;
  }

}
