import {Pipe, PipeTransform} from "@angular/core";
import {IWorkflowStepBase} from "../models/workflow-step/workflow-step-base.model";
import {WorkflowStepPreparationModel} from "../models/workflow-step/workflow-step-preparation.model";
import {WorkflowStepSampleSelectionModel} from "../models/workflow-step/workflow-step-sample-selection.model";
import {WorkflowStepDnaExtractionModel} from "../models/workflow-step/workflow-step-dna-extraction.model";
import {WorkFlowStepTargetPcrModel} from "../models/workflow-step/work-flow-step-target-pcr.model";
import {WorkflowStepAddingChecksModel} from "../models/workflow-step/workflow-step-adding-checks.model";
import {WorkflowStepQuantificationFirstModel} from "../models/workflow-step/workflow-step-quantification-first.model";
import {
  WorkflowStepMeasurementOnGlomaxFirstModel
} from "../models/workflow-step/workflow-step-measurement-on-glomax-first.model";
import {
  WorkflowStepNormalisationPreparationModel
} from "../models/workflow-step/workflow-step-normalisation-preparation.model";
import {WorkflowStepNormalisation} from "../models/workflow-step/workflow-step-normalization.model";
import {WorkflowStepTargetPoolingModel} from "../models/workflow-step/workflow-step-target-pooling.model";
import {WorkflowStepPurificationModel} from "../models/workflow-step/workflow-step-purification.model";
import {WorkflowStepIndexPcrSetupModel} from "../models/workflow-step/workflow-step-index-pcr-setup.model";
import {
  WorkflowStepLibraryPlateSelectionModel
} from "../models/workflow-step/workflow-step-library-plate-selection.model";
import {WorkflowStepQuantificationSecondModel} from "../models/workflow-step/workflow-step-quantification-second.model";
import {
  WorkflowStepMeasurementOnGlomaxSecondModel
} from "../models/workflow-step/workflow-step-measurement-on-glomax-second.model";
import {
  WorkflowStepLibraryNormalisationAndPooling
} from "../models/workflow-step/workflow-step-library-normalisation-and-pooling";
import {WorkflowStepLibraryAgilantModel} from "../models/workflow-step/workflow-step-library-agilant.model";
import {WorkflowStepLibraryQubitModel} from "../models/workflow-step/workflow-step-library-qubit.model";
import {
  WorkflowStepLibraryNgsSequencingModel
} from "../models/workflow-step/workflow-step-library-ngs-sequencing.model";
import {
  WorkflowStepLibraryPostSequencingModel
} from "../models/workflow-step/workflow-step-library-post-sequencing.model";
import {
  WorkflowStepDnaExtractionSwabLysisModel
} from "../models/workflow-step/workflow-step-dna-extraction-swab-lysis.model";
import {WorkflowStepFinishedModel} from "../models/workflow-step/workflow-step-finished.model";
import {
  WorkflowInstanceStepLibraryIndexPurificationEditModel
} from "../models/workflow-step/edit/workflow-instance-step-library-index-purification-edit.model";
import {
  WorkflowStepLibraryIndexPurificationModel
} from "../models/workflow-step/workflow-step-library-index-purification.model";
import {WorkflowStepLibraryPoolingModel} from "../models/workflow-step/workflow-step-library-pooling.model";

@Pipe({
  name: 'workflowStepCast'
})
export class WorkflowStepCastPipe implements PipeTransform {

  transform(workflowStepBase: IWorkflowStepBase): any {
    if (workflowStepBase instanceof WorkflowStepPreparationModel) {
      return workflowStepBase as WorkflowStepPreparationModel;
    } else if (workflowStepBase instanceof WorkflowStepSampleSelectionModel) {
      return workflowStepBase as WorkflowStepSampleSelectionModel;
    } else if (workflowStepBase instanceof WorkflowStepDnaExtractionModel) {
      return workflowStepBase as WorkflowStepDnaExtractionModel;
    } else if (workflowStepBase instanceof WorkflowStepDnaExtractionSwabLysisModel) {
      return workflowStepBase as WorkflowStepDnaExtractionSwabLysisModel;
    } else if (workflowStepBase instanceof WorkflowStepAddingChecksModel) {
      return workflowStepBase as WorkflowStepAddingChecksModel;
    } else if (workflowStepBase instanceof WorkFlowStepTargetPcrModel) {
      return workflowStepBase as WorkFlowStepTargetPcrModel;
    } else if (workflowStepBase instanceof WorkflowStepQuantificationFirstModel) {
      return workflowStepBase as WorkflowStepQuantificationFirstModel;
    } else if (workflowStepBase instanceof WorkflowStepMeasurementOnGlomaxFirstModel) {
      return workflowStepBase as WorkflowStepMeasurementOnGlomaxFirstModel;
    } else if (workflowStepBase instanceof  WorkflowStepNormalisationPreparationModel) {
      return workflowStepBase as WorkflowStepNormalisationPreparationModel;
    } else if (workflowStepBase instanceof WorkflowStepNormalisation) {
      return workflowStepBase as WorkflowStepNormalisation;
    } else if (workflowStepBase instanceof WorkflowStepTargetPoolingModel) {
      return workflowStepBase as WorkflowStepTargetPoolingModel;
    } else if (workflowStepBase instanceof WorkflowStepPurificationModel) {
      return workflowStepBase as WorkflowStepPurificationModel;
    } else if (workflowStepBase instanceof WorkflowStepIndexPcrSetupModel) {
      return workflowStepBase as WorkflowStepIndexPcrSetupModel;
    } else if (workflowStepBase instanceof  WorkflowStepLibraryPlateSelectionModel) {
      return workflowStepBase as WorkflowStepLibraryPlateSelectionModel;
    } else if (workflowStepBase instanceof WorkflowStepQuantificationSecondModel) {
      return workflowStepBase as WorkflowStepQuantificationSecondModel;
    } else if (workflowStepBase instanceof WorkflowStepMeasurementOnGlomaxSecondModel) {
      return workflowStepBase as WorkflowStepMeasurementOnGlomaxSecondModel;
    } else if (workflowStepBase instanceof WorkflowStepLibraryNormalisationAndPooling) {
      return workflowStepBase as WorkflowStepLibraryNormalisationAndPooling;
    } else if (workflowStepBase instanceof WorkflowStepLibraryIndexPurificationModel) {
      return workflowStepBase as WorkflowStepLibraryIndexPurificationModel;
    } else if (workflowStepBase instanceof WorkflowStepLibraryAgilantModel) {
      return workflowStepBase as WorkflowStepLibraryAgilantModel;
    } else if (workflowStepBase instanceof WorkflowStepLibraryQubitModel) {
      return workflowStepBase as WorkflowStepLibraryQubitModel;
    } else if (workflowStepBase instanceof WorkflowStepLibraryPoolingModel) {
      return workflowStepBase as WorkflowStepLibraryPoolingModel;
    } else if (workflowStepBase instanceof WorkflowStepLibraryNgsSequencingModel) {
      return workflowStepBase as WorkflowStepLibraryNgsSequencingModel;
    } else if (workflowStepBase instanceof WorkflowStepLibraryPostSequencingModel) {
      return workflowStepBase as WorkflowStepLibraryPostSequencingModel;
    } else if (workflowStepBase instanceof WorkflowStepFinishedModel) {
      return workflowStepBase as WorkflowStepFinishedModel;
    }

    throw Error('unknown class type');
  }

}
