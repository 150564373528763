import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Globals} from "../global/globals";
import {firstValueFrom, map, Observable} from "rxjs";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileImage,
  faFilePdf,
  faFileVideo
} from '@fortawesome/free-regular-svg-icons';
import {FileType} from "../enums/FileType";
import {FileRecord} from "../models/file/FileRecord";


@Injectable({
  providedIn: 'root'
})
export class FileService {

  private readonly baseUri: string;

  constructor(private http: HttpClient,
              private globals: Globals) {
    this.baseUri = this.globals.backendUri + '/files';
  }

  uploadFiles(fileType: FileType, newFiles: FormData): Observable<FileRecord[]> {
    return this.http.post<FileRecord[]>(`${this.baseUri}?fileType=${fileType}`, newFiles)
      .pipe(map(this.mapFileRecordArray()));
  }

  downloadFile(id: number, fileName: string): void {

    const headers = new HttpHeaders({
      'cp-noContentType': 'true'
    });

    firstValueFrom(this.http.get(this.baseUri + '/' + id, {
      headers,
      responseType: 'blob'
    })).then(resp => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = URL.createObjectURL(resp);
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(a.href);
      a.remove();
    });
  }

  private mapFileRecordArray() {
    return (files: FileRecord[]) => {
      let summary: FileRecord[] = [];

      if (files && files.length > 0) {
        summary = files.map(this.mapFileRecord());
      }

      return summary;
    };
  }


  private mapFileRecord() {
    return (f: FileRecord) => {
      let file = Object.assign(new FileRecord(), f);

      // file.created = DateUtils.convertToUTCWithTime(file.created);

      return file;
    };
  }

  getFileIconBasedOnMimeType(mimeType: string): IconDefinition {
    let fileIcon = faFileAlt;

    if (mimeType) {
      if (mimeType.includes('image')) {
        fileIcon = faFileImage;
      } else if (mimeType.includes('pdf')) {
        fileIcon = faFilePdf;
      } else if (mimeType.includes('zip') || mimeType.includes('rar')) {
        fileIcon = faFileArchive;
      } else if (mimeType.includes('audio')) {
        fileIcon = faFileAudio;
      } else if (mimeType.includes('video')) {
        fileIcon = faFileVideo;
      }
    }

    return fileIcon;
  }
}
