import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BatchModel} from "../../models/batch/batch.model";
import {FormGroup, UntypedFormGroup} from "@angular/forms";
import {BatchFilterForm, BatchFilterModel} from "../../models/batch/batch-filter.model";
import {BatchState} from "../../enums/BatchState";
import {BatchType} from "../../enums/BatchType";
import {BatchService} from "../../services/batch.service";
import {WorkflowDetailClientService} from "../dialog/workflow-detail-dialog/workflow-detail-client.service";

@Component({
  selector: 'app-batch-selector',
  templateUrl: './batch-selector.component.html',
  styleUrls: ['./batch-selector.component.scss']
})
export class BatchSelectorComponent implements OnInit {

  @Input() readOnly: boolean = false;
  @Input() showExpirationDate: boolean = true;
  @Input() form: UntypedFormGroup;
  @Output() onChange = new EventEmitter();

  type: BatchType;
  batches: BatchModel[];
  batchFilterForm: FormGroup<BatchFilterForm>;
  states: BatchState[] = [];
  isLoading: boolean = false;
  minDate: Date;
  newBatchDialogVisible: boolean = false;

  constructor(private batchService: BatchService,
              public workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.states.push(BatchState.IN_USE);
    this.type = BatchType.getEnumByValue(this.form?.get('batch').value.type);
    this.minDate = new Date();
    if (this.readOnly) {
      this.form.get('batch').disable();
    }
    this.batchFilterForm = BatchFilterModel.initFilterForm();
    this.filterBatches();
  }

  onSelectBatch(selectedBatch: BatchModel) {
    this.form.get('newExpirationDate').patchValue(selectedBatch.expirationDate);
    this.form.get('newExpirationDate').disable();
    this.onChange.emit();
  }

  onSetExpirationDate() {
    const val = this.form.get('newExpirationDate').value as Date;
    if (val?.getHours() !== 12) {
      val.setHours(12);
      this.form.get('newExpirationDate').patchValue(val);
    }
    this.onChange.emit();
  }

  filterBatches(event?: any) {
    this.isLoading = true;
    this.batchService.getAllBatchesSelectableForWorkflowInstance(
      this.workflowDetailClientService.workflowInstance?.id,
      this.type,
      event?.query)
      .subscribe({
        next: (resp: BatchModel[]) => {
          this.batches = resp;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  onNewBatchClicked() {
    this.newBatchDialogVisible = true;
  }

  createBatchDialogClosed(newBatch: BatchModel) {
    if (newBatch && newBatch.id) {
      this.form.get('batch').patchValue(newBatch);
      this.form.get('newExpirationDate').disable();
      this.onSelectBatch(newBatch);
    }
    this.newBatchDialogVisible = false;
    this.onChange.emit();
  }

}
