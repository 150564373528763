<div class="batches-container">
  <app-search-fields-container *ngIf="batchFilterForm" [formGroup]="batchFilterForm">
    <div class="batches-search">
      <!-- batch number -->
      <core-input-text
        [formControlName]="BatchFilterModel.BATCH_NUMBER"
        [showClearButton]="false"
        [removeDefaultBottomErrorMargin]="true"
        topLabel="Chargennummer">
      </core-input-text>

      <core-input-dropdown
        formControlName="type"
        id="type"
        topLabel="Typ"
        [options]="optionBatchType"
        [removeDefaultBottomErrorMargin]="true">
      </core-input-dropdown>

      <core-date-picker
        [formControlName]="BatchFilterModel.EXPIRATION_DATE_FROM"
        [maxDate]="filterExpirationDateTo"
        [showClearButton]="false"
        [removeDefaultBottomErrorMargin]="true"
        topLabel="Ablaufdatum - Von">
      </core-date-picker>

      <core-date-picker
        [formControlName]="BatchFilterModel.EXPIRATION_DATE_TO"
        [minDate]="filterExpirationDateFrom"
        [removeDefaultBottomErrorMargin]="true"
        [showClearButton]="false"
        topLabel="Ablaufdatum - Bis">
      </core-date-picker>

      <!--State-->
      <core-checkbox
        [formControlName]="BatchFilterModel.STATE"
        [removeDefaultBottomErrorMargin]="true"
        [showClearButton]="false"
        topLabel="Status"
        class="state-checkbox">

        <p-checkbox
          [formControl]="batchFilterForm.controls[BatchFilterModel.STATE]"
          [inputId]="BatchState.NEW"
          [value]="BatchState.NEW"
          (click)="batchFilterForm.markAsDirty()"
          label="Neu Angelegt"
          name="state">
        </p-checkbox>

        <p-checkbox
          [formControl]="batchFilterForm.controls[BatchFilterModel.STATE]"
          [inputId]="BatchState.IN_USE"
          [value]="BatchState.IN_USE"
          (click)="batchFilterForm.markAsDirty()"
          label="In Verwendung"
          name="state">
        </p-checkbox>

        <p-checkbox
          [formControl]="batchFilterForm.controls[BatchFilterModel.STATE]"
          [inputId]="BatchState.INACTIVE"
          [value]="BatchState.INACTIVE"
          (click)="batchFilterForm.markAsDirty()"
          label="Inaktiv"
          name="state">
        </p-checkbox>

        <p-checkbox
          [formControl]="batchFilterForm.controls[BatchFilterModel.STATE]"
          [inputId]="BatchState.EXPIRED"
          [value]="BatchState.EXPIRED"
          (click)="batchFilterForm.markAsDirty()"
          label="Abgelaufen"
          name="state">
        </p-checkbox>

        <p-checkbox
          [formControl]="batchFilterForm.controls[BatchFilterModel.STATE]"
          [inputId]="BatchState.BLOCKED"
          [value]="BatchState.BLOCKED"
          (click)="batchFilterForm.markAsDirty()"
          label="Gesperrt"
          name="state">
        </p-checkbox>
      </core-checkbox>

      <!-- empty div to shift the button -->
      <div>
        <core-button
          (onClick)="resetFilter()"
          *ngIf="showClearButton"
          class="reset-btn"
          [backgroundColor]="'#607D8B'"
          [color]="'#ffffff'"
          classList="p-button-outlined"
          text="Filter zurücksetzen">
        </core-button>
      </div>

      <core-button
        (onClick)="createNewBatch()"
        class="new-batch-btn"
        classList="p-button-success"
        text="Neue Charge erstellen">
      </core-button>
    </div>
  </app-search-fields-container>

  <div class="batches-table">
    <p-table
      (onPage)="changePage($event)"
      [(first)]="first"
      [lazy]="true"
      [paginator]="true"
      (onSort)="onSort($event)"
      [totalRecords]="totalElements"
      [columns]="batchTableColumns"
      [customSort]="true"
      [responsive]="true"
      [rows]="this.pageSize"
      [scrollHeight]="'flex'"
      [scrollable]="true"
      [value]="batches"
      [loading]="isLoading"
      responsiveLayout="scroll">
      <ng-template let-columns pTemplate="header">
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.label}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template let-columns="columns" let-rowData pTemplate="body">
        <tr>
          <td *ngFor="let col of columns">
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'approvedAt'">
                {{ rowData[col.field] | date:globals.dateFormat }}
              </ng-container>
              <ng-container *ngSwitchCase="'expirationDate'">
                {{ rowData[col.field] | date:globals.dateFormat }}
              </ng-container>
              <ng-container *ngSwitchCase="'type'">
                {{ rowData[col.field] | batchTypeReadable }}
              </ng-container>
              <ng-container *ngSwitchCase="'batchState'">
                <div style="display: flex; justify-content: space-between">
                  {{ rowData[col.field] | batchStateReadable }}
                  <fa-icon
                    *ngIf="rowData.comment !== null"
                    [icon]="faMessage" [pTooltip]="rowData.comment"
                    style="display: flex; justify-content: flex-end"
                  ></fa-icon>
                </div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{rowData[col.field]}}
              </ng-container>
            </ng-container>
          </td>
          <td>
            <div class="action-btns">
              <core-button (onClick)="showConfirmation(rowData, BatchState.BLOCKED)"
                           *ngIf="showButton(rowData, BatchState.IN_USE)"
                           classList="p-button-outlined p-button-danger"
                           text="Sperren">
              </core-button>
              <core-button (onClick)="showConfirmation(rowData, BatchState.INACTIVE)"
                           *ngIf="showButton(rowData, BatchState.IN_USE)"
                           classList="p-button-outlined p-button-warning"
                           text="Inaktiv">
              </core-button>
              <core-button (onClick)="showConfirmation(rowData, BatchState.IN_USE)"
                           *ngIf="showButton(rowData, BatchState.INACTIVE) || showButton(rowData, BatchState.BLOCKED)"
                           classList="p-button-outlined p-button-success"
                           text="Reaktivieren">
              </core-button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-state-update-with-comment-dialog
  (closeDialog)="onCloseDialog($event)"
  (onSaveChanges)="saveChanges($event)"
  [form]="form"
  [header]="header"
  [saveButtonText]="saveButtonText"
  [message]="message"
  [showStateUpdateDialog]="showConfirmDialog"
></app-state-update-with-comment-dialog>

<app-new-batch-dialog
  *ngIf="newBatchDialogVisible"
  [showDialog]="newBatchDialogVisible"
  [isCreationWithApproval]="true"
  (closeDialog)="newBatchDialogVisible = false">
</app-new-batch-dialog>

