import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceStepPreparationService} from "../../../../services/workflow-instance-step-preparation.service";
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {WorkflowStepPreparationModel} from "../../../../models/workflow-step/workflow-step-preparation.model";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {MessageService} from "../../../../services/message.service";
import {PrinterState} from "../../../../enums/PrinterState";
import {faCircle, faPrint} from '@fortawesome/free-solid-svg-icons';
import {Workflow} from "../../../../enums/Workflow";
import {PlateMode} from "../../../../enums/PlateMode";
import {PlateModel} from "../../../../models/Plate/plate.model";

@Component({
  selector: 'app-preparation',
  templateUrl: './preparation.component.html',
  styleUrls: ['./preparation.component.scss']
})
export class PreparationComponent implements OnInit {
  PlateMode = PlateMode;
  PrinterState = PrinterState;
  faCircle = faCircle;
  faPrint = faPrint;

  Workflow = Workflow;

  plates: PlateModel[] = [];

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workFlowStep: WorkflowStepPreparationModel;

  constructor(private workflowInstanceStepPreparationService: WorkflowInstanceStepPreparationService,
              private detailClientService: WorkflowDetailClientService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.detailClientService.isStepValid$.next(true);
    if (this.workflowInstance.workflow === Workflow.WORKFLOW_96) {
      this.plates = this.plates?.length > 0 ? this.plates.map(PlateModel.fromData) : new Array(1).fill(new PlateModel());
    } else if (this.workflowInstance.workflow === Workflow.WORKFLOW_384) {
      this.plates = this.plates?.length > 0 ? this.plates.map(PlateModel.fromData) : new Array(4).fill(null);
    }
  }

  reprintTicket(workflowInstanceStepId: number) {
    this.workflowInstanceStepPreparationService.printTicket(workflowInstanceStepId).subscribe({
      next: (resp) => {
        if (PrinterState.PRINTED == resp) {
          this.messageService.addSuccessMessage("Ticket wurde erfolgreich gedruckt!");
        } else {
          this.messageService.addErrorMessage("Ticket konnte nicht gedruckt werden!", PrinterState.getPrinterStateReadable(resp));
        }
        this.workFlowStep.printerState = resp;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

}
