import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {BatchModel} from "../../batch/batch.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {WorkflowStepIndexPcrSetupModel} from "../workflow-step-index-pcr-setup.model";
import {PlateModel} from "../../Plate/plate.model";

export class WorkflowInstanceStepIndexPcrSetupEditModel extends WorkflowInstanceStepEditModel {

  batches: BatchModel[];
  plates: PlateModel[]
  dnaEntered: boolean;

  static initFromStep(step: WorkflowStepIndexPcrSetupModel): WorkflowInstanceStepIndexPcrSetupEditModel {
    const ret = new WorkflowInstanceStepIndexPcrSetupEditModel();
    ret.workflowStep = WorkflowStep.INDEX_PCR;
    ret.workflowInstanceStepId = step.id;
    ret.plates = step.plates;
    ret.batches = step.batches.filter(BatchModel.isValid);
    return ret;
  }

}
