import {BatchModel} from "../models/batch/batch.model";
import {UntypedFormArray} from "@angular/forms";
import {BatchType} from "../enums/BatchType";

export class BatchUtils {

  /**
   * init a form array depends on the provided batch types
   * @param savedBatches already saved batches in the workflow step
   * @param batchTypes required batch types for workflow step
   * @param readOnly readonly option
   */
  static initBatchFormArray(savedBatches: BatchModel[], batchTypes: BatchType[], readOnly: boolean): UntypedFormArray {
    let batchModels: BatchModel[] = [];
    if (!savedBatches || !readOnly) {
      // for each batch type we need a form group in the form array
      batchTypes.forEach(type => {
        const savedBatch = savedBatches.find(b => b.type === type);
        // workflow step has already saved data for batch type -> this batch should used
        if (savedBatch) {
          batchModels.push(savedBatch);
        } else {
        // not batch for batch type found -> create a default one
          const batchModel = new BatchModel();
          batchModel.type = type;
          batchModels.push(batchModel);
        }
      });
    } else {
      batchModels = savedBatches
    }

    // init the form array
    return BatchModel.initFormArrayForBatchSelection(batchModels, readOnly, false);
  }
}
