import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {BatchModel} from "../../batch/batch.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {WorkflowStepLibraryQubitModel} from "../workflow-step-library-qubit.model";

// Inner class equivalent to Java's ConcentrationEditDto
export class ConcentrationEditModel {
  id: number;
  concentration: number;
}

export class WorkflowInstanceStepLibraryQubitEditModel extends WorkflowInstanceStepEditModel {
  batches: BatchModel[];
  concentrations: ConcentrationEditModel[]; // Update to match the Java model structure

  static initFromStep(step: WorkflowStepLibraryQubitModel): WorkflowInstanceStepLibraryQubitEditModel {
    const ret = new WorkflowInstanceStepLibraryQubitEditModel();
    ret.workflowStep = WorkflowStep.QUALITY_ASSURANCE_QUBIT;
    ret.workflowInstanceStepId = step.id;

    // Map step.concentrations to the ConcentrationEditModel structure
    ret.concentrations = step.concentrations.map(concentration => {
      return {id: concentration.id, concentration: concentration.concentration};
    });

    ret.batches = step.batches.filter(BatchModel.isValid);
    return ret;
  }

}
