import {Injectable} from "@angular/core";
import {PositionModel} from "../models/Plate/position.model";
import {map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {PositionStatusUpdateDtoModel} from "../models/Plate/position-status-update-dto.model";


@Injectable({
  providedIn: 'root'
})
export class PositionService {

  private readonly baseUri: string;

  constructor(private http: HttpClient,
              private globals: Globals) {
    this.baseUri = this.globals.backendUri + '/positions'
  }

  updatePositionState(position: PositionStatusUpdateDtoModel): Observable<PositionModel> {
    return this.http.put<PositionModel>(`${this.baseUri}`, position)
      .pipe(map(response => PositionModel.fromData(response)));
  }
}
