<app-workflow-step-layout>
  <ng-container info>
    <div class="container">
      <div class="data-container">
      <ng-container>
        <div>Check Barcodes:</div>
        <div [ngClass]="workflowStep?.checkBarcodes === TecanStatus.OK ? 'ok' : 'not-ok'"
             class="status-indicator">
        </div>
      </ng-container>
      <ng-container>
        <div>Check Run:</div>
        <div [ngClass]="workflowStep?.checkRun === TecanStatus.OK ? 'ok' : 'not-ok'"
             class="status-indicator">
        </div>
      </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container plate>
    <app-plate-view
      [plateSize]="workflowInstance.workflow"
      [plateMode]="PlateMode.PIP_STATUS"
      [workflowInstance]="workflowInstance"
      [plates]="workflowStep?.plates">
    </app-plate-view>
  </ng-container>
</app-workflow-step-layout>
