<app-workflow-step-layout>

  <ng-container info>
    <div class="library-plate-selection-info">
      <label>
        Workflow(s) wählen
      </label>

      <p-autoComplete
        #wiAutocomplete
        [disabled]="readOnly || disableInput"
        [(ngModel)]="selectedWorkflow"
        (completeMethod)="filterWorkflows($event)"
        [dropdown]="true"
        [forceSelection]="true"
        [suggestions]="workflowSuggestions"
        (onSelect)="onWorkflowSelected($event)"
        appendTo="body"
        [style]="{width: '100%'}">
        <ng-template let-workflow pTemplate="item">
          <ng-container *ngIf="workflow.workflow === Workflow.WORKFLOW_96">
            {{ workflow.workflowNumber + '_' + workflow.indexPrimerPlate }}
          </ng-container>
          <ng-container *ngIf="workflow.workflow === Workflow.WORKFLOW_384">
            {{ workflow.workflowNumber }}
          </ng-container>
        </ng-template>
      </p-autoComplete>

      <core-button
        class="rest-btn"
        classList="p-button-secondary"
        [disabled]="readOnly"
        [width]="'100%'"
        (onClick)="resetSelection()"
        [text]="'Zurücksetzen'">
      </core-button>

      <hr>

      <label>
        Workflownummer übernehmen von:
      </label>

      <p-autoComplete
        [disabled]="readOnly || disableInput"
        [(ngModel)]="workflowStep.takeOverWorkflowInstance"
        (completeMethod)="initWorkflowNumberTakeOverList()"
        [dropdown]="true"
        [forceSelection]="true"
        [showClear]="true"
        (onClear)="onClearTakeOverworkflow()"
        [suggestions]="workflowNumberTakeOverList"
        appendTo="body"
        field="workflowNumber"
        [style]="{width: '100%'}">
      </p-autoComplete>
    </div>
  </ng-container>

  <ng-container plate>
    <div class="library-plate-selection-list-container">
      <ng-container *ngIf="workflowInstance.workflow === Workflow.LIBRARY_96">
        <div *ngFor="let plate of maxPlateCount; let i = index" class="library-plate-selection-plate-container">
          <app-plate
            [ignoreHeightBoundary]="true"
            [workflow]="Workflow.WORKFLOW_96"
            [showNameLink]="true"
            [nameLink]="getSelectedWorkflowByIndex(i)"
            (onNameLinkClick)="navigateToWorkFlow($event, i)">
          </app-plate>
        </div>
      </ng-container>
      <ng-container *ngIf="workflowInstance.workflow === Workflow.LIBRARY_384">
        <div *ngFor="let plate of maxPlateCount; let i = index" class="library-plate-selection-plate-container">
          <app-plate
            [workflow]="Workflow.WORKFLOW_96"
            [showNameLink]="true"
            [nameLink]="getSelectedWorkflowByIndexForLibrary(i)"
            (onNameLinkClick)="navigateToWorkFlow($event, 0)">
          </app-plate>
        </div>
      </ng-container>
    </div>
  </ng-container>

</app-workflow-step-layout>
