export class CyclerModel {

  id: number;
  version: number;
  name: string;

  static fromData(data: CyclerModel): CyclerModel {
    return Object.assign(new CyclerModel(), data);
  }

}
