import {Component, EventEmitter, Input, Output} from '@angular/core';
import { faPlus } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-plus-button',
  template: `
    <div (click)="onClick()" class="plus-button-container" [ngClass]="{'disabled': disabled}" [pTooltip]="tooltip">
      <fa-icon [icon]="faPlus"></fa-icon>
    </div>
  `,
  styleUrls: ['./plus-button.component.scss']
})
export class PlusButtonComponent {

  @Input() disabled: boolean = false;
  @Input() tooltip: string;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  faPlus = faPlus;

  onClick(): void {
    if (!this.disabled) {
      this.clicked.emit();
    }
  }

}
