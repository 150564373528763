import {PlateModel} from "./plate.model";
import {TecanStatus} from "../../enums/TecanStatus";

export class DetailedPlateModel {

  id: number;
  water: number;
  plate: PlateModel;
  checkRun: TecanStatus;

  static fromData(data: DetailedPlateModel): DetailedPlateModel {
    const ret = new DetailedPlateModel();
    ret.id = data.id;
    ret.water = data.water;
    ret.checkRun = data.checkRun
    ret.plate = PlateModel.fromData(data.plate);
    return ret;
  }

}
