import {EnumUtils} from './EnumUtils';
import {SelectItem} from "../models/select-item.model";

export enum WorkflowStep {
  // workflow 96 and 384
  PREPARATION = 'PREPARATION',
  SAMPLE_SELECTION = 'SAMPLE_SELECTION',
  DNA_EXTRACTION_SWAB_LYSIS = 'DNA_EXTRACTION_SWAB_LYSIS',
  DNA_EXTRACTION_SWAB = 'DNA_EXTRACTION_SWAB',
  ADDING_CHECKS = 'ADDING_CHECKS',
  QUANTIFICATION_FIRST = 'QUANTIFICATION_FIRST',
  MEASUREMENT_GLOMAX_FIRST = 'MEASUREMENT_GLOMAX_FIRST',
  NORMALIZATION = 'NORMALIZATION',
  NORMALIZATION_PREPARATION = 'NORMALIZATION_PREPARATION',
  TARGET_PCR = 'TARGET_PCR',
  TARGET_POOLING = 'TARGET_POOLING',
  PURIFICATION = 'PURIFICATION',
  INDEX_PCR = 'INDEX_PCR',
  QUANTIFICATION_SECOND = 'QUANTIFICATION_SECOND',
  MEASUREMENT_GLOMAX_SECOND = 'MEASUREMENT_GLOMAX_SECOND',

  // library
  WORKFLOW_SELECTION = 'WORKFLOW_SELECTION',
  NORMALIZATION_AND_POOLING = 'NORMALIZATION_AND_POOLING',
  INDEX_PURIFICATION = 'INDEX_PURIFICATION',
  QUALITY_ASSURANCE_AGILENT = 'QUALITY_ASSURANCE_AGILENT',
  QUALITY_ASSURANCE_QUBIT = 'QUALITY_ASSURANCE_QUBIT',
  POOLING = 'POOLING',
  NGS_SEQUENCING = 'NGS_SEQUENCING',
  POST_SEQUENCING = 'POST_SEQUENCING',

  // all workflows
  FINISHED = 'FINISHED',
  ABORTED = 'ABORTED'
}

export namespace WorkflowStep {

  export function getWorkFlowStepReadable(workflowEnum: WorkflowStep): string {
    if (!workflowEnum) {
      return '';
    }
    switch (workflowEnum) {
      case WorkflowStep.PREPARATION:
        return 'Vorbereitung';
      case WorkflowStep.SAMPLE_SELECTION:
        return 'Probenauswahl';
      case WorkflowStep.DNA_EXTRACTION_SWAB:
        return 'DNA-Extraktion';
      case WorkflowStep.DNA_EXTRACTION_SWAB_LYSIS:
        return 'DNA-Extraktion Swab Lysis';
      case WorkflowStep.ADDING_CHECKS:
        return 'Hinzufügen der Kontrollen';
      case WorkflowStep.QUANTIFICATION_FIRST:
        return 'Quantifizierung Vorbereitung (1)';
      case WorkflowStep.MEASUREMENT_GLOMAX_FIRST:
        return 'Messung am Glomax (1)';
      case WorkflowStep.NORMALIZATION_PREPARATION:
      case WorkflowStep.NORMALIZATION:
        return 'Normalisierung für Target PCR';
      case WorkflowStep.TARGET_PCR:
        return 'Target PCR Setup';
      case WorkflowStep.TARGET_POOLING:
        return 'Target Pooling';
      case WorkflowStep.PURIFICATION:
        return 'Target Aufreinigung';
      case WorkflowStep.INDEX_PCR:
        return 'Index PCR Setup';
      case WorkflowStep.QUANTIFICATION_SECOND:
        return 'Quantifizierung Vorbereitung (2)';
      case WorkflowStep.MEASUREMENT_GLOMAX_SECOND:
        return 'Messung am Glomax (2)';
      case WorkflowStep.WORKFLOW_SELECTION:
        return 'Platten Selektion';
      case WorkflowStep.NORMALIZATION_AND_POOLING:
        return 'Normalisierung und Pooling: Integra';
      case WorkflowStep.INDEX_PURIFICATION:
        return 'Index Aufreinigung';
      case WorkflowStep.QUALITY_ASSURANCE_AGILENT:
        return 'Qualitätskontrolle Agilent';
      case WorkflowStep.QUALITY_ASSURANCE_QUBIT:
        return 'Quantitätskontrolle Qubit';
      case WorkflowStep.POOLING:
        return 'Pooling der Pools';
      case WorkflowStep.NGS_SEQUENCING:
        return 'Sequenzierung';
      case WorkflowStep.POST_SEQUENCING:
        return 'Post Sequenzierung';
      case WorkflowStep.FINISHED:
        return 'Abgeschlossen';
      case WorkflowStep.ABORTED:
        return 'Abgebrochen';
      default:
        return '';
    }
  }

  // returns all steps which should be shown in the dashboard for the workflow 96 and 384
  export function getAllForDashboardInWorkflow96And384(): WorkflowStep[][] {
    return [
      [WorkflowStep.PREPARATION],
      [WorkflowStep.SAMPLE_SELECTION],
      [WorkflowStep.DNA_EXTRACTION_SWAB_LYSIS, WorkflowStep.DNA_EXTRACTION_SWAB],
      [WorkflowStep.ADDING_CHECKS],
      [WorkflowStep.QUANTIFICATION_FIRST],
      [WorkflowStep.MEASUREMENT_GLOMAX_FIRST],
      [WorkflowStep.NORMALIZATION_PREPARATION, WorkflowStep.NORMALIZATION],
      [WorkflowStep.TARGET_PCR],
      [WorkflowStep.TARGET_POOLING],
      [WorkflowStep.PURIFICATION],
      [WorkflowStep.INDEX_PCR],
      [WorkflowStep.QUANTIFICATION_SECOND],
      [WorkflowStep.MEASUREMENT_GLOMAX_SECOND]
    ];
  }

  // returns all steps which should be shown in the dashboard for the library
  export function getAllForDashboardInLibrary(): WorkflowStep[][] {
    return [
      [WorkflowStep.WORKFLOW_SELECTION],
      [WorkflowStep.NORMALIZATION_AND_POOLING],
      [WorkflowStep.INDEX_PURIFICATION],
      [WorkflowStep.QUALITY_ASSURANCE_AGILENT],
      [WorkflowStep.QUALITY_ASSURANCE_QUBIT],
      [WorkflowStep.POOLING],
      [WorkflowStep.NGS_SEQUENCING],
      [WorkflowStep.POST_SEQUENCING]
    ];
  }

  export function getAll(): WorkflowStep[] {
    return EnumUtils.getAll<WorkflowStep>(WorkflowStep, 'string');
  }

  export function getEnumByValue(value: string): WorkflowStep {
    return EnumUtils.getEnumByValue<WorkflowStep>(WorkflowStep, 'string', value);
  }

  export function getAllAsSelectItem(): SelectItem[] {
    return WorkflowStep.getAll().map(workflowStep => ({
      id: workflowStep,
      name: WorkflowStep.getWorkFlowStepReadable(workflowStep)
    }));
  }

}
