import {ElementRef} from '@angular/core';

export class TablePaginationUtils {

  static calculateNumberOfRowsForTableBasedOnParentDivSizeAndFetchData(
    div: ElementRef,
    onSearch: (paginationSize: number) => void,
    defaultHeaderSize: number = 40,
    defaultFooterSize: number = 40,
    defaultRowHeight: number = 40) {
    setTimeout(() => {
      const tableHeight = div.nativeElement.clientHeight;
      let paginationSize = Math.floor((tableHeight - defaultHeaderSize - defaultFooterSize) / defaultRowHeight);
      if (paginationSize <= 1) paginationSize = 1;
      onSearch(paginationSize);
    }, 1);
  }

}
