import {Pipe, PipeTransform} from '@angular/core';
import {BatchState, getBatchStateReadable} from "../enums/BatchState";

@Pipe({
  name: 'batchStateReadable'
})
export class BatchStateReadablePipe implements PipeTransform {

  transform(value: BatchState): string {
    return getBatchStateReadable(value);
  }

}
