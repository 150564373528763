/* eslint-disable @typescript-eslint/naming-convention */
export enum Role {
  HLAUSER = 'ROLE_S_APP_BSZ_HLA_USER',
  HLAADMIN = 'ROLE_S_APP_BSZ_HLA_ADMIN'
}

export function getUserRoleReadable(role: Role): string {
  if (!role) {
    return '';
  }

  switch (role) {
    case Role.HLAUSER:
      return 'Benutzer';
    case Role.HLAADMIN:
      return 'Admin';

  }
}
