<label *ngIf="topLabel" [for]="id" class="core_input_top_label">{{topLabel}}</label>
<div class="core-input-label-container">
  <label *ngIf="label" [ngClass]="required ? 'required' : ''">{{label}}</label>
  <div class="p-inputgroup core-input-container core_input_flex_between">
    <div class="core-input-container-info-icon" *ngIf="infoLabel">
      <fa-icon
        [pTooltip]="infoLabel"
        *ngIf="infoLabel"
        [icon]="faInfoCircle">
      </fa-icon>
    </div>
    <input
      (keyup.enter)="onKeyUpEnter.emit(value)"
      (ngModelChange)="valueChanged($event)"
      [disabled]="disabled"
      [readonly]="readonly"
      [id]="id"
      [ngClass]="control.touched && control.errors ? 'core_input_invalid' : ''"
      [ngModel]="value"
      [placeholder]="placeholder"
      [style]="{width: '100%'}"
      class="core-input"
      pInputText
      type="text">
    <button
      (click)="onRevertPressed()"
      *ngIf="showRevertButton"
      [disabled]="disabled"
      class="core-input-button-clear p-inputgroup-addon"
      icon="pi pi-replay"
      pButton
      tabindex="-1">
    </button>
    <button
      (click)="onClear()"
      *ngIf="showClearButton"
      [disabled]="disabled"
      class="p-button-secondary core-input-button-clear p-inputgroup-addon"
      icon="pi pi-times"
      pButton
      tabindex="-1">
    </button>
  </div>
</div>
<div
  *ngIf="!removeDefaultBottomErrorMargin"
  [ngClass]="[label ? 'core_input_error_container_with_label' : '', removeDefaultBottomErrorMargin ? '' : 'core_input_error_container_min_height']"
  class="core_input_error">
  <small *ngIf="control.touched && control.errors?.required">
    Dieses Feld ist erforderlich!
  </small>
  <small *ngIf="control.touched && control.errors?.minlength">
    Dieses Feld muss mindestens {{control.errors.minlength.requiredLength}} Zeichen lang sein!
  </small>
  <small *ngIf="control.touched && control.errors?.maxlength">
    Dieses Feld darf maximal {{control.errors.maxlength.requiredLength}} Zeichen lang sein!
  </small>
</div>
