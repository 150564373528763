import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkflowInstanceStepCardComponent} from './workflow-instance-step-card.component';
import {CustomPipeModule} from "../../pipes/custom-pipe.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";


@NgModule({
  declarations: [
    WorkflowInstanceStepCardComponent
  ],
  exports: [
    WorkflowInstanceStepCardComponent
  ],
    imports: [
        CommonModule,
        CustomPipeModule,
        FontAwesomeModule
    ]
})
export class WorkflowInstanceStepCardModule {
}
