<div class="menu-bar">

  <b (click)="onRedirectToDashboard()" class="menu-logo">HLA</b>

  <div class="menu-navigation-container">
    <div *ngFor="let menu of headerMenuItems"
         [routerLinkActive]="'menu-item-active'"
         [routerLink]="menu.path"
         class="menu-item">
      <div>{{ menu.name }}</div>
    </div>
  </div>

  <div class="header-file-processing-history-icon" (click)="onShowFileProcessingHistory()">
    <fa-icon [icon]="faFileImport"></fa-icon>
    <span *ngIf="countOfUnconfirmedFileErrors > 0" class="badge">{{countOfUnconfirmedFileErrors > 9 ? '9+' : countOfUnconfirmedFileErrors}}</span>
  </div>


  <!-- used for border -->
  <div class="border"></div>

  <div class="menu-logout">
    <div class="menu-username-with-icon">
      <fa-icon [icon]="faUser"></fa-icon>
      <div>{{ userName + ' ( ' + userRoleReadable + ' )'}}</div>
    </div>
    <div (click)="onLogout()" class="menu-item">Logout</div>
  </div>
</div>

<p-dialog
  [(visible)]="showFileProcessingDialog"
  [closable]="true"
  header="Abgeschlossene Dateiverarbeitungen"
  [modal]="true">
  <div class="file-processing-dialog-container">
    <app-file-processing-history
      *ngIf="showFileProcessingDialog"
    (onConfirmPressed)="getCountOfUnconfirmedFileErrors()">
    </app-file-processing-history>
  </div>
</p-dialog>
