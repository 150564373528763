import {WorkflowInstanceStepEditModel} from "./workflow-instance-step-edit.model";
import {WorkflowStepMeasurementOnGlomaxFirstModel} from "../workflow-step-measurement-on-glomax-first.model";
import {WorkflowStep} from "../../../enums/WorkflowStep";
import {BatchModel} from "../../batch/batch.model";

export class WorkflowInstanceStepMeasurementOnGlomaxFirstEditModel extends WorkflowInstanceStepEditModel {

  batches: BatchModel[];

  static initFromStep(step: WorkflowStepMeasurementOnGlomaxFirstModel): WorkflowInstanceStepMeasurementOnGlomaxFirstEditModel {
    const ret = new WorkflowInstanceStepMeasurementOnGlomaxFirstEditModel();
    ret.workflowStep = WorkflowStep.MEASUREMENT_GLOMAX_FIRST;
    ret.workflowInstanceStepId = step.id;
    ret.batches = step.batches.filter(BatchModel.isValid);
    return ret;
  }
}
