export enum PositionStatus {
  OK = 'OK',
  NOT_OK = 'NOT_OK'
}

export function getPositionStatusReadable(status: PositionStatus): string {
  if (!status) return '';

  switch (status) {
    case PositionStatus.OK:
      return 'OK';
    case PositionStatus.NOT_OK:
      return 'Nicht OK';
  }
}
