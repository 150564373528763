import {NgModule} from '@angular/core';
import {WorkflowInstanceFilterPipe} from './workflow-instance-filter.pipe';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {BatchStateReadablePipe} from './batch-state-readable.pipe';
import {PositionStateReadablePipe} from "./position-state-readable.pipe";
import {PositionYToLetterPipe} from "./position-y-to-letter.pipe";
import {WorkflowStepReadablePipe} from './workflow-step-readable.pipe';
import {WorkflowStepCastPipe} from './workflow-step-cast.pipe';
import {CrtStateReadablePipe} from './crt-state-readable.pipe';
import {PathTypeReadablePipe} from './path-type-readable.pipe';
import {TruncatePipe} from "./truncate.pipe";
import {BatchTypeReadablePipe} from './batch-type-readable.pipe';
import {WorkflowNamePipe} from './workflow-name.pipe';
import {PlateSizePipe} from './plate-size.pipe';
import {WorkflowReadablePipe} from "./workflow-readable.pipe";
import {IsWorkflowStepSkippedPipe} from './is-workflow-step-skipped.pipe';
import {PcrTubeSizePipe} from "./pcr-tube-size.pipe";

@NgModule({
  declarations: [
    WorkflowInstanceFilterPipe,
    BatchStateReadablePipe,
    PositionStateReadablePipe,
    PositionYToLetterPipe,
    WorkflowStepReadablePipe,
    WorkflowStepCastPipe,
    CrtStateReadablePipe,
    PathTypeReadablePipe,
    TruncatePipe,
    BatchTypeReadablePipe,
    WorkflowNamePipe,
    PlateSizePipe,
    PcrTubeSizePipe,
    WorkflowReadablePipe,
    IsWorkflowStepSkippedPipe
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    WorkflowInstanceFilterPipe,
    BatchStateReadablePipe,
    PositionStateReadablePipe,
    PositionYToLetterPipe,
    WorkflowStepReadablePipe,
    WorkflowStepCastPipe,
    CrtStateReadablePipe,
    PathTypeReadablePipe,
    TruncatePipe,
    BatchTypeReadablePipe,
    WorkflowNamePipe,
    PlateSizePipe,
    PcrTubeSizePipe,
    WorkflowReadablePipe,
    IsWorkflowStepSkippedPipe
  ]

})
export class CustomPipeModule {
}
