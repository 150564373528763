import {EnumUtils} from './EnumUtils';
import {SelectItem} from "../models/select-item.model";

export enum Workflow {
  WORKFLOW_96 = 'WORKFLOW_96',
  WORKFLOW_384 = 'WORKFLOW_384',
  LIBRARY_96 = 'LIBRARY_96',
  LIBRARY_384 = 'LIBRARY_384'
}

export namespace Workflow {

  export function getWorkFlowReadable(workflow: Workflow): string {
    if (!workflow) {
      return '';
    }
    switch (workflow) {
      case Workflow.WORKFLOW_96:
        return 'Workflow 96';
      case Workflow.WORKFLOW_384:
        return 'Workflow 384';
      case Workflow.LIBRARY_96:
        return 'Library 96';
      case Workflow.LIBRARY_384:
        return 'Library 384';
      default:
        return '';
    }
  }

  export function getAll(): Workflow[] {
    return EnumUtils.getAll<Workflow>(Workflow, 'string');
  }

  export function getEnumByValue(value: string): Workflow {
    return EnumUtils.getEnumByValue<Workflow>(Workflow, 'string', value);
  }

  export function getAllAsSelectItem(): SelectItem[] {
    return Workflow.getAll().map(workflow => ({id: workflow, name: Workflow.getWorkFlowReadable(workflow)}));
  }

  export function getCreateWorkflowOptions(): SelectItem[] {
    return getAllAsSelectItem().filter(item => item.id === Workflow.WORKFLOW_96 || item.id === Workflow.WORKFLOW_384);
  }

  export function getCreateLibraryOptions(): SelectItem[] {
    return getAllAsSelectItem().filter(item => item.id === Workflow.LIBRARY_96 || item.id === Workflow.LIBRARY_384);
  }
}
