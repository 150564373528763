import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import {FileProcessingHistoryService} from "../../services/file-processing-history.service";
import {PageableRequestModel} from "../../models/pagable/pageable-request.model";
import {FileProcessingHistoryPageModel} from "../../models/pagable/file-processing-history-page.model";
import {FileProcessingHistoryModel} from "../../models/file/file-processing-history.model";
import {TablePaginationUtils} from "../../utils/table-pagination.utils";
import {PaginationBaseComponent} from "../pagination-base.component";
import {SortEvent} from "primeng/api";
import {TableColumnModel} from "../../models/table-column.model";
import {FileErrorType} from "../../enums/FileErrorType";
import {FileErrorCode} from "../../enums/FileErrorCode";
import {Globals} from "../../global/globals";
import {MessageService} from "../../services/message.service";
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-file-processing-history',
  templateUrl: 'file-processing-history.component.html',
  styleUrls: ['file-processing-history.component.scss', '../../../assets/table.scss']
})
export class FileProcessingHistoryComponent extends PaginationBaseComponent implements OnInit, AfterViewInit {
  FileErrorType = FileErrorType;
  FileErrorCode = FileErrorCode;

  currentPage: number = 0;
  onlyUnconfirmed = true;
  errorIcon = faTriangleExclamation;

  entries: FileProcessingHistoryModel[] = [];
  columns: TableColumnModel[];

  @Output() onConfirmPressed = new EventEmitter();

  @ViewChild('tableParentDiv', {read: ElementRef, static: false}) tableParentDiv: ElementRef;

  constructor(private fileProcessingHistoryService: FileProcessingHistoryService,
              private messageService: MessageService,
              public globals: Globals) {
    super();
  }

  ngOnInit(): void {
    this.columns = TableColumnModel.initFileProcessingHistoryTableColumns();
  }

  ngAfterViewInit(): void {
    TablePaginationUtils.calculateNumberOfRowsForTableBasedOnParentDivSizeAndFetchData(
      this.tableParentDiv,
      this.onPageSizeChange.bind(this),
      90,
      40,
      50);
  }

  onConfirm(val: FileProcessingHistoryModel) {
    this.fileProcessingHistoryService.confirm(val.id)
      .subscribe({
        next: (resp) => {
          // fine to reload here
          this.messageService.addSuccessMessage('Fehler wurde bestätigt!');
          this.reload();
          this.onConfirmPressed.emit();
        },
        error: () => {
        }
      });
  }

  reload() {
    this.fetchPage(this.currentPage);
  }

  private onPageSizeChange(size: number) {
    this.pageSize = size;
    this.first = 0;
    this.fetchPage(this.currentPage);
  }

  fetchPage(page: number): void {
    this.currentPage = page;
    if (page === 0) {
      this.first = 0;
    }
    const pageAble = new PageableRequestModel(page, this.pageSize);
    this.isLoading = true;
    this.fileProcessingHistoryService.findAll(pageAble, this.onlyUnconfirmed)
      .subscribe({
        next: (page: FileProcessingHistoryPageModel) => {
          this.entries = page.content;
          this.totalElements = page.totalElements;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  changePage(event: any): void {
    const page = event ? event.first / event.rows : 0;
    this.currentPage = page;
    this.fetchPage(page);
  }

  onSort(sortEvent: SortEvent): void {
    // there is no sorting here, by default it is sorted by the date desc
  }

}
