import { Pipe, PipeTransform } from '@angular/core';
import {PlateModel} from "../models/Plate/plate.model";
import {IWorkflowStepBase} from "../models/workflow-step/workflow-step-base.model";

@Pipe({
  name: 'workflowName'
})
export class WorkflowNamePipe implements PipeTransform {

  transform(workflowStep: IWorkflowStepBase, plate: PlateModel): string {
    if (!workflowStep) {
      return '';
    }

    if (plate && plate.suffix != null) {
      return workflowStep.workflowInstance.workflowNumber + '_' + plate.suffix;
    }

    return workflowStep.workflowInstance.workflowNumber;
  }

}
