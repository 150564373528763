import {Component, Input, OnInit} from '@angular/core';
import {UnsubscribeBaseComponent} from "../../../unsubscribe-base.component";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {Workflow} from 'src/app/enums/Workflow';
import {WorkflowStep} from 'src/app/enums/WorkflowStep';
import {BatchModel} from "../../../../models/batch/batch.model";
import {takeUntil} from "rxjs";
import {BatchType} from "../../../../enums/BatchType";
import {WorkflowInstanceService} from "../../../../services/workflow-instance.service";
import {FileService} from "../../../../services/file.service";
import {MessageService} from "../../../../services/message.service";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {WorkflowStepLibraryQubitModel} from "../../../../models/workflow-step/workflow-step-library-qubit.model";
import {BatchUtils} from "../../../../utils/batch.utils";

@Component({
  selector: 'app-library-qubit',
  templateUrl: './library-qubit.component.html',
  styleUrls: ['./library-qubit.component.scss']
})
export class LibraryQubitComponent extends UnsubscribeBaseComponent implements OnInit {

  Workflow = Workflow;
  WorkflowStep = WorkflowStep;

  readonly CONCENTRATION = 'concentration'
  concentrationForm: FormArray;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepLibraryQubitModel;
  @Input() readOnly: boolean = false;

  batchFormArray: UntypedFormArray;
  batchTypes: BatchType[] = [];

  constructor(private workflowInstanceService: WorkflowInstanceService,
              private fileService: FileService,
              private messageService: MessageService,
              public workflowDetailClientService: WorkflowDetailClientService) {
    super();
  }

  ngOnInit() {
    this.initConcentrationForm();
    this.batchTypes = BatchType.getAllForQubit();
    this.batchFormArray = BatchUtils.initBatchFormArray(this.workflowStep.batches, this.batchTypes, this.readOnly);
    this.validateStep();
  }

  private initConcentrationForm(): void {
    this.concentrationForm = new FormArray([]);
    this.workflowStep.concentrations.forEach(concentration => {
      const formGroup = new FormGroup({
        id: new FormControl(concentration.id),
        ad: new FormControl(concentration.ad),
        dilution: new FormControl(concentration.dilution),
        concentration: new FormControl(concentration.concentration, [Validators.required])
      });
      this.concentrationForm.push(formGroup);
      formGroup.get(this.CONCENTRATION).valueChanges.pipe(takeUntil(this.unSubscribe))
        .subscribe(value => {
          const foundConcentration = this.workflowStep.concentrations.find(c => c.id === formGroup.get('id').value);
          if (foundConcentration) {
            foundConcentration.concentration = value;
          }
          this.validateStep();
        });
    });
  }

  getFormGroupFromAbstractControl(form: AbstractControl): UntypedFormGroup {
    return form as UntypedFormGroup;
  }

  validateStep(): void {
    this.workflowStep.batches = BatchModel.getBatchesFromBatchSelectionFormArray(this.batchFormArray);
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

}
