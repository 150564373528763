import {Component, Input, OnInit} from '@angular/core';
import {
  WorkflowStepQuantificationFirstModel
} from "../../../../models/workflow-step/workflow-step-quantification-first.model";
import {PositionModel} from "../../../../models/Plate/position.model";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {BatchType} from "../../../../enums/BatchType";
import {PlateMode} from "../../../../enums/PlateMode";
import {UntypedFormArray} from "@angular/forms";
import {BatchModel} from "../../../../models/batch/batch.model";
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";

@Component({
  selector: 'app-quantification-first',
  templateUrl: './quantification-first.component.html',
  styleUrls: ['./quantification-first.component.scss']
})
export class QuantificationFirstComponent implements OnInit {
  BatchType = BatchType;
  PlateMode = PlateMode;
  batchFormArray: UntypedFormArray;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepQuantificationFirstModel;
  @Input() readOnly: boolean = false;
  selectedPosition: PositionModel;

  constructor(public workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.batchFormArray = BatchModel.initFormArrayForBatchSelection(this.workflowStep.batches, true, false);
    this.validateStep();
  }

  onCellClick(position: PositionModel) {
    this.selectedPosition = position;
  }

  validateStep() {
    this.workflowStep.batches = BatchModel.getBatchesFromBatchSelectionFormArray(this.batchFormArray);
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

}
