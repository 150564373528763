import {Component, Input, OnInit} from '@angular/core';
import {Workflow} from 'src/app/enums/Workflow';
import {WorkflowStep} from 'src/app/enums/WorkflowStep';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {WorkflowStepLibraryAgilantModel} from "../../../../models/workflow-step/workflow-step-library-agilant.model";
import {WorkflowInstanceService} from "../../../../services/workflow-instance.service";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {AbstractControl, FormControl, FormGroup, UntypedFormArray, UntypedFormGroup, Validators} from "@angular/forms";
import {FormUtil} from "../../../../utils/form.util";
import {FileService} from "../../../../services/file.service";
import {FileType} from "../../../../enums/FileType";
import {MessageService} from "../../../../services/message.service";
import {BatchType} from "../../../../enums/BatchType";
import {BatchModel} from "../../../../models/batch/batch.model";
import {UnsubscribeBaseComponent} from "../../../unsubscribe-base.component";
import {FileUploadValidator} from "../../../../validators/file-upload-validator";
import {BatchUtils} from "../../../../utils/batch.utils";

@Component({
  selector: 'app-library-agilant',
  templateUrl: './library-agilant.component.html',
  styleUrls: ['./library-agilant.component.scss']
})
export class LibraryAgilantComponent extends UnsubscribeBaseComponent implements OnInit {
  Workflow = Workflow;
  WorkflowStep = WorkflowStep;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepLibraryAgilantModel;
  @Input() readOnly: boolean = false;


  readonly NEW_FILES = 'newFiles';
  filesForm: FormGroup;

  batchFormArray: UntypedFormArray;
  batchTypes: BatchType[] = [];
  BatchType = BatchType;

  constructor(private workflowInstanceService: WorkflowInstanceService,
              public workflowDetailClientService: WorkflowDetailClientService,
              private fileService: FileService,
              private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.initFilesForm();
    this.batchTypes = BatchType.getAllForAgilant();
    this.batchFormArray = BatchUtils.initBatchFormArray(this.workflowStep.batches, this.batchTypes, this.readOnly);
    this.validateStep();
  }

  private initFilesForm(): void {
    this.filesForm = new FormGroup({
      newFiles: new FormControl(null, [Validators.required, FileUploadValidator.maxFileSizeValidator(10000000)])
    });
  }

  getFormGroupFromAbstractControl(form: AbstractControl): UntypedFormGroup {
    return form as UntypedFormGroup;
  }

  saveUpload(): void {
    if (this.filesForm.valid && Object.values(this.filesForm.value[this.NEW_FILES])?.length) {
      const documentsForm: FormData = FormUtil.toFormData(this.filesForm.value);

      this.fileService.uploadFiles(FileType.INTEGRA, documentsForm)
        .subscribe(
          resp => {
            this.workflowStep.file = resp[0];
            this.initFilesForm();
            this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
          }
        );
    } else {
      FormUtil.markFormControlsAsTouchedAndDirty(this.filesForm);
      this.messageService.addErrorMessage('Bitte wählen Sie zuerst Dateien aus!');
    }
  }

  onRemoveFile() {
    this.workflowStep.file = null;
    this.workflowDetailClientService.silentSave();
    this.validateStep();
  }

  validateStep(): void {
    this.workflowStep.batches = BatchModel.getBatchesFromBatchSelectionFormArray(this.batchFormArray);
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

}
